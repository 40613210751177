// @flow

import React, {useState, useEffect} from "react";

import {
  Container,
  Grid,
  Card,
} from "tabler-react";

import SiteWrapper from "../SiteWrapper.react";
import './ProfileData.scss'
import UserForm from '../Users/UserManage.form.react'

import {userService} from '../_services'

const INIT_USER = {
  id: '',
  username: '',
  name: '',
  surname: '',
  companyId: '',
  type: '',
  avatarUrl: null
}

function ProfileData() {
  const [user, setUser] = useState(INIT_USER)

  useEffect( () => {
    const auth = localStorage.getItem("auth");
    userService.profile(auth).then(
      user => {
        setUser(user)
      },
      error => {
        console.log(error);
        return error;
      }
    );
  }, [])

  const {id, username, name, surname, companyId, type, avatarUrl} = user

  console.log(user)

  return (
    <SiteWrapper>
      <div className="my-3 my-md-5">
        <Container>
          <Grid.Row>
            <Grid.Col lg={12}>
            <Card>
                <Card.Body>
                   <Card.Title>Edit Profile</Card.Title>
                   <Grid.Row>
                      <Grid.Col sm={12} md={12}>
                        {
                          user !== INIT_USER &&
                          <UserForm
                            isEditRequest={true}
                            userId={id}
                            username={username}
                            name={name}
                            surname={surname}
                            companySelected={companyId}
                            typeSelected={type}
                            userAvatarUrl={avatarUrl}
                            resetForm={null}
                            callbackOnSave={null}
                          />
                        }
                      </Grid.Col>
                    </Grid.Row>
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Container>
      </div>
    </SiteWrapper>
  );
}

export default ProfileData;
