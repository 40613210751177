
import React, { useState, useEffect } from 'react';

import {
  Button,
  Form
} from "tabler-react";
import { assetService, geoInfoService } from '../_services';

function PanelDataWrongFieldReversedReact(props) {

  const [fields, setFields] = useState([])
  const [selected, setSelected] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    setFields(['city', 'district', 'region', 'address'])
  }, [])

  const handleField = (field = '') => {
    if(field !== ''){
      setLoading(true)
      field = field.toLowerCase()
      assetService.getWrongReverseByField(field).then(
        result => {
          setLoading(false)
        },
        error => {
          setLoading(false)
          console.error("Error: " + error)
        }
      )
    }
  }

  return (
    <Form.Group label="Export Wrong Field Reversed">
      <div className="row">
        <div className="col-8">
          <Form.Select onChange={ev => setSelected(ev.target.value)} loading={loading}>
            <option selected={selected === ''}>
              Select Field
            </option>
            {fields.map(item => <option value={item} selected={selected===item}>{item.toUpperCase()}</option> )}
          </Form.Select>
        </div>
        <div className="col-4">
          <Button color="primary" className="ml-2" icon="download" disabled={selected === ''} onClick={() => handleField(selected)} loading={loading}>Export Xls</Button>
        </div>
      </div>
    </Form.Group>
  )

}

export default PanelDataWrongFieldReversedReact;
