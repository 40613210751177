import { authHeader } from "../_helpers";
import { userService } from "."
import {defineConfig} from '../config'

export const shareService = {
  getApiPathDooh,
  createShare,
  getSharesByResource,
  updateShare,
  deleteShare,
  tokenAuth
};

const {apiUrl, apiVer} = defineConfig.SHARE_SERVICE

function getApiPathDooh(){
  return (apiUrl+apiVer)
}

function createShare(shareObj) {
    const url = apiUrl+apiVer+"/share/create";
  
    const requestOptions = {
      method: "POST",
      headers: authHeader("application/json"),
      body: JSON.stringify(shareObj)
    };
  
    console.log(requestOptions)
  
    return fetch(
      url,
      requestOptions
    )
      .then(handleResponse)
      .then(res => {
        return res;
      });
}

function deleteShare(id) {
    const url = apiUrl+apiVer+"/share/delete?id=" + id;
  
    const requestOptions = {
      method: "DELETE",
      headers: authHeader("application/json"),
    };
  
    console.log(requestOptions)
  
    return fetch(
      url,
      requestOptions
    )
      .then(handleResponse)
      .then(res => {
        return res;
      });
}

function getSharesByResource(resourceId, resourceType) {
    const url = apiUrl+apiVer+"/share/byResource?resourceId="+resourceId+"&resourceType="+resourceType;
  
    const requestOptions = {
      method: "GET",
      headers: authHeader("application/json"),
    };
  
    return fetch(
      url,
      requestOptions
    )
      .then(handleResponse)
      .then(res => {
        return res;
      });
}

function updateShare(shareObj) {
    const url = apiUrl+apiVer+"/share/update?id=" + shareObj.id;
  
    const requestOptions = {
      method: "POST",
      headers: authHeader("application/json"),
      body: JSON.stringify(shareObj)
    };
  
    console.log(requestOptions)
  
    return fetch(
      url,
      requestOptions
    )
      .then(handleResponse)
      .then(res => {
        return res;
      });
}

function tokenAuth(resourceId, resourceType, token) {
    const url = apiUrl+apiVer+"/token/auth?resourceId="+resourceId+"&resourceType="+resourceType+"&token="+token;
  
    const requestOptions = {
      method: "POST",
    //   headers: authHeader("application/json"),
    };
  
    console.log(requestOptions)
  
    return fetch(
      url,
      requestOptions
    )
      .then(handleResponse)
      .then(res => {
        return res;
      });
}


function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {

      switch(response.status){
        case 401:
          // auto logout if 401 response returned from api
          userService.logout();
          window.location.replace("/404");
          break;
        case 422:
          return data;
        default:
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
