// @flow

import * as React from "react";

import {
  Grid,
  Icon,
  Form
} from "tabler-react";

import './Pagination.scss'

class Campaigns extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            selectedOption: '1',
            numberPages: 0,
            offsetPageDisp : 0,
            carryPageDisp : 0
        }
        this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
    }

    componentDidMount(){
        // console.log (this.props)
        this.setState({
            selectedOption : this.props.activePage,
            numberPages: Math.ceil(this.props.totalItemsCount/this.props.itemsCountPerPage),
            offsetPageDisp : Math.floor(this.props.maxPageRangeDisplayed/2)
        })
        // console.log('Numero pagine ' +Math.ceil(this.props.totalItemsCount/this.props.itemsCountPerPage))
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.totalItemsCount!==this.props.totalItemsCount){
            this.setState({
                selectedOption : nextProps.activePage,
                numberPages: Math.ceil(nextProps.totalItemsCount/nextProps.itemsCountPerPage),
                offsetPageDisp : Math.floor(nextProps.maxPageRangeDisplayed/2)
            })
        }
    }

    onRadioBtnClick(ev) {
        if(ev.target.value <= this.state.numberPages && ev.target.value > 0 && ev.target.value != this.state.selectedOption) {
            // console.log('pagina selezionata: '+ ev.target.value)
            this.setState({ selectedOption : ev.target.value });
            this.props.handlePageChange(ev.target.value)
        }
    }

    onBtnClick(pageRequested) {
        if(pageRequested <= this.state.numberPages && pageRequested > 0 && pageRequested != this.state.selectedOption) {
            // console.log('pagina selezionata: '+pageRequested)
            this.setState({ selectedOption : pageRequested});
            this.props.handlePageChange(pageRequested)
        }
    }

    backwardBtn(activePage){
        if(activePage>this.state.offsetPageDisp+1)
            return(<Form.SelectGroupItem className="btn-pagination-fixed"
                label={
                    <Icon prefix="fa" name="backward"/>
                }
                name="backward"
                value="backward"
                type="radio"
                checked
                onClick={() => this.onBtnClick(1)}
            />
            )
    };
    prevBtn(activePage){
        if(activePage>this.state.offsetPageDisp+1)
            return(<Form.SelectGroupItem className="btn-pagination-fixed"
                label={
                    <Icon prefix="fa" name="caret-left"/>
                }
                name="prev"
                value="prev"
                type="radio"
                checked
                onClick={() => this.onBtnClick(activePage-1)}
            />
            )
    };
    nextBtn(activePage){
        if(activePage<this.state.numberPages-this.state.offsetPageDisp)
            return(<Form.SelectGroupItem className="btn-pagination-fixed"
                label={
                    <Icon prefix="fa" name="caret-right"/>
                }
                name="next"
                value="next"
                type="radio"
                checked
                onClick={() => this.onBtnClick(activePage+1)}
            />
            )
    };
    forwardBtn(activePage){
        if(activePage<this.state.numberPages-(this.state.offsetPageDisp))
            return(<Form.SelectGroupItem className="btn-pagination-fixed"
                label={
                    <Icon prefix="fa" name="forward"/>
                }
                name="forward"
                value="forward"
                type="radio"
                checked
                onClick={() => this.onBtnClick(this.state.numberPages)}
            />
            )
    };

  render() {
    const items = []
    let activePage = parseInt(this.state.selectedOption)
    const numberPages = this.state.numberPages
    let startVisPage = (activePage - this.state.offsetPageDisp >= 1) ? (activePage - this.state.offsetPageDisp) : 1
    let endVisPage = (activePage + this.state.offsetPageDisp <= numberPages) ? (activePage + this.state.offsetPageDisp) : numberPages

    // Pages
    for(var i=startVisPage; i<=endVisPage; i++){
        items.push(<Form.SelectGroupItem
            label={i}
            value={i}
            name="page"
            type="radio"
            onClick={(ev) => this.onRadioBtnClick(ev)}
            checked={this.state.selectedOption == i}
        />)
    }

    return (
        <Grid.Row>
            <Grid.Col sm={12}>
                <Form.Group>
                    <Form.SelectGroup pills className="text-center d-inline-block">
                        {this.backwardBtn(activePage)}
                        {this.prevBtn(activePage)}
                        {items}
                        {this.nextBtn(activePage)}
                        {this.forwardBtn(activePage)}
                    </Form.SelectGroup>
                </Form.Group>
            </Grid.Col>
        </Grid.Row>
    );
  }
}

export default Campaigns;
