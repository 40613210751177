// @flow

import * as React from "react";

import { Steps } from 'antd';

const { Step } = Steps;

class DoohStepper extends React.Component {
  render(){
    const {current, labels} = this.props
    const size = labels.length
    return(
      <>
        <Steps
          type="navigation"
          size="small"
          current={current}
          // onChange={this.onChange}
          className="site-navigation-steps"
        >
          {
            labels.map((item,index)=>{
              const status = index < current ? 'finish' : (index === current) ? 'process' : 'wait'
              return (
                <Step
                  // title={"Step " + (index+1)}
                  title={item.name}
                  // subTitle="00:00:05"
                  status={status}
                  description={item.desc}
                />
              )
            })
          }
        </Steps>
      </>
    )
  }
}

export default DoohStepper;
