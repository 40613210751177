// @flow

import * as React from "react";

import {
  Page,
  Grid,
  Card,
  Dropdown,
  Table,
  Form,
  Icon,
  Container,
  Dimmer,
  Button,
  Progress,
  Text
} from "tabler-react";

import SiteWrapper from "../SiteWrapper.react";

import HeatMap from "../components/Map/HeatMap.react"
import {audienceService} from "../_services"

class Test extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            heatmapData: []
        }
    }

    componentDidMount(){
        this.getAudience(0, 100, "")
    }

    getAudience(startAge=0, endAge=100, gender=""){
        audienceService.readAudience(startAge, endAge, gender).then(
            result => {
                const heatmapData = result.map((item)=> {
                    return {
                        lat: item.location.y,
                        lng: item.location.x,
                        weight: item.weight
                    }
                })
                this.setState({
                    heatmapData
                })
            },
            error => {
                console.error(error);
            }
        )
    }

    render() {
        const {heatmapData} = this.state

        // TO DO
        const options = (
            <React.Fragment>
                <Form.Group label="Gender">
                    <Form.Checkbox
                        isInline
                        label="Male"
                        name="gender-checkboxes"
                        value="M"
                    />
                    <Form.Checkbox
                        isInline
                        label="Female"
                        name="gender-checkboxes"
                        value="F"
                    />
                </Form.Group>
            </React.Fragment>
        )

        return (
            <SiteWrapper {...this.state}>
                <Page.Content>
                    <Page.Header title="Test Audience Map" /* TO DO options={options} */ />
                    <Grid.Row cards deck>
                        <Grid.Col sm={12}>
                            <Card.Map className="bg-light">
                                <HeatMap heatmapData={heatmapData} weight={false}/>
                            </Card.Map>
                        </Grid.Col>
                    </Grid.Row>
                </Page.Content>
            </SiteWrapper>
        )
    }
} 


export default Test;