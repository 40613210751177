// @flow

import * as React from "react";
import _ from "lodash"
import {
  Grid,
  Form,
  Card,
  Table,
  Dimmer,
  Container
} from "tabler-react";

import CircularProgressbar from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

class AuditCounters extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            data: [],
            
            wrongs: 0,
            wrongsFix: 0,
            wrongsTBFix: 0,
            
            anomaly: 0,
            anomalyFix: 0,
            anomalyTBFix: 0,

            wrongsPerc: 0,
            wrongsFixPerc: 0,
            wrongsTBFixPerc: 0,

            anomalyPerc: 0,
            anomalyFixPerc: 0,
            anomalyTBFixPerc: 0,

            isLoading: true
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.data!==this.props.data){
            this.setState({
                data: nextProps.data,
                isLoading: false
            }, () => this.getCounters())
        }
    }

    getCounters(){
        // console.log(this.state.data)
        let data = this.state.data

        // const counterGroupByStatus = _.groupBy(data, "resultKinId")

        const counterGroupByStatusBME = _.groupBy(data, "resultBmeId")

        let arWrongsFix = counterGroupByStatusBME[1]
            ? counterGroupByStatusBME[1].filter(function(item) {
                const isChecked = item.resultKinId != item.resultBmeId
                if (isChecked) {
                    return item;
                }
            })
            : []

        let arAnomalyFix = counterGroupByStatusBME[2]
            ? counterGroupByStatusBME[2].filter(function(item) {
                const isChecked = item.resultKinId != item.resultBmeId
                if (isChecked) {
                    return item;
                }
            })
            : []

        const wrongsBME = counterGroupByStatusBME[1] ? counterGroupByStatusBME[1].length : 0
        const wrongsFix = arWrongsFix.length
        const wrongsTBFix = wrongsBME - wrongsFix
        
        const anomalyBME = counterGroupByStatusBME[2] ? counterGroupByStatusBME[2].length : 0
        const anomalyFix = arAnomalyFix.length
        const anomalyTBFix = anomalyBME - anomalyFix

        this.setState({
            wrongs: wrongsBME,
            wrongsFix: wrongsFix,
            wrongsTBFix: wrongsTBFix,
            
            anomaly: anomalyBME,
            anomalyFix: anomalyFix,
            anomalyTBFix: anomalyTBFix,

            wrongsPerc: 100,
            wrongsFixPerc: (wrongsFix/wrongsBME)*100,
            wrongsTBFixPerc: (wrongsTBFix/wrongsBME)*100,

            anomalyPerc: 100,
            anomalyFixPerc: (anomalyFix/anomalyBME)*100,
            anomalyTBFixPerc: (anomalyTBFix/anomalyBME)*100,
        })
    }

    render(){
        return (
            <Grid.Row cards deck>
                <Grid.Col sm={12}>
                    <Grid.Row cards deck>
                        <Grid.Col>
                            <Dimmer active={this.state.isLoading} loader={this.state.isLoading} className="">
                                <Table striped={true} className="mb-0 table-bordered table-responsive-md bg-white mb-4 table-sm">
                                    <Table.Header>
                                        <Table.ColHeader alignContent="center">Wrong To Be Managed</Table.ColHeader>
                                        <Table.ColHeader alignContent="center">Be My Eye</Table.ColHeader>
                                        <Table.ColHeader alignContent="center">Kinetic</Table.ColHeader>
                                        <Table.ColHeader alignContent="center">Anomaly To Be Managed</Table.ColHeader>
                                        <Table.ColHeader alignContent="center">Be My Eye</Table.ColHeader>
                                        <Table.ColHeader alignContent="center">Kinetic</Table.ColHeader>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Col alignContent="center">
                                            <Grid.Col className="text-center">
                                                <div style={{ width: "2.2em", margin: "auto"}}>
                                                    <CircularProgressbar
                                                        percentage={this.state.wrongsTBFixPerc}
                                                        text={`${this.state.wrongsTBFix}`}
                                                        strokeWidth={11}
                                                        initialAnimation={0}
                                                        styles={{
                                                            path: {
                                                                // Path color
                                                                stroke: `#cd201f`,
                                                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                                strokeLinecap: "round",
                                                                // Customize transition animation
                                                                transition: "stroke-dashoffset 1.5s ease .2s",
                                                            },
                                                            text: {
                                                                fill: "#666",
                                                                fontSize: "2.8em",
                                                                fontWeight: "bold",
                                                            },
                                                        }}
                                                    />
                                                </div>
                                            </Grid.Col>
                                        </Table.Col>
                                        <Table.Col alignContent="center"><div className="p-2"><strong>{this.state.wrongs}</strong></div></Table.Col>
                                        <Table.Col alignContent="center"><div className="p-2"><strong>{this.state.wrongsFix}</strong></div></Table.Col>
                                        <Table.Col alignContent="center">
                                            <div style={{ width: "2.2em", margin: "auto"}}>
                                                <CircularProgressbar
                                                    percentage={this.state.anomalyTBFixPerc}
                                                    text={`${this.state.anomalyTBFix}`}
                                                    strokeWidth={11}
                                                    initialAnimation={0}
                                                    styles={{
                                                        path: {
                                                            // Path color
                                                            stroke: `#f1c40f`,
                                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                            strokeLinecap: "round",
                                                            // Customize transition animation
                                                            transition: "stroke-dashoffset 1.5s ease .2s",
                                                        },
                                                        text: {
                                                            fill: "#666",
                                                            fontSize: "2.8em",
                                                            fontWeight: "bold",
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </Table.Col>
                                        <Table.Col alignContent="center"><div className="p-2"><strong>{this.state.anomaly}</strong></div></Table.Col>
                                        <Table.Col alignContent="center"><div className="p-2"><strong>{this.state.anomalyFix}</strong></div></Table.Col>
                                    </Table.Body>
                                </Table>
                            </Dimmer>
                        </Grid.Col>
                    </Grid.Row>

                </Grid.Col>
            </Grid.Row>
        )
    }
}

export default AuditCounters;