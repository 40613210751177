// @flow

import * as React from "react";

import {
  Card,
  Table,
  Form,
  Alert,
  Dimmer,
  Button,
  Tag,
  Tooltip,
  Icon
} from "tabler-react";

import "./PoiManage.scss"
import { poiService, geoInfoService } from "../../_services";
import MapSearchControl from "../../components/Map/MapSearchControl.react"
import MiniMap from "../../components/Map/MiniMap.react"
import UploadIcon from "../../components/UploadIcon/UploadIcon.react"
import iconPOI from "../../components/Map/icons/poi.png"
import { toast } from 'react-toastify';

const INIT_LOCATION_SEARCH = {
  name: '',
  address: '',
  comune: '',
  brand: '',
  type: '',
  note: '',
  lat: null,
  lng: null,
  location: null,
  marker: null,
  markerId: null,
}

class PoiManageForm extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      errorMessage: "",
      success: false,
      loading: false,
      groupId: null,
      groupName: '',
      locationSearch: INIT_LOCATION_SEARCH,
      locationEdit: null,
      pois: [],
      isEditRequest: false,
      id: 0,
      inputSearchControl: null
    }
    this.handlerCenterPosition = this.handlerCenterPosition.bind(this);
    this.upsertPOI = this.upsertPOI.bind(this);
    this.addPOI = this.addPOI.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this)
    this.onChangeGroupName = this.onChangeGroupName.bind(this)
    this.onClickSave = this.onClickSave.bind(this)
    this.onClickResetForm = this.onClickResetForm.bind(this)
    this.onClickResetFormSinglePOI = this.onClickResetFormSinglePOI.bind(this)
    this.onClickRemovePOI = this.onClickRemovePOI.bind(this)
    this.onClickEditPOI = this.onClickEditPOI.bind(this)
    this.cancelEditPOI = this.cancelEditPOI.bind(this)
    this.onChangePosition = this.onChangePosition.bind(this)
    this.getReverseGeocoding = this.getReverseGeocoding.bind(this)
  }

  componentDidMount(){
    const {isEditRequest, pois, groupName, groupId} = this.props
    if(isEditRequest){
      this.setState({isEditRequest, pois, groupName, groupId, locationSearch: INIT_LOCATION_SEARCH})
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.isEditRequest!==this.props.isEditRequest){
      const {isEditRequest, pois, groupName, groupId} = nextProps
      this.setState({
        isEditRequest, pois, groupName, groupId, locationSearch: INIT_LOCATION_SEARCH
      })
    }
  }

  handlerCenterPosition(props, places) {
    let arrayPlaces = []
    for(let i=0; i<places.length;i++){
      let place = places[i]

      const brand = place.name
      const name = place.name
      const type = place.types[0] ? place.types[0] : "ND"
      const address = place.formatted_address
      const region = ""
      const district = ""
      let comune = "ND"
      const note = ""

      // Cerco il comune dal campo address_components di gmaps se definito
      if(place.address_components){
        const address = place.address_components
        for(let j=0; j<address.length && comune === "ND"; j++){
          if(address[j].types){
            const type = address[j].types[0]
            if(type === "locality"){
              comune = address[j].long_name ? address[j].long_name : "ND"
            }
          }
        }
      }

      const lat = place.geometry.location.lat()
      const lng = place.geometry.location.lng()

      // let poiGroupName = place.name + " - " + place.formatted_address
      arrayPlaces.push({type, name, brand, address, district, region, comune, note, lat, lng})
    }

    if(arrayPlaces.length>0){
      const poi = arrayPlaces[0]
      const {locationSearch} = this.state
      const idKinPoi = locationSearch.idKinPoi ? locationSearch.idKinPoi : null
      const idKinPoiGroup = locationSearch.idKinPoiGroup ? locationSearch.idKinPoiGroup : null
      const idPoiGroup = locationSearch.idPoiGroup ? locationSearch.idPoiGroup : null

      const {lat, lng} = poi
      const location = {x: lng, y: lat, type: "Point", coordinates: [lng, lat]}

      this.setState({
        locationSearch: {
          ...poi,
          location,
          idKinPoi,
          idKinPoiGroup,
          idPoiGroup
        }
      })
    }
  }

  onChangeInput(e, field){
    const {value} = e.target
    let {locationSearch} = this.state
    locationSearch[field] = value
    this.setState({
      locationSearch
    })
  }

  onChangeIconObj = (markerId, marker) => {
    let {locationSearch} = this.state
    locationSearch.markerId = markerId
    if(markerId === 'default'){
      locationSearch.marker = null
    }
    else{
      locationSearch.marker = marker
    }
    this.setState({locationSearch})
  }

  onChangePosition(position){
    const {lat, lng} = position
    this.getReverseGeocoding(lat, lng)
  }

  getReverseGeocoding(lat, lng){
    // const query = lat + ',' + lng

    geoInfoService.getArcGisInfoFromQuery(lat, lng).then(
      result => {
        let addOn = {}
        // ArcGis restituisce 200 anche se si verifica un errore
        // -- Esito positivo
        if(result.address && !result.error){
          const info = result.address
          addOn = {
            location: {x: lng, y: lat, type: "Point", coordinates: [lng, lat]},
            name: info.LongLabel,
            brand: 'ND',
            type: info.Addr_type,
            region: info.Region,
            district: info.Subregion,
            comune: info.City,
            address: info.Address,
            lat,
            lng
          }
        }
        // -- Esito negativo
        else{
          addOn = {
            location: {x: lng, y: lat, type: "Point", coordinates: [lng, lat]},
            name: 'ND',
            brand: 'ND',
            type: 'ND',
            region: 'ND',
            district: 'ND',
            comune: 'ND',
            address: 'ND',
            lat,
            lng
          }
        }

        this.setState((state)=>({
          locationSearch: {
            ...state.locationSearch,
            ...addOn
          }
        }))
      }
    )
  }

  onChangeGroupName(e){
    const {value} = e.target
    this.setState({
      groupName: value
    })
  }

  onClickSave(callbackAfterFinish = false){
    const {groupId, groupName, pois, isEditRequest} = this.state
    console.log('saving', pois)
    if(isEditRequest && (groupId !== null)){
      this.updateGroupPOI(groupId, groupName, pois, callbackAfterFinish)
    }
    else{
      this.saveGroupPOI(groupName, pois, callbackAfterFinish)
    }
  }

  onClickResetForm(){
    const {isEditRequest, groupId} = this.state
    if(isEditRequest && (groupId !== null)){
      this.props.resetForm();
    }
    else{
      this.setState({
        groupId: null,
        groupName: '',
        locationSearch: INIT_LOCATION_SEARCH,
        pois: [],
      })
    }
  }

  onClickResetFormSinglePOI(){
    this.setState({
      locationSearch: INIT_LOCATION_SEARCH,
    })
  }

  onClickRemovePOI(el){
    let pois = this.state.pois.filter((item)=> (item.name !== el.name && item.location !== el.location))
    this.setState({
      pois
    })
  }

  onClickEditPOI(el, index){
    const {locationSearch} = this.state
    if(locationSearch === INIT_LOCATION_SEARCH){
      this.setState({
        locationSearch: {
          ...el,
          lat: el.location.y,
          lng: el.location.x,
        },
        locationEdit: index
      },()=>document.getElementById('form-poi-manage').scrollIntoView())
    }
    else{
      toast.info("First save the open POI. Then retry");
    }
  }

  saveGroupPOI(groupName, pois, callbackAfterFinish = false){
    this.setState({
      errorMessage: "",
      success: false,
      loading: true
    })
    poiService.createPOIGroup(groupName, pois).then(
      result => {
        toast.success('POI Group ' +groupName+ ' created successful!');
        if(callbackAfterFinish){
          const idPoiGroup = result[0].idPoiGroup ? result[0].idPoiGroup : null
          callbackAfterFinish(idPoiGroup)
        }
        else{
          this.props.resetForm();
        }
        this.setState({
          success: true,
          loading: false,
          groupName: '',
          locationSearch: INIT_LOCATION_SEARCH,
          pois: [],
        })
      },
      error => {
        toast.error("Si è verificato un errore: " + error.toString());
        this.setState({
          errorMessage: error,
          success: false,
          loading: false
        })
        return false;
      }
    );
  }

  updateGroupPOI(poiGroupId, groupName, pois, callbackAfterFinish = false){
    // Escludo gli attributi non utili
    pois = pois.map((item, index) => {
      const {id, idKinPoi, idKinPoiGroup, idPoiGroup, type, name, brand, address, district, region, comune, note, location, marker} = item
      let {markerId} = item
      if(marker){
        if(marker.id){
          markerId = marker.id //workaround per BE
        }
      }

      return {id, idKinPoi, idKinPoiGroup, idPoiGroup, type, name, brand, address, district, region, comune, note, location, markerId }
    })
    poiService.updatePOIGroup(poiGroupId, groupName, pois).then(
      result => {
        toast.success('POI Group ' +groupName+ ' modified successful!');
        if(callbackAfterFinish){
          callbackAfterFinish(poiGroupId)
        }
        else{
          this.props.resetForm();
        }

        this.setState({
          success: true,
          loading: false,
          groupName: '',
          locationSearch: INIT_LOCATION_SEARCH,
          pois: [],
        })
      },
      error => {
        toast.error("Si è verificato un errore: " + error.toString());
        this.setState({
          errorMessage: error,
          success: false,
          loading: false
        })
        return false;
      }
    );
  }

  upsertPOI(){
    const {locationSearch, locationEdit} = this.state
    if(locationEdit === null){
      this.addPOI()
      toast.success("New POI added!")
    }
    else{
      toast.success("POI saved!")
      let pois = this.state.pois.filter((item)=>item.id !== locationSearch.id)
      this.setState({
        pois
      }, ()=>this.addPOI())
    }
  }

  addPOI(){
    const {locationSearch} = this.state
    this.setState(prevState => ({
      id: prevState.id + 1,
      pois: [...prevState.pois, locationSearch],
      locationEdit: null
    }),()=>this.setState({locationSearch: INIT_LOCATION_SEARCH}))
  }

  removePOI(el){
    let pois = this.state.pois.filter((item)=>item.name !== el)
    this.setState({ pois })
    toast.success("POI removed!")
  }

  cancelEditPOI(){
    this.setState({
      locationEdit: null,
      locationSearch: INIT_LOCATION_SEARCH
    })
  }

  getPOIGroupListPreview(previewPOIs) {
    return(
      <React.Fragment>
        {previewPOIs.length>0 &&
          <Table className={"table-hover table-sm " + (this.state.locationEdit !== null && "opacity-2")}>
            <Table.Header>
              <Table.ColHeader></Table.ColHeader>
              <Table.ColHeader>Name</Table.ColHeader>
              <Table.ColHeader>City</Table.ColHeader>
              <Table.ColHeader>Address</Table.ColHeader>
              <Table.ColHeader>Brand</Table.ColHeader>
              <Table.ColHeader>Type</Table.ColHeader>
              <Table.ColHeader>Action</Table.ColHeader>
            </Table.Header>
            <Table.Body>
              {previewPOIs && previewPOIs.map((item, index) => {
                  const {name, comune, address, brand, type, location, note, marker} = item
                  const title = 'Note: ' + note + ' | ' + 'Lat: ' + location.y + ' - Lng: ' + location.x
                  return (
                    <Table.Row title={title} className={(this.state.locationEdit === index && "table-active")}>
                      <Table.Col>
                        <img className="h-5" src={marker ? marker.fileImgix : iconPOI} alt={index}/>
                      </Table.Col>
                      <Table.Col className="font-weight-bold">
                        {name}
                      </Table.Col>
                      <Table.Col>{comune}</Table.Col>
                      <Table.Col>
                        <span className="text-truncate d-inline-block" style={{maxWidth: "150px"}} title={address}>
                          {address}
                        </span>
                      </Table.Col>
                      <Table.Col>
                        <span className="text-truncate d-inline-block" style={{maxWidth: "100px"}} title={brand}>
                          {brand}
                        </span>
                      </Table.Col>
                      <Table.Col>
                        <Tag>{type}</Tag>
                      </Table.Col>
                      <Table.Col>
                        <Button.List>
                          <Button color="primary" outline size="sm" icon="edit" className="p-1" disabled={this.state.locationEdit !== null} onClick={()=>this.onClickEditPOI(item, index)}/>
                          <Button color="danger" outline size="sm" icon="x" className="p-1" disabled={this.state.locationEdit !== null} onClick={()=>this.onClickRemovePOI(item)}/>
                        </Button.List>
                      </Table.Col>
                    </Table.Row>
                  )
              })}
            </Table.Body>
          </Table>
        }

      </React.Fragment>
    )
  }

  render(){
    const {errorMessage, locationSearch, groupName} = this.state
    const isEditRequest = this.state.isEditRequest && this.state.groupId !== null
    const issetLocation = (locationSearch===INIT_LOCATION_SEARCH)

    const buttonListSave = (
      <React.Fragment>
        <div className="row">
          <div className="col-12 text-right align-self-center">
            <Button.List>
              { isEditRequest &&
                <Button
                    color="danger"
                    onClick={this.onClickResetForm}
                    disabled={this.state.loading || this.state.locationSearch !== INIT_LOCATION_SEARCH}
                  >
                    Cancel
                </Button>
              }
              <Button
                color="primary"
                onClick={()=>this.onClickSave(false)}
                disabled={this.state.groupName === "" || this.state.pois.length === 0 || this.state.locationSearch !== INIT_LOCATION_SEARCH}
                loading={this.state.loading}
                outline={!!this.props.callbackOnSave}
              >
                {isEditRequest ? "Save changes" : "Save"}
              </Button>{' '}
              {
              this.props.callbackOnSave &&
                <Button
                  color="primary"
                  onClick={()=>this.onClickSave(this.props.callbackOnSave)}
                  disabled={this.state.groupName === "" || this.state.pois.length === 0 || this.state.locationSearch !== INIT_LOCATION_SEARCH}
                  loading={this.state.loading}
                >
                  {isEditRequest ? "Save changes and use" : "Save and use"}
                </Button>
              }
            </Button.List>
          </div>
        </div>
      </React.Fragment>
    )

    return(
      <div>
        <div className="row" id="form-poi-manage">
          <div className="col-6">
            <Form.Group
              label="Group Name"
            >
              <Form.Input className={"mb-3 " + (groupName.trim() === "" && "border border-primary")} placeholder="Name..." value={groupName} required onChange={this.onChangeGroupName} />
            </Form.Group>
          </div>
          <div className="col-6 align-self-center">
            <Dimmer className="dimmer-opacity" active={groupName.trim()===""} loader={false}>
              {buttonListSave}
            </Dimmer>
          </div>
          <div className="col-12 pb-5">
            <Dimmer className={"dimmer-opacity " + (this.state.locationSearch !== INIT_LOCATION_SEARCH && "shadow border border-info")} active={groupName.trim()===""} loader={false}>
              <Form.FieldSet className="mb-0">
                <div className="row">
                  <div className="col-6 mb-1">
                    <Form.Group
                      label="Search POI by Kinetic Map"
                    >
                      <MapSearchControl
                        handlerCenterPosition={(places)=>this.handlerCenterPosition(this, places)}
                        onReset={this.onClickResetFormSinglePOI}
                        placeholder="City, Address, ..."
                        key={this.state.id}
                      />
                    </Form.Group>
                    <Card.Map className="border rounded mt-2 card-map-poi-manage">
                      {this.state.locationSearch ?
                        <MiniMap key={locationSearch.lat + '-' + locationSearch.lng} lat={locationSearch.lat} lng={locationSearch.lng} zoomControl={true} draggable={true} parentChangePosition={this.onChangePosition}/>
                        :
                        <MiniMap lat={null} lng={null} zoomControl={true}/>
                      }
                    </Card.Map>
                  </div>
                  <div className="col-6 mb-1 z-index-1">
                    <div className="row">
                      <div className={"col-8 " + (!locationSearch ? 'invisible' : '')}>

                          {locationSearch &&
                            <small>
                              <div className="row">
                                <div className="col">
                                  <strong>Lat</strong>
                                  <br/>
                                  <code className="px-1 py-1 my-3 d-block">{locationSearch.lat ? locationSearch.lat : 'No selected'}</code>
                                </div>
                                <div className="col">
                                  <strong>Lng</strong>
                                  <br/>
                                  <code className="px-1 py-1 my-3 d-block">{locationSearch.lng ? locationSearch.lng : 'No selected'}</code>
                                </div>
                              </div>
                            </small>
                          }

                      </div>
                      <div className="col-4 align-self-center">
                        {
                          this.state.locationEdit === null ?
                              <Button className="mt-4" color={!issetLocation ? 'primary' : 'secondary'} size="sm" block onClick={this.upsertPOI} disabled={issetLocation}>Add POI</Button>
                            :
                              <Button.List>
                                <Button className="mt-4" color="secondary" size="sm" onClick={this.cancelEditPOI}>Cancel</Button>
                                <Button className="mt-4" color={!issetLocation ? 'primary' : 'secondary'}  size="sm" onClick={this.upsertPOI} disabled={issetLocation}>Save POI</Button>
                              </Button.List>
                        }
                      </div>
                    </div>

                    {/* <Form.Group
                      label={<br/>}
                      className="mb-2 mt-1"
                    >

                    </Form.Group> */}
                    <Form.Group
                      label={'Icon'}
                      className="mb-2"
                    >
                      <UploadIcon disabled={issetLocation} handleChangeIcon={this.onChangeIconObj} selected={locationSearch.marker ? locationSearch.marker.id : "default"}/>
                    </Form.Group>
                    <div className="row">
                      <div className="col">
                        <Form.Group
                          label={'Name'}
                          className="mb-2"
                        >
                          <Form.Input
                            disabled={issetLocation}
                            value={locationSearch ? locationSearch.name : ''}
                            onChange={(e)=>this.onChangeInput(e, 'name')}
                          />
                        </Form.Group>
                      </div>
                      <div className="col">
                        <Form.Group
                          label="Brand"
                          className="mb-2"
                        >
                          <Form.Input
                            disabled={issetLocation}
                            value={locationSearch ? locationSearch.brand : ''}
                            onChange={(e)=>this.onChangeInput(e, 'brand')}
                          />
                        </Form.Group>
                      </div>
                    </div>


                    <Form.Group
                      label="Address"
                      className="mb-2"
                    >
                      <Form.Input
                        disabled={issetLocation}
                        value={locationSearch ? locationSearch.address : ''}
                        onChange={(e)=>this.onChangeInput(e, 'address')}
                      />
                    </Form.Group>
                    <Form.Group
                      label="City"
                      className="mb-2"
                    >
                      <Form.Input
                        disabled={issetLocation}
                        value={locationSearch ? locationSearch.comune : ''}
                        onChange={(e)=>this.onChangeInput(e, 'comune')}
                      />
                    </Form.Group>
                    <Form.Group
                      label="Type"
                      className="mb-2"
                    >
                      <Form.Input
                        disabled={issetLocation}
                        value={locationSearch ? locationSearch.type : ''}
                        onChange={(e)=>this.onChangeInput(e, 'type')}
                      />
                    </Form.Group>

                  </div>
                </div>
              </Form.FieldSet>
            </Dimmer>
          </div>
        </div>
        <Dimmer className="dimmer-opacity" active={groupName.trim()===""} loader={false}>
          <div className="row">
            <div className="col-8 offset-2 text-center">
              {
                errorMessage!=="" &&
                <Alert type="danger">
                  {errorMessage}
                </Alert>
              }
            </div>
          </div>
          <div className="row">
            <div className="col">
              {this.getPOIGroupListPreview(this.state.pois)}
            </div>
          </div>
          {buttonListSave}
        </Dimmer>
      </div>
    )
  }

}

export default PoiManageForm;
