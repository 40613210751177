// @flow

import * as React from "react";

import iconPOI from "../../components/Map/icons/poi.png"
import iconError from "./brokenImage.png"

import {
  Form,
  Button,
  Dimmer
} from "tabler-react";

import {toast} from "react-toastify"
import {poiService} from '../../_services/poi.service'


import './UploadIcon.scss'

class PoiManageUploadIcon extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      icons: [],
      issetFile: null,
      loadingUpload: false,
      iconSelected: "default",
      disabled: true
    }

    this.handleChangeUploadIcon = this.handleChangeUploadIcon.bind(this)
    this.onUploadSubmit = this.onUploadSubmit.bind(this)
    this.onChangeIcon = this.onChangeIcon.bind(this)
  }

  componentWillMount(){
    this.getAllIcons()
  }

  componentDidMount(){
    const {selected, disabled} = this.props
    console.log('componentDidMount', selected)
    this.setState({
      iconSelected: selected,
      disabled
    })
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.selected!==this.props.selected){
      const {selected} = nextProps
      this.setState({
        iconSelected: selected
      })
    }

    if(nextProps.disabled!==this.props.disabled){
      const {disabled} = nextProps
      this.setState({
        disabled
      })
    }
  }

  getAllIcons(){
    poiService.readMarkersIcons().then(
      result => {
        console.log(result)
        this.setState({
          icons: result
        })
      },
      error => {
        toast.error('Error: ' + error.toString())
        console.error(error);
      }
    )
  }

  getUrlPhotoBE(filename){
    return poiService.getImageMarkerByName(filename)
  }


  handleChangeUploadIcon(ev){
    this.setState({
      issetFile: ev.target.files,
    })
  }

  onChangeIcon(ev){
    const {value} = ev.target
    console.log(value)
    this.setState({
      iconSelected: value
    })

    const {icons} = this.state
    let icon = icons.filter(ico => ico.id === value)
    icon = icon.length > 0 ? icon[0] : null

    this.props.handleChangeIcon(value, icon)
  }

  onUploadSubmit(e){
    e.preventDefault() // Stop form submit
    this.setState({
      loadingUpload: true
    })

    let formData = new FormData()
    const file = this.state.issetFile[0]

    formData.append("name", 'marker_'+Date.now());
    formData.append("file", file, file.name);

    poiService.createMarkerIcon(formData).then(
      result => {
        console.log(result)
        this.getAllIcons()
        this.setState({
          loadingUpload: false
        })
      },
      error => {
        toast.error(error)
        this.setState({
          loadingUpload: false
        })
      }
    );
  }

  render(){
    const {icons, loadingUpload, iconSelected, disabled} = this.state

    return(
      <div className="row upload-icon-tool">
        <div className="col-8">
          <Dimmer active={disabled} loader={loadingUpload}>
            <Form.SelectGroup pills className="bg-white border" onChange={this.onChangeIcon}>
              <Form.SelectGroupItem
                name="iconPoi"
                value="default"
                checked={iconSelected === 'default'}
                label={<div className="img-fluid-contain rounded-circle" style={{ backgroundImage: `url(${iconPOI})`}} />}
              />

              {
                icons.map(icon =>{
                  return(
                    <Form.SelectGroupItem
                      name="iconPoi"
                      value={icon.id}
                      checked={iconSelected === icon.id}
                      label={<div className="img-fluid-contain rounded-circle" style={{ backgroundImage: `url(${icon.fileImgix})`}} />}
                    />
                  )
                })
              }
            </Form.SelectGroup>
          </Dimmer>
        </div>
        <div className="col-4">
          <Dimmer active={disabled}>
            <div className="row">
              <div className="col-12 align-self-center">
                <Form.Group className="mb-0">
                  <Form.FileInput accept=".png" label="Browse png icon" disabled={disabled} onChange={this.handleChangeUploadIcon}/>
                </Form.Group>
              </div>
              <div className="col-12 align-self-center">
                <Button color="secondary" size="sm" block icon="upload" onClick={this.onUploadSubmit} disabled={this.state.issetFile===null} loading={loadingUpload}>
                  Upload
                </Button>
              </div>
            </div>
          </Dimmer>
        </div>
      </div>

    )
  }
}


export default PoiManageUploadIcon;
