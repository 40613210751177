import * as React from "react";
import { Button, Icon, Stamp, Badge as BadgeTabler } from "tabler-react"
import { Table, Badge, List, Checkbox, Dropdown, Button as ButtonAntd, Popover, Modal, Input, Space} from 'antd';
import { EnvironmentOutlined, DesktopOutlined, LoadingOutlined, FilterFilled, AudioOutlined, AudioMutedOutlined, SettingOutlined, FilterOutlined, DotChartOutlined, SearchOutlined } from '@ant-design/icons';

import styles from 'antd/dist/antd.css';

import { doohService, audienceService } from '../_services'
import { toast } from "react-toastify";

import MapFilterSlider from '../components/Map/MapFilterSlider.react'

import HeatMapTableAudience from '../Planner/Planner.HeatMapTableAudience.react'
import AudienceIndexMinMax from './AudienceIndexMinMax.react'


import kineticDB, {
  bulkcreate
} from '../database/DoohData.db';


import _ from 'lodash'

function sortNames(a, b) {
  if(a !== null && !isNaN(a)){
    a = a.toString()
  }
  if(b !== null &&  !isNaN(b)){
    b = b.toString()
  }
  var nameA = a && a.toUpperCase(); // ignora maiuscole e minuscole
  var nameB = b && b.toUpperCase(); // ignora maiuscole e minuscole
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // i nomi devono essere uguali
  return 0;
}


const expandable = { expandedRowRender: record => <p>Address: {record.site}</p> };
const title = () => 'Here is title';
const showHeader = true;
const footer = () => 'Here is footer';
const pagination = { position: 'bottom' };

const DEFAULT_VALUES = {
  type: [],
  installation: [],
  region: [],
  district: [],
  city: [],
  postalCode: [],
  publisher: [],
  adsDuration: [],
  audio: [],
  mediaOwner:  [],
  dataType: [],
  videoType: [],
  videoFormat: [],
  orientation: [],
  screenFormat: [],
  screenType: [],
  cinema: [],
  gdo: [],
  note: []
  
}

const INIT_COLUMNS = [
  {
    field: 'type',
    label: 'Environment',
    visible: true
  },
  {
    field: 'installation',
    label: 'Type',
    visible: true
  },
  {
    field: 'publisher',
    label: 'Publisher',
    visible: true
  },
  {
    field: 'region',
    label: 'Region',
    visible: true
  },
  {
    field: 'district',
    label: 'District',
    visible: true
  },
  {
    field: 'city',
    label: 'City',
    visible: true
  },
  {
    field: 'postalCode',
    label: 'Postal code',
    visible: true
  },
  {
    field: 'audio',
    label: 'Audio',
    visible: true
  },
  {
    field: 'address',
    label: 'Address',
    visible: true
  },
  {
    field: 'adsDuration',
    label: 'Ads Duration',
    visible: true
  },
  {
    field: 'mediaOwner',
    label: 'Media Owner',
    visible: true
  },
  {
    field: 'dataType',
    label: 'Data Type',
    visible: false
  },
  {
    field: 'videoType',
    label: 'Video Type',
    visible: false
  },
  {
    field: 'videoFormat',
    label: 'Video Format',
    visible: false
  },
  {
    field: 'orientation',
    label: 'Orientation',
    visible: true
  },
  {
    field: 'index',
    label: 'Index',
    visible: true,
    disabled: true
  },
  {
    field: 'screenFormat',
    label: 'Screen Format',
    visible: false
  },
  {
    field: 'screenType',
    label: 'Screen Type',
    visible: false
  },
  {
    field: 'cinema',
    label: 'Cinema',
    visible: false
  },
  {
    field: 'gdo',
    label: 'GDO',
    visible: false
  },
  {
    field: 'note',
    label: 'Note',
    visible: false
  },
]

const db = kineticDB('kineticDB', {
  doohData: `++id`
});

class DoohPlanCart extends React.Component {
  state = {
    bordered: false,
    loading: true,
    loadingAudience: false,
    loadingValues: false,
    pagination,
    size: 'default',
    expandable: undefined,
    title: undefined,
    showHeader,
    footer: undefined,
    rowSelection: {},
    selectedRowKeys: [],
    scroll: { y: 600 },
    hasData: true,
    tableLayout: undefined,
    top: 'none',
    bottom: 'bottomRight',

    isFormHidden: true,

    visibleDropdownColumns: false,
    activeColumns: INIT_COLUMNS,

    data: [],
    dataAll: [],
    filters: {},
    // sortedInfo: null,
    cart: [],
    showCart: false,

    audiences:[],
    audienceFilter: null,

    hideExcluded: true,
    indexMin: null,
    indexMax: null,
    lockFilter: null,
    lockFilterValues: null,

    values: DEFAULT_VALUES
  };

  constructor(props){
    super(props)

    this.handleToggleForm = this.handleToggleForm.bind(this)
    this.handleFilterDropdown = this.handleFilterDropdown.bind(this)
    this.getValuesByField = this.getValuesByField.bind(this)
    this.addToCart = this.addToCart.bind(this)
    this.resetSelected = this.resetSelected.bind(this)
    this.resetValues = this.resetValues.bind(this)
    this.resetFilters = this.resetFilters.bind(this)
    this.getColumnTemplate = this.getColumnTemplate.bind(this)
  }

  componentWillMount(){
    const {cart, readonly} = this.props

    if(!readonly){
      this.getDooh()
    }
    else{
      this.setState({loading: false})
    }

    this.setState({
      cart
    })
  }

  componentDidMount(){
    const {audienceFilter} = this.props

    this.setState({
      audienceFilter
    }, () => audienceFilter !== null && setTimeout(this.getAudienceIndexes, 2000))
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.cart!==this.props.cart){
      this.setState({
        cart: nextProps.cart,
      })
    }
    if(nextProps.audienceFilter !== this.props.audienceFilter){
      this.setState({
        audienceFilter: nextProps.audienceFilter,
        loadingAudience: true
      }, () => setTimeout(this.getAudienceIndexes, 2000))
    }
  }

  strToCapitalize(str){
    return str.replace(/\b[a-zA-Z]/g, (match) => match.toUpperCase())
  }

  getDooh(){
    doohService.getAllDooh().then(
      result => {
        result.map((el, index) => {
          el.idKinFace = el.idKinFrame
          return el.key = el.id;
        });

        bulkcreate(db.doohData, result, true);
        this.setState({
          data: result,
          dataAll: result
        }, ()=> this.setState({loading: false}))
      },
      error => {
        toast.error('Error: ' + error)
      }
    )
  }

  getAudienceIndexes = () => {
    const {readonly} = this.props;
    const {dataAll, cart, audienceFilter} = this.state;
    if(typeof audienceFilter === "undefined" || audienceFilter === null){
      this.setState({
        loadingAudience: false
      });
      // TODO: Empty indexes
      return;
    }

    let ids = []
    if(readonly){
      ids = cart.map((d) => d.parentFrame || d.idKinFace)
      ids = _.uniq(ids)
      this.getIndexesAudienceByIdsCart(ids, audienceFilter.id)
    }
    else{
      ids = dataAll.map((d) => d.childrenIdKinFrame === null ? (d.idKinFace) : d.childrenIdKinFrame)
      ids = _.flatten(ids)

      this.getIndexesAudienceByIds(ids, audienceFilter.id)
    }
  }

  getIndexesAudienceByIds = (ids, audienceId) => {
    let {dataAll} = this.state

    if(!audienceId){
      const dataWithAudienceNull = dataAll.map(a => {
        a.index = null;
        return a;
      })
      this.setState({
        audiences: [],
        dataAll: dataWithAudienceNull,
        data: dataWithAudienceNull,
      })
      return null;
    }

    // this.getAudienceDetails(audienceId);

    audienceService.getIndexesAudienceByIds(ids, audienceId, 'dooh').then(
      result => {
        const dataWithAudience = dataAll.map(a => {
          if(a.childrenIdKinFrame === null){
            let audienceFound = result.find(r => (r.faceId === a.idKinFace) || (a.parentFrame !== null && r.faceId === a.parentFrame))
            a.index = typeof audienceFound !== 'undefined' ? audienceFound.index : null;
          }
          else{
            let audienceFound = result.filter(r => a.childrenIdKinFrame.includes(r.faceId))
            const indexesAud = audienceFound.map(aud => aud.index)
            const minIndex = _.min(indexesAud)
            const maxIndex = _.max(indexesAud)
            a.minIndex = minIndex
            a.maxIndex = maxIndex
          }
          return a;
        })

        this.setState({
          data: dataWithAudience,
          audiences: result,
          loadingAudience: false
        })
      },
      error => {
        toast.error('Error: ' + error);
        const dataWithAudienceNull = dataAll.map(a => {
          delete a.minIndex
          delete a.maxIndex
          a.index = null;
          return a;
        })
        this.setState({
          audiences: [],
          data: dataWithAudienceNull,
          dataAll: dataWithAudienceNull,
          loadingAudience: false
        })
      }
    )
  }

  getIndexesAudienceByIdsCart = (ids, audienceId) => {
    let {cart} = this.state

    if(!audienceId){
      const cartWithAudienceNull = cart.map(a => {
        a.index = null;
        return a;
      })
      this.setState({
        audiences: [],
        cart: cartWithAudienceNull,
      })
      return null;
    }

    // this.getAudienceDetails(audienceId);

    audienceService.getIndexesAudienceByIds(ids, audienceId, 'dooh').then(
      result => {
        const cartWithAudience = cart.map(a => {
          let audienceFound = result.find(r => (r.faceId === a.idKinFace) || (a.parentFrame !== null && r.faceId === a.parentFrame))
          a.index = typeof audienceFound !== 'undefined' ? audienceFound.index : null;
          return a;
        })

        this.setState({
          cart: cartWithAudience,
          audiences: result,
          loadingAudience: false
        })
      },
      error => {
        toast.error('Error: ' + error);
        const cartWithAudienceNull = cart.map(a => {
          a.index = null;
          return a;
        })
        this.setState({
          audiences: [],
          cart: cartWithAudienceNull,
          loadingAudience: false
        })
      }
    )
  }

  getValuesByField(field, filters, page){
    this.setState({
      loadingValues: true
    })
    doohService.getDistinctByFieldFiltered(field, filters, page).then(
      result => {
        if(result.content.length>0){
          const resultObj = result.content.map(item=>{
            return {
              text: item,
              value: item,
            }
          })

          let values = this.state.values[field]
          values = values.concat(resultObj)

          this.setState((state)=> ({
            values: {
              ...state.values,
              [field]: values
            }
          }))

          if(page < result.totalPages - 1){
            this.getValuesByField(field, filters, page + 1)
          }
          else{
            this.setState({
              loadingValues: false
            })
          }
        }
        else{
          this.setState({
            loadingValues: false
          })
        }
      }
    )
  }

  resetSelected(){
    this.setState({
      selectedRowKeys: []
    })
  }

  addToCart(){
    const {selectedRowKeys} = this.state
    const size = selectedRowKeys.length

    this.props.handleAddToCart(selectedRowKeys)

    this.setState({
      showCart: true,
      selectedRowKeys: [],
    }, ()=> toast.success(size + ' item' + (size!==1 ? 's' : '') + ' added to cart!'))
  }

  resetValues(){
    this.setState({
      values: DEFAULT_VALUES
    })
  }

  handleToggle = prop => enable => {
    this.setState({ [prop]: enable });
  };

  handleToggleForm() {
    this.setState((state)=>({
      isFormHidden: !state.isFormHidden
    }));
  };

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  handleTableLayoutChange = e => {
    this.setState({ tableLayout: e.target.value });
  };

  handleExpandChange = enable => {
    this.setState({ expandable: enable ? expandable : undefined });
  };

  handleEllipsisChange = enable => {
    this.setState({ ellipsis: enable });
  };

  handleTitleChange = enable => {
    this.setState({ title: enable ? title : undefined });
  };

  handleHeaderChange = enable => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = enable => {
    this.setState({ footer: enable ? footer : undefined });
  };

  handleRowSelectionChange = enable => {
    this.setState({ rowSelection: enable ? {} : undefined });
  };

  handleYScrollChange = enable => {
    this.setState({ yScroll: enable });
  };

  handleXScrollChange = e => {
    this.setState({ xScroll: e.target.value });
  };

  handleDataChange = hasData => {
    this.setState({ hasData });
  };

  handleChangeTable = (pagination, nextFilters, sorter) => {
    let {filters} = this.state

    const isEmptyFilter = !Object.values(nextFilters).some(x => (x !== null && x !== ''));

    Object.keys(nextFilters).map(key => {
      if(nextFilters[key] && nextFilters[key].length > 0){
        filters[key] = nextFilters[key]
      }
      else if(nextFilters[key] === null){
        filters[key] = null;
      }
      //else MALEDETTI CINESI!!!
    })

    const filtersArray = Object.keys(filters).map(function(key) {
      if(filters[key] && filters[key].length > 0){
        return {
          field: key,
          value: filters[key]
        }
      }
    }).filter(item => item !== undefined);

    const title = () => isEmptyFilter ? undefined : (
      <React.Fragment>
        <p>
          <Icon name="filter" className="mr-3"/>
          {filtersArray.map(item =>
            item.value.map(value =>
              <Stamp color="primary" className="mr-1">
                {value}
              </Stamp>
            )
          )}
          <Button color="link" size="sm" onClick={this.resetFilters}>Clean filters</Button>
        </p>
      </React.Fragment>
    )

    this.setState({
      title,
      filters: !isEmptyFilter ? filters : {},
      // sortedInfo: sorter // TO DO...
    })
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  getColumnTemplate(fieldName, label = null, isBoolean = false, customRender = null, fixed = null, width = null, isSearch = false, commaSeparated = false){

    const {readonly} = this.props || false
    const isVisible = this.state.activeColumns.find(col=> col.field===fieldName);
    // sortedInfo = sortedInfo || {};
    return {
      title: !label ? this.strToCapitalize(fieldName) : label,
      dataIndex: fieldName,
      ...(!isBoolean && {sorter: (a, b) => sortNames(a[fieldName], b[fieldName])}),
      // sortOrder: sortedInfo.columnKey === fieldName && sortedInfo.order, //TO DO.. inibisce l'onchange del sorting
      filters: (!readonly) && !isSearch && this.state.values[fieldName],
      onFilter: (value, record) => {
        const name = record[fieldName]!==null && (isBoolean ? record[fieldName].toString().toUpperCase() : record[fieldName].toUpperCase())

        if(isSearch){
          if(commaSeparated){
            const itemList = name.split(',');
            const orSearchList = value.split(',');

            return itemList.some(item => orSearchList.includes(item));
          }

          return (
            (record[fieldName]!==null) ? 
              (name.toUpperCase().includes(value.toUpperCase())) 
            : (value === "(Empty)") 
          )
        }

        return (
          (record[fieldName]!==null) ? 
            (name === value.toUpperCase()) 
          : (value === "(Empty)") 
        )
      },
      filterSearch: !isSearch,
      filterDropdown:  !isSearch ? null : ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={e => e.stopPropagation()}
        >
          <Input
            // ref={searchInput}
            placeholder={`Search`}
            value={`${selectedKeys[0] || ""}`}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, fieldName)}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
          <Space>
            <Button
              color="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, fieldName)}
              icon={"search"}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
            <Button
              onClick={clearFilters}
              color="danger"
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              Cancel
            </Button>
          </Space>
        </div>
      ),
      className: (isVisible && isVisible.visible) ? '' : 'd-none',
      onFilterDropdownVisibleChange: visible => !isSearch && this.handleFilterDropdown(visible, fieldName),
      filterIcon: filtered => this.state.loadingValues ? <LoadingOutlined style={{ color: '#1890ff'}}/> : (!isSearch ? <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }}/> : <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}/>),
      filteredValue: this.state.filters[fieldName] || null,
      fixed: fixed,
      width: width,
      ellipsis: true,
      render: (value, record) => {
        if(customRender !== null){
          return customRender(value, record) 
        }
        
        if(isBoolean){
          if(value===true){
           return 'ON'
          }else if(value===false){
           return 'OFF'
          }
        } else {
          return value
        }
      }
    }
  };

  handleFilterDropdown(visible, fieldName){
    if(visible){
      const {filters} = this.state
      let filtersCleaned = _.pickBy(filters, _.identity);
      filtersCleaned = _.omit(filtersCleaned, fieldName);
      this.getValuesByField(fieldName, filtersCleaned, 0)
    }
    else{
      setTimeout(this.resetValues, 50);
    }
  }

  resetFilters(){
    this.setState({
      filters: {}
    }, () => this.handleChangeTable({current: 1, pageSize: 10, position: [this.state.top, this.state.bottom]}, {}, {}))
  }

  handleVisibleChangeDropdownColumns = flag => {
    this.setState({ visibleDropdownColumns: flag });
  };

  handleChangeToggleColumn = (ev, field) => {
    const {checked} = ev.target
    let {activeColumns} = this.state
    activeColumns = activeColumns.map(col => {
      if(col.field === field){
        col.visible = checked
      }
      return col
    })
    this.setState({
      activeColumns
    })
  }

  renderHeatmap = (faceIds, record = null) => {
    const titleHeatmap = record === null ? (
      faceIds.length === 1 ? 
        ('Heatmap ID: ' + faceIds)
        : 'Heatmap of ' + faceIds.length + ' frames'
      )
    :
    (record.city + " - " + record.publisher + " - " + record.type + " - " + record.installation)

    return(
      <ButtonAntd
        type="link"
        icon={<DotChartOutlined/>}
        onClick={ () => {
          Modal.info({
            title: titleHeatmap,
            closable: true,
            width: 850,
            content: (
              <HeatMapTableAudience faceIds={faceIds} audienceFilter={this.state.audienceFilter} type='dooh'/>
            ),
            // onOk() {},
          });
        }}
      />
    )
  }

  renderIndex = (value, record) => {
    let faceIds = []
    
    if(record.childrenIdKinFrame === null){
      if(record.parentFrame === null){
        faceIds = [record.idKinFace]
      }
      else{
        faceIds = [record.parentFrame]
      }
    }
    else{
      faceIds = record.childrenIdKinFrame
    }

    return ((value && !isNaN(value)) || (record.parentIndex && !isNaN(record.parentIndex)) || record.childrenIdKinFrame !== null) ? (
      <>
        {record.childrenIdKinFrame === null ?
          <>
            {record.parentFrame === null && <BadgeTabler color="info" className="mr-1">{value.toFixed(2)}</BadgeTabler>}
            {record.parentFrame !== null && <BadgeTabler color="secondary" className="mr-1">{value.toFixed(2)}</BadgeTabler>}
            {this.renderHeatmap(faceIds, record)}
          </>
          :
          // <AudienceIndexMinMax ids={faceIds} audienceFilter={this.state.audienceFilter} type='dooh' extra={this.renderHeatmap(faceIds)}/>
          <>
            {
              (isNaN(record.minIndex) && isNaN(record.maxIndex)) ? "-"
              :
              <>
                  <BadgeTabler color="danger" className="mr-1">{record.minIndex.toFixed(2)}</BadgeTabler>
                  <br/>
                  <BadgeTabler color="success" className="mr-1">{record.maxIndex.toFixed(2)}</BadgeTabler>
                  {this.renderHeatmap(faceIds)}
              </>
            }
          </>
        }
        
      </>
    )
    : (typeof record.parentIndex !== "undefined" && record.parentIndex !== null ? record.parentIndex : '-')
  }

  onFilterIndex = (indexMin, indexMax) => {
    const {lockFilter} = this.state

    this.setState({
      lockFilterValues: {indexMin, indexMax},
      isLoadingFilter: true
    })

    if(lockFilter) {
      clearTimeout(lockFilter);
    }

    this.setState({
      lockFilter: setTimeout(this.handleFilterIndex, 2000),
      isLoadingFilter: true
    })
  }

  condMinMaxFilter = (item, indexMin, indexMax) => {
    const condSingle = (
      item.index !== null)
      && (
        indexMin === null
        ||
        (item.index >= indexMin)
      )
      && (
        indexMax === null
        ||
        (item.index <= indexMax)
    )

    const condNetwork = (
      (!isNaN(item.minIndex) && !isNaN(item.maxIndex))
      &&(
        (
          indexMin === null
          ||
          (item.minIndex >= indexMin && item.minIndex <= indexMax)
        )
        ||
        (
          indexMax === null
          ||
          (item.maxIndex >= indexMin && item.maxIndex <= indexMax)
        )
      )
    )

    return (condSingle || condNetwork)
  }

  handleFilterIndex = () => {
    const {lockFilterValues, hideExcluded, dataAll} = this.state

    if(!lockFilterValues){
      this.setState({
        isLoadingFilter: false
      })
      return;
    }

    const {indexMin, indexMax} = lockFilterValues

    const data = dataAll.filter(item => {
      const isIndexValidate = (
        (item.index === null && !hideExcluded)
        ||
        ( this.condMinMaxFilter(item, indexMin, indexMax))
      )
      return isIndexValidate
    })

    this.setState({
      indexMin, indexMax, isLoadingFilter: false, data
    })
  }

  handleChangeFilterHideExcluded = (value) => {
    this.setState({
      hideExcluded: value,
      isLoadingFilter: true
    }, () => setTimeout(this.handleFilterIndex, 1500))
  }

  render() {
    const { xScroll, yScroll, isFormHidden, data, cart, selectedRowKeys, loading, loadingAudience, ...state } = this.state;
    const {readonly} = this.props || false
    const isAddressVisible = this.state.activeColumns.find(col=> col.field==='address');

    const scroll = {};
    if (yScroll) {
      scroll.y = 500;
    }
    if (xScroll) {
      scroll.x = '100vw';
    }

    let columns = [
      {
        title: <EnvironmentOutlined />,
        dataIndex: readonly ? 'modality' : 'childrenCount',
        width: readonly ? 100 : 100,
        fixed: 'left',
        render: (item, record) => item ?
          (readonly
            ? (<span>
                {item.toUpperCase(item)}
                {record.audio ? <AudioOutlined className="float-right mt-1"/> : <AudioMutedOutlined className="float-right mt-1"/>}
              </span>)
            :(
              <>
                <Badge count={item} overflowCount={1000} style={{backgroundColor: "#cf467f"}} />
                {record.audio ? <AudioOutlined className="float-right mt-1"/> : <AudioMutedOutlined className="float-right mt-1"/>}
              </>
            )
          )
        : record.audio ? <AudioOutlined /> : <AudioMutedOutlined />
      },
      this.getColumnTemplate('type', 'Environment'),
      this.getColumnTemplate('installation', 'Type'),
      this.getColumnTemplate('publisher'),
      this.getColumnTemplate('region'),
      this.getColumnTemplate('district', false, false, null, 50),
      this.getColumnTemplate('city'),
      this.getColumnTemplate(
        'postalCode',
        "Postal code (comma separated)",
        false,
        (value, record) => (
          (record.modality === "NETWORK" && !readonly) ? <em title={value}>Multiple</em> : (value ? value : "NA")
        ),
        null,
        null,
        true,
        true
      ),
      this.getColumnTemplate('audio','Audio', true),
      {
        title: 'Address',
        dataIndex: 'site',
        visible: false,
        className: (isAddressVisible && isAddressVisible.visible) ? '' : 'd-none',
      },
      this.getColumnTemplate('adsDuration', 'Ads Duration'),
      this.getColumnTemplate('mediaOwner', 'Media Owner'),
      this.getColumnTemplate('dataType', 'Data Type'),
      this.getColumnTemplate('videoType', 'Video Type'),
      this.getColumnTemplate('videoFormat', 'Video Format'),
      this.getColumnTemplate('orientation', 'Orientation'),
      this.getColumnTemplate('screenFormat', 'Screen Format'),
      this.getColumnTemplate('screenType', 'Screen Type'),
      this.getColumnTemplate('cinema', 'Cinema'),
      this.getColumnTemplate('gdo', 'GDO'),
      this.getColumnTemplate('note', 'Note'),
      this.getColumnTemplate('index', 'Index', false, this.renderIndex, 'right' )
    ];

    if(readonly){
      columns.shift()
      columns.unshift({
        title: <DesktopOutlined />,
        dataIndex: 'numberMonitors',
        width: 100,
        fixed: "left",
        render: (item, record) => (
          <>
            <Badge count={item} overflowCount={1000} style={{backgroundColor: "#cf467f"}} />
            {record.audio ? <AudioOutlined className="float-right mt-1"/> : <AudioMutedOutlined className="float-right mt-1"/>}
          </>
        )
      })
    }

    if(!this.state.audienceFilter){
      columns.pop();
    }

    const tableColumns = columns.map(item => ({ ...item, ellipsis: state.ellipsis }));
    if (xScroll === 'fixed') {
      tableColumns[0].fixed = true;
      tableColumns[tableColumns.length - 1].fixed = 'right';
    }

    const rowSelection = {
      selectedRowKeys,
      selections: [
        Table.SELECTION_ALL,
        Table.SELECTION_INVERT
      ],
      getCheckboxProps: record => ({
        disabled: cart.indexOf(record.id) !== -1,
        id: record.id,
      }),
      onChange: this.onSelectChange,
    }

    const menu = (
      <div className="bg-white border shadow">
        <List
          size="small"
          // bordered
          dataSource={this.state.activeColumns}
          renderItem={item => <List.Item>
              <Checkbox 
                key={item.field} 
                checked={item.visible} 
                disabled={item.disabled} 
                onChange={(ev) => this.handleChangeToggleColumn(ev, item.field)}
              >
                  {item.label}
              </Checkbox>
            </List.Item>
          }
        />
      </div>
    );

    const renderFilterRangeIndexes = () => {
      const {data, dataAll, isLoadingFilter, indexMin, indexMax} = this.state
      return(
        <React.Fragment>
          <div className="row">
            <div className="col">
            <MapFilterSlider
              onChange={this.onFilterIndex}
              hideExcluded={this.state.hideExcluded}
              setHideExcluded={(value) => this.handleChangeFilterHideExcluded(value)}
              isInsideMap={false}
              defaultMin={_.min(dataAll.map(({index}) => parseFloat(index)).filter(num => !isNaN(num)))}
              defaultMax={_.max(dataAll.map(({index}) => parseFloat(index)).filter(num => !isNaN(num)))}
              value={[indexMin, indexMax]}
              loading={isLoadingFilter}
              count={data.length}
            />
            </div>
          </div>
         
        </React.Fragment>
      )
    }
    
    return (
      <div className={styles}>
        { !readonly &&
          <div className="row">
            <div className="col mb-3">
              {selectedRowKeys.length>0 &&
                <div className="row">
                  <div className="col-12 text-right">
                    <Button.List>
                      <Button className="ml-2 border-right text-dark" size="lg" disabled color="link">
                        Selected {selectedRowKeys.length} items
                      </Button>
                      <Button className="ml-2" color="secondary" onClick={this.addToCart} disabled={selectedRowKeys.length<=0} loading={loading} icon="shopping-cart">
                        Add to Cart
                      </Button>
                      <Button className="ml-2" color="link" onClick={this.resetSelected} disabled={selectedRowKeys.length<=0} loading={loading}>
                        Cancel
                      </Button>
                    </Button.List>
                  </div>
                </div>
              }
            </div>
          </div>
        }
        <Dropdown
          overlay={menu}
          onVisibleChange={this.handleVisibleChangeDropdownColumns}
          visible={this.state.visibleDropdownColumns}
        >
          <ButtonAntd className="my-2">
            <SettingOutlined /> Show/Hide Columns
          </ButtonAntd>
        </Dropdown>

        {
          (readonly !== true && this.state.audienceFilter !== null && (!loading && !loadingAudience)) &&
          <Popover placement="bottom" content={renderFilterRangeIndexes()} trigger="click">
            <ButtonAntd className="ml-2" >
              <FilterOutlined /> Filter by Index <BadgeTabler className="ml-1" color="info">{this.state.audienceFilter.label}</BadgeTabler>
            </ButtonAntd>
          </Popover>
        }

        <Table
          {...this.state}
          rowSelection={readonly ? false : rowSelection}
          pagination={{ position: [this.state.top, this.state.bottom] }}
          columns={tableColumns}
          dataSource={state.hasData ? (readonly ? cart : data) : null}
          // scroll={{ y: 600, x: "100vw" }}
          scroll={columns.length > 8 ? { x: 2800 } : false}
          loading={loading || loadingAudience}
          onChange={this.handleChangeTable}
        />
      </div>
    );
  }
}

export default DoohPlanCart;
