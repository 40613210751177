import { authHeader } from "../_helpers";
import { userService } from "."
import FileSaver from 'file-saver';
import {defineConfig} from '../config'

export const searchService = {
    getApiPathSearch,
    createSearch,
    readSearch,
    deleteSearch,
    getJsonSearch,
    getJsonSearchByPolygons,
    getXlsSearch,
    getPlansFindQuery,
    getFileFromUrl
};

const { apiUrl, apiVer} = defineConfig.SEARCH_SERVICE

function getApiPathSearch(){
  return (apiUrl+apiVer)
}

function createSearch(name) {
  const url = apiUrl+apiVer+"/search/kinetic/planner/create";

  const bodyJson = {
    "name": name
  }

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(bodyJson)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function readSearch(id) {
  const url = apiUrl+apiVer+"/search/kinetic/planner/idsearch/"+id+"/get";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function deleteSearch(id){
  const url = apiUrl+apiVer+"/search/kinetic/planner/idsearch/"+id+"/delete";

  const requestOptions = {
    method: "DELETE",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getJsonSearch(id, POIs, radius, searchQuery = null, audienceId = null) {
    const requestUrl = apiUrl+apiVer+'/search/find/assets/bycenter/byradius/active';

    const bodyJson = {
        "id": id,
        "pois": POIs,
        "searchQuery": searchQuery,
        "radius": POIs.length > 0 ? radius : 0,
        "audienceId": audienceId,
        "active": 1
    }

    const requestOptions = {
        method: "POST",
        headers: authHeader('application/json'),
        body: JSON.stringify(bodyJson)
    };

    return fetch(requestUrl, requestOptions)
        .then(handleResponse)
  }

function getJsonSearchByPolygons(id, POIs, radius, searchQuery = null, audienceId = null, polygons = [], travelConfig = {}) {
    const requestUrl = apiUrl+apiVer+'/search/find/assets/polygons/active';

    const bodyJson = {
      "id": id,
      "pois": POIs,
      "searchQuery": searchQuery,
      "radius": POIs.length > 0 ? radius : 0,
      "audienceId": audienceId,
      "area": polygons,
      ...travelConfig,
      "active": 1
    }

    const requestOptions = {
        method: "POST",
        headers: authHeader('application/json'),
        body: JSON.stringify(bodyJson)
    };

    return fetch(requestUrl, requestOptions)
        .then(handleResponse)
  }

function getXlsSearch(id, POIs, radius, searchQuery = null, audienceId, polygons = [], travelConfig = {}) {
    const requestUrl = apiUrl+apiVer+'/search/xls/export/download';

    const bodyJson = {
      "id": id,
      "pois": POIs,
      "searchQuery": searchQuery,
      "radius": POIs.length > 0 ? radius : 0,
      "audienceId": audienceId,
      "area": polygons,
      ...travelConfig,
      "active": 1
    }

    const requestOptions = {
      method: "POST",
      headers: authHeader('application/json'),
      body: JSON.stringify(bodyJson)
    };

    const fileName = "exportPlanner.xlsx";


    return fetch(requestUrl, requestOptions)
        .then(response => {
          console.log("scarico....")
          return response.blob()
        })
        .then(function(blob) {
          FileSaver.saveAs(blob, fileName)
          return true;
        })
}

function getPlansFindQuery(date = "2019-5", query = "", page = 0, limit = 10, desc = false) {
  query = query.trim() == '' ? "" : query.trim()

  let orderBy = desc ? 'desc' : 'asc'

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(apiUrl+apiVer+"/search/find/date/"+date+"/page/"+page+"/size/"+limit+"/order/"+orderBy + "?planName="+query, requestOptions)
  .then(handleResponse);
}

function getFileFromUrl(url, fileName = null) {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    if(fileName === null){
      fileName = url.split('/')
      fileName = fileName[fileName.length-1]
    }

    return fetch(url, requestOptions)
      .then(response => {
        console.log("scarico....")
        return response.blob()
      })
      .then(function(blob) {
        FileSaver.saveAs(blob, fileName)
        return true;
      })
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {

      switch(response.status){
        case 401:
          // auto logout if 401 response returned from api
          userService.logout();
          window.location.reload(true);
          break;
        case 422:
          return data;
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
