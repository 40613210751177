import React, { Component } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class CarouselWithNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      items: [],
      photoIndex: 0,
      photoIsOpen: false,
      images: [],
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }
  
  componentDidMount(){
    this.setState({
      items: this.props.images
    })
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.images!==this.props.items){
      this.setState({
        items: nextProps.images
      })
    }
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === this.state.items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.state.items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex, photoIndex, photoIsOpen } = this.state;

    const slides = this.state.items.map((item,index) => {
      return (
        <CarouselItem className="mh-100"
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item}
        >
          <img src={item} alt={'image'} style={{"height": "274px","width": "100%","objectFit": "contain", "cursor": "pointer"}}
            onClick={() => this.setState({
                photoIndex: index,
                images: this.state.items
            }, () => this.setState({photoIsOpen: true}))}
          />
          {/* <CarouselCaption captionText={item.caption} captionHeader={item.caption} /> */}
        </CarouselItem>
      );
    });

    return (
        <div className="col-12">
            <Carousel
                activeIndex={activeIndex}
                next={this.next}
                previous={this.previous}
            >
                <CarouselIndicators items={this.state.items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                {slides}
                {/* <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} /> */}
            </Carousel>
            {photoIsOpen && (
                <Lightbox
                    mainSrc={this.state.images[photoIndex]}
                    nextSrc={this.state.images[(photoIndex + 1) % this.state.images.length]}
                    prevSrc={this.state.images[(photoIndex + this.state.images.length - 1) % this.state.images.length]}
                    onCloseRequest={() => this.setState({ photoIsOpen: false })}
                    onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + this.state.images.length - 1) % this.state.images.length,
                        })
                    }
                    onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + 1) % this.state.images.length,
                        })
                    }
                />
            )}
        </div>
    );
  }
}


export default CarouselWithNavigation;