import { authHeader } from "../_helpers";
import { userService } from "."
import FileSaver from 'file-saver';
import {defineConfig} from '../config'

export const doohEstimationService = {
  getApiPathSearch,
  getPlansFindQuery,
  duplicateSearch,
  createSearch,
  readSearch,
  readSearchAll,
  updateSearch,
  getHistoriesByIds,
  getResultJson,
  getResultXls,
  getQuotesXls,
  calculateQuotes,
  deleteSearch
};

const {apiUrl, apiVer} = defineConfig.DOOH_SERVICE

function getApiPathSearch(){
  return (apiUrl+apiVer)
}

function duplicateSearch(id) {
  const url = apiUrl+apiVer+"/dooh/estimation/duplicate/id/" + id;

  // const bodyJson = {
  //   "name": name
  // }

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    // body: JSON.stringify(bodyJson)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function createSearch(name) {
  const url = apiUrl+apiVer+"/dooh/estimation/create";

  const bodyJson = {
    "name": name
  }

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(bodyJson)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function readSearch(id) {
  const url = apiUrl+apiVer+"/dooh/estimation/get/id/"+id;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function readSearchAll() {
  const url = apiUrl+apiVer+"/dooh/estimation/get/all";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getPlansFindQuery(date = "2020-10", query = "%2A", page = 0, limit = 10, desc = false) {
  query = query.trim() == '' ? "%2A" : query.trim()

  let orderBy = desc ? 'desc' : 'asc'

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(apiUrl+apiVer+"/dooh/estimation/find/date/"+date+"/query/"+ query + "/page/"+page+"/size/"+limit+"/order/"+orderBy, requestOptions)
  .then(handleResponse);
}

function updateSearch(id, obj) {
  const url = apiUrl+apiVer+"/dooh/estimation/update";

  const bodyJson = {
    ...obj,
    "id": id
  }

  const requestOptions = {
    method: "PUT",
    headers: authHeader("application/json"),
    body: JSON.stringify(bodyJson)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}


function deleteSearch(id){
  const url = apiUrl+apiVer+"/dooh/estimation/delete";

  const bodyJson = {
    "id": id,
  }

  const requestOptions = {
    method: "DELETE",
    headers: authHeader("application/json"),
    body: JSON.stringify(bodyJson)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function calculateQuotes(id) {
  const url = apiUrl+apiVer+"/dooh/estimation/finalize/id/"+id;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}


function getHistoriesByIds(estimationId, ids) {
  const url = apiUrl+apiVer+"/dooh/estimation/get/historiesByIds/"+ estimationId;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(ids)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getResultJson(id) {
  const url = apiUrl+apiVer+"/dooh/estimation/get/location/estid/"+id;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}


function getResultXls(id, fileName) {
  const url = apiUrl+apiVer+"/dooh/estimation/location/xls/export/estid/"+id;
  getFileFromUrl(url, fileName)
}

function getQuotesXls(id, fileName) {
  const url = apiUrl+apiVer+"/dooh/estimation/quotes/xls/export/estid/"+id;
  getFileFromUrl(url, fileName)
}

function getFileFromUrl(url, fileName = null) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  if(fileName === null){
    fileName = url.split('/')
    fileName = fileName[fileName.length-1]
  }

  return fetch(url, requestOptions)
    .then(response => {
      console.log("scarico....")
      return response.blob()
    })
    .then(function(blob) {
      FileSaver.saveAs(blob, fileName)
      return true;
    })
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {

      switch(response.status){
        case 401:
          // auto logout if 401 response returned from api
          userService.logout();
          window.location.reload(true);
          break;
        case 422:
          return data;
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
