/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { message, Typography, Divider, Spin, Card } from "antd";
import { audienceService } from "../_services";
import _ from "lodash";

function SyncingChart(props) {
  const {type = "single", chartKey, groupKey, assetType, params = ["mediaOwner", "dashboardType", "circuit"], layout = "vertical"} = props;

  const optionsTemplate = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
      group: groupKey ? groupKey : "default_group_id",
      id: chartKey ? chartKey : "default_chart_id",
      toolbar: {
        show: false,
      },
      zoom: { enabled: false },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#FF1654", "#247BA0"],
    series: [],
    stroke: {
      width: [4, 4],
    },
    plotOptions: {
      bar: {
        columnWidth: "20%",
      },
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      axisTicks: {
        show: true,
      },
      axisBorder: {
        // show: true,
      },
      labels: {
        style: {
          // colors: "#FF1654"
        },
      },
      title: {
        text: "(%)",
        offsetX: "80px",
      },
    },
    tooltip: {
      shared: true,
    },
    legend: {
      show: true,
    },
    markers: {
      size: 5,
      hover: {
        sizeOffset: 2,
      },
    },
  };

  const [options, setOptions] = useState(optionsTemplate);
  const [optionsDoubleTop, setOptionsDoubleTop] = useState(optionsTemplate)
  const [optionsDoubleBot, setOptionsDoubleBot] = useState(optionsTemplate)
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData(type)
  }, [props.filters, props.days, props.firstDays, props.secondDays]);


  const generateOptionsDouble = (res) => {
    const maxValueTop = Math.max(
      ...res.firstChart.series[0].data,
      ...res.firstChart.series[1].data
    );
    setOptionsDoubleTop({
      ...optionsTemplate,
      chart: {
        ...optionsTemplate.chart,
        id: `${chartKey}-top`
      },
      series: res.firstChart.series,
      xaxis: {
        categories: res.firstChart.categories,
      },
      yaxis: {
        ...optionsTemplate.yaxis,
        min: 0,
        max: maxValueTop,
      },
    });

    const maxValueBot = Math.max(
      ...res.secondChart.series[0].data,
      ...res.secondChart.series[1].data
    );
    setOptionsDoubleBot({
      ...optionsTemplate,
      chart: {
        ...optionsTemplate.chart,
        id: `${chartKey}-bottom`
      },
      series: res.secondChart.series,
      xaxis: {
        categories: res.secondChart.categories,
      },
      yaxis: {
        ...optionsTemplate.yaxis,
        min: 0,
        max: maxValueBot,
      },
    });

    setTimeout(() => setLoading(false), 1000);
  }

  const generateOptions = (res) => {
    const maxValue = Math.max(
      ...res.series[0].data,
      ...res.series[1].data
    );
    setOptions({
      ...optionsTemplate,
      series: res.series,
      xaxis: {
        categories: res.categories,
      },
      yaxis: {
        ...optionsTemplate.yaxis,
        min: 0,
        max: maxValue,
      },
    });
    
    setTimeout(() => setLoading(false), 1000);
  }

  const getData = (type) => {
    let filters = props.filters

    if(filters[params[0]]===''){
      delete filters[params[0]]
    }
    if(filters[params[1]]===''){
      delete filters[params[1]]
    }
    if(filters[params[2]]===''){
      delete filters[params[2]]
    }

    const filteredData = _.filter(props.allData, filters);

    const filteredIds = filteredData.map(value => value.idKinFace);

    if(type ==='double') {
      setLoading(true);
      setOptionsDoubleTop({})
      setOptionsDoubleBot({})
      audienceService.getDataForSynchingChartDouble(
        filteredIds,
        props.audienceFilter.id,
        props.firstDays,
        props.secondDays,
        assetType
      ).then(
        res => {
          generateOptionsDouble(res)
        },
        error => {
          console.error("error: " + error);
          setLoading(false);
        }
      );
    } else {
      if(!props.audienceFilter)
        return;

      setOptions(optionsTemplate)
      setLoading(true);

      audienceService.getDataForSynchingChart(
        filteredIds,
        props.audienceFilter.id,
        props.days,
        assetType
      ).then(
        res => {
          generateOptions(res)
        },
        error => {
          console.error("error: " + error);
          setLoading(false);
        }
      );
    }
  };

  const SingleChart = () => (
    (options.series && options.series.length > 0) ?
      <div className="col-12 text-center">
        <ReactApexChart
          key={props.key}
          options={options && options}
          series={options.series}
          height={250}
          width={1000}
          className="text-center"
        />
      </div>
      :
      <h2 className='text-poppins mt-6 text-muted text-center'>No Data</h2>
  )

  const DoubleChart = () => (
    (optionsDoubleBot.series && optionsDoubleBot.series.length > 0 && optionsDoubleTop.series.length > 0 ) ?
      <>
        <div className={`${layout === "horizontal" ? "col-6" : "col-12"} text-center m-0 p-0`}>
          <Card title="Total Users" bordered={false} headStyle={{background: "rgb(250, 250, 250)", fontWeight: "bold"}} bodyStyle={{padding: 0}}>
            <ReactApexChart
              options={optionsDoubleTop}
              series={optionsDoubleTop.series}
              height={250}
              // width={1000}
              className="text-center"
            />
          </Card>
        </div>
        {/* <Divider type="horizontal" style={{marginLeft:'10px'}} ></Divider> */}
        <div className={`${layout === "horizontal" ? "col-6" : "col-12"} text-center m-0 p-0`}>
          <Card title="Users in Target" bordered={false} headStyle={{background: "rgb(250, 250, 250)", fontWeight: "bold"}} bodyStyle={{padding: 0}}>
            {/* <Typography.Title level={5} className='mb-0'>Users in Target</Typography.Title> */}
            <ReactApexChart
              options={optionsDoubleBot}
              series={optionsDoubleBot.series}
              height={250}
              // width={1000}
              className="text-center"
            />
          </Card>
          
        </div>
      </> 
      :
      <>
        <div className="col-12 text-center m-0 p-0">
          <Card title="Total Users" bordered={false} headStyle={{background: "rgb(250, 250, 250)", fontWeight: "bold"}} bodyStyle={{padding: 0}}>
            <h2 className='text-poppins mt-6 text-muted'>No Data</h2>
          </Card>
        </div>
        {/* <Divider type="horizontal" style={{marginLeft:'10px'}} ></Divider> */}
        <div className="col-12 text-center m-0 p-0">
          <Card title="Users in Target" bordered={false} headStyle={{background: "rgb(250, 250, 250)", fontWeight: "bold"}} bodyStyle={{padding: 0}}>
            <h2 className='text-poppins mt-6 text-muted'>No Data</h2>
          </Card>
        </div>
      </>
  )

  return (
    <>
      {loading ?
        <div className="col-12 text-center p-5" style={{minHeight: 150}}>
          <h3 className="mt-3 mb-5">Loading...</h3>
          <Spin/>
        </div>
        :
        <>
          {type === "single" && SingleChart()}
          {type === "double" && DoubleChart()}
        </>
      }
    </>
  );
}

export default SyncingChart;
