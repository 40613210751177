// @flow

import * as React from "react";

import {
  Form,
  Page,
  Grid,
  Card,
  Button,
  Badge,
  Dropdown,
  Dimmer
} from "tabler-react";

import {Popconfirm, Modal, message} from 'antd'

import SiteWrapper from "../SiteWrapper.react";

import DoohPlanCart from './Dooh.Plan.Cart.react'
import DoohPlanAmbiences from './Dooh.Plan.Ambiences.react'
import DoohPlanQuotes from './Dooh.Plan.Quotes.react'
import DoohDrawer from './Dooh.Drawer.react'
import DoohStepper from './Dooh.Stepper.react'
import MapPlanner from '../components/Map/MapPlanner.react'
import DetailMap from '../components/Map/DetailMap.Planner.react'

import AudienceDoohFilter from './DoohFilters/AudienceDooh.Filter.react'

import {toast} from 'react-toastify'

import {doohEstimationService, doohService, audienceService} from "../_services"
import sanitize from "sanitize-filename"
import { formatNumber } from '../_helpers'
import _ from "lodash"

import kineticDB, {
  getData,
} from '../database/DoohData.db';
import DoohInsight from "./Dooh.Insight.react";

const db = kineticDB('kineticDB', {
  doohData: `++id`
});

class DoohPlanner extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      id: props.match.params.id,
      name: '',
      result: [],
      cart: [],
      cartData: [],
      historyData: [],
      step: 0,

      audienceId: null,

      ambiences: [],
      totalOTS: 0,
      size: null,
      budget: null,
      editingName: false,
      owner: '-',
      progress: null,
      createdAt: '-',
      updatedAt: '-',
      loading: false,

      selectedMarker: null,
      audienceFilter: null,
      audienceFilterActive: null,

      penetrationRate: 0
    }

    this.addToCart = this.addToCart.bind(this)
    this.deleteFromCart = this.deleteFromCart.bind(this)
    this.saveCart = this.saveCart.bind(this)
    this.refreshAmbiences = this.refreshAmbiences.bind(this)
    this.convertCartToAmbiences = this.convertCartToAmbiences.bind(this)
    this.handleChangeStep = this.handleChangeStep.bind(this)
    this.handleAddAmbience = this.handleAddAmbience.bind(this)
    this.handleDeleteAmbience = this.handleDeleteAmbience.bind(this)
    this.handleUpdateAmbience = this.handleUpdateAmbience.bind(this)
    this.handleUpdateAmbiences = this.handleUpdateAmbiences.bind(this)
    this.handleChangeBudget = this.handleChangeBudget.bind(this)
    this.handleMarkerSelected = this.handleMarkerSelected.bind(this)
    this.onChangePlanName = this.onChangePlanName.bind(this)
    this.onClickSavePlanName = this.onClickSavePlanName.bind(this)
    this.onClickExportLocations = this.onClickExportLocations.bind(this)
    this.onClickExportQuotes = this.onClickExportQuotes.bind(this)
    this.getResultList = this.getResultList.bind(this)
    this.handleStateAudience = this.handleStateAudience.bind(this)
    this.getAudienceDetails = this.getAudienceDetails.bind(this)
  }

  componentDidMount(){
    this.readSearch(this.state.id)
    this.getResultList()
  }

  readSearch(id){
    doohEstimationService.readSearch(id).then(
        result => {
          let {
            ambiences,
            totalOTS,
            budget,
            name,
            owner,
            audienceId,
            progress,
            createdAt,
            updatedAt
          } = result

          createdAt = createdAt ? (createdAt.split('T')[0] ? createdAt.split('T')[0] : '-') : '-'
          updatedAt = updatedAt ? (updatedAt.split('T')[0] ? updatedAt.split('T')[0] : '-') : '-'

          const cart = this.getCartByAmbiences(ambiences)
          const histories = this.getHistoryByAmbiences(ambiences)
          this.saveCartDataInLocal(cart, progress)

          this.setState({
            cart,
            histories,
            ambiences,
            totalOTS,
            budget,
            name,
            owner,
            audienceId,
            step: progress,
            // step: 4,
            createdAt,
            updatedAt
          }, () => this.getAudienceDetails(this.state.audienceId))
        },
        error => {
          this.setState({error:true})
          console.error(error);
        }
    );
  }

  getCartByAmbiences(ambiences){
    const doohsArray = ambiences.map(amb => amb.doohs)

    let cart = doohsArray.reduce( (accumulator, currentValue) => {
        return accumulator.concat(currentValue);
      }, []
    );

    cart = _.uniq(cart)
    return cart
  }

  getHistoryByAmbiences(ambiences){
    const historiesArray = ambiences.map(amb => amb.histories)

    let histories = historiesArray.reduce( (accumulator, currentValue) => {
        return accumulator.concat(currentValue);
      }, []
    );

    histories = _.uniq(histories)

    doohService.getHistoriesByIds(histories).then(
      result => {
        const historiesData = result.map(h => {
          h.key = h.id
          return h
        })

        this.setState({
          historiesData
        })
      }
    )

    return histories
  }

  onChangePlanName(ev) {
    const {value} = ev.target

    this.setState({
      name: value
    })
  }

  onClickSavePlanName(){
    const {name} = this.state
    this.setState({
      editingName: false
    }, () => this.handleChangePlanName(name))
  }

  onClickSaveAudience = () => {
    const {audienceId} = this.state
    this.handleChangeAudienceId(audienceId)
  }

  calculatePenetrationRate = () => {
    if(!this.state.audienceId)
      return;
    
    const faceIds = this.getFaceIds(this.state.result);
    audienceService.getIndexesAudienceByIds(faceIds, this.state.audienceId, "dooh").then(
        result => {
            const sumTargetUser = result.reduce((a, b) => a + b.targetUserSeen, 0);
            const sumTotalUser = result.reduce((a, b) => a + b.totalUserSeen, 0);

            // console.log("DOOH PLAN: sumTargetUser/sumTotalUser", sumTargetUser, sumTotalUser)

            this.setState({
              penetrationRate: (sumTargetUser/sumTotalUser) * 100
            })
        },
        error => {
            message.error("Error: " + error)
        }
    )
  }

  addToCart(items){
    this.setState((state) => ({
      loading: true,
      cart: [...state.cart, ...items]
    }), ()=>this.saveCart())
  }

  deleteFromCart(id){
    let {cart} = this.state
    cart = cart.filter((item)=>item !== id)

    this.setState({
      cart
    }, ()=>this.saveCart())
  }

  async saveCartDataInLocal (cart, step){
    const {id} = this.state

    // Get from Indexed DB
    const doohData = await getData(db.doohData);
    
    let cartData = cart.map((el) => {
      return doohData.find(o => o.id === el);
    });
    
    cartData = _.compact(cartData);

    this.setState({
      cartData
    })
    return await cartData;
  }

  async saveCart() {
    const {id, cart, name, step, ambiences} = this.state

    let nextAmbiences;

    const cartData = await this.saveCartDataInLocal(cart)

    // Ambienti già esistenti | TO DO...
    if(ambiences.length > 0 && step !== 0){
      nextAmbiences = this.appendToCart(ambiences, cartData)
    }
    // Nessun ambience ancora creato
    else{
      nextAmbiences = [
        {
          name: 'All cart',
          doohs: cart,
          histories: [] // vuoto per il BE
        }
      ]
    }

    const planData = {
      ambiences: nextAmbiences,
      progress: step,
      name
    }

    doohEstimationService.updateSearch(id, planData).then(
      result => {
        const {ambiences} = result
        const histories = this.getHistoryByAmbiences(ambiences)
        this.setState({
          loading: false,
          ambiences,
          histories
        })
      },
      error => {
        toast.error("Error: " + error)
      }
    )
  }

  appendToCart(prevAmbiences, cartData){
    let nextAmbiences = this.convertCartToAmbiences(cartData)
    nextAmbiences = [...nextAmbiences, ...prevAmbiences]
    nextAmbiences = _.uniqBy(nextAmbiences, 'name')

    return nextAmbiences
  }

  convertCartToAmbiences(cartData, nextStep){
    const {id, histories} = this.state

    doohService.getHistoriesByIds(histories).then(
      result => {
        const historiesData = result.map(h => {
          h.key = h.id
          return h
        })

        this.setState({
          historiesData
        })

        const ambiences = historiesData.map(hd => {
          const name = hd.publisher + ' - ' + hd.installation + ' in ' + (hd.site ? (hd.site + ' - ') : '') + hd.city
          return {
            name,
            histories: [hd.id],
            visible: false // default all disabled
          }
        })

        doohEstimationService.updateSearch(id, {progress: nextStep, ambiences}).then(
          result => {
            const {ambiences} = result
            const cart = this.getCartByAmbiences(ambiences)
            const histories = this.getHistoryByAmbiences(ambiences)
            this.setState({
              step: nextStep,
              cart,
              ambiences,
              histories,
              loading: false
            })
          }
        )
      }
    )
  }

  handleChangeBudget(budget){
    let {id, step} = this.state

    this.setState({
      loading: true
    },
      () => doohEstimationService.updateSearch(id, {budget, progress: step}).then(
        result => {
          const {ambiences, budget} = result
          this.setState({
            ambiences,
            budget
          }, () => this.refreshAmbiences())
        }
      )
    )
  }

  handleChangePlanName(name){
    let {id, step} = this.state

    this.setState({
      loading: true
    },
      () => doohEstimationService.updateSearch(id, {name, progress: step}).then(
        result => {
          const {name} = result
          this.setState({
            name,
            loading: false
          })
        }
      )
    )
  }

  handleChangeAudienceId(audienceId){
    let {id, step} = this.state

    // Workaround per Java, disattiva l'audience e lo aggiorna
    if(audienceId === null){
      audienceId = "";
    }

    this.setState({
      loading: true
    },
      () => doohEstimationService.updateSearch(id, {audienceId, progress: step}).then(
        result => {
          const {audienceId} = result
          this.setState({
            audienceId,
            loading: false
          })
        }
      )
    )
  }

  handleAddAmbience(row){
    let {ambiences, id, step} = this.state
    ambiences.unshift(row)

    doohEstimationService.updateSearch(id, {ambiences, progress: step}).then(
      result => {
        const {ambiences} = result
        this.setState({
          ambiences
        })
      }
    )
  }

  handleDeleteAmbience(indexToBeDelete){
    let {ambiences, id, step} = this.state

    const removed = ambiences.splice(indexToBeDelete, 1)

    doohEstimationService.updateSearch(id, {ambiences, progress: step}).then(
      result => {
        const {ambiences} = result
        this.setState({
          ambiences
        }, () => toast.success('Ambience removed successful!'))
      }
    )
  }

  handleUpdateAmbience(row, index, refresh = true){
    let {ambiences, id, step} = this.state
    ambiences[index] = row

    this.setState({
      loading: true
    })

    doohEstimationService.updateSearch(id, {ambiences, progress: step}).then(
      result => {
        const {ambiences} = result
        this.setState({
          ambiences,
        }, () => refresh ? this.refreshAmbiences() : this.setState({loading: false}))
      }
    )
  }

  handleUpdateAmbiences(ambiences, budget){
    let {id, step} = this.state

    this.setState({
      loading: true
    },
      () => doohEstimationService.updateSearch(id, {budget, ambiences, progress: step}).then(
        result => {
          const {ambiences, budget} = result
          this.setState({
            budget,
            ambiences
          }, () => this.refreshAmbiences())
        }
      )
    )
  }

  handleChangeStep(nextStep, save = true){
    if(save){
      const {id, step, cartData} = this.state
      this.setState({
        loading: true
      })
      if(step === 0 && nextStep >= 1){
        this.convertCartToAmbiences(cartData, nextStep)
      }
      else{
        doohEstimationService.updateSearch(id, {progress: nextStep}).then(
          result => {
            if(nextStep === 0 && step !== 0){
              const {ambiences} = result
              const cart = this.getCartByAmbiences(ambiences)
              this.setState({
                ambiences,
                cart,
                step: nextStep
              })
            }
            else{
              this.setState({
                step: nextStep
              })
            }

            if(nextStep === 2){
              this.refreshAmbiences()
            }
            else{
              setTimeout( () => this.setState({loading: false}), 1000);
            }
            if((nextStep >=3 && nextStep<=5) && step <= 2){

              this.getResultList()
            }
            else if(nextStep >=3 && nextStep<=5){
              const {audienceFilterActive} = this.state

              this.setState({
                audienceFilterActive: null
              }, () => this.setState({
                audienceFilterActive
              }))
            }
          }
        )
      }
    }
    else{
      this.setState({
        step: nextStep,
      })
    }
  }

  refreshAmbiences(){
    const {id} = this.state
    doohEstimationService.calculateQuotes(id).then(
      result => {
        const {ambiences, totalOTS} = result
        this.setState({
          ambiences,
          totalOTS,
          loading: false
        })
      }
    )
  }

  getResultList(){
    const {id} = this.state

    doohEstimationService.getResultJson(id).then(
      result => {
        result.map((el, index) => {
          el.idKinFace = el.idKinFrame
          return el.key = el.id;
        });
        this.setState({
          result,
        }, () => this.calculatePenetrationRate(result))
      }
    )
  }

  onClickExportLocations(){
    const {id, name} = this.state
    let fileName = "export_dooh_plan-" + name.replaceAll(' ','_') + ".xlsx"
    fileName = sanitize(fileName)
    doohEstimationService.getResultXls(id, fileName);
  }

  onClickExportQuotes(){
    const {id, name} = this.state
    let fileName = "export_dooh_plan_quotes-" + name.replaceAll(' ','_') + ".xlsx"
    fileName = sanitize(fileName)
    doohEstimationService.getQuotesXls(id, fileName);
  }

  getStepDesc(step){
    switch(step){
      case null:
      case 0:
        return 'New plan'
      case 1:
        return 'Manage ambiences'
      case 2:
        return 'Estimation complete'
      default:
        return '-'
    }
  }

  getStepLabelsArray(){
    return ([
      {
        name: 'CHOOSE'
      },
      {
        name: 'ORGANIZE'
      },
      {
        name: 'DEFINE'
      }
    ])
  }

  handleMarkerSelected (nextSelected) {
    const {selectedMarker} = this.state
    if(nextSelected !== selectedMarker){
      this.setState({
        selectedMarker: nextSelected
      })
    }
  }

  handleStateAudience(audienceFilter){
    this.setState({
      audienceFilter
    })
  }

  onChangeFilterSelected = (save = false) => {
    const {audienceFilter} = this.state;

    let audienceId = null;
    if(audienceFilter !== null){
      audienceId = audienceFilter.id
    }

    this.setState({
      audienceId: null,
      audienceFilterActive: null
    }, () =>
    this.setState({
      audienceId,
      audienceFilterActive: audienceFilter
    }, () => {
      if(save) {
        this.onClickSaveAudience()
      }
    }))
    
  }

  getAudienceDetails = (audienceId) => {
    if(!audienceId)
      return;

    audienceService.getAudienceDetailsById(audienceId).then(
      result => {
        this.setState({
          audienceFilter: result
        }, () => this.onChangeFilterSelected(false))
      },
      error => {
        toast.error('Error: ' + error);
      }
    )
  }
  

  openAudienceFilter = () => {
    const {audienceFilter} = this.state;

    Modal.info({
      title: "Audience filters",
      width: 1100,
      closable: true,
      onOk: () => this.onChangeFilterSelected(true),
      content: (
        <div className="w-100 pt-5">
          <AudienceDoohFilter
            onChange={this.handleStateAudience}
            value={audienceFilter}
          />
        </div>
      )
    });
  }

  getFaceIds = (items) => {
    return items.map(({idKinFace, parentFrame}) => ( parentFrame || idKinFace ));
  }

  render() {
    const { id, cart, historiesData, step, name, ambiences, totalOTS, createdAt, updatedAt, owner, budget, editingName, result, audienceFilter, penetrationRate, loading } = this.state;

    const otsPaidTotal = formatNumber(totalOTS, true, true, false)
    const faceIds = this.getFaceIds(result);

    const dataMap = result.map((item, index) => {
      return{
        ...item,
        idKinFace: item.idKinFrame,
        location: {
          x: item.location.y,
          y: item.location.x,
        },
        cimasa: item.id,
        poi: null,
        mediaOwner: item.mediaOwner,
        dashboardType: item.installation,
        segment: item.type,
        format: item.ots,
        region: item.region,
        district: item.district,
        city: item.city,
        numberMonitors: item.numberMonitors,
        ots: item.ots
      }
    });

    let numberFrames = dataMap.length>0 && dataMap.reduce( ( sum, { numberMonitors } ) => sum + numberMonitors , 0)
    numberFrames = formatNumber(numberFrames, true, true, false)

    const title = (
      <React.Fragment>
        <h1 className="page-title mb-0 mt-0 p-0">
          <span hidden={editingName}>{name}</span>
          <Form.Input
            className={editingName ? 'form-control-lg mb-2' : 'd-none'}
            name="planName"
            placeholder="Plan name"
            onChange={this.onChangePlanName}
            value={name}
          />
          <Button color="link" icon="edit" title="Edit plan name" className={editingName && 'd-none'} onClick={()=>this.setState({editingName: !editingName})}/>
        </h1>
        <h4><small className="mb-0 mt-0 p-0">Owner: {owner} - Created: {createdAt} - Last Update: {updatedAt}</small></h4>
      </React.Fragment>
    )

    const subTitle = (
      <React.Fragment>
        <Grid.Row className={!editingName && 'd-none'}>
            <Grid.Col className="p-0 m-0">
              <Button color="link" onClick={this.onClickSavePlanName}>Save</Button>
            </Grid.Col>
          </Grid.Row>
      </React.Fragment>
    )

    const options = (
      <React.Fragment>
        <div className="btn-list-flex">
          <Button.List>
            {
              step === 0 &&
                <>
                  <Button loading={loading} color="secondary" size="lg" icon="users" disabled={loading} onClick={this.openAudienceFilter}>
                    Audience
                    <Badge color="info" className="ml-1">
                      {audienceFilter ? "ON" : "OFF"}
                    </Badge>
                  </Button>
                  <DoohDrawer loading={loading} id={id} list={cart} cartData={this.state.cartData} handleChangeStep={this.handleChangeStep} onRemove={this.deleteFromCart}>
                    <Button.List>
                      <Button loading={loading} color="secondary" size="lg" icon="shopping-cart" disabled={loading || cart.length<=0}>
                      Cart
                        <Badge color="primary" className="ml-1">
                          {cart.length}
                        </Badge>
                      </Button>
                      <Button loading={loading} color="primary" size="lg" disabled={loading || cart.length<=0}>
                        Next...
                      </Button>
                    </Button.List>
                  </DoohDrawer>
                </>
            }
            {
              step == 1 &&
                <Popconfirm
                  placement="bottom"
                  title={"If you go back you may lose your changes. Are you sure?"}
                  onConfirm={()=>this.handleChangeStep(step - 1)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button color="secondary" size="lg">
                    Back
                  </Button>
                </Popconfirm>
            }
            {
              (step > 1 && step < 3) &&
                <Button color="secondary" size="lg" onClick={()=>this.handleChangeStep(step - 1)}>
                  Back
                </Button>
            }
            {
              (step > 0 && step <= 2) &&
                <Button color="primary" size="lg" onClick={()=>this.handleChangeStep(step + 1)} disabled={ambiences.filter(el => el.visible).length === 0}>
                  {step === 2 ? "Confirm" : "Next"}
                </Button>
            }
            {
              step >= 3 &&
              <>
              
                <Button color="secondary" icon="settings" onClick={()=>this.handleChangeStep(1)}>
                  Organize
                </Button>
                <Button color="secondary" icon="sliders" onClick={()=>this.handleChangeStep(2)}>
                  Define plan
                </Button>
              </>
            }
            {
              step >= 3 &&
              <Button.Dropdown color="primary" className="ml-2" value="Export Xls" icon="download">
                <Dropdown.Item onClick={this.onClickExportLocations}>List</Dropdown.Item>
                <Dropdown.Item onClick={this.onClickExportQuotes}>Proposal</Dropdown.Item>
              </Button.Dropdown>
            }
          </Button.List>
          
        </div>
      </React.Fragment>
    )

    return (
      <SiteWrapper {...this.state}>
        <Page.Content className="page-content-wide page-content-wide-fluid-s">
          <Page.Header title={title} subTitle={subTitle} options={options}/>
          <Grid.Row cards deck>
            {
              step < 3 &&
              <Grid.Col sm={12}>
                <DoohStepper current={step} labels={this.getStepLabelsArray()}/>
              </Grid.Col>
            }
            <Grid.Col sm={12}>
              { step < 3 &&
                <Card className={step !== 1 && "py-4 px-5"}>
                  { step === 0 && 
                    <DoohPlanCart
                      handleAddToCart={this.addToCart}
                      cart={cart}
                      audienceFilter={this.state.audienceFilterActive}
                    />
                  }

                  { step === 1 &&
                    <DoohPlanAmbiences
                      histories={historiesData}
                      ambiences={ambiences}
                      handleAddAmbience={this.handleAddAmbience}
                      handleDeleteAmbience={this.handleDeleteAmbience}
                      handleUpdateAmbience={this.handleUpdateAmbience}
                      handleUpdateAmbiences={this.handleUpdateAmbiences}
                      loading={loading}
                    />
                  }

                  { step === 2 &&
                    <DoohPlanQuotes
                      idEstimation={id}
                      ambiences={ambiences}
                      budget={budget}
                      audienceFilter={this.state.audienceFilterActive}
                      loading={loading}
                      handleUpdateAmbience={this.handleUpdateAmbience}
                      handleUpdateAmbiences={this.handleUpdateAmbiences}
                      handleChangeBudget={this.handleChangeBudget}

                    />
                  }
                </Card>
              }

              { step >= 3 &&
                <Card>
                  <Card.Header>
                    {/* <Card.Title>Your search results: {result.length} frames</Card.Title> */}
                    <Card.Options>
                      <Button className="mr-2" loading={loading} color="secondary" icon="users" disabled={true} onClick={this.openAudienceFilter}>
                        Audience
                        <Badge color={audienceFilter ? "info" : "secondary"} className="ml-1">
                          {audienceFilter ? audienceFilter.label : "OFF"}
                        </Badge>
                      </Button>
                      <Button icon="list" color={step===3 ? "primary" : "secondary"} onClick={()=>this.handleChangeStep(3, false)} disabled={loading}>
                        LIST
                      </Button>
                      <Button className="ml-2" icon="file-text" color={step===5 ? "primary" : "secondary"} onClick={()=>this.handleChangeStep(5, false)} disabled={loading}>
                        PROPOSAL
                      </Button>
                      <Button className="ml-2" icon="map" color={step===4 ? "primary" : "secondary"} onClick={()=>this.handleChangeStep(4, false)} disabled={loading}>
                        MAP
                      </Button>
                      {
                        this.state.audienceFilterActive &&
                        <Button className="ml-2" icon="pie-chart" color={step===6 ? "primary" : "secondary"} onClick={()=>this.handleChangeStep(6, false)} disabled={loading}>
                          INSIGHT
                        </Button>
                      }
                    </Card.Options>
                  </Card.Header>
                  <Card.Body className="m-0 p-0 card-body-planner-dooh">
                    <Dimmer active={loading} loader={loading}>
                      {
                        step === 3 &&
                        <div className="px-2">
                          <DoohPlanCart cart={result} readonly audienceFilter={this.state.audienceFilterActive}/>
                        </div>
                      }

                      {
                        step === 4 &&
                        <>
                          <Card.Map className="shadow">
                            <MapPlanner 
                              data={dataMap}
                              typeFrame="dooh" 
                              extra={{otsPaidTotal, numberFrames, penetrationRate}} 
                              selectedMarker={this.state.selectedMarker}
                              enableSearch 
                              handleMarkerSelected={this.handleMarkerSelected} 
                              audienceFilter={this.state.audienceFilterActive}
                            />
                          </Card.Map>
                        </>
                      }

                      {
                        step === 5 &&
                        <div className="py-3 px-5">
                          <DoohPlanQuotes
                            readonly
                            idEstimation={id}
                            ambiences={ambiences}
                            budget={budget}
                            audienceFilter={this.state.audienceFilterActive}
                            loading={loading}
                          />
                        </div>
                      }
                      {
                        step === 6 &&
                        <div className="p-0">
                          <DoohInsight
                            ots={otsPaidTotal}
                            numberFrames={numberFrames}
                            faceIds={faceIds}
                            audienceFilter={this.state.audienceFilter}
                            allData={result}
                          />
                        </div>
                      }
                    </Dimmer>
                    <DetailMap type="dooh" marker={this.state.selectedMarker} dataMap={dataMap} audienceFilter={this.state.audienceFilterActive} onClose={()=>this.setState({selectedMarker: null})} />
                  </Card.Body>
                </Card>
              }
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      </SiteWrapper>
    )
  }
}


export default DoohPlanner;
