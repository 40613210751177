// @flow

import * as React from "react";
import { Form, FormCard, FormTextInput, StandaloneFormPage } from "../../../"

class ForgotPasswordPageSetPsw extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            isLoading: false,
            checkCode: "",
            password: "",
            checkPassword: ""
        }
    }

    render(){
        const {
            action,
            method,
            onSubmit,
            onChange,
            onBlur,
            values,
            username,
            code,
            email,
            isLoading,
            errorMessages,
        } = this.props;

        return (
            <StandaloneFormPage imageURL={"/demo/brand/kinetic.png"}>
                <FormCard
                    buttonText={"Save password"}
                    buttonLoading={isLoading}
                    title={"Change new password"}
                    onSubmit={onSubmit}
                    action={action}
                    method={method}
                >

                <Form.Group label="Username">
                    <Form.StaticText>
                        {username}
                    </Form.StaticText>
                </Form.Group>

                <FormTextInput
                    name="password"
                    label={"New password"}
                    placeholder={"Insert new password"}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={values && values.code}
                    error={errorMessages && errorMessages.pass}
                    type="password"
                />
                <FormTextInput
                    name="checkPassword"
                    label={"Confirm new password"}
                    placeholder={"Confirm new password"}
                    error={errorMessages && errorMessages.check}
                    type="password"
                />
                </FormCard>
            </StandaloneFormPage>
        )
    }
}

export default ForgotPasswordPageSetPsw;



