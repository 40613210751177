import React, { useState, useEffect } from 'react';
import {Redirect} from "react-router-dom";
import {
  Button,
  Page,
  Grid,
  Card,
} from "tabler-react";

import SiteWrapper from "../SiteWrapper.react";
import PanelDataAssetByRegion from './PanelData.AssetByRegion.react'
import PanelWrongFieldReversed from './PanelData.WrongFieldReversed.react'
import PanelDataAssetByMO from './PanelData.AssetByMO.react'
import PanelDataAssetDistinctByLocation from './PanelData.idKinFaceByLocation.react'
import PanelDataTools from './PanelData.Tools.react'

function PanelData(props) {

  const [isAdmin, setIsAdmin] = useState(true)

  useEffect(()=>{
    const type = localStorage.getItem("type")
    const isSuperAdmin = type === "admin"
    const isUserAdmin = type === "user"
    setIsAdmin(isSuperAdmin || isUserAdmin)
  }, [])

  if(!isAdmin){
    return <Redirect
        to={{ pathname: "/404", state: { from: props.location } }}
    />
  }

  const buttonImport = isAdmin && <Button className="mr-4" color="link" icon="upload" RootComponent="a" href="/asset/import/planner">Import Asset</Button>

  return (
    <SiteWrapper>
      <Page.Content>
        <Page.Header
          title="Panel Data"
          options={buttonImport}
        />
        <Grid.Row cards deck>
          <Grid.Col sm={12}>
            <Card className="p-4">
              <div className="row">
                <div className="col-6">

                  <div className="row">
                    <div className="col-12">
                      <PanelDataAssetByMO/>
                    </div>
                    <div className="col-12">
                      <PanelDataAssetByRegion/>
                    </div>
                    <div className="col-12">
                      <PanelWrongFieldReversed/>
                    </div>
                    <div className="col-12">
                      <PanelDataAssetDistinctByLocation/>
                    </div>
                  </div>
              
                </div>

                <div className="col-6">
                  <PanelDataTools/>
                </div>
              </div>
              
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  )
}

export default PanelData;
