// @flow

import * as React from "react";

import {
  Page,
  Grid,
  Card,
  Container,
  Nav,
  Table,
  Button,
  Dimmer,
  colors
} from "tabler-react";

import SiteWrapper from "../SiteWrapper.react";

import UploadFile from "../components/UploadDataAsset/upload/Upload"
import '../components/UploadData/UploadData.scss'

class DoohAssetImport extends React.Component {
    constructor(props){
        super(props)
        this.state = ({
            assetDoohInfo: [
                {
                  assetDooh: '',
                  numPanels: 0
                }
            ],
            isUploaded: false,
            isLoading: false,
            filename: '',
            numPanels: null,
            error: ""
        })
        this.handlerUploadProgress = this.handlerUploadProgress.bind(this)
        this.handlerResponse = this.handlerResponse.bind(this)
    }

    handlerUploadProgress(progress) {
        this.setState({
          isUploaded: progress,
          isLoading: true
        })
        console.log('[IMPORT] Caricamento effettuato!')
    }

    handlerResponse(result, filename){
        console.log('[IMPORT] response....')
        console.log(result)
        this.setState({
          numPanels: result,
          filename,
          isLoading: false
        })
    }

    render() {
      const {filename, numPanels} = this.state
      const title = (
          <React.Fragment>
              <h1 className="page-title mb-0 mt-0 p-0">Import DOOH Asset</h1>
          </React.Fragment>
      )

      return (
      <SiteWrapper {...this.state}>
          <Page.Content>
              <Page.Header
                  title={title}
                  // options={options}
              />
              <Grid.Row cards deck>
                  <Grid.Col sm={12}>
                      <Card className="bg-light">
                          <Container>
                              <Nav className="bg-white">
                                  <Nav.Item value="Upload file" active />
                              </Nav>
                          </Container>
                      </Card>
                  </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                  <Grid.Col sm={12} className="card-p-0">
                      <Card className="bg-light">
                          <Container>
                              <UploadFile handlerUploadProgress={this.handlerUploadProgress} handlerResponse={this.handlerResponse} type="dooh" />
                          </Container>
                      </Card>

                  </Grid.Col>
              </Grid.Row>
              {this.state.isUploaded &&
                  <Dimmer active={this.state.isLoading} loader={this.state.isLoading}>
                      <Grid.Row cards deck>
                          <Grid.Col sm={12}>
                              <Card className="bg-light" title="Imported Asset DOOH">
                                  <Table striped={true} responsive>
                                      <Table.Header>
                                          <Table.ColHeader>Asset</Table.ColHeader>
                                          <Table.ColHeader>N° Panels</Table.ColHeader>
                                      </Table.Header>
                                      <Table.Body>
                                          {[this.state.assetDoohInfo] ? [this.state.assetDoohInfo].map((c, index) => {
                                                return <Table.Row key={index}>
                                                    <Table.Col>{filename}</Table.Col>
                                                    <Table.Col><strong>{numPanels}</strong></Table.Col>
                                                </Table.Row>
                                            }) : <div>Asset not be able to load ({this.state.error})</div>
                                          }
                                      </Table.Body>
                                  </Table>
                              </Card>
                          </Grid.Col>
                      </Grid.Row>
                      <Grid.Row>
                          <Grid.Col className="text-right">
                              <Button color="primary" RootComponent="a" href={"/dooh/plan"}>Back to DOOH Plan</Button>
                          </Grid.Col>
                      </Grid.Row>
                  </Dimmer>
              }
          </Page.Content>
      </SiteWrapper>
      );
    }
}

export default DoohAssetImport;
