// @flow

import * as React from "react";

import {
  Page,
  Grid,
  Card,
  Dropdown,
  Table,
  Form,
  Icon,
  Dimmer,
  Button
} from "tabler-react";
import {DatePicker} from 'antd';
import moment from 'moment';

import CircularProgressbar from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import SiteWrapper from "../SiteWrapper.react";
import Pagination from "../components/Pagination"
import DownloaderPolling from "../components/DownloaderPolling"
import { campaignService, statsService } from "../_services";

const monthFormat = 'YYYY-M';
const monthFormatFE = 'MMMM YYYY';
const today = new Date()
const todayStr = (today.getFullYear('Y').toString())+ '-' + (today.getMonth()+1)

class Campaigns extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      userInfo: [],
      campaigns: [],
      campaignsState: [],
      lastCampaignsPercCheck: [],
      activePage: 1,
      itemsCountPerPage: 15,
      totalItemsCount: 0,
      maxPageRangeDisplayed: 5,
      offsetQuery: 0,
      sendingQuery: false,
      querySearch: "",
      dateSearch: todayStr,
      error: null,
      isAdvertiser: true,
      isLoading: true,
    };

    this.handleChangeQuery = this.handleChangeQuery.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handlerChangePublish = this.handlerChangePublish.bind(this);
    this.getCampaignReport = this.getCampaignReport.bind(this);
  }

  timer = null

  componentDidMount() {
    this.getLocalUserInfo()
    this.setState({
      user: JSON.parse(localStorage.getItem("user")),
      campaigns: [],
    });
    this.loadData(this.state.dateSearch, "%2A", this.state.offsetQuery, this.state.itemsCountPerPage, true);
  }

  getLocalUserInfo(){
    if(localStorage.getItem("type") == "admin" || localStorage.getItem("type") == "user"){
        this.setState({
            isAdvertiser: false
        })
    }
    else{
        this.setState({
            isAdvertiser: true
        })
    }
  }

  loadData(date, query, offset, limit, desc){
    campaignService.getCampaignsFindQuery(date, query, offset, limit, desc).then(
      result => {
        if(result.content){
          this.setState({
            campaigns: result.content,
            totalItemsCount: result.totalElements,
            isLoading: false
          }, () => this.loadCampaignsCertification())
        }
        else{
          this.setState({isLoading: false})
        }
      },
      error => {
        this.setState({
          error,
          isLoading: false
        })
      }
    );
  }

  loadCampaignsCertification(){
    let arrayChecks = []
    let arrayStatuses = []
    let arrayCamp = this.state.campaigns

    for(let i=0; i<arrayCamp.length; i++){
      arrayChecks[i] = {trackingPanels: -1, totalPanels: -1}
      arrayStatuses[i] = arrayCamp[i].inReport
      statsService.getStatsCertificationByCampaign(arrayCamp[i].campaignId).then(
        result => {
          arrayChecks[i] = result
          this.setState({
            lastCampaignsPercCheck: arrayChecks,
            campaignsState: arrayStatuses
          })
        },
        error => {
          console.error(error);
        }
      );
    }

    this.setState({
      lastCampaignsPercCheck: arrayChecks,
      campaignsState: arrayStatuses
    })
  }

  getCampaignReport(id){
    if(id){
        campaignService.getXlsCampaign(id);
    }
  }

  handlePageChange = (pageNumber) => {
    let offset = pageNumber-1
    this.setState({
      activePage: pageNumber,
      offsetQuery: offset,
      isLoading: true
    });

    this.loadData(this.state.dateSearch, this.state.querySearch, offset, this.state.itemsCountPerPage, true);
  }

  handleChangeQuery(ev) {
    this.setState({
      querySearch: ev.target.value
    })
    if(this.state.sendingQuery) {
      clearTimeout(this.state.sendingQuery);
    }

    this.setState({
      sendingQuery: setTimeout(this.handleQuery, 1000),
      isLoading: true
    })
  }

  handlerChangePublish(ev, index){
    if(localStorage.getItem("type") == "admin" || localStorage.getItem("type") == "user"){
      let id = this.state.campaigns[index].campaignId
      let arr = this.state.campaignsState
      arr[index] = arr[index]==1 ? 0 : 1

      campaignService.setReportByIdCampaign(id, arr[index]).then(
        result => {
          this.setState({
            campaignsState: arr
          })
          // , ()=>this.callInitializeData())
        },
        error => {
          console.error(error);
        }
      );
    }
  }

  callInitializeData(){
    statsService.initializeData()
  }

  handleQuery = e => {
    this.loadData(this.state.dateSearch, this.state.querySearch, this.state.offsetQuery, this.state.itemsCountPerPage, true);
    this.setState({sendingQuery: false})
  }

  handleChangeDate(momObj) {
    let dateStr;
    if(momObj === null){
      dateStr = todayStr
    }
    else{
      dateStr = moment(momObj).format()
      dateStr = dateStr.split('T')[0]
      dateStr = dateStr.split('-')[0] + '-' + parseInt(dateStr.split('-')[1])
    }

    this.setState({
      dateSearch: dateStr,
      activePage: 1,
      offsetQuery: 0,
      isLoading: true
    }, () => this.loadData(dateStr, this.state.querySearch, 0, this.state.itemsCountPerPage, true))
  }

  itemNotFound(){
    if(this.state.totalItemsCount<=0 && this.state.error === null){
      return(
        <Card.Alert color="secondary">
          No campaign found
        </Card.Alert>
      )
    }
  }

  errorLoad(){
    if(this.state.error !== null){
      return(
        <Card.Alert color="danger">
          Error: "{this.state.error.toString()}"
        </Card.Alert>
      )
    }
  }

  render() {
    const options = (
      <React.Fragment>
        <div hidden={this.state.querySearch != ""}>
          {/* <Form.DatePicker disabled={this.state.querySearch != ""} format="mm/yyyy" minYear={2018} key={this.state.dateSearch} onChange={ev => this.handleChangeDate(ev)} /> */}
          <DatePicker
            className="mr-1"
            size="large"
            defaultValue={moment(this.state.dateSearch, monthFormat)}
            value={moment(this.state.dateSearch, monthFormat)}
            format={monthFormatFE}
            picker="month"
            onChange={(ev, value) => this.handleChangeDate(ev)}
            style={{height: "38px", minWidth: 162}}
          />
        </div>
        <Form.Input icon="search" placeholder="Search campaign" onChange={ev => this.handleChangeQuery(ev)}  />
        {localStorage.getItem("type") == "admin" ?
          <Button className="ml-1" color="primary" RootComponent="a" href="/campaigns/import">Import Campaign</Button>
          : ""
        }
      </React.Fragment>
    );

    return (
      <SiteWrapper {...this.state}>
        <Page.Content>
          <Page.Header
            title="Campaigns"
            subTitle={this.state.totalItemsCount + " campaigns found"}
            options={options}
          />
          <Grid.Row cards deck>
            <Grid.Col sm={12}>
              <Card>
                <Dimmer active={this.state.isLoading} loader={this.state.isLoading}>
                  <Table striped={true}>
                    <Table.Header>
                      <Table.ColHeader>Advertiser</Table.ColHeader>
                      <Table.ColHeader>Campaign</Table.ColHeader>
                      <Table.ColHeader>Start Date</Table.ColHeader>
                      <Table.ColHeader>End Date</Table.ColHeader>
                      <Table.ColHeader>Status</Table.ColHeader>
                      <Table.ColHeader alignContent="center">Photo check (%)</Table.ColHeader>
                      <Table.ColHeader alignContent="center">Action</Table.ColHeader>
                      {(localStorage.getItem("type") == "admin" || localStorage.getItem("type") == "user") ?
                        <Table.ColHeader>Publish</Table.ColHeader>
                        : ""
                      }
                    </Table.Header>
                    <Table.Body>
                      {this.state.campaigns.map((c, index) => {
                        if(this.state.lastCampaignsPercCheck.length==this.state.campaigns.length){
                              const trackingPanels = this.state.lastCampaignsPercCheck[index].trackingPanels
                              const totalPanels = this.state.lastCampaignsPercCheck[index].totalPanels
                              const trackingPerc = totalPanels != 0 ? ((trackingPanels / totalPanels) * 100).toFixed(0) : 0
                              const isLoading = trackingPanels == -1 ? true : false
                              let status = '';
                              switch(c.status){
                                case "END":
                                  status = (c.inReport == 0 ? "PENDING" : c.status)
                                  break;
                                case "ACTIVE":
                                  status = "ON AIR"
                                  break;
                                case "imported":
                                  status = "IMPORTED"
                                  break;
                                default:
                                  status = "SCHEDULED"
                              }

                              return <Table.Row key={index}>
                                <Table.Col>{c.advertiser}</Table.Col>
                                <Table.Col>{c.campaign}</Table.Col>
                                <Table.Col>{c.startDate}</Table.Col>
                                <Table.Col>{c.endDate}</Table.Col>
                                <Table.Col>{status}</Table.Col>

                                <Table.Col className="p-0 pt-2">
                                  {!isLoading ?
                                    <div style={{ width: "2.2em", margin: "auto"}} title={"Tracking: " + trackingPanels + " (Total: "+ totalPanels + ")"}>
                                      <CircularProgressbar
                                        percentage={trackingPerc}
                                        text={`${trackingPerc}`}
                                        strokeWidth={11}
                                        initialAnimation={0}
                                        styles={{
                                          path: {
                                            // Path color
                                            stroke: `#666`,
                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                            strokeLinecap: "round",
                                            // Customize transition animation
                                            transition: "stroke-dashoffset 1.5s ease .2s",
                                          },
                                          text: {
                                            fill: "#666",
                                            fontSize: "2.8em",
                                            fontWeight: "bold",
                                          },
                                        }}
                                      />
                                    </div>
                                    : <Dimmer active={true} loader={true}>-</Dimmer>
                                  }
                                </Table.Col>

                                <Table.Col alignContent="center">
                                  <a href={'/campaigns/'+c.campaignId+'/map'}>
                                    <Icon link={true} name="map-pin" className="m-1" />
                                  </a>
                                  <a href={'/campaigns/'+c.campaignId+'/photo'}>
                                    <Icon link={true} name="camera" className="m-1" />
                                  </a>

                                  <Dropdown className="mr-1 ml-1"
                                    trigger={
                                      <Dropdown.Trigger
                                        icon="more-vertical"
                                        className="text-primary"
                                        toggle={false}
                                      />
                                    }
                                    position="right"
                                    items={
                                      <React.Fragment>
                                        <Dropdown.Item disabled={true} icon="tag"><span disabled href="#" className="text-muted">Audience</span></Dropdown.Item>
                                        {localStorage.getItem("type") == "admin" ||  localStorage.getItem("type") == "user" ?
                                          <Dropdown.Item icon="edit"><a href={'/campaigns/'+c.campaignId+'/justificative'}>Manage</a></Dropdown.Item>
                                          : ""
                                        }
                                        {localStorage.getItem("type") == "admin" ?
                                          <Dropdown.Item icon="image"><a href={'/campaigns/import/id/'+c.campaignId}>Edit Campaign / Upload Ad</a></Dropdown.Item>
                                          : ""
                                        }
                                        <Dropdown.Item icon="download"><a href="#" onClick={() => this.getCampaignReport(c.idKinCampaign)}>Download Xls</a></Dropdown.Item>
                                        {/* <DownloaderPolling className="dropdown-item" labelButton=" Download Zip" classIcon={"fe fe-download dropdown-icon"}  idkc={c.idKinCampaign}/> */}
                                      </React.Fragment>
                                    }
                                  />
                                </Table.Col>
                                {(localStorage.getItem("type") == "admin" || localStorage.getItem("type") == "user")
                                  && this.state.campaignsState.length==this.state.campaigns.length ?
                                  <Table.Col>
                                    <Form.Group label="" className="p-0 m-0">
                                      <Form.Switch
                                        checked={this.state.campaignsState[index] == 1 ? true : false}
                                        name={"enable"+index}
                                        value="enabled"
                                        label=""
                                        onClick={(ev) => this.handlerChangePublish(ev, index)}
                                      />
                                    </Form.Group>
                                  </Table.Col>
                                  : ""
                                }

                              </Table.Row>
                            }
                            else{
                              return '-'
                            }
                      })}
                    </Table.Body>
                  </Table>
                  {this.itemNotFound()}
                  {this.errorLoad()}
                </Dimmer>
              </Card>

            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              {
                !this.state.isLoading ?
                  <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.itemsCountPerPage}
                    totalItemsCount={this.state.totalItemsCount}
                    maxPageRangeDisplayed={this.state.maxPageRangeDisplayed}
                    handlePageChange={this.handlePageChange}
                  /> : ''
              }
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      </SiteWrapper>
    );
  }
}

export default Campaigns;
