// @flow

import * as React from "react";
import {Redirect} from "react-router-dom";
import {
  Page,
  Grid,
  Card,
  Table,
  Icon,
  Dimmer,
  Button,
  Badge,
  Form,
} from "tabler-react";

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {DatePicker} from 'antd'
import "react-circular-progressbar/dist/styles.css";
import moment from 'moment'

import SiteWrapper from "../SiteWrapper.react";
import Pagination from "../components/Pagination"
import { searchService } from "../_services";
import ShareManage from "../Share/ShareManage";

const monthFormat = 'YYYY-M';
const monthFormatFE = 'MMMM YYYY';
const today = new Date()
const todayStr = (today.getFullYear('Y').toString())+ '-' + (today.getMonth()+1)

class PlannerOpen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      userInfo: [],
      campaigns: [],
      campaignsState: [],
      lastCampaignsPercCheck: [],
      activePage: 1,
      itemsCountPerPage: 15,
      totalItemsCount: 0,
      maxPageRangeDisplayed: 5,
      offsetQuery: 0,
      sendingQuery: false,
      querySearch: "",
      dateSearch: todayStr,
      error: null,
      isKineticUser: true,
      isAdmin: false,
      isLoading: true,

      deletedPlan: false,
      deletingPlanId: null,
      deletingPlanName: "",
    };

    this.handleChangeQuery = this.handleChangeQuery.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.toggleModalDelete = this.toggleModalDelete.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this)
  }

  timer = null

  componentDidMount() {
    this.getLocalUserInfo()
    this.setState({
      user: JSON.parse(localStorage.getItem("user")),
      campaigns: [],
    });
    this.loadData(this.state.dateSearch, "", this.state.offsetQuery, this.state.itemsCountPerPage, true);
  }

  getLocalUserInfo(){
    if(localStorage.getItem("type") === "admin" || localStorage.getItem("type") === "user" || localStorage.getItem("type") == "companyleader" || localStorage.getItem("type") == "companyuser"){
        this.setState({
          isKineticUser: true
        })
    }
    else{
        this.setState({
          isKineticUser: false
        })
    }
    if(localStorage.getItem("type") === "admin"){
      this.setState({isAdmin: true})
    }
    else{
      this.setState({isAdmin: false})
    }
  }

  loadData(date, query, offset, limit, desc){
    searchService.getPlansFindQuery(date, query, offset, limit, desc).then(
      result => {
        let plans = result.content
        // if(localStorage.getItem("type") == "companyleader" || localStorage.getItem("type") == "companyuser"){
        //   const user = JSON.parse(localStorage.getItem("user"))
        //   const owner = user.username
        //   plans = plans.filter(plan => plan.owner === owner)
        // }

        this.setState({
          campaigns: plans,
          totalItemsCount: result.totalElements,
          isLoading: false
        })
      },
      error => {
        this.setState({
          error,
          isLoading: false
        })
      }
    );
  }

  handlePageChange = (pageNumber) => {
    let offset = pageNumber-1
    this.setState({
      activePage: pageNumber,
      offsetQuery: offset,
      isLoading: true
    });

    this.loadData(this.state.dateSearch, this.state.querySearch, offset, this.state.itemsCountPerPage, true);
  }

  handleChangeQuery(ev) {
    this.setState({
      querySearch: ev.target.value
    })
    if(this.state.sendingQuery) {
      clearTimeout(this.state.sendingQuery);
    }

    this.setState({
      sendingQuery: setTimeout(this.handleQuery, 1000),
      isLoading: true
    })
  }


  handleQuery = e => {
    this.loadData(this.state.dateSearch, this.state.querySearch, this.state.offsetQuery, this.state.itemsCountPerPage, true);
    this.setState({sendingQuery: false})
  }

  handleChangeDate(momObj) {
    let dateStr;
    if(momObj === null){
      dateStr = todayStr
    }
    else{
      dateStr = moment(momObj).format()
      dateStr = dateStr.split('T')[0]
      dateStr = dateStr.split('-')[0] + '-' + parseInt(dateStr.split('-')[1])
    }

    this.setState({
      dateSearch: dateStr,
      activePage: 1,
      offsetQuery: 0,
      isLoading: true
    }, this.loadData(dateStr, this.state.querySearch, 0, this.state.itemsCountPerPage, true))
  }


  toggleModalDelete(deletingPlanId = null, deletingPlanName = "") {
    this.setState(prevState => ({
      isOpenModalDelete: !prevState.isOpenModalDelete,
      deletedPlan: false
    }));

    if(deletingPlanId){
      this.setState({deletingPlanId, deletingPlanName})
    }
  }

  onClickDelete(ev){
    let id = this.state.deletingPlanId;
    if(id){
        searchService.deleteSearch(id).then(
            result => {
                this.setState({
                    deletedPlan: true,
                    deletingPlanName: ""
                },()=>this.handlePageChange(this.state.activePage))
            },
            error => {
                console.error(error);
            }
        );
    }
  }

  itemNotFound(){
    if(this.state.totalItemsCount<=0 && this.state.error === null){
      return(
        <Card.Alert color="secondary">
          No plan found
        </Card.Alert>
      )
    }
  }

  errorLoad(){
    if(this.state.error !== null){
      return(
        <Card.Alert color="danger">
          Error: "{this.state.error.toString()}"
        </Card.Alert>
      )
    }
  }



  render() {
    const {isKineticUser, isAdmin} = this.state

    if(this.state.error || !isKineticUser){
      return <Redirect
          to={{ pathname: "/404", state: { from: this.props.location } }}
      />
    }

    const options = (
      <React.Fragment>
        <div hidden={this.state.querySearch != ""}>
          <DatePicker
            className="mr-1"
            size="large"
            defaultValue={moment(this.state.dateSearch, monthFormat)}
            value={moment(this.state.dateSearch, monthFormat)}
            format={monthFormatFE}
            picker="month"
            onChange={(ev, value) => this.handleChangeDate(ev)}
            style={{height: "38px", minWidth: 162}}
          />
        </div>
        <Form.Input icon="search" placeholder="Search plan" onChange={ev => this.handleChangeQuery(ev)}  />
        <Button className="ml-1" color="primary" RootComponent="a" href="/planner/plan/new">New plan</Button>

        <Modal isOpen={this.state.isOpenModalDelete} toggle={this.toggleModalDelete}>
          <ModalHeader toggle={this.toggleModalDelete}>Delete plan</ModalHeader>
          <ModalBody>
              <div className="row">
                  <div className="col p-4">
                      {!this.state.deletedPlan ?
                          <p>Are you sure you want to delete the plan <strong>{this.state.deletingPlanName}</strong>?<br/>(This action is irreversible)</p>
                          : <p className="text-success">Plan successfully deleted!</p>
                      }
                  </div>
              </div>
          </ModalBody>
          {!this.state.deletedPlan ?
              <ModalFooter>
                  <Button color="danger" onClick={this.onClickDelete} disabled={this.state.deletedPlan}>Delete</Button>{' '}
                  <Button color="secondary" onClick={this.toggleModalDelete}>Close</Button>
              </ModalFooter>
              :
              <ModalFooter>
                  <Button color="primary" onClick={this.toggleModalDelete}>Close</Button>
              </ModalFooter>
          }
        </Modal>

      </React.Fragment>
    );

    return (
      <SiteWrapper {...this.state}>
        <Page.Content>
          <Page.Header
            title="Plans"
            subTitle={this.state.totalItemsCount + " plans found"}
            options={options}
          />
          <Grid.Row cards deck>
            <Grid.Col sm={12}>
              <Card>
                <Dimmer active={this.state.isLoading} loader={this.state.isLoading}>
                  <Table striped={true}>
                    <Table.Header>
                      <Table.ColHeader>Plan Name</Table.ColHeader>
                      <Table.ColHeader>Owner</Table.ColHeader>
                      <Table.ColHeader>Created at</Table.ColHeader>
                      <Table.ColHeader>Update at</Table.ColHeader>
                      <Table.ColHeader>Frames</Table.ColHeader>
                      <Table.ColHeader alignContent="center">Audience</Table.ColHeader>
                      <Table.ColHeader alignContent="center">Action</Table.ColHeader>
                    </Table.Header>
                    <Table.Body>
                      {this.state.campaigns.map((c, index) => {

                        return <Table.Row key={index}>
                          <Table.Col>{c.name}</Table.Col>
                          <Table.Col>{c.owner}</Table.Col>
                          <Table.Col>{c.createdAt}</Table.Col>
                          <Table.Col>{c.updatedAt}</Table.Col>
                          <Table.Col>{c.size}</Table.Col>
                          <Table.Col alignContent="center">
                            <Badge color={c.audienceId === null ? "secondary" : "info"}>
                              {c.audienceId === null ? "OFF" : "ON"}
                            </Badge>
                          </Table.Col>

                          <Table.Col alignContent="center">
                            <a className="link-nodecoration" href={'/planner/plan/'+c.id+'/'} title="Open plan">
                              <Icon link={true} name="list" className="m-2 fa-lg link-nodecoration" />
                            </a>
                            <span href="#" onClick={() => this.toggleModalDelete(c.id, c.name)} title={"Delete " + c.name}>
                              <Icon link={true} name="trash" className="m-2 fa-lg link-nodecoration" />
                            </span>
                            {/* <ShareManage id={c.id} name={c.name} resourceType="planner">
                              <span className="link-nodecoration" title="Share plan...">
                                <Icon link={true} name="link" className="m-2 fa-lg link-nodecoration" />
                              </span>
                            </ShareManage> */}
                          </Table.Col>

                        </Table.Row>

                      })}
                    </Table.Body>
                  </Table>
                  {this.itemNotFound()}
                  {this.errorLoad()}
                </Dimmer>
              </Card>

            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              {
                !this.state.isLoading ?
                  <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.itemsCountPerPage}
                    totalItemsCount={this.state.totalItemsCount}
                    maxPageRangeDisplayed={this.state.maxPageRangeDisplayed}
                    handlePageChange={this.handlePageChange}
                  /> : ''
              }
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      </SiteWrapper>
    );
  }
}

export default PlannerOpen;
