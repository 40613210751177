// @flow

import * as React from "react";
import _ from "lodash"
import {
  Grid,
  Form,
  Dimmer
} from "tabler-react";

// import { justificativeService, campaignService } from "../_services";

class MediaOwnersReportFilters extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            periods: [],
            mediaowners: [],
            advertisers: [],
            selectedPeriod: "",
            selectedMedOwn: "",
            selectedAdv: "",
            isLoading: true,
            isAdvertiser: true,
            mediaOwnerGroup: ""
        }
    }

    componentDidMount(){
        this.getLocalUserInfo()

        let periods = []

        var startYear = 2018
        var currentDate = new Date();
        var currentYear = currentDate.getFullYear();

        for(let y=startYear; y <=currentYear; y++){
            periods.unshift(y)
        }

        this.setState({
            periods: periods,
            selectedPeriod: currentYear,
        })
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.mediaowners!==this.props.mediaowners){
            let mediaowners = nextProps.mediaowners
            mediaowners = _.orderBy(mediaowners, 0,['asc'])
            this.setState({
                mediaowners: mediaowners,
                isLoading: false
            })
        }

        if(nextProps.advertisers!==this.props.advertisers){
          let advertisers = nextProps.advertisers
          advertisers = _.orderBy(advertisers, 0,['asc'])
          this.setState({
            advertisers: advertisers,
            isLoading: false
          })
        }
    }


    getLocalUserInfo(){
        if(localStorage.getItem("type") == "admin" || localStorage.getItem("type") == "user" || localStorage.getItem("type") == "mediaOwner"){
            this.setState({
                isAdvertiser: false
            })
        }
        else{
            this.setState({
                isAdvertiser: false //workaround
            })
        }

        if(localStorage.getItem("type") == "mediaOwner"){
            let mediaOwnerGroup = localStorage.getItem("group") ? localStorage.getItem("group").toUpperCase() : "";
            this.setState({
                mediaOwnerGroup,
                selectedMedOwn: mediaOwnerGroup
            })
        }
    }

    handleChangeAdvertiser(ev) {
      let val = ev.target.value
      let defaultMO = this.state.mediaOwnerGroup
      this.setState({
        selectedMedOwn: defaultMO,
        selectedAdv: val,
      }, ()=> this.props.handleState(this.state))
    }

    handleChangeMediaOwner(ev) {
        let val = ev.target.value
        this.setState({
            selectedMedOwn: val,
        }, ()=> this.props.handleState(this.state))
    }

    handleChangePeriod(ev) {
        let val = ev.target.value
        this.setState({
            selectedPeriod: val,
        }, ()=> this.props.handleState(this.state))
    }

    render() {
        return(
            <Dimmer active={this.state.isLoading} loader={this.state.isLoading}>
                <Grid.Row className="mt-3">
                    <Grid.Col sm="3">
                        <Form.Group label="Advertiser">
                            <Form.Select name="advertiser" disabled={this.state.advertisers.length <= 1} onChange={ev => this.handleChangeAdvertiser(ev)}>
                                {this.state.advertisers.length > 1 && <option value="" label="All" selected={this.state.selectedAdv == ""}>All</option>}
                                {this.state.advertisers && this.state.advertisers.map((r, index) => {
                                    return <option key={index} value={r} label={r} selected={this.state.selectedAdv == r }>{r}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    </Grid.Col>
                    <Grid.Col sm="3">
                        <Form.Group label="Media Owner">
                            <Form.Select name="mediaowner" disabled={this.state.mediaOwnerGroup} onChange={ev => this.handleChangeMediaOwner(ev)}>
                                <option value="" label="All" selected={this.state.selectedMedOwn == ""}>All</option>
                                {this.state.mediaowners && this.state.mediaowners.map((r, index) => {
                                    return <option key={index} value={r} label={r} selected={this.state.selectedMedOwn == r || this.state.mediaOwnerGroup == r }>{r}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    </Grid.Col>
                    <Grid.Col sm="2" offsetSm="4">
                        <Form.Group label="Period">
                            <Form.Select name="format" onChange={ev => this.handleChangePeriod(ev)}>
                                {/* <option value="" label="All" selected={this.state.selectedFrmt == ""}/> */}
                                {this.state.periods && this.state.periods.map((r, index) => {
                                    return <option key={index} value={r} label={r} selected={this.state.selectedPeriod == r}>{r}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    </Grid.Col>
                </Grid.Row>
                {/* {this.errorLoad()} */}
            </Dimmer>
        )
    }
}

export default MediaOwnersReportFilters;
