/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { Select, Tabs, Card} from "antd";
import SyncingChart from "./SynchingCharts.Chart";
import SyncingFilters from "./SynchingCharts.Filters";
import _ from "lodash";

const { Option } = Select;
const { TabPane } = Tabs;

function CompareDays(props) {
  const {
    assetType,
    params = ["mediaOwner", "dashboardType", "circuit"],
    labels = ["Media Owner", "Type", "Circuit"],
    hiddenPeriodFilters = false
  } = props;
  
  const defaultFirstDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
  ] 
  const defaultSecondDays = [
    "Saturday",
    "Sunday",
  ]

  const [mediaOwners, setMediaOwners] = useState([])
  const [dashboardTypes, setDashboardTypes] = useState([])
  const [circuits, setCircuits] = useState([])
  const [currentMediaOwner, setCurrentMediaOwner] = useState('')
  const [currentDashboardType, setCurrentDashboardType] = useState('')
  const [currentCircuit, setCurrentCircuit] = useState('')
  const [selectedDaysTop, setSelectedDaysTop] = useState(defaultFirstDays);
  const [selectedDaysBot, setSelectedDaysBot] = useState(defaultSecondDays);


  useEffect(()=> {
    const mediaOwners = _.uniq(props.allData.map(value=> value && value[params[0]]))
    setMediaOwners(mediaOwners)
    const dashboardTypes = _.uniq(props.allData.map(value=> value && value[params[1]]))
    setDashboardTypes(dashboardTypes)
    const circuits = _.uniq(props.allData.map(value=> value && value[params[2]]))
    setCircuits(circuits)
  },[])

  const onChangeDays = (mode, value) => {
    if(mode==='top'){
      setSelectedDaysTop(value)
      setSelectedDaysBot([...selectedDaysBot])
    } else {
      setSelectedDaysBot(value)
      setSelectedDaysTop([...selectedDaysTop])
    }
  };

  const onResetFilters = () => {
    setCurrentMediaOwner('')
    setCurrentDashboardType('')
    setCurrentCircuit('')
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <p className="text-center mt-2 mb-2">
            <strong>Time when audience is the most active.</strong> Hourly Users distribution per selected days. Value expressed in %. Lookback window 12 weeks.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col mb-3">
          <SyncingFilters
            allData={props.allData}
            filters={['firstDays', 'secondDays', ...params]}
            labels={labels}
            params={params}
            hiddenPeriodFilters={hiddenPeriodFilters}
            mediaOwners={mediaOwners}
            circuits={circuits}
            dashboardTypes={dashboardTypes}
            currentMediaOwner={currentMediaOwner}
            currentCircuit={currentCircuit}
            currentDashboardType={currentDashboardType}
            firstDays={selectedDaysTop}
            secondDays={selectedDaysBot}
            onChangeDays={onChangeDays}
            onChangeMediaOwner={(e)=>setCurrentMediaOwner(e)}
            onChangeDashboardType={(e)=>setCurrentDashboardType(e)}
            onChangeCircuit={(e)=>setCurrentCircuit(e)}
            onReset={onResetFilters}
          />
        </div>
      </div>
      
      <Card
        key={'chartDouble' + JSON.stringify(selectedDaysTop) }
        size="small"
        headStyle={{padding: 0}}
        bodyStyle={{margin: 0, paddingTop: 0}}
      >
        <div className="row">
          <SyncingChart  
            chartKey={'double'}
            groupKey={'doubleGroup'}
            layout="horizontal"
            type='double'
            allData={props.allData}
            params={params}
            audienceFilter={props.audienceFilter}
            assetType={assetType}
            filters={
              {
                [params[0]]: currentMediaOwner,
                [params[1]]: currentDashboardType,
                [params[2]]: currentCircuit,
              }
            }
            firstDays={selectedDaysTop}
            secondDays={selectedDaysBot}
          />
        </div>
      </Card>
    </>
  );
}

export default CompareDays;
