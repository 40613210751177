import React from 'react';
import { Transfer } from 'antd';
import styles from 'antd/dist/antd.css';

class CompanyTransfer extends React.Component {
  state = {
    itemsData: [],
    targetKeys: [],
  };

  componentDidMount() {
    let {items, targetKeys} = this.props
    this.setData(items)
    this.setSelectedData(targetKeys)
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.items !== this.props.items){
      const {items} = nextProps
      this.setData(items)
    }

    if(nextProps.selected !== this.props.selected){
      const {selected} = nextProps
      this.setSelectedData(selected)
    }
  }

  setData = (items) => {
    items = items.map((item) => {
      let obj = {}
      obj.key = item
      obj.title = item
      return obj
    })
    this.setState({ itemsData: items });
  };

  setSelectedData = (targetKeys) => {
    console.log(targetKeys)
    this.setState({ targetKeys });
  }

  filterOption = (inputValue, option) => option.title.indexOf(inputValue.toLowerCase()) > -1 || option.title.indexOf(inputValue.toUpperCase()) > -1;

  handleChange = targetKeys => {
    this.setState({ targetKeys });
    this.props.onChange(targetKeys);
  };

  handleSearch = (dir, value) => {
    console.log('search:', dir, value);
  };

  render() {
    const {targetKeys, itemsData} = this.state
    return (
      <div className={styles}>
        <Transfer
          dataSource={itemsData}
          showSearch
          filterOption={this.filterOption}
          targetKeys={targetKeys}
          onChange={this.handleChange}
          // onSearch={this.handleSearch}
          listStyle={{
            width: "100%",
            height: 350,
          }}
          render={item => item.title}
        />
      </div>
    );
  }
}

export default CompanyTransfer;
