import * as React from "react";
import "./MapDetailsMarker.scss"

import { BlogCard, Tag, Badge, Button } from "tabler-react";
import { Modal } from "antd";
import StreetView from "../StreetView.react";
import HeatMapTableAudience from '../../../Planner/Planner.HeatMapTableAudience.react'
// import {Image} from 'antd'

class MapDetailsMarker extends React.Component {

  constructor(props){
    super(props)
    this.onClickIcon = this.onClickIcon.bind(this)
  }

  onClickIcon(){
    if(this.props.onClose){
      this.props.onClose()
    }
  }

  render(){
    const {marker, daysForContacts} = this.props
    const {region, district, city, mediaOwner, dashboardType, format, segment, idKinFace, lat, lng, numberMonitors, index} = marker
    const {className, type, iconImg, iconText, typeFrame, audienceFilter} = this.props

    let titleHeatMap = dashboardType + " " + format + " " + mediaOwner + " " + city
    titleHeatMap += " (Index: " + index + ")"

    const openHeatMap = (faceId) => {
      Modal.info({
        title: titleHeatMap,
        closable: true,
        width: 850,
        content: (
          <HeatMapTableAudience faceIds={[faceId]} audienceFilter={audienceFilter} type={typeFrame}/>
        ),
        // onOk() {},
      });
    }

    return (
      <div className={"map-details-marker " + className + " " + (type==="vertical" ? "map-details-marker-vertical" : "map-details-marker-horizontal")}>
        {
          typeFrame === 'ooh' &&
            <BlogCard
              aside={type!=="vertical"}
              title={
                city + " (" + district + ") - " + region
              }
              description={
                <div>
                  <span><strong>Media Owner:</strong> {mediaOwner}</span><br/>
                  <span><strong>Type:</strong> {dashboardType}</span><br/>
                  <span><strong>Format:</strong> {format}</span><br/>
                 {audienceFilter && 
                  <span>
                    <strong>Index:</strong>
                    {index ?
                      <Badge color="info" className="mx-1">
                        {index}
                      </Badge>
                      : " -"
                    }
                    {index && <Button className="ml-2" color="secondary" size="sm" icon="external-link"  onClick={() => openHeatMap(idKinFace)}>Heatmap</Button>}
                  </span>
                }
                </div>
              }
              profileHref={"#"}
              iconName={"x"}
              iconClick={this.onClickIcon}
              authorName={iconText}
              avatarImgSrc={iconImg}
              date={"IDKINFACE: " + idKinFace}
            >
              <div className="row">
                <div className="col-12">
                  <div className="map-details-marker-streetview">
                  {
                    (lat && lng) && <StreetView lat={lat} lng={lng} minimal={true} />
                  }
                  </div>
                </div>
              </div>
            </BlogCard>
        }

        {
          typeFrame === 'dooh' &&
            <BlogCard
              title={ city + " (" + district + ") - " + region}
              description={
                <div>
                  <span><strong>Publisher:</strong> {mediaOwner}</span><br/>
                  <span><strong>Environment:</strong> {segment}</span><br/>
                  <span><strong>Type:</strong> {dashboardType}</span><br/>
                  <span><strong>Frames:</strong> {numberMonitors}</span>
                </div>
              }
              profileHref={"#"}
              iconName={"x"}
              iconClick={this.onClickIcon}
              authorName={iconText}
              avatarImgSrc={iconImg}
            />
        }

        {
          typeFrame === 'bigimpact' &&
            <BlogCard
              aside={type!=="vertical"}
              title={mediaOwner && mediaOwner.toUpperCase()}
              description={
                <div>
                  <span><strong>Location:</strong> {city + " - " +marker.placeName + " " + marker.address}</span><br/>
                  <span><strong>Type:</strong> {marker.size} - {marker.mq}</span><br/>
                  <span><strong>Date:</strong> Update: {marker.lastUpdate} - Start {marker.startDate} - End {marker.endDate}</span>
                </div>
              }
              profileHref={"#"}
              iconName={"x"}
              iconClick={this.onClickIcon}
              authorName={iconText}
              avatarImgSrc={"https://www.pubblicomnow-online.it/wp-content/uploads/2019/12/Affissione-Air-Italy-800x445.jpg"}
              date={
                <>
                  <h5>{marker.contacts} per day</h5>
                  {
                    daysForContacts > 1 &&
                    <h5>
                      <Tag color="info">{marker.contacts * daysForContacts} per {daysForContacts} days</Tag>
                    </h5>
                  }
                </>
              }
            >
              <div className="row">
                <div className="col-12">
                  <div className="map-details-marker-streetview">
                  {
                    (lat && lng) && <StreetView lat={lat} lng={lng} minimal={true} />
                  }
                  </div>
                </div>
              </div>
            </BlogCard>
        }
      </div>
    )
  }
}

export default MapDetailsMarker;
