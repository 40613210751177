// @flow

import * as React from "react";

import {
  Form,
  Alert,
  Dimmer,
  Button,
  Avatar,
  Tag
} from "tabler-react";

import "./UserManage.scss"
import { userService, companyService } from "../_services";
import { toast } from 'react-toastify';

const generator = require('generate-password');

const typesList = userService.getTypesList()

const INIT_USER = {
  userId: null,
  name: '',
  surname: '',
  username: '',
  password: '',
  avatarUrl: null,
  companySelected: '',
  typeSelected: ''
}

class UserManageForm extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      errorMessage: "",
      success: false,
      loading: false,
      companiesList: [],
      companySelected: '',
      isEditRequest: false,
      issetFile: false,
      ...INIT_USER,
      isAdmin: false,
      visiblePassword: true
    }
  }

  componentDidMount(){
    const user = JSON.parse(localStorage.getItem("user"));
    if(user.type==='admin'){
      this.setState({
        isAdmin: true
      })
    }

    const {isEditRequest, username, name, surname, userId, companySelected, typeSelected, userAvatarUrl} = this.props
    if(isEditRequest){
      this.setState({isEditRequest, username, name, surname, userId, companySelected, typeSelected, userAvatarUrl})
    }

    this.setState({
      loading: true
    }, this.loadAdvertisers)
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.isEditRequest!==this.props.isEditRequest){
      const {isEditRequest, username, name, surname, userId, companySelected, typeSelected, userAvatarUrl} = this.props
      this.setState({isEditRequest, username, name, surname, userId, companySelected, typeSelected, userAvatarUrl})
    }
  }

  loadAdvertisers = () => {
    companyService.readCompanyAll().then(
      result => {
        this.setState({
          companiesList: result,
          loading: false
        })
      },
      error => {
        console.error(error);
      }
    )
  }

  handleChangeAvatar = (ev) => {
    this.setState({
      issetFile: ev.target.files
    })
  }

  handleChangeAdvertisers = (advertisersSelected) => {
    this.setState({
      advertisersSelected
    })
  }

  onChangeUsername = (ev) => {
    const {value} = ev.target
    this.setState({
      username: value
    })
  }

  onChangePwd = (ev) => {
    const {value} = ev.target
    this.setState({
      password: value
    })
  }

  onChangeFirstname = (ev) => {
    const {value} = ev.target
    this.setState({
      name: value
    })
  }

  onChangeSurname = (ev) => {
    const {value} = ev.target
    this.setState({
      surname: value
    })
  }

  onChangeType = (ev) => {
    const {value} = ev.target
    this.setState({
      typeSelected: value
    })
  }

  onChangeCompany = (ev) => {
    const {value} = ev.target
    this.setState({
      companySelected: value
    })
  }

  uploadAvatar = (e) => {
    e.preventDefault() // Stop form submit
    this.setState({
      buttonAssignActive: false
    })

    let formData = new FormData()
    const file = this.state.issetFile[0]

    formData.append("logo", file, file.name);
    formData.append("name", file.name);

    companyService.uploadLogo(formData).then(
        result => {
          const userAvatarUrl = result.id
          this.setState({
            userAvatarUrl
          })
          toast.success("Avatar uploaded!")
        },
        error => {
          console.error(error);
        }
    );
  }

  onClickSave = (callbackAfterFinish = false) => {
    const {userId, username, password, userAvatarUrl, companySelected, typeSelected, name, surname, isEditRequest} = this.state

    let userObj = {
      name,
      surname,
      username,
      password,
      group: "",
      avatarUrl: userAvatarUrl,
      companyId: companySelected,
      type: typeSelected,
      isActive: 1
    }

    if(isEditRequest && (userId !== null)){
      delete userObj.password
      this.updateUser(userId, userObj, callbackAfterFinish)
    }
    else{
      this.createUser(userObj, callbackAfterFinish)
    }
  }

  createUser = (user, callbackAfterFinish = false) => {
    this.setState({
      errorMessage: "",
      success: false,
      loading: true
    })

    userService.createUser(user).then(
      result => {
        toast.success('User ' + user.username + ' created successful!');
        if(callbackAfterFinish){
          const id = result[0].id ? result[0].id : null
          callbackAfterFinish(id)
        }
        else{
          this.props.resetForm();
        }
        this.setState({
          success: true,
          loading: false,
          ...INIT_USER
        })
      },
      error => {
        toast.error("Si è verificato un errore: " + error.toString());
        this.setState({
          errorMessage: error,
          success: false,
          loading: false
        })
        return false;
      }
    );
  }

  updateUser = (userId, user, callbackAfterFinish = false) => {
    this.setState({
      errorMessage: "",
      success: false,
      loading: true
    })

    userService.updateUser(userId, user).then(
      result => {
        toast.success('User ' + user.username + ' saved successful!');
        if(callbackAfterFinish){
          const id = result[0].id ? result[0].id : null
          callbackAfterFinish(id)
          this.setState({
            success: true,
            loading: false,
            ...INIT_USER
          })
        }
        else{
          if(this.props.resetForm){
            this.props.resetForm();
          }
          else{
            toast.info("Logout and login again to apply changes.")
            this.setState({
              success: true,
              loading: false
            })
          }
        }
      },
      error => {
        toast.error("Si è verificato un errore: " + error.toString());
        this.setState({
          errorMessage: error,
          success: false,
          loading: false
        })
        return false;
      }
    );
  }

  getUrlAvatarById(id){
    return companyService.getUrlLogoById(id)
  }

  onClickResetForm = () => {
    const {isEditRequest, userId} = this.state
    if(isEditRequest && (userId !== null)){
      this.props.resetForm();
    }
    else{
      this.setState({
        ...INIT_USER
      })
    }
  }

  getColorByType(type){
    return userService.getColorByType(type)
  }

  isValidForm(){
    const {username, name, surname, companySelected, typeSelected, password, isEditRequest, isAdmin} = this.state
    const isValidUsername = username && username.trim().length >= 8
    const isValidName = name && name.trim().length > 0
    const isValidSurname = surname && surname.trim().length > 0
    const isValidType = typeSelected && typeSelected.length > 2
    const isValidCompany = isAdmin || (companySelected && companySelected.length > 10)
    const isValidPassword = password && password.length >= 4

    if(isEditRequest){
      return isValidCompany && isValidName && isValidSurname && isValidUsername && isValidType && isValidCompany
    }
    else{
      return isValidCompany && isValidName && isValidSurname && isValidUsername && isValidType && isValidCompany && isValidPassword
    }
  }

  isEditOwnProfile(){
    const {resetForm, callbackOnSave} = this.props
    const {isEditRequest} = this.state
    return (resetForm === null && callbackOnSave === null && isEditRequest)
  }

  toggleVisiblePassword = () => {
    this.setState(state =>({
      visiblePassword: !state.visiblePassword
    }))
  }

  onClickGeneratePsw = () => {
    const password = generator.generate({
      length: 10,
      numbers: true,
      uppercase: false
    });

    this.setState({
      password,
      visiblePassword: true
    })
  }

  _onBackClick = (event) => {
    window.history.back();
    event.preventDefault();
    return true;
  };

  render(){
    const {username, password, name, surname, typeSelected, companySelected, companiesList, isEditRequest, userAvatarUrl, issetFile, errorMessage, isAdmin, visiblePassword} = this.state

    let abbrName = 'U';
    if(name && surname){
      abbrName = name.slice(0, 1).toUpperCase() + surname.slice(0, 1).toUpperCase();
    }

    const buttonListSave = (
      <React.Fragment>
        <div className="row">
          <div className="col-12 text-right align-self-center">
            <Button.List>
              {this.isEditOwnProfile() && <Button color="danger" onClick={this._onBackClick}>Cancel</Button> }
              { (!this.isEditOwnProfile() && isEditRequest && this.props.resetForm !== null) &&
                <Button
                    color="danger"
                    onClick={this.onClickResetForm}
                    disabled={this.state.loading}
                  >
                    Cancel
                </Button>
              }
              <Button
                color="primary"
                onClick={()=>this.onClickSave(false)}
                disabled={!this.isValidForm()}
                loading={this.state.loading}
                outline={!!this.props.callbackOnSave}
              >
                {isEditRequest ? "Save changes" : "Save"}
              </Button>{' '}
            </Button.List>
          </div>
        </div>
      </React.Fragment>
    )

    return(
      <div className="pt-2">
        <div className="row">
          <div className="col-4">
            <Form.Group>
              <div className="mb-3 text-center">
                <div className="p-2 mb-1">
                {
                  userAvatarUrl ? <>
                    <Avatar size="xxl" imageURL={this.getUrlAvatarById(userAvatarUrl)}/>
                    <Button className="pull-right position-absolute text-danger" color="link" icon="trash-2" onClick={()=>this.setState({userAvatarUrl: ''})} disabled={!userAvatarUrl}/>
                  </>
                  : <Avatar size="xxl" color={this.getColorByType(typeSelected)} style={{fontSize: "4rem", padding: "3.5rem"}}>{abbrName}</Avatar>
                }
                </div>
              </div>

              <Form.Group>
                <div className="row">
                  <div className="col-9 pr-1">
                    <Form.FileInput accept=".png" label="Choose file png" onChange={this.handleChangeAvatar}/>
                  </div>
                  <div className="col-3 pl-1">
                    <Button color="primary" block onClick={this.uploadAvatar} disabled={!issetFile}>Apply</Button>
                  </div>
                </div>
                <p className="mt-2 p-2 alert alert-light">
                  Allowed extension: <code>png</code>
                  <br/>
                  Maximum size: <code>1MB</code>
                </p>
              </Form.Group>
            </Form.Group>
          </div>

          <div className="col-8">
            <div className="row">

              <div className="col-6">
                <Form.Group
                  label="Username/Email"
                >
                  <Form.Input className="mb-3" placeholder="Insert username/email" value={username} readOnly={isEditRequest} required onChange={this.onChangeUsername} />
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group
                  label={isEditRequest ? "Reset password" : "Password"}
                >
                  {this.isEditOwnProfile() && <Button color="primary" href="/forgot-password" RootComponent="a">Reset Password</Button>}
                  {(!this.isEditOwnProfile() && isEditRequest) && <Button color="secondary" disabled={username.length < 8 || !isEditRequest || 1===1}>Send to {username} (Not available)</Button>}
                  {(!this.isEditOwnProfile() && !isEditRequest) &&
                      <Form.InputGroup>
                        <Form.Input type={visiblePassword ? "text" : "password"} autocomplete={false} placeholder="Insert password" value={password} onChange={this.onChangePwd} />
                        <Form.InputGroupAppend>
                          <Button color="secondary" icon={visiblePassword ? "eye-off" : "eye"} onClick={this.toggleVisiblePassword}/>
                        </Form.InputGroupAppend>
                        <Form.InputGroupAppend>
                          <Button
                            color="secondary"
                            onClick={this.onClickGeneratePsw}
                          >
                            Generate
                          </Button>
                        </Form.InputGroupAppend>
                      </Form.InputGroup>
                  }
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group
                  label="Firstname"
                >
                  <Form.Input readOnly={!isAdmin} className="mb-3" placeholder="Insert first name" value={name} required onChange={this.onChangeFirstname} />
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group
                  label="Surname"
                >
                  <Form.Input readOnly={!isAdmin} className="mb-3" placeholder="Insert surname" value={surname} required onChange={this.onChangeSurname} />
                </Form.Group>
              </div>

              <div className="col-6">
                <Form.Group
                  label="Company"
                  value={companySelected}
                >
                  <Form.Select required onChange={this.onChangeCompany} readOnly={!isAdmin}>
                    {isAdmin && <option value="">No company [User disabled]</option>}
                    {companiesList.filter(c => (isAdmin && c.active) || (!isAdmin && c.id === companySelected)).map(c => <option selected={c.id === companySelected} value={c.id}>{c.name}</option>)}
                  </Form.Select>
                </Form.Group>
              </div>

              <div className="col-3">
                {
                  isAdmin &&
                  <Form.Group
                    label="Type"
                    value={typeSelected}
                    >
                    {
                      typesList.filter(t => isAdmin || t.value === typeSelected).map(t =>
                        <Form.Radio
                          disabled={!isAdmin}
                          required
                          checked={t.value === typeSelected}
                          name="user-type"
                          label={<Tag color={t.color}>{t.label}</Tag>}
                          value={t.value}
                          onChange={this.onChangeType}
                        />
                      )
                    }
                  </Form.Group>
                }
              </div>
              <div className="col-3">
                {
                  isAdmin && typesList.filter(t => t.value === typeSelected).map(t =>
                    <Form.Group
                      label={<small>Type description</small>}
                      >
                      <div className="text-muted p-0">
                        <small>
                          {t.desc}
                        </small>
                      </div>
                    </Form.Group>
                  )
                }
              </div>

            </div>
          </div>

        </div>
        <Dimmer className="dimmer-opacity" active={username.trim()===""} loader={false}>
          <div className="row">
            <div className="col-8 offset-2 text-center">
              {
                errorMessage!=="" &&
                <Alert type="danger">
                  {errorMessage}
                </Alert>
              }
            </div>
          </div>
          {buttonListSave}
        </Dimmer>
      </div>
    )
  }

}

export default UserManageForm;
