// @flow

import * as React from "react";

import {
  Page,
  Grid,
  Card,
  Dimmer
} from "tabler-react";

import {Modal, Radio} from 'antd'

import MapPlanner from '../components/Map/MapPlanner.react'
import DetailMap from '../components/Map/DetailMap.Planner.react'

import AudienceDoohFilter from './DoohFilters/AudienceDooh.Filter.react'

import {toast} from 'react-toastify'

import {doohEstimationService, doohService, audienceService} from "../_services"
import sanitize from "sanitize-filename"
import { formatNumber } from '../_helpers'
import _ from "lodash"

import { EnvironmentOutlined, UnorderedListOutlined } from "@ant-design/icons";

import LogoDefaultGuest from '../static/images/nexus.png'
import DoohPlanGuestCart from "./Dooh.Plan.Guest.Cart.react";


class DoohPlannerGuest extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      id: props.match ? props.match.params.id : props.id,
      name: '',
      result: [],
      cart: [],
      cartData: [],
      historyData: [],
      step: 4,

      audienceId: null,
      totalOTS: 0,

      size: null,
      budget: null,
      editingName: false,
      owner: '-',
      progress: null,
      createdAt: '-',
      updatedAt: '-',
      loading: false,

      selectedMarker: null,
      audienceFilter: null,
      audienceFilterActive: null
    }

    this.addToCart = this.addToCart.bind(this)
    this.deleteFromCart = this.deleteFromCart.bind(this)
    this.handleChangeBudget = this.handleChangeBudget.bind(this)
    this.handleMarkerSelected = this.handleMarkerSelected.bind(this)
    this.onChangePlanName = this.onChangePlanName.bind(this)
    this.onClickSavePlanName = this.onClickSavePlanName.bind(this)
    this.onClickExportLocations = this.onClickExportLocations.bind(this)
    this.onClickExportQuotes = this.onClickExportQuotes.bind(this)
    this.getResultList = this.getResultList.bind(this)
    this.handleStateAudience = this.handleStateAudience.bind(this)
    this.getAudienceDetails = this.getAudienceDetails.bind(this)
  }

  componentDidMount(){
    this.readSearch(this.state.id)
    this.getResultList()
  }

  readSearch(id){
    doohEstimationService.readSearch(id).then(
        result => {
          let {
            totalOTS,
            budget,
            name,
            owner,
            audienceId,
            createdAt,
            updatedAt
          } = result

          createdAt = createdAt ? (createdAt.split('T')[0] ? createdAt.split('T')[0] : '-') : '-'
          updatedAt = updatedAt ? (updatedAt.split('T')[0] ? updatedAt.split('T')[0] : '-') : '-'

          this.setState({
            totalOTS,
            budget,
            name,
            owner,
            audienceId,
            createdAt,
            updatedAt
          });
        },
        error => {
          this.setState({error:true})
          console.error(error);
        }
    );
  }

  onChangePlanName(ev) {
    const {value} = ev.target

    this.setState({
      name: value
    })
  }

  onClickSavePlanName(){
    const {name} = this.state
    this.setState({
      editingName: false
    }, () => this.handleChangePlanName(name))
  }

  onClickSaveAudience = () => {
    const {audienceId} = this.state
    this.handleChangeAudienceId(audienceId)
  }

  addToCart(items){
    this.setState((state) => ({
      loading: true,
      cart: [...state.cart, ...items]
    }), ()=>this.saveCart())
  }

  deleteFromCart(id){
    let {cart} = this.state
    cart = cart.filter((item)=>item !== id)

    this.setState({
      cart
    }, ()=>this.saveCart())
  }

  handleChangeBudget(budget){
    let {id} = this.state

    this.setState({
      loading: true
    },
      () => doohEstimationService.updateSearch(id, {budget}).then(
        result => {
          const {budget} = result
          this.setState({
            budget
          }, () => this.refreshAmbiences())
        }
      )
    )
  }

  handleChangePlanName(name){
    let {id} = this.state

    this.setState({
      loading: true
    },
      () => doohEstimationService.updateSearch(id, {name}).then(
        result => {
          const {name} = result
          this.setState({
            name,
            loading: false
          })
        }
      )
    )
  }

  handleChangeAudienceId(audienceId){
    let {id} = this.state

    // Workaround per Java, disattiva l'audience e lo aggiorna
    if(audienceId === null){
      audienceId = "";
    }

    this.setState({
      loading: true
    },
      () => doohEstimationService.updateSearch(id, {audienceId}).then(
        result => {
          const {audienceId} = result
          this.setState({
            audienceId,
            loading: false
          })
        }
      )
    )
  }

  getResultList(){
    const {id, audienceFilterActive} = this.state

    this.setState({
      audienceFilterActive: null
    })

    doohEstimationService.getResultJson(id).then(
      result => {
        result.map((el) => {
          el.idKinFace = el.idKinFrame
          return el.key = el.id;
        });
        this.setState({
          result,
          audienceFilterActive
        })
      }
    )
  }

  onClickExportLocations(){
    const {id, name} = this.state
    let fileName = "export_dooh_plan-" + name.replaceAll(' ','_') + ".xlsx"
    fileName = sanitize(fileName)
    doohEstimationService.getResultXls(id, fileName);
  }

  onClickExportQuotes(){
    const {id, name} = this.state
    let fileName = "export_dooh_plan_quotes-" + name.replaceAll(' ','_') + ".xlsx"
    fileName = sanitize(fileName)
    doohEstimationService.getQuotesXls(id, fileName);
  }

  getStepDesc(step){
    switch(step){
      case null:
      case 0:
        return 'New plan'
      case 1:
        return 'Manage ambiences'
      case 2:
        return 'Estimation complete'
      default:
        return '-'
    }
  }

  getStepLabelsArray(){
    return ([
      {
        name: 'CHOOSE'
      },
      {
        name: 'ORGANIZE'
      },
      {
        name: 'DEFINE'
      }
    ])
  }

  handleMarkerSelected (nextSelected) {
    const {selectedMarker} = this.state
    if(nextSelected !== selectedMarker){
      this.setState({
        selectedMarker: nextSelected
      })
    }
  }

  handleStateAudience(audienceFilter){
    this.setState({
      audienceFilter
    })
  }

  onChangeFilterSelected = (save = false) => {
    const {audienceFilter} = this.state;
    let audienceId = null;
    if(audienceFilter !== null){
      audienceId = audienceFilter.id
    }

    this.setState({
      audienceId: null,
      audienceFilterActive: null
    }, () =>
    this.setState({
      audienceId,
      audienceFilterActive: audienceFilter
    }, () => {
      if(save) {
        this.onClickSaveAudience()
      }
    }))
    
  }

  getAudienceDetails = (audienceId) => {
    if(!audienceId)
      return;

    audienceService.getAudienceDetailsById(audienceId).then(
      result => {
        this.setState({
          audienceFilter: result
        }, () => this.onChangeFilterSelected(false))
      },
      error => {
        toast.error('Error: ' + error);
      }
    )
  }
  

  openAudienceFilter = () => {
    const {audienceFilter} = this.state;

    Modal.info({
      title: "Audience filters",
      width: 1100,
      closable: true,
      onOk: () => this.onChangeFilterSelected(true),
      content: (
        <div className="w-100 pt-5">
          <AudienceDoohFilter
            onChange={this.handleStateAudience}
            value={audienceFilter}
          />
        </div>
      )
    });
  }

  render() {
    const {step, result, loading, totalOTS} = this.state

    const otsPaidTotal = formatNumber(totalOTS, true, true, false)

    const dataMap = result.map((item) => {
      return{
        ...item,
        idKinFace: item.idKinFrame,
        location: {
          x: item.location.y,
          y: item.location.x,
        },
        cimasa: item.id,
        poi: null,
        mediaOwner: item.mediaOwner,
        dashboardType: item.installation,
        segment: item.type,
        format: item.ots,
        region: item.region,
        district: item.district,
        city: item.city,
        numberMonitors: item.numberMonitors,
        ots: item.ots
      }
    });

    let numberFrames = dataMap.length>0 && dataMap.reduce( ( sum, { numberMonitors } ) => sum + numberMonitors , 0)
    numberFrames = formatNumber(numberFrames, true, true, false)

    const options = (
      <React.Fragment>
        <div className="btn-list-flex">
          <Radio.Group defaultValue={4} value={step} buttonStyle="solid" onChange={(ev) => this.setState({step: ev.target.value})}>
            <Radio.Button value={4}><EnvironmentOutlined className="mr-1"/> MAP</Radio.Button>
            <Radio.Button value={3}><UnorderedListOutlined className="mr-1"/> LIST</Radio.Button>
          </Radio.Group>
        </div>
      </React.Fragment>
    )
    
    const title = (readOnly = false) => (
      <React.Fragment>
        <h1 className="page-title mb-0 mt-0 p-0">{this.state.name} - Budget: € {formatNumber(this.state.budget, true, true, false)}</h1>
      </React.Fragment>
    )

    const {readOnly} = this.props;

    return (
      // <SiteWrapper {...this.state} readOnly>
        <Page.Content className="page-content-wide page-content-wide-guest">
          <Page.Header
            title={
              <div className="row">
                <div className="col-auto">
                  <img src={LogoDefaultGuest} alt="logo" style={{maxWidth: 150}}/>
                </div>
                <div className="col">
                  {title(readOnly)}
                </div>
              </div>
            }
            options={options}
          />
          <Grid.Row cards deck>
            
            <Grid.Col sm={12}>
              { step >= 3 &&
                <Card className="m-0">
                  <Card.Body className="m-0 p-0 card-body-planner-dooh-guest">
                    <Dimmer active={loading} loader={loading}>
                      {
                        step === 3 &&
                        <div className="px-2">
                          <DoohPlanGuestCart cart={result} readonly audienceFilter={null}/>
                        </div>
                      }

                      {
                        step === 4 &&
                        <>
                          <Card.Map className="shadow m-0">
                            <MapPlanner 
                              data={dataMap}
                              typeFrame="dooh"
                              extra={{otsPaidTotal, numberFrames}} 
                              selectedMarker={this.state.selectedMarker}
                              enableSearch 
                              handleMarkerSelected={this.handleMarkerSelected} 
                              audienceFilter={this.state.audienceFilterActive}
                            />
                          </Card.Map>
                        </>
                      }
                    </Dimmer>
                    <DetailMap type="dooh" marker={this.state.selectedMarker} dataMap={dataMap} audienceFilter={this.state.audienceFilterActive} onClose={()=>this.setState({selectedMarker: null})} />
                  </Card.Body>
                </Card>
              }
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      // </SiteWrapper>
    )
  }
}


export default DoohPlannerGuest;
