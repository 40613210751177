// @flow

import * as React from "react";

import {
  Grid,
  Card,
  Table,
  Button,
  Dimmer,
  Icon,
  Dropdown,
  Form,
} from "tabler-react";

import _ from "lodash"
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Pagination from '../components/Pagination/Pagination.react'

import {imageService} from "../_services"

class CampaignsPanelList extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            items: [],

            activePage: 1,
            itemsCountPerPage: 50,
            totalItemsCount: 0,
            maxPageRangeDisplayed: 5,
            offsetQuery: 0,
            sendingQuery: false,

            photoIndex: 0,
            photoIsOpen: false,
            images: [],

            isLoading: false,
            seqIds: []
        }

        this.handlerChangeItem = this.handlerChangeItem.bind(this)
        this.handleSelectAllFiltered = this.handleSelectAllFiltered.bind(this)
    }

    componentDidMount() {
        this.setState({
            items: this.props.data,
            totalItemsCount: this.props.data.length
        })
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.data!==this.props.data){
            this.setState({
                items: nextProps.data,
                totalItemsCount: nextProps.data.length
            }, ()=> console.log(this.state.items));
        }
        if(nextProps.emptyRequest !== this.props.emptyRequest && nextProps.emptyRequest==1){
            this.setState({
                seqIds: []
            }, ()=>this.props.handleItemsList([]));
        }
    }

    handlerChangeItem(ev){
        let sel = ev.target.value
        let checked = ev.target.checked
        if(checked){
            this.setState((state)=>({
                seqIds: [...state.seqIds, sel]
            }), ()=>this.props.handleItemsList(this.state.seqIds))
        }
        else{
            this.setState({
                seqIds: this.state.seqIds.filter(function(id) {
                    return id !== sel
                })
            }, ()=>this.props.handleItemsList(this.state.seqIds));
        }
    }

    handlePageChange = (pageNumber) => {
        let offset = pageNumber-1
        this.setState({
            activePage: pageNumber,
            offsetQuery: offset,
            isLoading: true
        });

        this.setState({
            isLoading: false
        })
    }

    handleSelectAllFiltered(){
        let items = this.state.items
        let seqIds = _.groupBy(items, "seqId")
        seqIds = _.keys(seqIds);

        this.setState({
            seqIds: seqIds
        }, ()=>this.props.handleItemsList(this.state.seqIds));
    }

    getValidateUrl(img, isCreativity = false){
      return imageService.getUrlPhoto(img, isCreativity)
    }

    render(){
        const { photoIndex, photoIsOpen } = this.state;

        return(
            <div>
                <Grid.Row cards deck>
                    <Grid.Col sm={12}>
                        <Card>
                            <Dimmer active={this.state.isLoading} loader={this.state.isLoading}>
                                <Table striped={true} className="mb-0 table-bordered table-responsive-md">
                                    <Table.Header>
                                        <Table.ColHeader>
                                            <Button color="secondary" size="sm" onClick={this.handleSelectAllFiltered} disabled={this.state.items.length == this.state.seqIds.length}>
                                                Select all ({this.state.items.length})
                                            </Button>
                                            {/* <Form.Group>
                                                <Form.Checkbox
                                                    label=" "
                                                    name="items-selectAll"
                                                    value="All"
                                                />
                                            </Form.Group> */}
                                        </Table.ColHeader>
                                        <Table.ColHeader>Info Panel</Table.ColHeader>
                                        <Table.ColHeader>Address</Table.ColHeader>
                                        <Table.ColHeader>Position</Table.ColHeader>
                                        <Table.ColHeader>Note</Table.ColHeader>
                                        <Table.ColHeader alignContent="center">Preview</Table.ColHeader>
                                    </Table.Header>
                                    <Table.Body>
                                        {!this.state.isLoading && this.state.items.slice(
                                            this.state.itemsCountPerPage*this.state.offsetQuery,
                                            this.state.itemsCountPerPage*(this.state.offsetQuery+1))
                                            .map((item, index) => {
                                            const thisIndexAudit = index
                                            return <Table.Row key={index}>
                                                <Table.Col alignContent="center">
                                                    <Form.Group>
                                                        <Form.Checkbox
                                                            label=" "
                                                            name="items-select"
                                                            value={item.seqId}
                                                            checked={this.state.seqIds.indexOf(item.seqId) != -1}
                                                            onChange={(ev) => this.handlerChangeItem(ev)}
                                                        />
                                                    </Form.Group>
                                                </Table.Col>
                                                <Table.Col className="align-middle">
                                                    <Grid.Row>
                                                            <Grid.Col md={6}>SeqId:</Grid.Col>
                                                            <div className="col-6 text-truncate" title={item.seqId}>
                                                                <strong>{item.seqId}</strong>
                                                            </div>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                            <Grid.Col md={6}>MO:</Grid.Col>
                                                            <div className="col-6 text-truncate" title={item.mediaOwner}>
                                                                <strong>{item.mediaOwner}</strong>
                                                            </div>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                            <Grid.Col md={6}>Format:</Grid.Col>
                                                            <div className="col-6 text-truncate" title={item.panelType}>
                                                                <strong>{item.panelType}</strong>
                                                            </div>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                            <Grid.Col md={6}>Size:</Grid.Col>
                                                            <div className="col-6 text-truncate" title={item.format ? item.format : '-' }>
                                                                <strong>{item.formatoDesc ? item.formatoDesc : '-' }</strong>
                                                            </div>
                                                    </Grid.Row>
                                                </Table.Col>
                                                <Table.Col className="align-middle">
                                                    <Grid.Row>
                                                            <Grid.Col md={5}>Region:</Grid.Col>
                                                            <Grid.Col md={7}><strong>{item.region}</strong></Grid.Col>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                            <Grid.Col md={5}>District:</Grid.Col>
                                                            <Grid.Col md={7}><strong>{item.district}</strong></Grid.Col>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                            <div className="col-5">
                                                                City:
                                                            </div>
                                                            <div className="col-7 text-truncate" title={item.municipality}>
                                                                <strong>{item.municipality}</strong>
                                                            </div>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                            <div className="col-5">
                                                                Address:
                                                            </div>
                                                            <div className="col-7 text-truncate" title={item.address + ' ' + item.address2}>
                                                                <strong>{item.address}</strong>
                                                            </div>
                                                    </Grid.Row>
                                                </Table.Col>

                                                <Table.Col className="align-middle">
                                                    <Grid.Row>
                                                            <div className="col-5">
                                                                Lat:
                                                            </div>
                                                            <div className="col-7 text-truncate">
                                                                <strong>{item.lat}</strong>
                                                            </div>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                            <div className="col-5">
                                                                Long:
                                                            </div>
                                                            <div className="col-7 text-truncate">
                                                                <strong>{item.lng}</strong>
                                                            </div>
                                                    </Grid.Row>
                                                </Table.Col>
                                                {item.note ?
                                                    <Table.Col alignContent="center" className="td-128 align-middle">
                                                        <p className="text-primary"><Icon prefix="fa" name="sticky-note" className="fa-lg" /></p>
                                                        <p className="text-truncate td-128" title={item.note}>{item.note}</p>
                                                    </Table.Col>
                                                    :
                                                    <Table.Col alignContent="center" className="td-128 align-middle">
                                                        <p className="text-secondary"><Icon prefix="fa" name="sticky-note" className="fa-lg" /></p>
                                                        <p className="text-truncate td-128"><em>Nessuna nota</em></p>
                                                    </Table.Col>
                                                }
                                                <Table.Col alignContent="center" className="td-gallery td-135 align-middle pt-0 pb-0">
                                                    <div className="row">
                                                        {(item.photosAds) ?
                                                            [item.photosAds].map((item, index) => {
                                                                if(item){
                                                                  let src = this.getValidateUrl(item, true);
                                                                  return <div className="col-12 pl-1 pr-1"
                                                                              onClick={() => this.setState({
                                                                                  photoIndex: index,
                                                                                  images: [src]
                                                                              }, () => this.setState({photoIsOpen: true}))}>
                                                                      <img alt="Creativity" src={src} style={{"max-height": "100px","width": "100%","objectFit": "contain", "cursor": "pointer"}}/>
                                                                  </div>
                                                                }
                                                                else{
                                                                    return <p className="p-2 text-center"><em>No images</em></p>
                                                                }
                                                            })
                                                            : (item.creativityDec) ?
                                                            [item.creativityDec].map((item, index) => {
                                                                if(item){
                                                                  let src = this.getValidateUrl(item, true);
                                                                  return <div className="col-12 pl-1 pr-1"
                                                                              onClick={() => this.setState({
                                                                                  photoIndex: index,
                                                                                  images: [src]
                                                                              }, () => this.setState({photoIsOpen: true}))}>
                                                                      <img alt="Creativity" src={src} style={{"max-height": "100px","width": "100%","objectFit": "contain", "cursor": "pointer"}}/>
                                                                  </div>
                                                                }
                                                                else{
                                                                    return <p className="p-2 text-center"><em>No images</em></p>
                                                                }
                                                            })
                                                            :<p className="p-4 text-center"><em>No images</em></p>
                                                        }
                                                    </div>
                                                </Table.Col>
                                            </Table.Row>
                                        })}
                                    </Table.Body>
                                </Table>
                                {photoIsOpen && (
                                    <Lightbox
                                        mainSrc={this.state.images[photoIndex]}
                                        nextSrc={this.state.images[(photoIndex + 1) % this.state.images.length]}
                                        prevSrc={this.state.images[(photoIndex + this.state.images.length - 1) % this.state.images.length]}
                                        onCloseRequest={() => this.setState({ photoIsOpen: false })}
                                        onMovePrevRequest={() =>
                                            this.setState({
                                                photoIndex: (photoIndex + this.state.images.length - 1) % this.state.images.length,
                                            })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                photoIndex: (photoIndex + 1) % this.state.images.length,
                                            })
                                        }
                                    />
                                )}
                                {/* {this.itemNotFound()}
                                {this.errorLoad()} */}
                            </Dimmer>

                        </Card>
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Col>
                    {
                        !this.state.isLoading ?
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.itemsCountPerPage}
                            totalItemsCount={this.state.totalItemsCount}
                            maxPageRangeDisplayed={this.state.maxPageRangeDisplayed}
                            handlePageChange={this.handlePageChange}
                        /> : ''
                    }
                    </Grid.Col>
                </Grid.Row>
            </div>
        )
    }
}

export default CampaignsPanelList;
