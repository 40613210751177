import { authHeader } from "../_helpers";
import { userService } from "./"
import FileSaver from 'file-saver';
import {defineConfig} from '../config'

export const assetService = {
  getApiPathAsset,
  normalizeAssetPlanner,
  archiveInactiveAssetPlanner,
  parseNewReverseGeocode,
  getDistinctValuesOf,
  loadFileAsset,
  getAssetXlsByFieldValue,
  getFileFromUrl,
  getBigImpactAll,
  getWrongReverseByField,
  getIdKinFaceDistinctByLocation,
  getSimilarAssetsByLocationAndType
};

const {apiUrl, apiVer} = defineConfig.ASSET_SERVICE

function getApiPathAsset(){
  return (apiUrl+apiVer)
}

function getDistinctValuesOf(collection, field) {
  const url = apiUrl+apiVer+"/asset/field/"+field+"/distinct/get";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function normalizeAssetPlanner() {
  const url = apiUrl+apiVer+"/asset/dataset/normalize/all";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function archiveInactiveAssetPlanner() {
  const url = apiUrl+apiVer+"/asset/migrate-disactive";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getSimilarAssetsByLocationAndType(type, location){
  const url = apiUrl+apiVer+"/asset/find-location/"+type;

  const body = { ...location }

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
  .then(handleResponse)
  .then(res => {
    return res;
  });
}

function parseNewReverseGeocode() {
  const url = apiUrl+apiVer+"/asset/reverse/geocode/from/coordinates";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAssetXlsByFieldValue(field, value) {
  const url = apiUrl+apiVer+"/asset/dataset/xls/export/"+field+"/"+value;
  const fileName = 'export_assets_' + field + '_' + value + '.xlsx'
  return getFileFromUrl(url, fileName)
}

function getWrongReverseByField(field) {
  const url = apiUrl+apiVer+"/asset/wrongreversed/"+field+"/xls/export";
  const fileName = 'export_assets_' + field + '.xlsx'
  return getFileFromUrl(url, fileName)
}

function getIdKinFaceDistinctByLocation() {
  const url = apiUrl+apiVer+"/asset/ooh/xls/export/distinct-by-location";
  const fileName = 'export_idKinFaceDistinctByLocation_ooh.zip';
  return getFileFromUrl(url, fileName)
}

function loadFileAsset(formData, dooh = true) {
  const url = apiUrl+apiVer+"/asset/dataset/import";

  const headers = dooh ? {
    ...authHeader(),
    dooh
  }
  : { ...authHeader() }

  const requestOptions = {
    method: "POST",
    headers,
    body: formData
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getFileFromUrl(url, fileName = null) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  if(fileName === null){
    fileName = url.split('/')
    fileName = fileName[fileName.length-1]
  }

  return fetch(url, requestOptions)
    .then(response => {
      console.log("scarico....", url)
      return response.blob()
    })
    .then(function(blob) {
      FileSaver.saveAs(blob, fileName)
      return true;
    })
}


function getBigImpactAll() {
  const url = apiUrl+apiVer+"/bigimpact/all";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}


function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
        window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
