// @flow

import * as React from "react";

import {
  Page,
  Grid,
  Form,
  Button,
  Alert
} from "tabler-react";

import SiteWrapper from "../SiteWrapper.react";
import "./Planner.scss";
import {searchService} from "../_services"


class PlannerPlanNew extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            name: "",
            id: null,
            loading: false,
            error: null,
            success: null
        }

        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChangeInput(ev){
        const name = ev.target.value
        this.setState({name})
    }


    handleSubmit(ev){
        const name = this.state.name
        this.setState({loading: true})
        this.createSearch(name)
    }

    createSearch(name){
        searchService.createSearch(name).then(
            result => {
              this.setState({
                  id: result.id,
                  error: null,
                  loading: false,
                  success: true
              })
            },
            error => {
                this.setState({error:true})
                console.error(error);
            }
        );
    }

    render() {
        const {loading, error, success, name} = this.state
        return (
            <SiteWrapper {...this.state}>
                <Page.Content>
                    <Page.Header/>
                        <Grid.Row>
                            <Grid.Col sm={12} md={12} className="pt-4">
                                <p className="h3 text-center">Planner - New plan</p>
                            </Grid.Col>
                            <Grid.Col sm={8} md={8} offset={2} className="p-3">
                                <Form.Group>
                                    <Form.InputGroup>
                                        <Form.InputGroupPrepend>
                                            <Button color="secondary" size="lg" icon="chevron-left" RootComponent="a" href={"/planner/plan/"}>Back</Button>
                                        </Form.InputGroupPrepend>
                                        <Form.Input placeholder="Insert plan name" size="lg" required onChange={this.handleChangeInput} readOnly={loading || success} tick={success} error={error} />
                                        <Form.InputGroupAppend>
                                            <Button color="primary" size="lg" onClick={this.handleSubmit} loading={loading} disabled={success || name.trim().length<3}>Save and continue</Button>
                                        </Form.InputGroupAppend>
                                    </Form.InputGroup>
                                </Form.Group>
                                {success &&
                                    <div className="row">
                                        <div className="col-12">
                                            <Alert type="success" icon="check">
                                                Plan <strong>{this.state.name}</strong> successfully saved!
                                            </Alert>
                                        </div>
                                        <div className="col-12">
                                            <Button.List align="right">
                                                <Button color="primary" RootComponent="a" href={"/planner/plan/"+this.state.id}>Next step</Button>
                                            </Button.List>
                                        </div>
                                    </div>
                                }
                            </Grid.Col>
                        </Grid.Row>
                </Page.Content>
            </SiteWrapper>
        )
    }
}


export default PlannerPlanNew;
