// @flow

import * as React from "react";
import { Redirect } from "react-router-dom";

import {
  Page,
  Grid,
  Card,
  Container,
  Nav,
  Table,
  Button,
  Dimmer,
  Icon,
  Dropdown,
  Form,
  colors
} from "tabler-react";

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import _ from 'lodash'

import SiteWrapper from "../SiteWrapper.react";
import CampaignsReportFilters from "./Campaigns.ReportFilters.react"

import { campaignService, justificativeService } from "../_services";
import PanelList from './Campaigns.PanelList.react'
// import UploadFile from "../components/UploadData/upload/Upload"
import '../components/UploadData/UploadData.scss'
import CampaignNotificationConfig from "./Campaigns.NotificationConfig.react";

class CampaignsUploadCreativity extends React.Component {
    constructor(props){
        super(props)
        this.state = ({
            campaignInfo: {
                id: props.match.params.id,
                advertiser: '',
                campaign: '',
                startDate: '',
                endDate: '',
                createDate: '',
                numPanels: 0,
                idKinCampaign: -1,
                inReport: 1,
            },
            campaignPanels: [],
            campaignPanelsAll: [],
            
            timestamp: Date.now().toString(),

            regions: [],
            district: [],
            cities: [],
            mediaowners: [],
            formats: [],

            idR: "",
            prov: "",
            idC: "",
            mo: "",
            frmt: "",

            seqIds: [],
            emptyRequest: 0,
            issetFile: false,
            isOpenModal: false,
            isOpenModalDeleteCamp: false,
            isLoaded: false,
            buttonAssignActive: true,
            isAllAssigned: false,
            deletedCampaign: false,
            campaignNotFound: false,
        })
        this.handlerUploadProgress = this.handlerUploadProgress.bind(this)
        this.emptySeqIds = this.emptySeqIds.bind(this)
        this.toggleModalUpload = this.toggleModalUpload.bind(this);
        this.toggleModalDelete = this.toggleModalDelete.bind(this);
        this.handleChangeFile = this.handleChangeFile.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.onClickExportBME = this.onClickExportBME.bind(this)
        this.onClickDeleteCampaign = this.onClickDeleteCampaign.bind(this)
    }

    componentDidMount(){
        this.loadCampaignInfo(this.state.campaignInfo.id)
    }

    loadCampaignInfo(id){
        campaignService.getCampaignInfo(id).then(
          result => {
            this.setState({
                campaignInfo: result
            }, ()=> this.getCampaignPanels(this.state.campaignInfo.idKinCampaign, false))
          },
          error => {
            console.error(error);
            this.setState({
                campaignNotFound: true
            })
          }
        );
    }

    getCampaignPanels(idk, isReloading=false){
        justificativeService.getAuditFindByCampaign(idk).then(
          result => {
            if(isReloading){
                const {idR, prov, idC, mo, frmt} = this.state
                this.setState({
                    campaignPanels: result,
                    campaignPanelsAll: result,
                    isLoaded: false
                },()=>this.loadItemsFiltered(idR, prov, idC, mo, frmt))
            }
            else{
                this.setState({
                    campaignPanels: result,
                    campaignPanelsAll: result,
                    timestamp: Date.now().toString(),
                    isLoaded: true
                },()=>this.checkIfIsAllAssigned())
            }
          },
          error => {
            console.error(error);
          }
        );
    }

    checkIfIsAllAssigned(){
        let data = this.state.campaignPanelsAll
        const counterGroupByAds = _.groupBy(data, "photosAds")
        const notAssigned =  counterGroupByAds[null] ? counterGroupByAds[null].length : 0
        if(notAssigned<=0){
            this.setState({
                isAllAssigned: true
            })
        }
    }

    onClickExportBME(ev){
        console.log(ev)
        campaignService.getXlsBME(this.state.campaignInfo.idKinCampaign).then(
            result => {
                // console.log('getXlsBME')
                // console.log(result)
            },
            error => {
                console.error(error);
            }
        );
    }

    toggleModalDelete() {
        this.setState(prevState => ({
          isOpenModalDeleteCamp: !prevState.isOpenModalDeleteCamp
        }));
    }

    onClickDeleteCampaign(ev){
        // console.log(ev)
        let id = this.props.match.params.id;
        // console.log(id)
        if(id){
            campaignService.deleteCampaign(id).then(
                result => {
                    console.log(result)
                    this.setState({
                        deletedCampaign: true
                    })
                },
                error => {
                    console.error(error);
                }
            );
        }
    }

    
    passPropsFilters = (nProps) => {
        let idR = nProps.selectedRegL.trim().toLowerCase()
        let prov = nProps.selectedDis.trim().toLowerCase()
        let idC = nProps.selectedCitL.trim().toLowerCase()
        let mo = nProps.selectedMedOwn.trim().toLowerCase()
        let frmt = nProps.selectedFrmt.trim().toLowerCase()

        this.setState({
            idR,
            prov,
            idC,
            mo,
            frmt
        })

        this.loadItemsFiltered(idR, prov, idC, mo, frmt)
    }

    loadItemsFiltered(idR, prov, idC, mo, idF){
        // console.log(idR, prov, idC, mo, idF)

        let appoggio = this.state.campaignPanels
        let campaignPanelsAll = this.state.campaignPanelsAll
        let filteredPanels = campaignPanelsAll.filter(function(item) {
            let region = item.region || ""
            let district = item.district || ""
            let municipality = item.municipality || ""
            let mediaOwner = item.mediaOwner || ""
            let panelType = item.panelType || ""

            let condReg = region.trim().toLowerCase() == idR || !idR
            let condProv = district.trim().toLowerCase() == prov || !prov
            let condCity = municipality.trim().toLowerCase() == idC || !idC
            let condMO = mediaOwner.trim().toLowerCase() == mo || !mo
            let condFormat = panelType.trim().toLowerCase() == idF || !idF

            if (condReg && condProv && condCity && condMO && condFormat) {
                return item;
            }
        })
        appoggio = filteredPanels
        // this.state.campaignPanels = filtered
        this.setState({
            timestamp: Date.now().toString(),
            campaignPanels: appoggio,
            isLoaded: true
        })
    }


    
    handlerUploadProgress(progress) {
        this.setState({
          isUploaded: progress
        })
        console.log('Caricamento effettuato')
    }

    handleItemsList(list){
        if(list.length>0){
            this.setState({
                seqIds: list
            })
        }
        else{
            this.setState({
                emptyRequest: 0,
                seqIds: []
            })
        }
    }

    handleChangeFile(ev){
        // console.log(ev.target.files)
        this.setState({
            issetFile: ev.target.files
        })
    }

    onFormSubmit(e){
        e.preventDefault() // Stop form submit
        this.setState({
            buttonAssignActive: false
        })
        // const files = Array.from(e.target.files)
        let formData = new FormData()
        const file = this.state.issetFile[0]
        // console.log(file)
        formData.append("file", file, file.name);

        campaignService.updateFileUploadAd(formData, this.state.seqIds).then(
            result => {
                // console.log(result)
                this.setState({
                    isOpenModal: false
                }, ()=>this.reloadData())
            },
            error => {
                console.error(error);
            }
        );
    }

    reloadData(){
        this.setState({
            campaignPanels: [],
            isLoaded: false
        }, ()=> this.getCampaignPanels(this.state.campaignInfo.idKinCampaign, true))
    }

    toggleModalUpload() {
        this.setState(prevState => ({
          isOpenModal: !prevState.isOpenModal,
          buttonAssignActive: true
        }));
    }

    emptySeqIds(ev){
        this.setState({
            emptyRequest: 1
        })
    }

    render() {
        const title = (
            <React.Fragment>
                <h1 className="page-title mb-0 mt-0 p-0">{this.state.campaignInfo.advertiser} - {this.state.campaignInfo.campaign}</h1>
                <h4><small className="mb-0 mt-0 p-0">Panels: {this.state.campaignPanelsAll.length} - On Air: {this.state.campaignInfo.startDate} - {this.state.campaignInfo.endDate} - Last Update: {this.state.campaignInfo.lastUpdate ? this.state.campaignInfo.lastUpdate : "-"}</small></h4>
            </React.Fragment>
        )

        // ----------
        let allError = false
        let fieldsCheck = [
            {name: "mediaOwner", type: "string", label: "Media Owner",errors: [], values:[]},
            {name: "groupTypeId", type: "string", label: "Panel Type (matching for GroupTypeBme and Segment)", errors: [], values:[]},
            // {name: "segmentDesc", type: "string", label: "Segment Desc", errors: [], values:[]},
            // {name: "groupTypeId", type: "integer", label: "match Group Type ID", errors: [], values:[]},
            {name: "lat", type: "lat", label: "Latitude", errors: [], values:[]},
            {name: "lng", type: "lng", label: "Longitude", errors: [], values:[]}
        ]
        fieldsCheck = fieldsCheck.map((item, index) => {
            switch(item.type){
                case "string":
                case "integer":
                    let arrayError = _.groupBy(this.state.campaignPanels, item.name)
                    let listError = _.groupBy(arrayError[null], "seqId")
                    listError = _.keys(listError);
    
                    arrayError = _.keys(arrayError);
    
                    const error = (arrayError.indexOf("null")!= -1) || arrayError.length<=0
                    if(error){
                        // console.log("Error "+item.name+" found!")
                        // console.log(listError)
                        allError = true
                    }
                    //item.errors = listError
                    this.state.campaignPanels.map((panel, index) => {
                        for(let i=0; i<listError.length;i++){
                            if(listError[i] == panel.seqId){
                                item.errors.push(index+2) //index to number row excel
                            }
                        }
                    })
                break;
                case "lat":
                case "lng":
                    let arr = this.state.campaignPanels
                    let listCordError = arr.filter((panel, index) => {
                        if(item.type == "lat"){
                            // console.log('LAT:'+panel.lat)
                            return !(panel.lat>35 && panel.lat<50) || isNaN(panel.lat)
                        }
                        else if(item.type == "lng"){
                            // console.log('LONG:'+panel.lng)
                            return !(panel.lng>6 && panel.lng<21) || isNaN(panel.lng)
                        }
                    }) 
                    // console.log(listCordError)

                    if(listCordError.length>0){
                        let listCordValue = _.groupBy(listCordError, item.name)

                        listCordError = _.groupBy(listCordError, "seqId")
                        listCordError = _.keys(listCordError);

                        arr.map((panel, index) => {
                            for(let i=0; i<listCordError.length;i++){
                                if(listCordError[i] == panel.seqId){
                                    // console.log('aaaaa:'+listCordError[i]+ " -> " +panel.seqId)
                                    item.values.push(index+2) //index to number row excel
                                }
                            }
                        })
                        
                        listCordValue = _.keys(listCordValue);
                        item.errors = listCordValue
                        // console.log("Error "+item.name+" found!")
                        // console.log(listCordValue)
                        allError = true
                    }
                break;
            }
            return item
        })      
        // --------

        const selectedSeqIds = (
            <React.Fragment>
                {this.state.seqIds && this.state.seqIds.map((item, index) => {
                    return <Dropdown.Item>{item}</Dropdown.Item>
                })}
            </React.Fragment>
        )

        const dropdownSelected = (
            <React.Fragment>
                <Button.Dropdown type="button" color="secondary" arrow icon="check-square" value={"Selected " + this.state.seqIds.length + " panels"}>
                    {/* {selectedSeqIds}
                    <Dropdown.ItemDivider /> */}
                    <Dropdown.Item icon="trash" onClick={this.emptySeqIds}>Deselect all</Dropdown.Item>
                </Button.Dropdown>
            </React.Fragment>
        )

        const options = (
            <React.Fragment>
                <Button.List>
                    <Button color="link" RootComponent="a" href="/campaigns/">
                        Skip this step
                    </Button>
                    {this.state.seqIds.length>0 ? dropdownSelected : ''}

                    <CampaignNotificationConfig idCampaign={this.props.match.params.id}/>
                    
                    <Button color="primary" onClick={this.toggleModalUpload} disabled={this.state.seqIds.length===0}>
                        <Icon prefix="fa" name="upload" /> Upload Ad
                    </Button>

                    {/* <Button color="primary" onClick={this.onClickExportBME} disabled={true}>
                        <Icon prefix="fa" name="download"/> Export BME
                    </Button> */}

                    <Button color="danger" icon="trash" onClick={this.toggleModalDelete} disabled={this.state.campaignInfo.inReport == 1}>Delete campaign</Button>

                    {allError ?
                        <Button color="danger" disabled>Not available</Button>
                        :<Button color="primary" RootComponent="a"
                            // disabled={!this.state.isAllAssigned}
                            href={campaignService.getApiPathCampaign()+"/file/createBmeFile/idkc/"+this.state.campaignInfo.idKinCampaign}
                            target="_blank">
                            <Icon prefix="fa" name="download"/> Export BME
                        </Button>
                    }

                    <Modal isOpen={this.state.isOpenModalDeleteCamp} toggle={this.toggleModalDelete}>
                        <ModalHeader toggle={this.toggleModalDelete}>Delete campaign</ModalHeader>
                        <ModalBody>
                            <div className="row">
                                <div className="col p-4">
                                    {!this.state.deletedCampaign ?
                                        <p>Are you sure you want to delete the campaign <strong>{this.state.campaignInfo.campaign}</strong>?<br/>(This action is irreversible)</p>
                                        : <p className="text-success">Campaign successfully deleted!</p>
                                    }
                                </div>
                            </div>
                        </ModalBody>
                        {!this.state.deletedCampaign ?
                            <ModalFooter>
                                <Button color="danger" onClick={this.onClickDeleteCampaign} disabled={this.state.deletedCampaign}>Delete</Button>{' '}
                                <Button color="secondary" onClick={this.toggleModalDelete}>Cancel</Button>
                            </ModalFooter>
                            :
                            <ModalFooter>
                                <Button color="primary" RootComponent="a" href="/campaigns/">
                                    Back to Campaigns
                                </Button>
                            </ModalFooter>
                        }
                    </Modal>

                    <Modal isOpen={this.state.isOpenModal} toggle={this.toggleModalUpload}>
                        <ModalHeader toggle={this.toggleModalUpload}>Assign Ad</ModalHeader>
                        <ModalBody>
                            <div className="row">
                                <div className="col">
                                    Select file <code>png</code> or <code>jpg</code> to assign it to all panels selected
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Form.Group label="File images" onSubmit={this.onFormSubmit}>
                                        <Form.FileInput accept="image/*" 
                                            onChange={this.handleChangeFile}
                                            // onChange={this.onFormSubmit}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.onFormSubmit} disabled={!this.state.issetFile} loading={!this.state.buttonAssignActive}>Assign Ad</Button>{' '}
                            <Button color="secondary" onClick={this.toggleModalUpload} disabled={!this.state.buttonAssignActive}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </Button.List>
            </React.Fragment>
        )


        


        const warningMessages = (
            <React.Fragment>
                {allError
                    ? <div className="bg-danger p-4 mb-4 text-white h4">Some errors found! Try again to upload your campaign. Some description below:</div>: ""
                }
                {
                    fieldsCheck.map((item, index) => {
                        if(item.errors.length>0){
                            return <div className="bg-white p-4 mb-4 text-danger">Errors found with <strong>{item.label}</strong><br/>
                                <div className="bg-light p-2" style={{"overflow-y": "auto", "max-height": "100px"}}>
                                    {
                                        (item.values.length>0)
                                        ? <div>
                                            <code>Excel Rows: {JSON.stringify(item.values)}</code><br/>
                                            <code>Values: {JSON.stringify(item.errors)}</code>
                                        </div>
                                        : <code>Excel Rows: {JSON.stringify(item.errors)}</code>
                                    }
                                </div>
                            </div>
                        }
                        return ""
                    })
                }
            </React.Fragment>
        )

        if(this.state.campaignNotFound){
            return <Redirect
                to={{ pathname: "/404", state: { from: this.props.location } }}
            />
        }

        return (
        <SiteWrapper {...this.state}>
            <Page.Content>
                <Page.Header
                    title={title}
                    options={options}
                />
                <Grid.Row cards deck>
                    <Grid.Col sm={12}>
                        <Card className="bg-light">
                            <Container>
                                <Nav className="bg-white">
                                    <Nav.Item value="Upload file" />
                                    <Nav.Item value="Edit Campaign / Upload Ad" to={"/campaigns/import/id/"+this.props.match.params.id} active/>
                                    <Nav.Item value="Add transit/dynamic panels" to={"/campaigns/importAppend/id/"+this.props.match.params.id} />
                                </Nav>
                            </Container>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Col sm={12} className="card-p-0">
                        <Card className="bg-light">
                            <Container>
                                <CampaignsReportFilters 
                                    idC={this.state.id}
                                    handleState={this.passPropsFilters}
                                    idkc={this.state.campaignInfo.idKinCampaign}
                                    panels={this.state.campaignPanels}
                                    panelsAll={this.state.campaignPanelsAll}
                                />
                            </Container>
                        </Card>
                       
                    </Grid.Col>
                </Grid.Row>
                {this.state.isLoaded &&
                    <div>
                        {this.state.campaignPanels.length>0 ?
                                <Grid.Row>
                                    <Grid.Col>
                                        {warningMessages}
                                    </Grid.Col>
                                </Grid.Row>
                        : ""}    
                        <Grid.Row>
                            <Grid.Col>
                                <PanelList key={this.state.timestamp} data={this.state.campaignPanels} emptyRequest={this.state.emptyRequest} handleItemsList={(list)=>this.handleItemsList(list)}/>
                            </Grid.Col>
                        </Grid.Row>
                    </div>
                }

            </Page.Content>
        </SiteWrapper>
        );
    }
}

export default CampaignsUploadCreativity;
