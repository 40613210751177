// @flow

import * as React from "react";

import {
  Tabs,
  Tab,
  Card,
  Table,
  Tag,
  Dimmer,
  Icon,
  Button} from "tabler-react";

import { poiService } from "../../_services";
import "./PoiManage.scss"
import MiniMap from "../../components/Map/MiniMap.react"
import Pagination from '../../components/Pagination/Pagination.react'
import PoiManageForm from "./PoiManage.form.react"
import PoiManageImportXlsm from "./PoiManage.ImportXlsm.react"
import {toast} from "react-toastify"

class PoiManage extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      tab: "Open",
      errorMessage: "",
      groupList: [],
      locationSearch: null,
      previewId: null,
      previewPOIs: [],

      isEditRequest: false,
      editGroupId: null,
      editGroupName: null,
      editPois: [],

      isCloneRequest: false,
      cloneGroupId: null,
      cloneGroupName: null,
      clonePois: [],

      activePage: 1,
      itemsCountPerPage: 7,
      totalItemsCount: 0,
      maxPageRangeDisplayed: 5,
      offsetQuery: 0,
      sendingQuery: false,
      isLoading: false,
    }
    this.deleteGroup = this.deleteGroup.bind(this)
    this.editGroup = this.editGroup.bind(this)
    this.cloneGroup = this.cloneGroup.bind(this)
    this.showPOIByGroup = this.showPOIByGroup.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handlePageChangeCallBack = this.handlePageChangeCallBack.bind(this)
    this.getAllGroupsPOI = this.getAllGroupsPOI.bind(this)
    this.resetFormClone = this.resetFormClone.bind(this)
    this.addPOIsFromGroup = this.addPOIsFromGroup.bind(this)
  }

  componentDidMount(){
    this.getAllGroupsPOI();
    if(this.props.tab){
      this.setTabToShow(this.props.tab)
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.tab !== this.props.tab){
      let {tab} = nextProps
      if(tab){
        this.setTabToShow(tab)
      }
    }
  }

  setTabToShow(tab){
    this.setState({
      tab,
      isLoading: true
    }, ()=> this.resetForm())
  }

  resetForm(forced = false){
    const {tab} = this.state
    if(tab!== "Edit" || forced === true){
      this.setState({
        isEditRequest: false,
        editGroupId: null,
        editGroupName: null,
        editPois: [],
      }, ()=> this.getAllGroupsPOI())
    }
  }

  resetFormClone(){
    this.setState({
      isCloneRequest: false,
      cloneGroupId: null,
      cloneGroupName: null,
      clonePois: [],
    }, ()=> this.getAllGroupsPOI())
  }

  getAllGroupsPOI(){
    this.setState({isLoading: true})
    poiService.readGroups().then(
      result => {
        result = result.filter(el => el.name !== "")

        if(localStorage.getItem("type") == "companyleader" || localStorage.getItem("type") == "companyuser"){
          const user = JSON.parse(localStorage.getItem("user"))
          const owner = user.username
          result = result.filter(plan => plan.owner === owner)
        }
        this.setState({
          groupList: result,
          activePage: 1,
          totalItemsCount: result.length,
          offsetQuery: 0,
          isLoading: false
        })
      }
    )
  }

  showPOIByGroup(id){
    const {previewId} = this.state
    if(previewId !== id){
      poiService.readPOIsByGroup(id).then(
        result => {
          this.setState({
            previewPOIs: result.pois,
            previewId: id
          })
        }
      )
    }
    else{
      this.setState({
        previewPOIs: [],
        previewId: null
      })
    }
  }


  editGroup(id, name){
    this.setState({
      isEditRequest: false,
      editGroupId: null,
      editGroupName: null,
      editPois: [],
      previewPOIs: [],
      previewId: null
    })
    if(id){
      poiService.readPOIsByGroup(id).then(
        result => {
          this.setState({
            isEditRequest: true,
            editGroupId: id,
            editGroupName: name,
            editPois: result.pois,
          }, ()=> this.setTabToShow("Edit"))
        }
      )
    }
  }

  cloneGroup(id, name){
    this.setState({
      isCloneRequest: false,
      cloneGroupId: null,
      cloneGroupName: null,
      clonePois: [],
    })
    if(id){
      poiService.readPOIsByGroup(id).then(
        result => {
          this.setState({
            isCloneRequest: true,
            cloneGroupId: null,
            cloneGroupName: name,
            clonePois: result.pois,
          }, ()=> this.setTabToShow("New"))
        }
      )
    }
  }

  deleteGroup(id){
    if(id){
      poiService.deleteGroup(id)
      const groupList = this.state.groupList.filter(el => el.id !== id)
      this.setState({
          groupList
      }, ()=> toast.success('POI Group deleted successful!'))
    }
  }

  getPOIGroupListPreview() {
    const {previewPOIs} = this.state
    return(
      <React.Fragment>

        {previewPOIs && previewPOIs.map((item, index) => {
            const {name, comune, address, brand, type, location, note, marker} = item
            return (
              <div className="row mb-2">
                <div className="col-sm-auto">
                  <Card.Map className="border rounded card-minimap">
                  {location ?
                    <MiniMap lat={location.y} lng={location.x} icon={marker && marker.fileImgix}/>
                    : <p className="alert alert-danger">Location: corrupted data (please remove this item)</p>
                  }
                  </Card.Map>
                </div>
                <div className="col-9 p-1">
                  <div className={index%2 ? "px-3 py-2 rounded" : "px-3 py-2 rounded"} title={note}>
                    <p className="mb-0 text-truncate"><strong>Name:</strong> {name ? name : "-"}</p>
                    <p className="mb-0 text-truncate"><strong>Address:</strong> {address ? address : "-"}</p>
                    <p className="mb-0 text-truncate"><strong>City:</strong> {comune ? comune : "-"}</p>
                    <p className="mb-0 text-truncate"><strong>Type:</strong> {type ? type : "-"}</p>
                    <p className="mb-0 text-truncate"><strong>Brand:</strong> {brand ? brand : "-"}</p>
                    {location ?
                      <p className="mb-0 text-truncate"><strong>Location:</strong> Lat: {location.y ? location.y : "-"}, Long: {location.x ? location.x : "-"}</p>
                      : <p className="alert alert-danger">Location: corrupted data (please remove this item)</p>
                    }
                  </div>
                </div>
              </div>
            )
        })}
        {this.state.previewPOIs.length===0 && <p className="mb-0 text-truncate"><em>No POIs</em></p>}

      </React.Fragment>
    )
  }

  addPOIsFromGroup(idGroup){
    this.props.onSelect(idGroup)
  }

  handlePageChange = (pageNumber) => {
    let offset = pageNumber-1

    this.setState({
        isLoading: true
    },()=>setTimeout(this.handlePageChangeCallBack, 300, pageNumber, offset));
  }

  handlePageChangeCallBack(pageNumber, offset){
      this.setState({
          isLoading: false,
          activePage: pageNumber,
          offsetQuery: offset,
      })
  }

  render(){
    const {tab, editGroupId, isCloneRequest} = this.state
    let {groupList} = this.state
    groupList = groupList.slice(
      this.state.itemsCountPerPage*this.state.offsetQuery,
      this.state.itemsCountPerPage*(this.state.offsetQuery+1))

      return(
        <div className="tabs-poi-manage">
          <Button.List>
            <Button color="link" size="lg" className={tab==="Open" && "btn-tab-active"} onClick={()=>this.setTabToShow('Open')}>Open</Button>
            <Button color="link" size="lg" className={tab==="New" && "btn-tab-active"} onClick={()=>this.setTabToShow('New')}>New</Button>
            <Button color="link" size="lg" className={tab==="Import" && "btn-tab-active"} onClick={()=>this.setTabToShow('Import')}>Import</Button>
            <Button color="link" size="lg" className={tab==="Edit" && "btn-tab-active"} disabled={!this.state.isEditRequest} onClick={()=>this.setTabToShow('Edit')}>Edit</Button>
          </Button.List>
          <Tabs initialTab={tab} key={tab+'-'+editGroupId}>
            <Tab title="Open">
              <Dimmer active={this.state.isLoading} loader={this.state.isLoading}>
                <Table striped={true} className="table-transition-height">
                    <Table.Header>
                      <Table.ColHeader>Group Name</Table.ColHeader>
                      <Table.ColHeader>Created</Table.ColHeader>
                      <Table.ColHeader>Owner</Table.ColHeader>
                      <Table.ColHeader alignContent="right">POIs</Table.ColHeader>
                      <Table.ColHeader alignContent="right">
                        Action{' '}<Button className="ml-1" color="link" icon="refresh-ccw" size="sm" onClick={this.getAllGroupsPOI}/>
                      </Table.ColHeader>
                    </Table.Header>
                    <Table.Body>
                      {groupList.length>0
                        ? groupList.map((item) => {
                            const {createdAt, owner} = item
                            return(
                              <>
                                <Table.Row className={(this.state.previewId !== null && this.state.previewId === item.id) && "tr-transition border border-primary"}>
                                  <Table.Col>
                                    <div className={(this.state.previewId !== null && this.state.previewId === item.id) ? "font-weight-bold mb-2" : "mb-1"}>{item.name}</div>
                                  </Table.Col>
                                  <Table.Col>
                                    {createdAt}
                                  </Table.Col>
                                  <Table.Col>
                                    <Tag addOn={<Icon name="user" />}>{owner}</Tag>
                                  </Table.Col>
                                  <Table.Col alignContent="right">
                                    <Tag
                                      color={(this.state.previewId !== null && this.state.previewId === item.id) && "blue"}
                                      className="cursor-pointer border"
                                      rounded
                                      onClick={()=>this.showPOIByGroup(item.id)}
                                      >
                                        {item.poisObjectIds !== null ? item.poisObjectIds.length : "?"}
                                    </Tag>
                                  </Table.Col>
                                  <Table.Col alignContent="right">
                                    <Button.List className="d-inline-flex">
                                      <Button color="info" title="Edit" outline size="sm" icon="edit" onClick={()=>this.editGroup(item.id, item.name)}></Button>
                                      <Button color="info" title="Clone" outline size="sm" icon="copy" onClick={()=>this.cloneGroup(item.id, item.name)}></Button>
                                      {
                                        this.props.onSelect &&
                                        <Button color="primary" title="Add to search" outline size="sm" icon="plus" onClick={()=>this.addPOIsFromGroup(item.id)}/>
                                      }
                                      <Button color="danger" title="Delete" outline size="sm" icon="trash" onClick={()=>this.deleteGroup(item.id)}></Button>
                                    </Button.List>
                                  </Table.Col>
                                </Table.Row>
                                { // Preview POIs
                                  (this.state.previewId !== null && this.state.previewId === item.id) &&
                                  <Table.Row>
                                    <Table.Col colSpan={5}>
                                      {this.getPOIGroupListPreview()}
                                    </Table.Col>
                                  </Table.Row>
                                }
                              </>
                            )})
                        : <p className="p-2"><em>No POI Group found</em></p>
                      }
                    </Table.Body>
                </Table>
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.itemsCountPerPage}
                  totalItemsCount={this.state.totalItemsCount}
                  maxPageRangeDisplayed={this.state.maxPageRangeDisplayed}
                  handlePageChange={this.handlePageChange}
                />
              </Dimmer>
            </Tab>

            <Tab title="New">
              <PoiManageForm
                isEditRequest={isCloneRequest}
                groupId={null}
                groupName={isCloneRequest && ('Copy of ' + this.state.cloneGroupName)}
                pois={isCloneRequest && this.state.clonePois}
                resetForm={this.resetFormClone}
                callbackOnSave={this.props.onSelect ? this.props.onSelect : null}
              />
            </Tab>

            <Tab title="Import">
              <PoiManageImportXlsm
                onSave={this.editGroup}
              />
            </Tab>

            {
              this.state.isEditRequest &&
              <Tab title="Edit">
                <PoiManageForm
                  isEditRequest={this.state.isEditRequest}
                  groupId={this.state.editGroupId}
                  groupName={this.state.editGroupName}
                  pois={this.state.editPois}
                  resetForm={()=>this.setTabToShow('Open')}
                  callbackOnSave={this.props.onSelect ? this.props.onSelect : null}
                />
              </Tab>
            }
          </Tabs>
        </div>
      )
  }
}

export default PoiManage;
