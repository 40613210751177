// @flow

import * as React from "react";

import {
  Nav

} from "tabler-react";

import {companyService} from '../../_services'
import './IconDropdown.scss'


class IconDropdown extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      activePath: window.location.pathname,
      isKineticUser: false,
      isAdmin: false,
      hasRealDooh: false
    };
  }

  componentWillMount(){
    this.getLocalUserInfo()
  }

  checkLinkActive(name){
    let location = this.state.activePath;
    switch(name){
      case "planner":
        if(location.startsWith('/planner/')){
          return "nav-link-active";
        }
        break;
      case "analytics":
        if(location.startsWith('/campaigns/') || location.startsWith('/mediaowner/')){
          return "nav-link-active";
        }
        break;
      case "paneldata":
        if(location.startsWith('/paneldata/')){
          return "nav-link-active";
        }
        break;
    }
    return "";
  }

  getLocalUserInfo(){
    if(localStorage.getItem("type") == "admin" || localStorage.getItem("type") == "user" || localStorage.getItem("type") == "companyleader" || localStorage.getItem("type") == "companyuser"){
      this.setState({
        isKineticUser: true
      })
    }
    else{
      this.setState({
        isKineticUser: false
      })
    }

    if(localStorage.getItem("type") == "admin" || localStorage.getItem("type") == "user"){
      this.setState({
        isAdmin: true
      })
    }
    else{
      this.setState({
        isAdmin: false
      })
    }

    const hasRealDooh = companyService.getUserAllowedModule('realdooh')
    this.setState({
      hasRealDooh
    })

  }

  render() {
    const {isKineticUser, isAdmin, hasRealDooh} = this.state
    return (
      <Nav className="mx-auto ml-auto text-center icond">
        <Nav.Item to="/" value="Dashboard" className={(hasRealDooh && "disabled ")}/>

        <Nav.Item hasSubNav={isKineticUser && !hasRealDooh} value="Planner" icon={(isKineticUser && !hasRealDooh) ? "chevron-down" : null} key={isKineticUser} className={this.checkLinkActive('planner') + ' ' + ((!isKineticUser || hasRealDooh) ? 'disabled': 'children nav-link-primary')} >
          {(isKineticUser && !hasRealDooh) &&
            <div className="container py-4">
              <Nav.SubItem to="/planner/plan/new">
                <span className="text-uppercase">New</span><br/>
                <small>Generate a new search plan</small>
              </Nav.SubItem>
              <Nav.SubItem to="/planner/plan" >
                <span className="text-uppercase">Open</span><br/>
                <small>Open existing search plan</small>
              </Nav.SubItem>
              <Nav.SubItem to="/planner/poimanager" >
                <span className="text-uppercase">POI Manager</span><br/>
                <small>Manage existing POI Group</small>
              </Nav.SubItem>
              <Nav.SubItem to="/planner/panel-discovery">
                <span className="text-uppercase">Panel Discovery</span><br/>
                <small>Map and Analyze</small>
              </Nav.SubItem>
            </div>
          }
        </Nav.Item>
        <Nav.Item hasSubNav={!hasRealDooh} value="Campaign" icon={(!hasRealDooh) ? "chevron-down" : null} className={"children nav-link-primary " + this.checkLinkActive('analytics ') + (hasRealDooh && "disabled ")} >
          {
            !hasRealDooh &&
            <div className="container py-4">
              <Nav.SubItem to="/campaigns/">
                <span className="text-uppercase">List</span><br/>
                <small>Map, photo and manage</small>
              </Nav.SubItem>
              <Nav.SubItem to="/mediaowner/" >
                <span className="text-uppercase">Reporting</span><br/>
                <small>Standard and custom data analysis</small>
              </Nav.SubItem>
            </div>
          }
        </Nav.Item>

        {/* <Nav.Item hasSubNav={isKineticUser && !hasRealDooh} value="Big Impact" icon={(isKineticUser && !hasRealDooh) ? "chevron-down" : null} key={isKineticUser} className={this.checkLinkActive('planner') + ' ' + ((!isKineticUser || hasRealDooh) ? 'disabled': 'children nav-link-primary')} >
          {(isKineticUser && !hasRealDooh) &&
            <div className="container py-4">
              <Nav.SubItem to="/bigimpact/plan/new">
                <span className="text-uppercase">New</span><br/>
                <small>Generate a new big impact plan</small>
              </Nav.SubItem>
              <Nav.SubItem to="/bigimpact/plan" >
                <span className="text-uppercase">Open</span><br/>
                <small>Open existing big impact plan</small>
              </Nav.SubItem>
            </div>
          }
        </Nav.Item> */}

        <Nav.Item hasSubNav={isAdmin || hasRealDooh} value="ADVANCED DOOH" icon={(isAdmin || hasRealDooh) ? "chevron-down" : null} key={isAdmin || hasRealDooh} className={this.checkLinkActive('dooh') + ' ' + (!(isAdmin || hasRealDooh) ? 'disabled': 'children nav-link-primary')} >
          {(isAdmin || hasRealDooh) &&
            <div className="container py-4">
              <Nav.SubItem to="/dooh/plan/new">
                <span className="text-uppercase">New</span><br/>
                <small>Create new proposal</small>
              </Nav.SubItem>
              <Nav.SubItem to="/dooh/plan" >
                <span className="text-uppercase">Open</span><br/>
                <small>Open existing proposal</small>
              </Nav.SubItem>
              <Nav.SubItem to="/dooh/panel-discovery" >
                <span className="text-uppercase">Panel Discovery</span><br/>
                <small>Map and Analyze</small>
              </Nav.SubItem>
            </div>
          }
        </Nav.Item>

        <Nav.Item to="/paneldata" value="Panel Data" className={(!isAdmin && "disabled ") + this.checkLinkActive('paneldata')}></Nav.Item>
    </Nav>
    );
  }
}

export default IconDropdown;
