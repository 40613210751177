import * as React from "react";
import { Table, Badge } from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';
import styles from 'antd/dist/antd.css';
import './Dooh.Plan.Ambiences.Sorter.scss'

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));

const columns = [
  {
    title: '',
    dataIndex: 'sort',
    width: 30,
    className: 'drag-visible',
    render: () => <DragHandle />,
  },
  {
    title: 'SELECTION',
    dataIndex: 'name',
    className: 'drag-visible',
  },
  {
    title: 'DOOH',
    dataIndex: 'doohs',
    render: el => el ? (
      <Badge count={el.length} overflowCount={999} size="default" style={{backgroundColor: "#fff", color: "#999", boxShadow: "0 0 0 1px #d9d9d9 inset"}}/>
    ) : '-'
  },
  {
    title: 'ACTIVE',
    dataIndex: 'visible',
    className: 'drag-visible',
    render: value => value ? <Badge status="processing" text="ON" /> : <Badge status="default" text="OFF" />
  },
];

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

export class SortableTableAmbiences extends React.Component {
  state = {
    dataSource: [],
  };

  componentDidMount(){
    const dataSource = this.props.ambiences.map((amb, index) =>{
      amb.index = index
      return amb
    })

    this.setState({
      dataSource
    })
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.ambiences!==this.props.ambiences){
      const dataSource = this.props.ambiences.map((amb, index) =>{
        amb.index = index
        return amb
      })

      this.setState({
        dataSource: nextProps.ambiences
      })
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { dataSource } = this.state;
    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
      this.setState({ dataSource: newData });
      this.props.onSortEnd(newData)
    }
  };

  DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { dataSource } = this.state;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  render() {
    const { dataSource } = this.state;
    const DraggableContainer = props => (
      <SortableContainer
        useDragHandle
        helperClass="row-dragging"
        onSortEnd={this.onSortEnd}
        {...props}
      />
    );
    return (
      <Table
        style={styles}
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: this.DraggableBodyRow,
          },
        }}
      />
    );
  }
}
