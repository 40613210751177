// @flow

import * as React from "react";
import { Formik } from "formik";
import { LoginPage as TablerLoginPage } from "tabler-react";

import { userService, companyService } from "../_services";
import { toast, ToastContainer } from "react-toastify";

type Props = {||};

function LoginPage(props: Props): React.Node {
  userService.logout();

  const {error} = props.match.params
  if(error == 401){
    toast.error('Account disabled')
  }

  const getHomePagePath = (user = null) => {
    if(user === null)
      return "/logout";

    let pathHomepage = "/"

    let { from } = props.location.state || {
      from: { pathname: pathHomepage },
    };

    // Workaround REALDOOH for companies
    const hasRealDoohState = companyService.getUserAllowedModule('realdooh', user)
    if(hasRealDoohState){
      pathHomepage = "/dooh/plan"
      from = { pathname: pathHomepage }
    }

    return from
  }

  return (
    <>
      <ToastContainer position="bottom-center"/>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validate={values => {
          // same as above, but feel free to move this into a class method now.
          let errors = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          return errors;
        }}
        onSubmit={(
          values,
          { setSubmitting, setErrors /* setValues and other goodies */ }
        ) => {
          userService.login(values.email, values.password).then(
            user => {
              if(!user.companyId || !user.company){
                console.error("No company!")
                window.location.replace("/logout/401");
              }
              else {
                let from = getHomePagePath(user)
                props.history.push(from);
              }
            },
            error => {
              toast.error("Username or password wrong!")
            }
          );
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <TablerLoginPage
            onSubmit={handleSubmit}
            onChange={handleChange}
            onBlur={handleBlur}
            values={values}
            errors={errors}
            touched={touched}
          />
        )}
      />
    </>
  );
}

export default LoginPage;
