// @flow

import * as React from "react";
import {Redirect} from "react-router-dom";

import {
  Page,
  Grid,
  Card,
} from "tabler-react";


import SiteWrapper from "../SiteWrapper.react";
import CompanyManage from "./CompanyManage.react"

class Companies extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      isAdminUser: true
    }
  }

  componentWillMount(){
    this.getLocalUserInfo()
  }

  getLocalUserInfo(){
    if(localStorage.getItem("type") === "admin"){
      this.setState({
        isAdminUser: true
      })
    }
    else{
      this.setState({
        isAdminUser: false,
      })
    }
  }

  render() {

    if(this.state.error || !this.state.isAdminUser){
      return <Redirect
          to={{ pathname: "/404", state: { from: this.props.location } }}
      />
    }

    return (
      <SiteWrapper {...this.state}>
        <Page.Content>
          <Page.Header
            title="Companies"
            // subTitle={this.state.totalItemsCount + " CompanyGroups found"}
          />
          <Grid.Row cards deck>
            <Grid.Col sm={12}>
              <Card className="p-4">
                <CompanyManage/>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      </SiteWrapper>
    );
  }
}

export default Companies;
