// @flow

import * as React from "react";

import {
  Page,
  Grid,
  Card,
} from "tabler-react";

import Iframe from 'react-iframe'

import SiteWrapper from "../SiteWrapper.react";

class TestIframe extends React.Component {

    constructor(props){
        super(props)
    }

    render() {

        return (
            <SiteWrapper {...this.state}>
                <Page.Content>
                    <Page.Header title="OOH Panels"/>
                    <Grid.Row cards deck>
                        <Grid.Col sm={12}>
                            <Card>
                                <Iframe height="768" src="https://datastudio.google.com/embed/reporting/c4044fdf-6cfd-477b-8f84-002a04f616ea/page/1deLC" frameBorder="0" style="border:0" allowFullScreen/>
                            </Card>
                        </Grid.Col>
                    </Grid.Row>
                </Page.Content>
            </SiteWrapper>
        )
    }
} 


export default TestIframe;