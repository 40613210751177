import { authHeader } from "../_helpers";
import { userService } from "."
import {defineConfig} from '../config'

export const audienceService = {
    getApiPathaudience,
    getAllAudienceType,
    normalizeSimilarAssetByType,
    getAudienceDetailsById,
    getIndexesAudienceByIds,
    getWeeklyAudienceByIds,
    getDataForSynchingChart,
    getDataForSynchingChartDouble,
    syncDatabases,
};

// TODO: temporaneamente SEARCH, in attesa di migrazione BE a AUDIENCE_SERVICE
const {apiUrl, apiVer} = defineConfig.SEARCH_SERVICE

function getApiPathaudience(){
  return (apiUrl+apiVer)
}

function getAllAudienceType() {
  const url = apiUrl+apiVer+"/audience/type/all";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function normalizeSimilarAssetByType(type) {
  const url = apiUrl+apiVer+"/audience/normalize/" + type;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function syncDatabases() {
  const url = apiUrl+apiVer+"/audience/cronAudienceMysql/";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAudienceDetailsById(audienceId) {
  const url = apiUrl+apiVer+"/audience/target/" + audienceId;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getIndexesAudienceByIds(kinfaceids, audienceId, type = 'ooh') {
  const url = apiUrl+apiVer+"/audience/idx";

  const bodyJson = {
    audienceId,
    kinfaceids,
    type
  }

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(bodyJson)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getDataForSynchingChart(kinfaceids, audienceId, days, type = 'ooh') {
  const url = apiUrl+apiVer+"/audience/chart/synchingchartself";

  const headers = {...authHeader("application/json"), days};

  const bodyJson = {
    audienceId,
    kinfaceids,
    type
  }

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(bodyJson)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getDataForSynchingChartDouble(kinfaceids, audienceId, firstDates, secondDates, type = 'ooh') {
  const url = apiUrl+apiVer+"/audience/chart/synchingchartdoubleV2";

  const headers = {...authHeader("application/json"), firstDates, secondDates};

  const bodyJson = {
    audienceId,
    kinfaceids,
    type
  }

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(bodyJson)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getWeeklyAudienceByIds(kinfaceids, audienceId, orderType, valueType = 'index', type = 'ooh') {
  const url = apiUrl+apiVer+"/audience/chart/heatmap";

  const headers = {...authHeader("application/json"), orderType, valueType};

  const bodyJson = {
    audienceId,
    kinfaceids,
    type
  }

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(bodyJson)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {

      switch(response.status){
        case 401:
          // auto logout if 401 response returned from api
          userService.logout();
          window.location.reload(true);
          break;
        case 422:
          return data;
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}