import React from "react"
const { compose, withProps, lifecycle } = require("recompose");
const {
  withScriptjs,
} = require("react-google-maps");
const { StandaloneSearchBox } = require("react-google-maps/lib/components/places/StandaloneSearchBox");
const apiKey = "AIzaSyDk2tsrjgOei53e0A5X8J7nFfj-MiDymIY"
const PlacesWithStandaloneSearchBox = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key="+apiKey+"&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
  }),
  lifecycle({
    componentWillMount() {
      const refs = {}
      this.setState({
        places: this.props.places ? this.props.places: [],
        bounds: this.props.bounds,
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          this.setState({
            places,
          });
          this.props.handlerCenterPosition(places)
        },
      })
    }
  }),
  withScriptjs
)(props =>
  <div data-standalone-searchbox="" className="btn ml-5 mr-0 pb-0 pt-0">
    <StandaloneSearchBox
      ref={props.onSearchBoxMounted}
      bounds={props.bounds}
      onPlacesChanged={props.onPlacesChanged}
      controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
    >
      <input
        type="text"
        placeholder="Search Point of Interest"
        className="map-poi-input"
        style={{
          boxSizing: `border-box`,
          border: `1px solid rgba(0,40,100,.12)`,
          width: `240px`,
          height: `38px`,
          padding: `0 12px`,
          borderRadius: `3px`,
          // boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
          fontSize: `14px`,
          outline: `none`,
          textOverflow: `ellipses`,
        }}
      />
    </StandaloneSearchBox>
    <ol className="border shadow bg-white m-0" style={{"max-height":"200px", "max-width":"100px","overflow":"auto"}}>
      {props.places.map(({ place_id, formatted_address, geometry: { location } }) =>
        <li key={place_id}>
          {formatted_address}
          {" at "}
          ({location.lat()}, {location.lng()})
        </li>
      )}
    </ol>
  </div>
);

export default PlacesWithStandaloneSearchBox;
