import defaultSettings from './defaultSettings';
import {proxyConfig} from './proxy';
const { REACT_APP_ENV } = process.env;

const {
  ASSET_SERVICE,
  AUDIENCE_SERVICE,
  AUDIT_SERVICE,
  BIGIMPACT_SERVICE,
  CAMPAIGN_SERVICE,
  COMPANY_SERVICE,
  DOOH_SERVICE,
  DOOHESTIMATION_SERVICE,
  GEO_SERVICE,
  GEOISOLINE_SERVICE,
  PDF_SERVICE,
  POI_SERVICE,
  SEARCH_SERVICE,
  SHARE_SERVICE,
  STATS_SERVICE,
  USER_SERVICE
} = proxyConfig;

if(REACT_APP_ENV){
  console.log('REACT_APP_ENV', REACT_APP_ENV)
}

export const defineConfig = {
  ...defaultSettings,
  ASSET_SERVICE: ASSET_SERVICE(REACT_APP_ENV || 'prod'),
  AUDIENCE_SERVICE: AUDIENCE_SERVICE(REACT_APP_ENV || 'prod'),
  AUDIT_SERVICE: AUDIT_SERVICE(REACT_APP_ENV || 'prod'),
  BIGIMPACT_SERVICE: BIGIMPACT_SERVICE(REACT_APP_ENV || 'prod'),
  CAMPAIGN_SERVICE: CAMPAIGN_SERVICE(REACT_APP_ENV || 'prod'),
  COMPANY_SERVICE: COMPANY_SERVICE(REACT_APP_ENV || 'prod'),
  DOOH_SERVICE: DOOH_SERVICE(REACT_APP_ENV || 'prod'),
  DOOHESTIMATION_SERVICE: DOOHESTIMATION_SERVICE(REACT_APP_ENV || 'prod'),
  GEO_SERVICE: GEO_SERVICE(REACT_APP_ENV || 'prod'),
  GEOISOLINE_SERVICE: GEOISOLINE_SERVICE(REACT_APP_ENV || 'prod'),
  PDF_SERVICE: PDF_SERVICE(REACT_APP_ENV || 'prod'),
  POI_SERVICE: POI_SERVICE(REACT_APP_ENV || 'prod'),
  SEARCH_SERVICE: SEARCH_SERVICE(REACT_APP_ENV || 'prod'),
  SHARE_SERVICE: SHARE_SERVICE(REACT_APP_ENV || 'prod'),
  STATS_SERVICE: STATS_SERVICE(REACT_APP_ENV || 'prod'),
  USER_SERVICE: USER_SERVICE(REACT_APP_ENV || 'prod'),
  testEnv: REACT_APP_ENV
}
