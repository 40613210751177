// @flow

import * as React from "react";

import { Page, Grid, GalleryCard, Form, Button } from "tabler-react";
import Pagination from '../Pagination/Pagination.react'

import { justificativeService, imageService } from "../../_services";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import './Gallery.scss'

class Gallery extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            selected: -1,
            photoIndex: 0,
            photoIsOpen: false,
            images: [],
            seqId: null,
            data: null,

            activePage: 1,
            itemsCountPerPage: 12,
            totalItemsCount: 0,
            maxPageRangeDisplayed: 5,
            offsetQuery: 0,
            sendingQuery: false,
            isLoading: true,
        }

        this.handlePageChange = this.handlePageChange.bind(this)
        this.onPanelClickAllPhotos = this.onPanelClickAllPhotos.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.data!==this.props.data){
            this.setState({
                data: nextProps.data,
                totalItemsCount: nextProps.data.length,
                activePage: 1,
                selected:-1,
                isLoading: false
            });
        }
    }

    checkLabel(child) {
        const checkDate = child.resultBmeId == 3 && child.resultKinId !=3 ? this.getLastUpdateFormatted(child.lastUpdate) : child.checkDate
        const statusColor = child.resultKinId == 0 ? "bg-success"
            : child.resultKinId == 1 ? "bg-danger"
            : child.resultKinId == 2 ? "bg-warning"
            : "bg-muted"
        return(
            <React.Fragment>
                <div className="text-center">
                    <strong>
                        <span className={"status-icon " + statusColor} />
                        Check date:
                    </strong> {checkDate}
                </div>
            </React.Fragment>
        )
    }

    getLastUpdateFormatted(lastUpdate){
        let lastUpdateSeq = '-'
        if(lastUpdate){
            let lastUpdateTS = lastUpdate ? new Date(lastUpdate) : null
            let monthLUSeq = (lastUpdateTS.getMonth()+1)>=10 ? (lastUpdateTS.getMonth()+1) : ("0"+(lastUpdateTS.getMonth()+1))
            lastUpdateSeq = lastUpdateTS == "-" ? "-" : lastUpdateTS.getDate() + '/' + monthLUSeq + '/' + lastUpdateTS.getFullYear()
        }
        return lastUpdateSeq
    }

    getValidateUrl(img){
      return imageService.getUrlPhoto(img)
    }

    getPhotosCheckBySeqId(seqId, tracked = false, isKin = false, isTransitPanel=false){
        if(tracked && this.state.images.length == 0 && !isTransitPanel){
            justificativeService.getPhotoCheckBySeq(seqId).then(
                result => {
                    let images = []
                    if(isKin) {
                        for(let i=0; i<result.length; i++){
                            let url = result[i].kinPhotoCert
                            url = this.getValidateUrl(url)
                            images.push(url)
                        }
                        console.log('Immagini Kinetic')
                    }
                    else{
                        for(let i=0; i<result.length; i++){
                            let url = result[i].bmePhoto
                            url = this.getValidateUrl(url)
                            images.push(url)
                        }
                        console.log('Immagini BME')
                    }

                    this.setState({
                        images: images
                    }, ()=> this.setState({photoIsOpen: true}))
                    console.log(images)
                },
                error => {
                    console.error(error);
                }
            )
        }
        else{
            if(tracked) {
                this.setState({photoIsOpen: true})
            }
            else{
                console.log('Nessun controllo previsto!') // Non dovrebbe accadere in questa sezione
            }
        }
    }

    onPanelClick = (index, ev, seqId) => {
        let indexWOS = (this.state.itemsCountPerPage*this.state.offsetQuery) + index
        this.props.handleState(indexWOS, seqId)

        this.setState((state)=>({
            selected: index == state.selected ? -1 : index,
            seqId: seqId == state.seqId ? null : seqId
        }))
    }

    onPanelClickAllPhotos(ev, seqId, index, statusBME, statusKin) {
        let campaignPanels = this.state.data.slice(
            this.state.itemsCountPerPage*this.state.offsetQuery,
            this.state.itemsCountPerPage*(this.state.offsetQuery+1))

        let isKinAudit = (statusKin != statusBME)
        let isTracked = statusKin != 3 // non dovrebbe accadere in questa sezione

        const isTransitPanel = campaignPanels[index].groupTypeId === "-1"

        var condACDC = false
        if(campaignPanels[index].bmePhotoCheck){
            if(campaignPanels[index].bmePhotoCheck.length>1 || campaignPanels[index].bmePhotoCheckVisibility || campaignPanels[index].kinPhotoCheck || isTransitPanel){
                condACDC = true
                // console.log("condACDC -> " + seqId)
            }
        }

        this.setState({
            photoIndex: 0,
            // images: [], //svuoto
            photoIsOpen: condACDC,
            images: (campaignPanels[index].kinPhotoCheck ? [this.getValidateUrl(campaignPanels[index].kinPhotoCheck)]
                : campaignPanels[index].bmePhotoCheck ? (campaignPanels[index].bmePhotoCheck.length > 1 || isTransitPanel || campaignPanels[index].bmePhotoCheckVisibility ? this.getValidateListUrl(campaignPanels[index].bmePhotoCheck) : []) : [])
        }, () => this.getPhotosCheckBySeqId(seqId, isTracked, isKinAudit, isTransitPanel))

    }

    getValidateListUrl(imgs){
      for(let i=0; i<imgs.length; i++){
          let img = imgs[i]
          if(img){
            img = this.getValidateUrl(img)
          }
          else{
            img = ""
          }
          imgs[i] = img
      }
      return imgs
    }

    handlePageChange = (pageNumber) => {
        let offset = pageNumber-1
        this.setState({
            activePage: pageNumber,
            offsetQuery: offset,
            selected: -1,
            isLoading: true
        }, () => this.disableLoading());
    }

    disableLoading(){
        // console.log(this.state.itemsCountPerPage*this.state.offsetQuery +' > '+this.state.itemsCountPerPage*(this.state.offsetQuery+1))
        this.setState({isLoading: false})
    }

    getQueryStringForThumb(){
      return imageService.getQueryStringAPI(230, 230, true, 25)
    }

    render() {
        const { photoIndex, photoIsOpen } = this.state;
        if(this.state.data)
            return (
                <div>
                    <Grid.Row className="row-cards pt-3">
                        {!this.state.isLoading && this.state.data && this.state.data.slice(
                            this.state.itemsCountPerPage*this.state.offsetQuery,
                            this.state.itemsCountPerPage*(this.state.offsetQuery+1)).map((item, key) => (
                                (item.bmePhotoCheck || item.kinPhotoCheck) ?
                                    <Grid.Col sm={6} md={4} lg={3} key={key}>
                                        <div className="gallery-item" key={key} title={item.mediaOwner + ' - ' + item.municipality + ' - ' + item.panelType}>
                                            <GalleryCard className={(this.state.selected == key ? " border border-primary" : "")}>
                                                <GalleryCard.Image
                                                src={this.getValidateUrl(item.kinPhotoCheck ? item.kinPhotoCheck : item.bmePhotoCheck[0]) + this.getQueryStringForThumb()}
                                                alt={`Photo by ${item.seqId}`}
                                                style={{"max-height": "240px"}}
                                                />
                                                <GalleryCard.Footer>
                                                    <div className="row">
                                                        <div className="col-12 p-2">
                                                            <GalleryCard.Details
                                                                fullName={ this.checkLabel(item)}
                                                            />
                                                        </div>
                                                        <div className="col-12">
                                                            <Button.List>
                                                                <Button color="primary" size="sm" onClick={(e) => this.onPanelClick(key, e, item.seqId)} title={item.mediaOwner+' - '+item.panelType+' ('+item.municipality+ ': ' +item.address+')'}>
                                                                    Details
                                                                </Button>
                                                                <Button color="primary" size="sm" onClick={(e) => this.onPanelClickAllPhotos(e, item.seqId, key, item.resultBmeId, item.resultKinId)}>
                                                                    All Photos
                                                                </Button>
                                                            </Button.List>
                                                        </div>
                                                    </div>
                                                </GalleryCard.Footer>
                                            </GalleryCard>
                                        </div>
                                    </Grid.Col>
                                : <h3 className="p-5">No results</h3>
                            ))
                        }
                    </Grid.Row>
                    <Grid.Row>
                        {photoIsOpen && (
                            <Lightbox
                                mainSrc={this.state.images[photoIndex]}
                                nextSrc={this.state.images[(photoIndex + 1) % this.state.images.length]}
                                prevSrc={this.state.images[(photoIndex + this.state.images.length - 1) % this.state.images.length]}
                                onCloseRequest={() => this.setState({ photoIsOpen: false })}
                                onMovePrevRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + this.state.images.length - 1) % this.state.images.length,
                                    })
                                }
                                onMoveNextRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + 1) % this.state.images.length,
                                    })
                                }
                            />
                        )}
                        <Grid.Col>
                        {
                            !this.state.isLoading ?
                            <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.itemsCountPerPage}
                                totalItemsCount={this.state.totalItemsCount}
                                maxPageRangeDisplayed={this.state.maxPageRangeDisplayed}
                                handlePageChange={this.handlePageChange}
                            /> : ''
                        }
                        </Grid.Col>
                    </Grid.Row>
                    {this.state.data.length==0 ? <h3 className="pb-6 text-center">No results</h3> : ""}
                </div>
            );
        else return <h3 className="p-5">No photos</h3>
    }
}

export default Gallery;
