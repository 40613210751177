// @flow

import * as React from "react";
import {Redirect} from "react-router-dom";

import {
  Page,
  Grid,
  Card,
  Table,
  Form,
  Icon,
  Dimmer,
  Nav,
  Container,
  Button,
  Dropdown,
} from "tabler-react";

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import _ from 'lodash'
import SiteWrapper from "../SiteWrapper.react";
import Pagination from "../components/Pagination"
import DownloaderPolling from "../components/DownloaderPolling"
import { campaignService, justificativeService, pdfService, imageService } from "../_services";
import AuditFilters from "./Audit.Filters.react"
import AuditCounters from './Audit.Counters.react'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import './Audit.scss'
// import { finished } from "stream";

class Audit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: props.match.params.id,
            user: {},
            userInfo: [],
            items: [],
            timestamp: Date.now().toString(),

            activePage: 1,
            itemsCountPerPage: 20,
            totalItemsCount: 0,
            maxPageRangeDisplayed: 5,
            offsetQuery: 0,
            sendingQuery: false,

            photoIndex: 0,
            photoIsOpen: false,
            images : [],
            error: null,
            isLoading: true,

            campaignPanels: [],
            panelsAll: [],
            auditImages: [],
            isImagesLoading: true,

            isThumbnailSingleMode: true,

            campaignInfo: {
                id: props.match.params.id,
                advertiser: '',
                campaign: '',
                startDate: '',
                endDate: '',
                createDate: '',
                numPanels: 0,
                idKinCampaign: -1
            },

            selectedRegL: "",
            selectedDis: "",
            selectedCitL: "",
            selectedMedOwn: "",
            selectedFrmt: "",
            selectedChecked: "",
            selectedStatus: "",

            selectedOrder: "desc",
            querySearch: "",

            selectedSeqId: "",
            emptyRequest: 0,
            issetFile: false,
            issetMultiFile: false,
            exportType: "all",
            exportFiltered: "all",
            exportDateFrom: this.dateToYMD(new Date()),
            exportDateTo: this.dateToYMD(new Date()),
            photoSettingUrl: false,
            photoSettingState: false,
            isOpenModal: false,
            isOpenModalExport: false,
            isOpenModalMulti: false,
            isOpenModalPhS: false,
            isLoaded: false,
            buttonAssignActive: true,
            buttonAssignActiveMulti: true,
            buttonAssignActivePhS: true,
            isAllAssigned: false,
            countFiles: -1,

            defaultDate: new Date(),

            seqIds: [],

            pdfLoading: false,
            pdfError: null,

            forbidden: false
        };

        this.toggleModalUpload = this.toggleModalUpload.bind(this);
        this.toggleModalExport = this.toggleModalExport.bind(this);
        this.toggleModalPhotoSetting = this.toggleModalPhotoSetting.bind(this);
        this.toggleModalMultiUpload = this.toggleModalMultiUpload.bind(this);
        this.handleChangeFile = this.handleChangeFile.bind(this);
        this.handleChangeExport = this.handleChangeExport.bind(this);
        this.handleChangeExportFilter = this.handleChangeExportFilter.bind(this);
        this.handleChangeExportDateFrom = this.handleChangeExportDateFrom.bind(this);
        this.handleChangeExportDateTo = this.handleChangeExportDateTo.bind(this);
        this.handleChangeMultiFile = this.handleChangeMultiFile.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.onFormSubmitMulti = this.onFormSubmitMulti.bind(this)
        this.onFormSubmitPhS = this.onFormSubmitPhS.bind(this)
        this.handleChangeQuery = this.handleChangeQuery.bind(this);
        this.handleChangeOrder = this.handleChangeOrder.bind(this);

        this.handlerChangeItem = this.handlerChangeItem.bind(this)
        this.handleSelectAllFiltered = this.handleSelectAllFiltered.bind(this)
        this.emptySeqIds = this.emptySeqIds.bind(this)
        this.onChangeStatusValidation = this.onChangeStatusValidation.bind(this)
        this.getCampaignReport = this.getCampaignReport.bind(this)
        this.getCampaignZipReport = this.getCampaignZipReport.bind(this)
        this.getManagePdf = this.getManagePdf.bind(this)
        this.reloadAudit = this.reloadAudit.bind(this)
    }

    timer = null

    componentDidMount() {
      this.getLocalUserInfo()
      this.loadCampaignInfo(this.state.id)
    }

    getLocalUserInfo(){
      const type = localStorage.getItem("type")
      const user = localStorage.getItem("user")

      switch(type){
        case "admin":
        case "user":
        case "mediaOwner":
        case "companyleader":
        case "companyuser":
        case "mediaOwner":
          this.setState({
            user: JSON.parse(user),
            isAdvertiser: false,
            forbidden: false
          })
          break;
        case "advertiser":
          this.setState({
            isAdvertiser: true,
            forbidden: true
          })
          break;
        default:
          this.setState({
            forbidden: true
          })
      }
    }

    toggleModalUpload(ev, seqId = "") {
        this.setState(prevState => ({
          selectedSeqId: seqId,
          isOpenModal: !prevState.isOpenModal,
          buttonAssignActive: true
        }));
    }

    toggleModalExport(ev) {
        this.setState(prevState => ({
          isOpenModalExport: !prevState.isOpenModalExport
        }));
    }

    toggleModalPhotoSetting(ev, seqId = "", index = null) {
        this.setState(prevState => ({
          selectedSeqId: seqId,
          isOpenModalPhS: !prevState.isOpenModalPhS,
          buttonAssignActivePhS: true,
        }));
        if(index !== null){
            let panels = this.state.campaignPanels.slice(
                this.state.itemsCountPerPage*this.state.offsetQuery,
                this.state.itemsCountPerPage*(this.state.offsetQuery+1))
            // console.log(panels[index].bmePhotoCheck)
            this.setState({
                photoSettingUrl: panels[index].bmePhotoCheck,
                photoSettingState: panels[index].bmePhotoCheckVisibility ? panels[index].bmePhotoCheckVisibility : new Array(panels[index].bmePhotoCheck.length).fill(1)
            })
        }
        else{
            this.setState({
                photoSettingUrl: false,
                photoSettingState: false
            })
        }
    }

    togglePhotoState(index){
        let photoState = this.state.photoSettingState

        if(photoState){
            photoState[index] = photoState[index] == 1 ? 0 : 1
            this.setState({
                photoSettingState: photoState
            })
        }
    }

    checkPhotoState(numbers){
        if(numbers){
            let result = !numbers.every(function (el) {
                return el === 0;
            });
            return result
        }
        return false
    }

    toggleModalMultiUpload(ev, seqId = "") {
        this.setState(prevState => ({
          selectedSeqId: seqId,
          issetMultiFile: false,
          isOpenModalMulti: !prevState.isOpenModalMulti,
          buttonAssignActiveMulti: true
        }));
    }

    handleChangeFile(ev){
        // console.log(ev.target.files)
        this.setState({
            issetFile: ev.target.files
        })
    }

    handleChangeExport(ev){
        this.setState({
            exportType: ev.target.value,
            pdfError: null
        })
    }

    dateToYMD(date) {
        var d = date.getDate();
        var m = date.getMonth() + 1; //Month from 0 to 11
        var y = date.getFullYear();
        return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    }

    handleChangeExportFilter(ev){
        this.setState({
            exportFiltered: ev.target.value,
            pdfError: null
        })
    }

    handleChangeExportDateFrom(date){
        let stringDate = this.dateToYMD(date)
        this.setState({
            exportDateFrom: stringDate,
            pdfError: null
        })
    }

    handleChangeExportDateTo(date){
        let stringDate = this.dateToYMD(date)
        this.setState({
            exportDateTo: stringDate,
            pdfError: null
        })
    }

    handleChangeMultiFile(ev){
        // console.log(ev.target.files)
        this.setState({
            issetMultiFile: ev.target.files
        })
    }

    onFormSubmit(e){
        e.preventDefault() // Stop form submit
        this.setState({
            buttonAssignActive: false
        })
        // const files = Array.from(e.target.files)
        let formData = new FormData()
        const file = this.state.issetFile[0]
        // console.log(file)
        formData.append("file", file, file.name);

        justificativeService.setFileUploadPhotoCheck(formData, this.state.selectedSeqId).then(
            result => {
                // console.log(result)
                this.setState({
                    isOpenModal: false
                }, ()=>this.setAuditPanelStatus(this.state.selectedSeqId, 0))
                // ,()=>this.reloadData())
            },
            error => {
                console.error(error);
            }
        );
    }

    onFormSubmitMulti(e){
        e.preventDefault() // Stop form submit

        // const files = Array.from(e.target.files)
        var filesList = this.state.issetMultiFile
        const files = [...filesList]

        this.setState({
            buttonAssignActiveMulti: false,
            countFiles: files.length
        }, ()=>this.setFileUploadBme(files))
    }

    onFormSubmitPhS(e){
        e.preventDefault() // Stop form submit

        const seqId = this.state.selectedSeqId
        const photoSettingUrl = this.state.photoSettingUrl
        const photoSettingState = this.state.photoSettingState

        // console.log(photoSettingUrl)
        // console.log(photoSettingState)

        if(photoSettingState){
            justificativeService.setBmePhotoVisibility(seqId, photoSettingState).then(
                result => {
                    // console.log(result)
                    this.setState({
                        selectedSeqId: "",
                        isOpenModalPhS: false,
                        buttonAssignActivePhS: false,
                        photoSettingState: false,
                        photoSettingUrl: false
                    }, ()=>this.reloadAudit(this.state.campaignInfo.idKinCampaign))
                },
                error => {
                    console.error(error);
                }
            );
        }
    }

    setFileUploadBme(files){
        // console.log(files)
        if(files.length>0){
            // console.log(files.length)
            let formData = new FormData()
            let file = files.shift()
            let countFiles = this.state.countFiles - 1
            // console.log(file)
            formData.append("file", file, file.name);

            justificativeService.setFileUploadPhotoCheckDynamic(formData, this.state.selectedSeqId).then(
                result => {
                    // console.log(result)
                    this.setState({
                        countFiles: countFiles
                    }, ()=>this.setFileUploadBme(files))
                },
                error => {
                    console.error(error);
                }
            );
        }
        else{
            this.setState({
                isOpenModalMulti: false,
                countFiles: -1
            })
            this.setAuditPanelStatus(this.state.selectedSeqId, 0)
        }
    }

    loadCampaignInfo(id){
        campaignService.getCampaignInfo(id).then(
          result => {

            let startDate = result.startDate.split('/')
            let defaultDate = new Date(startDate[2], Number.parseInt(startDate[1])-1, Number.parseInt(startDate[0]), 12)
            let defaultDateString = startDate[2]+"-"+startDate[1]+"-"+startDate[0]

            this.setState({
              campaignInfo: result,
              creativities: result.creativity ? result.creativity : [],
              defaultDate,
              exportDateFrom: defaultDateString
            }, () => this.loadAudit(this.state.campaignInfo.idKinCampaign))
          },
          error => {
            this.setState({ forbidden: true })
            console.error(error);
          }
        );
    }

    loadAudit(id){
        justificativeService.getAuditFindByCampaign(id).then(
            result => {
                this.setState({
                    campaignPanels: result,
                    panelsAll: result,
                    totalItemsCount: result.length,
                    isLoading: false
                }, ()=> this.loadPhotoAudit())
            },
            error => {
                console.error(error);
            }
        )
    }

    getValidateUrl(img){
      return imageService.getUrlPhoto(img)
    }

    getValidateListUrl(imgs){
        for(let i=0; i<imgs.length; i++){
            let img = imgs[i]
            if(img){
              img = this.getValidateUrl(img)
            }
            else{
              img = ""
            }
            imgs[i] = img
        }
        return imgs
    }

    loadPhotoAudit(){
        this.setState({
            isImagesLoading: true
        })

        let array = this.state.campaignPanels.slice( this.state.itemsCountPerPage*this.state.offsetQuery, this.state.itemsCountPerPage*(this.state.offsetQuery+1))
        let auditImages = []
        for(let i=0; i< array.length; i++){
            auditImages[i] = (array[i].bmePhotoCheck ? (array[i].bmePhotoCheck.length > 1 ? array[i].bmePhotoCheck : []) : [])
            let seqId = array[i].seqId
            let resultKinId = array[i].resultKinId
            let resultBmeId = array[i].resultBmeId
            let lastUpdate = array[i].lastUpdate
            let tracked = resultKinId != 3
            let checked = resultKinId != resultBmeId

            if((tracked && auditImages[i].length == 0) || lastUpdate){
                justificativeService.getPhotoCheckBySeq(seqId).then(
                    result => {
                        if(checked || lastUpdate) {
                            if(result.length>0){
                                for(let j=result.length-1; j<result.length; j++){//acdc una sola foto
                                    let img = result[j].kinPhotoCheck
                                    img = this.getValidateUrl(img)
                                    auditImages[i]=[img] //solo ultima
                                }
                            }
                            else{
                                auditImages[i] = [this.getValidateUrl(array[i].kinPhotoCheck)]
                            }
                        }
                        else{
                            for(let j=0; j<result.length; j++){
                                let img = result[j].bmePhoto
                                img = this.getValidateUrl(img)
                                auditImages[i].push(img)
                            }
                        }
                        this.setState({
                            auditImages: auditImages
                        })
                    },
                    error => {
                        console.error(error);
                    }
                )
            }
            else if(tracked){
                let photoBmeState = array[i].bmePhotoCheckVisibility
                auditImages[i] = this.hidePhotoBme(photoBmeState, auditImages[i])
                auditImages[i] = this.getValidateListUrl(auditImages[i])
                this.setState({
                    auditImages: auditImages
                })
            }
            // else{
            //     console.log('Nessun controllo previsto!')
            // }
        }
    }

    setAuditPanelStatus(seqId, status){
        // let appoggio = this.state.campaignPanels
        justificativeService.setKinResId(seqId, status).then(
            result => {
                // console.log(result)
                this.reloadAudit(this.state.campaignInfo.idKinCampaign)
            },
            error => {
                console.error(error);
            }
        )
    }

    reloadAudit(id){
        let sort, direction
        // console.log("reloadAudit",this.state.selectedOrder)
        switch(this.state.selectedOrder){
            case "desc":
                sort = "checkDate"
                direction = "desc"
                break;
            case "asc":
                sort = "checkDate"
                direction = "asc"
                break;
            case "seqId":
                sort = "seqId"
                direction = "asc"
                break;
            default:
                sort = "checkDate"
                direction = "desc"
        }


        this.setState({
            isLoading: true
        },
        ()=>justificativeService.getAuditFindByCampaignOrdered(id, sort, direction).then(
            result => {
                this.setState({
                    campaignPanels: result,
                    panelsAll: result,
                    totalItemsCount: result.length,
                },
                ()=> this.loadDataFiltered(
                    this.state.selectedRegL,
                    this.state.selectedDis,
                    this.state.selectedCitL,
                    this.state.selectedMedOwn,
                    this.state.selectedFrmt,
                    this.state.selectedStatus,
                    this.state.selectedChecked,
                    this.state.selectedValidated
                ))
            }
        ))
    }


    passPropsFilters = (nProps) => {
        this.setState({
            selectedRegL: nProps.selectedRegL.trim().toLowerCase(),
            selectedDis: nProps.selectedDis.trim().toLowerCase(),
            selectedCitL: nProps.selectedCitL.trim().toLowerCase(),
            selectedMedOwn: nProps.selectedMedOwn.trim().toLowerCase(),
            selectedFrmt: nProps.selectedFrmt.trim().toLowerCase(),
            selectedChecked: nProps.selectedChecked.trim().toLowerCase(),
            selectedValidated: nProps.selectedValidated.trim().toLowerCase(),
            selectedStatus: nProps.selectedStatus.trim()
        })

        let idR = nProps.selectedRegL.trim().toLowerCase()
        let prov = nProps.selectedDis.trim().toLowerCase()
        let idC = nProps.selectedCitL.trim().toLowerCase()
        let mo = nProps.selectedMedOwn.trim().toLowerCase()
        let frmt = nProps.selectedFrmt.trim().toLowerCase()
        let status = nProps.selectedStatus.trim()
        let validated = nProps.selectedValidated.trim().toLowerCase()
        let checked = nProps.selectedChecked.trim().toLowerCase()

        this.loadDataFiltered(idR, prov, idC, mo, frmt, status, checked, validated)
    }

    loadDataBySeqId(search){
        let appoggio = this.state.campaignPanels
        let panelsAll = this.state.panelsAll

        let filteredPanels = panelsAll.filter(function(p) {
            let seqId = p.seqId.trim().toLowerCase() || ""
            let condSeqId = seqId.indexOf(search)>=0 || !search
            return condSeqId
        })

        appoggio = filteredPanels
        this.setState({
            campaignPanels: appoggio,
            totalItemsCount: appoggio.length,
            auditImages: [],
            timestamp: Date.now().toString(),
            isLoading: false
        }, ()=> this.loadPhotoAudit())
    }

    loadDataFiltered(idR, prov, idC, mo, idF, status, checked, validated){
        let appoggio = this.state.campaignPanels
        let panelsAll = this.state.panelsAll
        let filteredPanels = panelsAll.filter(function(item) {
            let p = item
            let region = p.region || ""
            let district = p.district || ""
            let municipality = p.municipality || ""
            let mediaOwner = p.mediaOwner || ""
            let panelType = p.panelType || ""
            let resultKinId = p.resultKinId
            let resultBmeId = p.resultBmeId
            let isChecked = (resultKinId != resultBmeId) || item.lastUpdate ? 'y' : 'n'
            let isValidated = p.kinValidationCheck == 1 ? 'y' : 'n'

            let condReg = region.trim().toLowerCase() == idR || !idR
            let condProv = district.trim().toLowerCase() == prov || !prov
            let condCity = municipality.trim().toLowerCase() == idC || !idC
            let condMO = mediaOwner.trim().toLowerCase() == mo || !mo
            let condFormat = panelType.trim().toLowerCase() == idF || !idF

            let condStatus = resultKinId == status || !status
            let condChecked = isChecked == checked || !checked
            let condValidated = isValidated == validated || !validated

            if (condReg && condProv && condCity && condMO && condFormat && condStatus && condChecked && condValidated) {
                return item;
            }
        })
        appoggio = filteredPanels
        this.setState({
            campaignPanels: appoggio,
            totalItemsCount: appoggio.length,
            auditImages: [],
            timestamp: Date.now().toString(),
            isLoading: false
        }, ()=> this.loadPhotoAudit())
    }

    getCampaignReport(){
        let id = this.state.campaignInfo.idKinCampaign
        if(id){
            campaignService.getXlsCampaign(id);
        }
    }

    getCampaignZipReport(){
        let id = this.state.campaignInfo.idKinCampaign
        if(id){
            campaignService.getZipCampaign(id);
        }
    }

    getManagePdf(){
        let type = this.state.exportType
        let from = type==="range" ? this.state.exportDateFrom : false
        let to = type==="range" ? this.state.exportDateTo : false

        let filtered = this.state.exportFiltered ? this.state.exportFiltered : "all"

        this.setState({
            pdfLoading: true,
            pdfError: null
        })

        let id = this.state.campaignInfo.idKinCampaign

        if(id){
            pdfService.getPdfManageUrl(id, type, from, to, filtered).then(
                result => {
                    pdfService.getPdfFromUrl(result.url).then(
                        finished => {
                            this.setState({
                                pdfLoading: !finished
                            })
                        },
                        error => {
                            this.setState({
                                pdfLoading: false
                            })
                            console.error(error);
                        });
                },
                error => {
                    this.setState({
                        pdfLoading: false,
                        pdfError: "No results: try another range!"
                    })
                    console.error(error);
                }
            )
        }
    }

    handlePageChange = (pageNumber) => {
        let offset = pageNumber-1
        this.setState({
            activePage: pageNumber,
            offsetQuery: offset,
            isLoading: true,
            auditImages: []
        }, ()=> this.loadPhotoAudit());

        this.setState({
            isLoading: false
        })
    }

    handleChangeQuery(ev) {
        this.setState({
          querySearch: ev.target.value.trim()
        })

        if(this.state.sendingQuery) {
          clearTimeout(this.state.sendingQuery);
        }

        this.setState({
          sendingQuery: setTimeout(this.handleQuery, 1000),
          isLoading: true
        })
    }

    handleChangeOrder(ev) {
        this.setState({
          selectedOrder: ev.target.value.trim()
        })

        if(this.state.sendingQuery) {
          clearTimeout(this.state.sendingQuery);
        }

        var id = this.state.campaignInfo.idKinCampaign
        this.setState({
          sendingQuery: setTimeout(()=>this.reloadAudit(id), 1000),
          isLoading: true
        })
    }

    handleQuery = e => {
        // console.log('nuova query: '+ this.state.querySearch)
        if(this.state.querySearch){
            this.loadDataBySeqId(this.state.querySearch)
        }
        else{
            this.loadDataFiltered(
                this.state.selectedRegL,
                this.state.selectedDis,
                this.state.selectedCitL,
                this.state.selectedMedOwn,
                this.state.selectedFrmt,
                this.state.selectedStatus,
                this.state.selectedChecked,
                this.state.selectedValidated
            )
        }

        this.setState({sendingQuery: false})
    }

    itemNotFound(){
        if(this.state.totalItemsCount<=0 && this.state.error === null){
            return(
                <Card.Alert color="secondary">
                No panel found
                </Card.Alert>
            )
        }
    }

    errorLoad(){
        if(this.state.error !== null){
            return(
                <Card.Alert color="danger">
                Error: "{this.state.error.toString()}"
                </Card.Alert>
            )
        }
    }

    onDrop = (acceptedFiles) => {
        // console.log(acceptedFiles);
    }

    handlerChangeItem(ev){
        let sel = ev.target.value
        let checked = ev.target.checked
        if(checked){
            this.setState((state)=>({
                seqIds: [...state.seqIds, sel]
            }))
        }
        else{
            this.setState({
                seqIds: this.state.seqIds.filter(function(id) {
                    return id !== sel
                })
            });
        }
    }

    handleSelectAllFiltered(){
        let items = this.state.campaignPanels
        let seqIds = _.groupBy(items, "seqId")
        seqIds = _.keys(seqIds);

        this.setState({
            seqIds: seqIds
        });
    }

    emptySeqIds(){
        this.setState({
            seqIds: []
        })
    }

    hidePhotoBme(photoBmeState, auditImage){
        if(auditImage && photoBmeState){
            auditImage = auditImage.filter(function(ph, index) {
                if(photoBmeState[index]==1){
                    return ph
                }
                else{
                    return false
                }
            })
            // console.log("auditImage")
            // console.log(auditImage)
        }

        return auditImage
    }


    onChangeStatusValidation(ev, status){
        this.setState({
            isLoading: true
        })
        const seqIds = this.state.seqIds
        this.emptySeqIds()
        justificativeService.setKinValidationStatus(status, seqIds).then(
            result => {
                // console.log(result)
                this.setState({
                    seqIds: [],
                    isLoading: false
                }, ()=>this.reloadAudit(this.state.campaignInfo.idKinCampaign))
            },
            error => {
                console.error(error);
            }
        );
    }

    render() {
        if(this.state.forbidden){
          return <Redirect
              to={{ pathname: "/404", state: { from: this.props.location } }}
          />
        }
        const { photoIndex, photoIsOpen } = this.state;
        const { auditImages } = this.state;

        let createDate = this.state.campaignInfo.createDate
        let lastUpdate = this.state.campaignInfo.lastUpdate ? this.state.campaignInfo.lastUpdate : '-'

        const title = (
            <React.Fragment>
                <h1 className="page-title mb-0 mt-0 p-0">{this.state.campaignInfo.advertiser} - {this.state.campaignInfo.campaign}</h1>
                <h4><small className="mb-0 mt-0 p-0">Panels: {this.state.panelsAll.length} - On Air: {this.state.campaignInfo.startDate} - {this.state.campaignInfo.endDate} - Last Update: {lastUpdate}</small></h4>
            </React.Fragment>
        )

        const dropdownSelected = (
            <React.Fragment>
                <Button.Dropdown type="button" color="secondary" arrow icon="check-square" value={"Selected " + this.state.seqIds.length + " panels"}>
                    <Dropdown.Item icon="trash" onClick={this.emptySeqIds}>Deselect all</Dropdown.Item>
                </Button.Dropdown>
            </React.Fragment>
        )

        const dropdownValidate = (
            <React.Fragment>
                <Button.Dropdown type="button" color="info" className="ml-1" arrow icon="thumbs-up" value="Change validate">
                    <Dropdown.Item onClick={(ev)=> this.onChangeStatusValidation(ev, 1)}><Icon name="thumbs-up" prefix="fa" color="success" /> Confirm validate</Dropdown.Item>
                    <Dropdown.Item onClick={(ev)=> this.onChangeStatusValidation(ev, 0)}><Icon name="thumbs-down" prefix="fa" color="danger"/> Remove validate</Dropdown.Item>
                </Button.Dropdown>
            </React.Fragment>
        )

        const options = (
            <React.Fragment>
                <Button color="link" RootComponent="a" href="/campaigns/">
                    Back to Campaigns
                </Button>
                {this.state.seqIds.length>0 ? dropdownSelected : ''}
                {this.state.seqIds.length>0 ? dropdownValidate : ''}

                {this.state.pdfLoading
                    ?
                    <Button loading color="primary">
                        Loading...
                    </Button>
                    :
                    <Button.Dropdown className="ml-1" value={<span><Icon prefix="fa" name="download" /> Export Manage</span>} color="primary" disabled={this.state.campaignInfo.idKinCampaign==-1}>
                        <Dropdown.Item to={campaignService.getApiPathCampaign()+"/campaigns/manageReport/idkc/"+this.state.campaignInfo.idKinCampaign}>Xls format</Dropdown.Item>
                        <Dropdown.Item to={"#"} onClick={this.toggleModalExport}>Pdf format...</Dropdown.Item>
                    </Button.Dropdown>
                }

                <Modal isOpen={this.state.isOpenModalExport} toggle={this.toggleModalExport}>
                    <ModalHeader toggle={this.toggleModalExport}>Export Pdf format</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col">
                                <Form.Group label="Filter by:">
                                    <Form.SwitchStack>
                                        <Form.Switch
                                            type="radio"
                                            name="radios-export-pdf-filter"
                                            value="new"
                                            label="To be managed"
                                            onChange={this.handleChangeExportFilter}
                                            checked={this.state.exportFiltered==="new"}
                                        />
                                        <Form.Switch
                                            type="radio"
                                            name="radios-export-pdf-filter"
                                            value="all"
                                            label="All"
                                            onChange={this.handleChangeExportFilter}
                                            checked={this.state.exportFiltered==="all"}
                                        />
                                    </Form.SwitchStack>
                                </Form.Group>
                                <Form.Group label="Select period:">
                                    <Form.Radio
                                        label="From day one"
                                        name="radios-export-pdf-period"
                                        value="all"
                                        onChange={this.handleChangeExport}
                                        checked={this.state.exportType==="all"}
                                    />
                                    <Form.Radio
                                        label="Custom range"
                                        name="radios-export-pdf-period"
                                        value="range"
                                        onChange={this.handleChangeExport}
                                        checked={this.state.exportType==="range"}
                                    />

                                </Form.Group>
                                {this.state.exportType === "range" &&
                                    <Grid.Row>
                                        <Grid.Col>
                                            <Form.Group label="From">
                                                <Form.DatePicker
                                                    defaultDate={this.state.defaultDate}
                                                    onChange={this.handleChangeExportDateFrom}
                                                    format="dd/mm/yyyy"
                                                    maxYear={2030}
                                                    minYear={2018}
                                                    monthLabels={[
                                                        'January',
                                                        'February',
                                                        'March',
                                                        'April',
                                                        'May',
                                                        'June',
                                                        'July',
                                                        'August',
                                                        'September',
                                                        'October',
                                                        'November',
                                                        'December'
                                                    ]}
                                                />
                                            </Form.Group>
                                        </Grid.Col>
                                        <Grid.Col>
                                            <Form.Group label="To">
                                                <Form.DatePicker
                                                    // defaultDate={this.state.defaultDate}
                                                    onChange={this.handleChangeExportDateTo}
                                                    format="dd/mm/yyyy"
                                                    maxYear={2030}
                                                    minYear={2018}
                                                    monthLabels={[
                                                        'January',
                                                        'February',
                                                        'March',
                                                        'April',
                                                        'May',
                                                        'June',
                                                        'July',
                                                        'August',
                                                        'September',
                                                        'October',
                                                        'November',
                                                        'December'
                                                    ]}
                                                />
                                            </Form.Group>
                                        </Grid.Col>
                                    </Grid.Row>
                                }
                            </div>
                        </div>
                        { this.state.pdfError && <div className="p-4 bg-danger text-white">{this.state.pdfError}</div>}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.getManagePdf()} loading={this.state.pdfLoading} disabled={this.state.exportDateTo < this.state.exportDateFrom}>Export</Button>{' '}
                        <Button color="secondary" onClick={this.toggleModalExport}>Close</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.isOpenModal} toggle={this.toggleModalUpload}>
                    <ModalHeader toggle={this.toggleModalUpload}>Assign New Photo Check</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col">
                                Select file <code>png</code>, <code>gif</code> or <code>jpg</code> to assign to <code>{this.state.selectedSeqId}</code>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Form.Group label="File images" onSubmit={this.onFormSubmit}>
                                    <Form.FileInput accept="image/*" label={"Choose one photo"}
                                        onChange={this.handleChangeFile}
                                        // onChange={this.onFormSubmit}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.onFormSubmit} disabled={!this.state.issetFile || !this.state.selectedSeqId} loading={!this.state.buttonAssignActive}>Assign Photo Check</Button>{' '}
                        <Button color="secondary" onClick={this.toggleModalUpload} disabled={!this.state.buttonAssignActive}>Close</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.isOpenModalMulti} toggle={this.toggleModalMultiUpload}>
                    <ModalHeader toggle={this.toggleModalMultiUpload}>Assign Multi Photo Check</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col">
                                Select files <code>png</code>, <code>gif</code> or <code>jpg</code> to assign to <code>{this.state.selectedSeqId}</code>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div class="input-group mb-4 mt-5">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Multi</span>
                                    </div>
                                    <div class="custom-file">
                                        <input type="file" multiple class="custom-file-input" onChange={this.handleChangeMultiFile}/>
                                        <label class="custom-file-label">{this.state.issetMultiFile ? (this.state.issetMultiFile.length + " selected") : "Choose one or more photos"}</label>
                                    </div>
                                </div>
                                {this.state.countFiles>=0 &&
                                    <div>File to upload: {this.state.countFiles}</div>
                                }
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.onFormSubmitMulti} disabled={!this.state.issetMultiFile || !this.state.selectedSeqId} loading={!this.state.buttonAssignActiveMulti}>Add Photos Check</Button>{' '}
                        <Button color="secondary" onClick={this.toggleModalMultiUpload} disabled={!this.state.buttonAssignActive}>Close</Button>
                    </ModalFooter>
                </Modal>

                <Modal size="lg" isOpen={this.state.isOpenModalPhS} toggle={this.toggleModalPhotoSetting}>
                    <ModalHeader toggle={this.toggleModalPhotoSetting}>Hide/Show BME Photo</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col mb-4">
                                Select/Deselect photo to show/hide for <code>{this.state.selectedSeqId}</code>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Form.Group>
                                    <Form.ImageCheck className="form-imagecheck-lg">
                                        {this.state.photoSettingUrl &&
                                            this.state.photoSettingUrl.map((item, index) => {
                                                return <Form.ImageCheckItem
                                                    imageURL={this.getValidateUrl(item)}
                                                    value="1"
                                                    col={{ sm: 4 }}
                                                    onClick={()=>this.togglePhotoState(index)}
                                                    checked={this.state.photoSettingState ? this.state.photoSettingState[index] : true}
                                                />
                                            })
                                        }
                                    </Form.ImageCheck>
                                    {
                                        !this.checkPhotoState(this.state.photoSettingState) &&
                                        <div className="mt-2 text-danger">Select at least one!</div>
                                    }
                                </Form.Group>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.onFormSubmitPhS} disabled={!this.state.selectedSeqId || !this.checkPhotoState(this.state.photoSettingState)} loading={!this.state.buttonAssignActivePhS}>Save</Button>{' '}
                        <Button color="secondary" onClick={this.toggleModalPhotoSetting} disabled={!this.state.buttonAssignActivePhS}>Close</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );

        const isImagesLoading = this.state.auditImages.length != this.state.campaignPanels.slice(
            this.state.itemsCountPerPage*this.state.offsetQuery,
            this.state.itemsCountPerPage*(this.state.offsetQuery+1)).length

        return (
        <SiteWrapper {...this.state}>
            <Page.Content>
                <Page.Header
                    title={title}
                    // subTitle={this.state.totalItemsCount + " panels found"}
                    options={options}
                />
                <Grid.Row cards deck>
                    <Grid.Col sm={12}>
                        <Card className="bg-light"  >
                            <Container>
                                <Nav className="bg-white">
                                    <Nav.Item to={"/campaigns/"+this.state.id+"/map"} value="Map" icon="map" />
                                    <Nav.Item to={"/campaigns/"+this.state.id+"/photo"} value="Photo" icon="camera" />
                                    <Nav.Item value="Audience" icon="users" disabled />
                                    <Nav.Item to={"/campaigns/"+this.state.id+"/justificative"} value="Manage" icon="edit" active />
                                    <Nav.Item to={"#"} value="Download Xls" icon="download" onClick={() => this.getCampaignReport()}/>
                                    <DownloaderPolling classButton="nav-link" labelButton=" Download Zip" classIcon={"fe fe-download fa-fw pr-1"} idkc={this.state.campaignInfo.idKinCampaign}/>
                                </Nav>

                                <AuditFilters
                                    idC={this.state.id}
                                    handleState={this.passPropsFilters}
                                    idkc={this.state.campaignInfo.idKinCampaign}
                                    panels={this.state.campaignPanels}
                                    panelsAll={this.state.panelsAll}
                                    disabled={this.state.querySearch.trim()}
                                />

                            </Container>
                        </Card>

                    </Grid.Col>
                </Grid.Row>

                <Grid.Row className={this.state.querySearch.trim() ? "d-none" : ""}>
                    <Grid.Col>
                        <AuditCounters data={this.state.panelsAll}/>
                    </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Col>
                        <Form.Group label="Order By">
                            <Form.Select onChange={ev => this.handleChangeOrder(ev)} disabled={this.state.querySearch}>
                                <option value="desc" selected={this.state.selectedOrder == "desc"}>Recent</option>
                                <option value="asc" selected={this.state.selectedOrder == "asc"}>Older</option>
                                <option value="seqId" selected={this.state.selectedOrder == "seqId"}>SeqId</option>
                            </Form.Select>
                        </Form.Group>
                    </Grid.Col>
                    <Grid.Col offset={6}>
                        <Form.Group label="&nbsp;">
                            <Form.Input icon="search" placeholder={"Search in "+this.state.campaignInfo.campaign} onChange={ev => this.handleChangeQuery(ev)}  />
                        </Form.Group>
                    </Grid.Col>
                </Grid.Row>

                <Grid.Row cards deck>
                    <Grid.Col sm={12}>
                        <Card>
                            <Dimmer active={this.state.isLoading} loader={this.state.isLoading} className={this.state.isLoading ? "p-5" : ""}>
                                <Table striped={true} className="mb-0 table-bordered table-responsive-md" >
                                    <Table.Header>
                                        <Table.ColHeader>
                                            <Button color="secondary" size="sm" onClick={this.handleSelectAllFiltered} disabled={this.state.campaignPanels.length == this.state.seqIds.length}>
                                                Select all ({this.state.campaignPanels.length})
                                            </Button>
                                        </Table.ColHeader>
                                        <Table.ColHeader>Panel</Table.ColHeader>
                                        <Table.ColHeader>Actual Status</Table.ColHeader>
                                        <Table.ColHeader>BME Note</Table.ColHeader>
                                        <Table.ColHeader>Photo</Table.ColHeader>
                                    </Table.Header>
                                    <Table.Body key={this.state.timestamp}>

                                    {this.state.campaignPanels.length>0 && !this.state.isLoading && this.state.campaignPanels.slice(
                                        this.state.itemsCountPerPage*this.state.offsetQuery,
                                        this.state.itemsCountPerPage*(this.state.offsetQuery+1))
                                        .map((item, index) => {
                                            const isTransitPanel = item.groupTypeId === "-1"
                                            const prefixPanel = isTransitPanel ? 'D-': 'A-'
                                            const thisIndexAudit = index

                                            let lastUpdateSeq = '-'
                                            if(item.lastUpdate){
                                                let lastUpdateTS = item.lastUpdate ? new Date(item.lastUpdate) : null
                                                let monthLUSeq = (lastUpdateTS.getMonth()+1)>=10 ? (lastUpdateTS.getMonth()+1) : ("0"+(lastUpdateTS.getMonth()+1))
                                                lastUpdateSeq = lastUpdateTS == "-" ? "-" : lastUpdateTS.getDate() + '/' + monthLUSeq + '/' + lastUpdateTS.getFullYear()
                                            }

                                            var photoBmeVisible = item.bmePhotoCheck
                                            let photoBmeState = item.bmePhotoCheckVisibility || null

                                            if(photoBmeVisible && photoBmeState && !isTransitPanel){
                                                photoBmeVisible = photoBmeVisible.filter(function(ph, index) {
                                                    if(photoBmeState[index]==1){
                                                        return ph
                                                    }
                                                    else{
                                                        return false
                                                    }
                                                })
                                            }

                                            let colImage = <div>No photos</div>
                                            if(!isImagesLoading){
                                                if(isTransitPanel && photoBmeVisible){
                                                    if(photoBmeVisible.length>0){
                                                        colImage = (
                                                            <div className="row">
                                                                {[photoBmeVisible[0]].map((ph, index) => {
                                                                    if(ph){
                                                                        let src = this.getValidateUrl(ph)
                                                                        let count = photoBmeVisible.length
                                                                        if(index==0){
                                                                            return <div className="col-12 pl-2 pr-2 pb-1" onClick={() => this.setState({
                                                                                        photoIndex: index,
                                                                                        images: this.getValidateListUrl(photoBmeVisible)
                                                                                    }, () => this.setState({photoIsOpen: true}))}>
                                                                                {(count > 1) ? <span className="text-over h2 text-white shadow-lg">+{count - 1}</span> : ''}
                                                                                <img className="img-fluid img-manage-maxheight border" src={src}/>
                                                                            </div>
                                                                        }
                                                                    }
                                                                    else{
                                                                        return <p className="p-2 text-center"><em>No images</em></p>
                                                                    }
                                                                })}
                                                            </div>
                                                        )
                                                    }
                                                }
                                                else if(item.kinPhotoCheck){
                                                    colImage = (
                                                        <Dimmer active={this.state.auditImages[thisIndexAudit].length<=0} loader={this.state.auditImages[thisIndexAudit].length<=0}>
                                                        <div className="row">
                                                            {[this.getValidateUrl(item.kinPhotoCheck)].map((ph, index) => {
                                                                if(ph){
                                                                    let src = ph
                                                                    let count = 1
                                                                    if(index<8){
                                                                        return <div className="col-12 pl-2 pr-2 pb-1" onClick={() => this.setState({
                                                                                    photoIndex: index,
                                                                                    images: [ph]
                                                                                }, () => this.setState({photoIsOpen: true}))}>
                                                                            {(index == 7 && count > 8) ? <span className="text-over h1 text-white shadow-lg">+{count - index - 1}</span> : ''}
                                                                            <img className="img-fluid img-manage-maxheight border" src={src}/>
                                                                        </div>
                                                                    }
                                                                }
                                                                else{
                                                                    return <p className="p-2 text-center"><em>No images</em></p>
                                                                }
                                                            })}
                                                        </div>
                                                        </Dimmer>
                                                    )
                                                }
                                                else{
                                                    colImage = (
                                                        <Dimmer active={this.state.auditImages[thisIndexAudit].length<=0} loader={this.state.auditImages[thisIndexAudit].length<=0}>
                                                        <div className="row">
                                                            {this.state.auditImages[thisIndexAudit].map((item, index) => {
                                                                if(item){
                                                                    let src = item + imageService.getQueryStringAPI()
                                                                    let count = this.state.auditImages[thisIndexAudit].length
                                                                    if(index==0){
                                                                        return <div className="col-12 pl-2 pr-2 pb-1" onClick={() => this.setState({
                                                                                    photoIndex: index,
                                                                                    images: this.state.auditImages[thisIndexAudit]
                                                                                }, () => this.setState({photoIsOpen: true}))}>
                                                                            {(count > 1) ? <span className="text-over h2 text-white shadow-lg">+ {count - 1}</span> : ''}
                                                                            <img className="img-fluid img-manage-maxheight border" src={src} alt="Foto"/>
                                                                        </div>
                                                                    }
                                                                }
                                                                else{
                                                                    return <p className="p-2 text-center"><em>No images</em></p>
                                                                }
                                                            })}
                                                        </div>
                                                        </Dimmer>
                                                    )
                                                }
                                            }

                                            return <Table.Row key={index}>
                                                <Table.Col alignContent="center" className="td-128 td-selectable-center">
                                                    <Form.Group>
                                                        <Form.Checkbox
                                                            label={(item.incrementalID && this.state.selectedOrder==="seqId" ? (prefixPanel + (item.incrementalID).toString()): ("#" + (item.incrementalID).toString()))}
                                                            name="items-select"
                                                            value={item.seqId}
                                                            checked={this.state.seqIds.indexOf(item.seqId) != -1}
                                                            onChange={(ev) => this.handlerChangeItem(ev)}
                                                        />
                                                    </Form.Group>

                                                    <p className="status-label h4 text-center" title="Validate status">
                                                        {
                                                            (item.kinValidationCheck)
                                                                ? item.kinValidationCheck==1
                                                                    ? <Icon prefix="fa" name="certificate text-secondary" className="fa-lg"/> : <h2>-</h2>
                                                                : <h2>-</h2>
                                                        }
                                                    </p>

                                                    <p className="status-label h4 text-center" title="Checked status">
                                                        {
                                                            (item.resultBmeId != item.resultKinId) || item.lastUpdate
                                                            ? <Icon prefix="fa" name="check-circle text-secondary" className="fa-lg"/>
                                                            : <h2>-</h2>
                                                        }
                                                    </p>

                                                </Table.Col>
                                                <Table.Col>
                                                    <Grid.Row>
                                                            <Grid.Col md={4}>Media Owner:</Grid.Col>
                                                            <Grid.Col md={8}><strong>{item.mediaOwner}</strong></Grid.Col>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                            <Grid.Col md={4}>Address:</Grid.Col>
                                                            <Grid.Col md={8}>
                                                                <p title={item.municipality + " - " + item.address} className="text-truncate td-250 mb-0">
                                                                    <strong>{item.municipality + " - " + (item.address ? item.address : '') }</strong>
                                                                </p>
                                                            </Grid.Col>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                            <Grid.Col md={4}>Note:</Grid.Col>
                                                            <Grid.Col md={8}>
                                                                <p title={item.note} className="text-truncate td-250 mb-0">
                                                                    <strong>{item.note}</strong>
                                                                </p>
                                                            </Grid.Col>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                            <Grid.Col md={4}>Format:</Grid.Col>
                                                            <Grid.Col md={8}><strong>{item.panelType}</strong></Grid.Col>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                            <Grid.Col md={4}>Check Date:</Grid.Col>
                                                            <Grid.Col md={8}><strong>{item.checkDate ? item.checkDate : '-'}</strong></Grid.Col>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                            <Grid.Col md={4}>SeqId:</Grid.Col>
                                                            <Grid.Col md={8}><strong>{item.seqId}</strong></Grid.Col>
                                                    </Grid.Row>

                                                </Table.Col>


                                                <Table.Col alignContent="center" className="align-middle">
                                                    <p className="status-label h4">
                                                        {
                                                            item.resultKinId==0 ? <span><span className={"status-icon bg-success"}/> Success</span>
                                                            : item.resultKinId==1 ? <span><span className={"status-icon bg-danger"}/> Wrong</span>
                                                            : item.resultKinId==2 ? <span><span className={"status-icon bg-warning"} title={item.secondAnswer}/> Anomaly</span>
                                                            : item.resultKinId==3 ? <span><span className={"status-icon bg-gray"} title={item.secondAnswer}/> Not tracked</span>
                                                            : 'Error'
                                                        }
                                                    </p>

                                                    <p className="status-edits">
                                                        <Button.Dropdown size="sm" color="secondary" value={<span><Icon prefix="fa" name="pencil" /> Edit</span>}>
                                                            {item.resultKinId != 0 ? <Dropdown.Item onClick={()=>this.setAuditPanelStatus(item.seqId, 0)}>Correct</Dropdown.Item>: ''}
                                                            {item.resultKinId != 1 ? <Dropdown.Item onClick={()=>this.setAuditPanelStatus(item.seqId, 1)}>Wrong</Dropdown.Item>: ''}
                                                            {item.resultKinId != 2 ? <Dropdown.Item onClick={()=>this.setAuditPanelStatus(item.seqId, 2)}>To be verified</Dropdown.Item>: ''}
                                                            {item.resultKinId != 3 ? <Dropdown.Item onClick={()=>this.setAuditPanelStatus(item.seqId, 3)}>Not tracked</Dropdown.Item>: ''}
                                                        </Button.Dropdown>
                                                    </p>
                                                    {isTransitPanel ?
                                                        <p className="status-label h6 text-info"><small>{item.classification}</small></p>
                                                        :
                                                        <p className="status-label h6"><small>BME:{' '}
                                                            {
                                                                item.resultBmeId==0 ? <span>Success</span>
                                                                : item.resultBmeId==1 ? <span>Wrong</span>
                                                                : item.resultBmeId==2 ? <span>Anomaly</span>
                                                                : item.resultBmeId==3 ? <span>Not tracked</span>
                                                                : 'Error'
                                                            }</small>
                                                        </p>
                                                    }
                                                </Table.Col>
                                                {item.firstAnswer ?
                                                    <Table.Col alignContent="center" className="td-128 align-middle" title={item.secondAnswer}>
                                                        <p className="text-primary"><Icon prefix="fa" name="sticky-note" className="fa-lg" /></p>
                                                        <p className="text-truncate td-128"><em>"{item.secondAnswer}"</em></p>
                                                    </Table.Col>
                                                    :
                                                        !isTransitPanel ?
                                                        <Table.Col alignContent="center" className="td-128 align-middle">
                                                            <p className="text-secondary"><Icon prefix="fa" name="sticky-note" className="fa-lg" /></p>
                                                            <p className="text-truncate td-128"><small>Nothing</small></p>
                                                        </Table.Col>
                                                        :
                                                            <Table.Col alignContent="center" className="td-128 align-middle">
                                                                <p className="text-truncate td-128 h2">-</p>
                                                            </Table.Col>
                                                }
                                                <Table.Col alignContent="center" className="td-gallery td-135 align-middle pt-0 pb-0">
                                                    <div className="row">
                                                        <div className="col-12 border-bottom pb-1">
                                                            {item.resultKinId !=3 ?
                                                                <Dimmer active={isImagesLoading} loader={isImagesLoading}>
                                                                    {colImage}
                                                                </Dimmer>
                                                            :   <div>No photos</div>
                                                            }
                                                        </div>
                                                        <div className="col-6">
                                                            <Grid.Row className="pt-2 text-left">
                                                                <div className="col-12"><small>Last Update:</small></div>
                                                                <div className="col-12" style={{"line-height": ".5em"}}>
                                                                    <small><strong>{lastUpdateSeq}</strong></small>
                                                                </div>
                                                            </Grid.Row>
                                                        </div>
                                                        <div className="col-6 pt-2 pl-0 pr-0">
                                                            {isTransitPanel ?
                                                                <Button color="secondary" onClick={(ev)=> this.toggleModalMultiUpload(ev, item.seqId)}><Icon prefix="fa" name="upload" className="fa-2x text-info" /></Button>
                                                                :
                                                                <Button.List>
                                                                    <Button color="secondary" onClick={(ev)=> this.toggleModalUpload(ev, item.seqId)}><Icon prefix="fa" name="upload" className="fa-2x text-primary" /></Button>
                                                                    { this.state.campaignInfo.idKinCampaign >= 1000 && // funzione non può essere retroattiva (solo nuove campagne)
                                                                        <Button color="secondary" disabled={!item.bmePhotoCheck || item.kinPhotoCheck} onClick={(ev)=> this.toggleModalPhotoSetting(ev, item.seqId, index)}><Icon prefix="fa" name="sliders" className="fa-2x text-primary" /></Button>
                                                                    }
                                                                </Button.List>
                                                            }
                                                        </div>
                                                    </div>
                                                </Table.Col>
                                            </Table.Row>
                                        })}
                                    </Table.Body>
                                </Table>
                                {photoIsOpen && (
                                    <Lightbox
                                        mainSrc={this.state.images[photoIndex]}
                                        nextSrc={this.state.images[(photoIndex + 1) % this.state.images.length]}
                                        prevSrc={this.state.images[(photoIndex + this.state.images.length - 1) % this.state.images.length]}
                                        onCloseRequest={() => this.setState({ photoIsOpen: false })}
                                        onMovePrevRequest={() =>
                                            this.setState({
                                                photoIndex: (photoIndex + this.state.images.length - 1) % this.state.images.length,
                                            })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                photoIndex: (photoIndex + 1) % this.state.images.length,
                                            })
                                        }
                                    />
                                )}
                                {this.itemNotFound()}
                                {this.errorLoad()}
                            </Dimmer>

                        </Card>
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Col>
                    {
                        !this.state.isLoading ?
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.itemsCountPerPage}
                            totalItemsCount={this.state.totalItemsCount}
                            maxPageRangeDisplayed={this.state.maxPageRangeDisplayed}
                            handlePageChange={this.handlePageChange}
                        /> : ''
                    }
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>
        );
    }
}

export default Audit;
