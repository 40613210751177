// @flow

import * as React from "react";

import {
  Page,
  Grid,
  Card,
  colors,
  Icon,
  Table,
  Button,
  Tab,
  TabbedCard,
  Form,
  Dimmer
} from "tabler-react";
import './static/HomePage.scss'

// import C3Chart from "react-c3js";

import _ from "lodash"

import SiteWrapper from "./SiteWrapper.react";
import { campaignService, statsService, userService } from "./_services";
import CircularProgressbar from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import './HomePage.scss'

// import AnimatedNumber from 'react-animated-number';

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.notificationSystem = React.createRef();

    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();

    this.state = {
      user: {},
      userInfo: [],
      lastCampaigns: [],
      lastCampaignsPercCheck: [],
      selectedPeriod: currentYear,

      numberCampaign: 0,
      numberCities: 0,
      numberDistrict: 0,
      numberPanel: 0,
      numberRegion: 0,
      numberTracking: 0,
      numberToBeVerified: 0,
      numberAllCorrect: 0,
      numberAllWrong:0,
      numberNotTracked: 0,

      isLoadingCampaign: true,
      isLoadingMO: true,
      isLoadingRegion: true,
      isLoadingDistrict: true,
      isLoadingCity: true,

      mediaOwnerTop: [],
      mediaOwnerWorst: [],

      mediaOwnerGroup: ""
    };

    this.handleChangeDate = this.handleChangeDate.bind(this)
  }

  addNotification = (event, message, level) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      message: message,
      level: level
    });
  };

  componentWillMount(){
    userService.checkProfile()
  }

  componentDidMount() {
    this.setState({
      user: JSON.parse(localStorage.getItem("user")),
      users: { loading: true },
      lastCampaigns: []
    });

    this.getLocalUserInfo();
  }

  getLocalUserInfo(){
    let mediaOwnerGroup = ""
    if(localStorage.getItem("type") == "admin" || localStorage.getItem("type") == "user" || localStorage.getItem("type") == "mediaOwner"){
      if(localStorage.getItem("type") == "mediaOwner"){
        mediaOwnerGroup = localStorage.getItem("group") ? localStorage.getItem("group").toUpperCase() : "";

        this.setState({
          mediaOwnerGroup
        })
      }
      this.setState({
        isAdvertiser: false
      },()=> this.loadReportMain(mediaOwnerGroup))
    }
    else{
      this.setState({
        isAdvertiser: true
      },()=> this.loadReportMain(mediaOwnerGroup))
    }
  }

  loadReportMain(mo){
    this.loadCampaignsNumbers(mo)
    this.loadGeoNumbers(mo)
    this.loadCampaignsFiltered(0, 5, true)
    this.loadMediaOwnerTop(this.state.selectedPeriod, mo)
  }

  loadCampaignsNumbers(mo){
    this.loadCampaignsReport(this.state.selectedPeriod, mo)
  }

  loadGeoNumbers(mo){
    this.loadRegionsReport(this.state.selectedPeriod, mo)
    this.loadDistrictsReport(this.state.selectedPeriod, mo)
    this.loadCitiesReport(this.state.selectedPeriod, mo)
  }

  loadCampaignsReport(period, mo){
    statsService.getMediaReportByCampaign(period, mo).then(
      result => {
        let counters = this.initCounters(result, true)
        this.setState({
          numberCampaign: counters.length,
          isLoadingCampaign: false,
        })
      },
      error => {
        console.error(error);
      }
    );
  }

  loadRegionsReport(period, mo){
    statsService.getMediaReportByRegion(period, mo).then(
        result => {
          let counters = this.initCounters(result, false)
          this.setState({
            numberRegion: counters.length,
            isLoadingRegion: false,
          })
        },
        error => {
          console.error(error);
        }
    );
  }

  loadDistrictsReport(period, mo){
    statsService.getMediaReportByDistrict(period, mo).then(
        result => {
          let counters = this.initCounters(result, false)
          this.setState({
            numberDistrict: counters.length,
            isLoadingDistrict: false,
          })
        },
        error => {
          console.error(error);
        }
    );
  }

  loadCitiesReport(period, mo){
    statsService.getMediaReportByCity(period, mo).then(
        result => {
          let counters = this.initCounters(result, false)
          this.setState({
            numberCities: counters.length,
            isLoadingCity: false,
          })
        },
        error => {
          console.error(error);
        }
    );
  }

  initCounters(source, useKeyId = false){
    let repGBy = null;

    if(useKeyId){
      repGBy = _.groupBy(source, "elementId")
    }
    else{
      repGBy = _.groupBy(source, "element")
    }

    let repGByValues = _.values(repGBy)
    let repGByLabels = _.keys(repGBy)
    var report = []

    //inizializzo tutto a zero
    for(let i=0; i<repGByValues.length; i++){
      report[i] = {name: repGByLabels[i], desc: "", numberCampaigns: 0, state0: 0, state1: 0, state2: 0, state3: 0, tracked:0, total:0}
    }

    //parso e setto i count
    for(let i=0; i<repGByValues.length; i++){
      for(let s=0; s<repGByValues[i].length; s++){
        if(useKeyId){
          report[i].desc = repGByValues[i][s].element
        }
        report[i].numberCampaigns = repGByValues[i][s].numberCampaigns
        report[i].total += repGByValues[i][s].count

        let isValidate = (repGByValues[i][s].kinValidationCheck == 1 || !this.state.isAdvertiser)
        if(isValidate){
          switch(repGByValues[i][s].resultKinId){
            case 0:
              report[i].state0 += repGByValues[i][s].count
              report[i].tracked += repGByValues[i][s].count
              break;
            case 1:
              report[i].state1 += repGByValues[i][s].count
              report[i].tracked += repGByValues[i][s].count
              break;
            case 2:
              report[i].state2 += repGByValues[i][s].count
              report[i].tracked += repGByValues[i][s].count
              break;
            case 3:
              report[i].state3 += repGByValues[i][s].count
              break;
          }
        }
        else{
          report[i].state3 += repGByValues[i][s].count
        }
      }
    }
    report = _.reverse(_.sortBy(report, "total"))
    return report
  }

  loadCampaignsFiltered(offset, limit, desc){
    // Last campaigns
    campaignService.getCampaignsFiltered(offset, limit, desc).then(
      campaigns => {
        this.setState({
          lastCampaigns: campaigns
        }, () => this.loadCampaignsCertification())
      },
      error => {
        console.error(error);
        // this.addNotification(error, JSON.stringify(error), 'error')
        //this.setState({ error, loading: false })
      }
    );
  }


  loadMediaOwnerTop(period, mo){
    statsService.getMediaReportByMediaOwner(period).then(
      result => {
        if(mo!=""){
          result = result.filter(item => {
            return item.element == mo;
          })
        }
        let counters = this.initCountersTotals(result)

        this.setState({
          mediaOwnerTop: this.initCounters(result, false),
          isLoadingMO: false,
          numberAllCorrect: counters.state0,
          numberAllWrong: counters.state1,
          numberToBeVerified: counters.state2,
          numberNotTracked: counters.state3,
          numberTracking: counters.state0 + counters.state1 + counters.state2,
          numberPanel: counters.state0 + counters.state1 + counters.state2 + counters.state3
        })
      },
      error => {
        console.error(error);
      }
    );
  }

  initCountersTotals(source){
    let repGBy = _.groupBy(source, "element")
    let repGByValues = _.values(repGBy)
    // let repGByLabels = _.keys(repGBy)
    var report = {numberCampaigns: 0, state0: 0, state1: 0, state2: 0, state3: 0, tracked:0, total:0}
    //parso e setto i count
    for(let i=0; i<repGByValues.length; i++){
      for(let s=0; s<repGByValues[i].length; s++){
        report.numberCampaigns = repGByValues[i][s].numberCampaigns
        report.total += repGByValues[i][s].count

        let isValidate = (repGByValues[i][s].kinValidationCheck === 1 || !this.state.isAdvertiser)
        if(isValidate){
          switch(repGByValues[i][s].resultKinId){
            case 0:
              report.state0 += repGByValues[i][s].count
              report.tracked += repGByValues[i][s].count
              break;
            case 1:
              report.state1 += repGByValues[i][s].count
              report.tracked += repGByValues[i][s].count
              break;
            case 2:
              report.state2 += repGByValues[i][s].count
              report.tracked += repGByValues[i][s].count
              break;
            case 3:
              report.state3 += repGByValues[i][s].count
              break;
            default:
              return;
          }
        }
        else{
          report.state3 += repGByValues[i][s].count
        }


      }
    }
    return report
  }

  loadMediaOwnerWorst(){
    statsService.getStatsMOSorted('worst').then(
      result => {
        this.setState({
          mediaOwnerWorst: result
        })
      },
      error => {
        console.error(error);
      }
    );
  }

  loadCampaignsCertification(){
    let arrayChecks = []
    let arrayCamp = this.state.lastCampaigns

    for(let i=0; i<arrayCamp.length; i++){
      arrayChecks[i] = {trackingPanels: -1, totalPanels: -1}
      statsService.getStatsCertificationByCampaign(arrayCamp[i].campaignId).then(
        result => {
          // arrayChecks.push(result)
          arrayChecks[i] = result
          this.setState({
            lastCampaignsPercCheck: arrayChecks
          })
        },
        error => {
          console.error(error);
        }
      );
    }

    this.setState({
      lastCampaignsPercCheck: arrayChecks
    })
  }

  handleChangeDate(ev) {
    let dateStr = (ev.getFullYear('Y').toString() )
    this.setState({
      selectedPeriod: dateStr,
      isLoadingCampaign: true,
      isLoadingMO: true,
      isLoadingRegion: true,
      isLoadingDistrict: true,
      isLoadingCity: true,
    }, ()=> this.loadReportMain(this.state.mediaOwnerGroup))
  }

  formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }

  render() {
    const campaignNumber = this.formatNumber(this.state.numberCampaign)
    const panelNumber = this.state.numberPanel ? this.formatNumber(this.state.numberPanel) : 0
    const regionNumber = this.state.numberRegion ? this.formatNumber(this.state.numberRegion) : 0
    const districtNumber = this.state.numberDistrict ? this.formatNumber(this.state.numberDistrict) : 0
    const citiesNumber = this.state.numberCities ? this.formatNumber(this.state.numberCities) : 0

    const correctGlobal = this.state.numberAllCorrect ? this.state.numberAllCorrect : 0
    const wrongGlobal = this.state.numberAllWrong ? this.state.numberAllWrong : 0
    const tBVGlobal = this.state.numberToBeVerified ? this.state.numberToBeVerified : 0
    const trackingGlobal = this.state.numberTracking ? this.state.numberTracking : 0

    let tracking = this.state.numberTracking ? (((trackingGlobal) / this.state.numberPanel) * 100).toFixed(1) : 0
    tracking = tracking.toString().replace('.',',')

    const correctGlobalPerc = this.state.numberPanel ? trackingGlobal === 0 ? "-" : Math.round(((correctGlobal/trackingGlobal) * 100)) : 0;
    let wrongGlobalPerc = "-"
    if(correctGlobalPerc !== "-"){
      wrongGlobalPerc = campaignNumber>0 ? 100 - correctGlobalPerc : 0
    }


    const options = (
      <React.Fragment>
        <Form.DatePicker format="yyyy" minYear={2018} onChange={ev => this.handleChangeDate(ev)} />
      </React.Fragment>
    );

    const links = (
      <React.Fragment>
        <Button.List className="btn-cta-top-right d-flex text-right" style={{width: 270}}>
          <Button
            color="primary"
            size="sm"
            className="text-white"
            RootComponent="a"
            href="/campaigns"
          >
            View Campaigns
          </Button>
          <Button
            RootComponent="a"
            href="/mediaowner"
            color="primary"
            size="sm"
          >
            View Reporting
          </Button>
        </Button.List>
      </React.Fragment>
    )

    return (
      <SiteWrapper {...this.state}>
        <Page.Content title="Dashboard" options={options}>
          {/* <button onClick={this.addNotification}>Add notification</button> */}
          {/* <NotificationSystem ref={this.notificationSystem} /> */}
          <Grid.Row cards deck>
            <Grid.Col xs={12} sm={6} md={6} lg={3}>
              <Card
                className="card-counters"
                title="Data"
                body={
                  <Grid.Col md={12} className="text-center">
                    <Dimmer active={this.state.isLoadingCampaign} loader={this.state.isLoadingCampaign}>
                      <h2>{campaignNumber}</h2>
                    </Dimmer>
                    <p>Campaigns</p>
                    <hr className="mt-3 mb-3" />
                    <Dimmer active={this.state.isLoadingMO} loader={this.state.isLoadingMO}>
                      <h2>{panelNumber}</h2>
                    </Dimmer>
                    <p>Panels</p>
                    <hr className="mt-3 mb-3" />
                    <Dimmer active={this.state.isLoadingMO} loader={this.state.isLoadingMO}>
                      <h2>{tracking}%</h2>
                    </Dimmer>
                    <p>% of tracking</p>
                  </Grid.Col>
                }
              />
            </Grid.Col>
            <Grid.Col xs={12} sm={6} md={6} lg={3}>
              <Card
                className="card-counters"
                title="Geo Details"
                body={
                  <Grid.Col md={12} className="text-center">
                    <Dimmer active={this.state.isLoadingRegion} loader={this.state.isLoadingRegion}>
                      <h2>{regionNumber}</h2>
                    </Dimmer>
                    <p>Regions</p>
                    <hr className="mt-3 mb-3" />
                    <Dimmer active={this.state.isLoadingDistrict} loader={this.state.isLoadingDistrict}>
                      <h2>{districtNumber}</h2>
                    </Dimmer>
                    <p>N° District</p>
                    <hr className="mt-3 mb-3" />
                    <Dimmer active={this.state.isLoadingCity} loader={this.state.isLoadingCity}>
                      <h2>{citiesNumber}</h2>
                    </Dimmer>
                    <p>N° Cities</p>
                  </Grid.Col>
                }
              />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Card
                className="card-counters"
                title="Certification"
                body={
                  <Grid.Row>
                    <Grid.Col md={6} className="text-center">
                      <Dimmer active={this.state.isLoadingMO} loader={this.state.isLoadingMO}>
                        <div className="col-12 pl-8 pr-8">
                          <CircularProgressbar
                            className="card-counters-chart"
                            percentage={correctGlobalPerc}
                            text={`${correctGlobalPerc}` + (correctGlobalPerc !== '-' ? '%' : '')}
                            strokeWidth={7}
                            initialAnimation={"0%"}
                            styles={{
                              path: {
                                // Path color
                                stroke: `#8ecf4d`,
                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                strokeLinecap: "round",
                                // Customize transition animation
                                transition: "stroke-dashoffset 0.5s ease 0s",
                              },
                              text: {
                                fill: "#495057",
                                fontSize: "1.9em",
                                fontWeight: "bold",
                              },
                            }}
                          />
                        </div>
                      </Dimmer>
                      <br />
                      <Dimmer active={this.state.isLoadingMO} loader={this.state.isLoadingMO}>
                        <h2>{this.formatNumber(correctGlobal)}</h2>
                      </Dimmer>
                      <p>n° correct exposition</p>
                    </Grid.Col>
                    <Grid.Col md={6} className="text-center">
                      <Dimmer active={this.state.isLoadingMO} loader={this.state.isLoadingMO}>
                        <div className="col-12 pl-8 pr-8">
                          <CircularProgressbar
                            className="card-counters-chart"
                            percentage={wrongGlobalPerc}
                            text={`${wrongGlobalPerc}` + (wrongGlobalPerc !== '-' ? '%' : '')}
                            strokeWidth={7}
                            initialAnimation={"0%"}
                            styles={{
                              path: {
                                // Path color
                                stroke: `#ee8277`,
                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                strokeLinecap: "round",
                                // Customize transition animation
                                transition: "stroke-dashoffset 0.5s ease 0s",
                              },
                              text: {
                                fill: "#495057",
                                fontSize: "1.9em",
                                fontWeight: "bold",
                              },
                            }}
                          />
                        </div>
                      </Dimmer>
                      <br />
                      <Dimmer active={this.state.isLoadingMO} loader={this.state.isLoadingMO}>
                        <h2>{this.formatNumber(wrongGlobal)}</h2>
                      </Dimmer>
                      <p>n° wrong exposition</p>
                    </Grid.Col>
                  </Grid.Row>
                }
              />
            </Grid.Col>

            <Grid.Col sm={12} md={12}>
              {links}
              <TabbedCard initialTab="Last campaigns">
                <Tab title="Last campaigns">
                  <Table striped={true}>
                    <Table.Header>
                      <Table.ColHeader>Advertiser</Table.ColHeader>
                      <Table.ColHeader>Campaign</Table.ColHeader>
                      <Table.ColHeader>Start Date</Table.ColHeader>
                      <Table.ColHeader>End Date</Table.ColHeader>
                      <Table.ColHeader>Status</Table.ColHeader>
                      <Table.ColHeader alignContent="center">Photo check (%)</Table.ColHeader>
                      {(localStorage.getItem("type") == "admin" || localStorage.getItem("type") == "user") ?
                        <Table.ColHeader>Publish</Table.ColHeader>
                        : ""
                      }
                    </Table.Header>
                    <Table.Body>
                      {this.state.lastCampaigns.map((c, index) => {
                        if(this.state.lastCampaignsPercCheck.length==this.state.lastCampaigns.length){
                          const trackingPanels = this.state.lastCampaignsPercCheck[index].trackingPanels
                          const totalPanels = this.state.lastCampaignsPercCheck[index].totalPanels
                          const trackingPerc = ((trackingPanels / totalPanels) * 100).toFixed(0)
                          const isLoading = trackingPanels == -1 ? true : false
                          let status = ''
                          switch(c.status){
                                case "END":
                                  status = (c.inReport == 0 ? "PENDING" : c.status)
                                  break;
                                case "ACTIVE":
                                  status = "ON AIR"
                                  break;
                                case "imported":
                                  status = "IMPORTED"
                                  break;
                                default:
                                  status = "SCHEDULED"
                          }
                          return <Table.Row key={index}>
                            <Table.Col>{c.advertiser}</Table.Col>
                            <Table.Col>{c.campaign}</Table.Col>
                            <Table.Col>{c.startDate}</Table.Col>
                            <Table.Col>{c.endDate}</Table.Col>
                            <Table.Col>{status}</Table.Col>
                            <Table.Col className="p-0 pt-2">
                              {!isLoading ?
                                <div style={{ width: "2.2em", margin: "auto"}} title={"Tracking: " + trackingPanels + " (Total: "+ totalPanels + ")"}>
                                  <CircularProgressbar
                                    percentage={!isNaN(trackingPerc) ? trackingPerc : 0}
                                    text={`${!isNaN(trackingPerc) ? trackingPerc : 0}`}
                                    strokeWidth={11}
                                    initialAnimation={0}
                                    styles={{
                                      path: {
                                        // Path color
                                        stroke: `#666`,
                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                        strokeLinecap: "round",
                                        // Customize transition animation
                                        transition: "stroke-dashoffset 1.5s ease .2s",
                                      },
                                      text: {
                                        fill: "#666",
                                        fontSize: "2.8em",
                                        fontWeight: "bold",
                                      },
                                    }}
                                  />
                                </div>
                                : <Dimmer active={true} loader={true}>-</Dimmer>
                              }
                            </Table.Col>
                            {(localStorage.getItem("type") == "admin" || localStorage.getItem("type") == "user") ?
                              <Table.Col>
                                <Form.Group label="" className="p-0 m-0">
                                  <Form.Switch
                                    checked={c.inReport==1 ? true : false}
                                    name={"enable"+index}
                                    value="enabled"
                                    disabled
                                    label=""
                                    onClick={this.handlerChangePublish}
                                  />
                                </Form.Group>
                              </Table.Col>
                              : ""
                            }
                          </Table.Row>
                        }
                        else{
                          return '-'
                        }
                      })}
                    </Table.Body>
                  </Table>
                </Tab>

                <Tab title="Media Owner">
                  <Table striped={true}>
                    <Table.Header>
                      <Table.ColHeader>Media Owner</Table.ColHeader>
                      <Table.ColHeader alignContent="center">N° Panels</Table.ColHeader>
                      <Table.ColHeader alignContent="center">Tracking (%)</Table.ColHeader>
                      <Table.ColHeader alignContent="center">Correct (%)</Table.ColHeader>
                    </Table.Header>
                    <Table.Body>
                      {this.state.mediaOwnerTop.slice(0,5).map((r, index) => {
                          const total = r.total
                          const correct = r.state0
                          const wrong = r.state1
                          const anomaly = r.state2
                          const notTracked = r.state3
                          const tracked = r.tracked

                          const correctPerc = ((correct/tracked) * 100).toFixed(1)
                          const wrongPerc = ((wrong/tracked) * 100).toFixed(1)

                          return <Table.Row>
                            <Table.Col>{r.name}</Table.Col>
                            <Table.Col alignContent="center">{this.formatNumber(total)}</Table.Col>
                            <Table.Col alignContent="center">{((tracked/total) * 100).toFixed(1).toString().replace('.',',')}%</Table.Col>
                            <Table.Col alignContent="center">{tracked != 0 ? correctPerc.toString().replace('.',',') : 0}%</Table.Col>
                          </Table.Row>
                      })}
                    </Table.Body>
                  </Table>
                  <div className="row" hidden>
                    {/* <div className="col-10" /> */}
                    <Button
                      color="primary"
                      size="sm"
                      className="col-2 text-white"
                      RootComponent="a"
                      href="/campaigns"
                    >
                      See all <Icon name="arrow-right" />
                    </Button>
                  </div>
                </Tab>

              </TabbedCard>
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      </SiteWrapper>
    );
  }
}

export default Home;
