export const proxyConfig = {
  ASSET_SERVICE,
  AUDIENCE_SERVICE,
  AUDIT_SERVICE,
  BIGIMPACT_SERVICE,
  CAMPAIGN_SERVICE,
  COMPANY_SERVICE,
  DOOH_SERVICE,
  DOOHESTIMATION_SERVICE,
  GEO_SERVICE,
  GEOISOLINE_SERVICE,
  PDF_SERVICE,
  POI_SERVICE,
  SEARCH_SERVICE,
  SHARE_SERVICE,
  STATS_SERVICE,
  USER_SERVICE
}

function ASSET_SERVICE(ambience) {
  let apiUrl, apiVer
  switch(ambience){
    case "dev":
      apiUrl = "http://localhost:8099/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://kinasset.codesour.dev/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://assetservice.kineticplatform.it/"
      apiVer = "v1"
      break;
  }

  return {apiUrl, apiVer}
}


function AUDIENCE_SERVICE(ambience) {
  let apiUrl, apiVer

  switch(ambience){
    case "dev":
      apiUrl = "http://192.168.1.33:8096/"
      apiVer = "v1"
      break;
    case "stage":
      // apiUrl = "https://kinaudience.codesour.dev/"
      apiUrl = "https://kinsearch.codesour.dev/"
      // apiUrl = "https://c99c-95-235-163-22.ngrok.io/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://kineticplatform.it:9084/"
      apiVer = "v1"
      break;
  }

  return {apiUrl, apiVer}
}

function AUDIT_SERVICE(ambience) {
  let apiUrl, apiVer

  switch(ambience){
    case "dev":
      apiUrl = "http://192.168.1.33:7000/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://kinaudit.codesour.dev/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://auditservice.kineticplatform.it/"
      apiVer = "v1"
      break;
  }

  return {apiUrl, apiVer}
}

function BIGIMPACT_SERVICE(ambience) {
  let apiUrl, apiVer

  switch(ambience){
    case "dev":
      apiUrl = "https://3babffaaf74b.ngrok.io/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://kinsearch.codesour.dev/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://searchservice.kineticplatform.it/"
      apiVer = "v1"
      break;
  }

  return {apiUrl, apiVer}
}

function CAMPAIGN_SERVICE(ambience) {
  let apiUrl, apiVer

  switch(ambience){
    case "dev":
      apiUrl = "http://localhost:8100/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://kincampaign.codesour.dev/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://campaignservice.kineticplatform.it/"
      apiVer = "v1"
      break;
  }

  return {apiUrl, apiVer}
}

function COMPANY_SERVICE(ambience) {
  let apiUrl, apiVer

  switch(ambience){
    case "dev":
      apiUrl = "http://localhost:8103/"
      // apiUrl = "https://kincompany-dev.codesour.dev/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://kincompany.codesour.dev/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://companyservice.kineticplatform.it/"
      apiVer = "v1"
      break;
  }

  return {apiUrl, apiVer}
}

function DOOH_SERVICE(ambience) {
  let apiUrl, apiVer

  switch(ambience){
    case "dev":
      apiUrl = "http://localhost:8095/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://kindooh.codesour.dev/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://doohservice.kineticplatform.it/"
      apiVer = "v1"
      break;
  }

  return {apiUrl, apiVer}
}

function DOOHESTIMATION_SERVICE(ambience) {
  let apiUrl, apiVer

  switch(ambience){
    case "dev":
      apiUrl = "http://localhost:8095/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://kindooh-dev.codesour.dev/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://doohservice.kineticplatform.it/"
      apiVer = "v1"
      break;
  }

  return {apiUrl, apiVer}
}

function GEO_SERVICE(ambience) {
  const apiKey = "AIzaSyDk2tsrjgOei53e0A5X8J7nFfj-MiDymIY"
  const apiGMaps = "https://maps.googleapis.com/maps/api/geocode/json"
  const apiArcGis = "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer"

  let apiUrl, apiVer

  switch(ambience){
    case "dev":
      apiUrl = "http://192.168.1.135:9400/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://kingeo.codesour.dev/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://geoservice.kineticplatform.it/"
      apiVer = "v1"
      break;
  }

  return {apiUrl, apiVer, apiKey, apiGMaps, apiArcGis}
}

function GEOISOLINE_SERVICE() {
  const apiKeyGeoApify = "3a84d38e7df544f0937fc1dbca38615b"
  const apiGeoApify = "https://api.geoapify.com/v1/isoline"

  return {apiKeyGeoApify, apiGeoApify}
}

function PDF_SERVICE(ambience) {
  let apiUrl, apiVer

  switch(ambience){
    case "dev":
      apiUrl = "http://192.168.1.33:3001/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://kinpdf.codesour.dev/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://pdfservice.kineticplatform.it/"
      apiVer = "v1"
      break;
  }

  return {apiUrl, apiVer}
}

function POI_SERVICE(ambience) {
  let apiUrl, apiVer

  switch(ambience){
    case "dev":
      apiUrl = "http://localhost:8098/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://kinpoi.codesour.dev/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://poiservice.kineticplatform.it/"
      apiVer = "v1"
      break;
    default:
  }

  return {apiUrl, apiVer}
}

function SEARCH_SERVICE(ambience) {
  let apiUrl, apiVer

  switch(ambience){
    case "dev":
      // apiUrl = "http://localhost:8097/"
      apiUrl = "https://a224-5-95-120-72.ngrok.io/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://kinsearch.codesour.dev/"
      // apiUrl = "https://c99c-95-235-163-22.ngrok.io/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://searchservice.kineticplatform.it/"
      apiVer = "v1"
      break;
  }

  return {apiUrl, apiVer}
}

function SHARE_SERVICE(ambience) {
  let apiUrl, apiVer

  switch(ambience){
    case "dev":
      // apiUrl = "http://localhost:8097/"
      apiUrl = "https://a224-5-95-120-72.ngrok.io/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://kinshare.codesour.dev/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://shareservice.kineticplatform.it/"
      apiVer = "v1"
      break;
    default:
  }

  return {apiUrl, apiVer}
}

function STATS_SERVICE(ambience) {
  let apiUrl, apiVer

  switch(ambience){
    case "dev":
      apiUrl = "http://localhost:8000/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://kinstats.codesour.dev/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://statsservice.kineticplatform.it/"
      apiVer = "v1"
      break;
  }

  return {apiUrl, apiVer}
}

function USER_SERVICE(ambience) {
  let apiUrl, apiVer

  switch(ambience){
    case "dev":
      apiUrl = "http://localhost:8101/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://kinauth.codesour.dev/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://authservice.kineticplatform.it/"
      apiVer = "v1"
      break;
  }

  return {apiUrl, apiVer}
}

