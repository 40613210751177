// @flow

import * as React from "react";
import {Redirect} from "react-router-dom";
import { Drawer } from "antd";

import {
  Page,
  Grid,
  Card,
  Dropdown,
  Table,
  Form,
  Container,
  Tab,
  TabbedCard,
  Nav,
  Icon,
  Button,
  Dimmer,
  colors
} from "tabler-react";

import _ from "lodash"
import { StickyContainer, Sticky } from 'react-sticky';

import SiteWrapper from "../SiteWrapper.react"
import CampaignsSummary from "./Campaigns.Summary.react"
import CampaignsCertification from "./Campaigns.Certification.react"
import CampaignsPanelInfo from "./Campaigns.PanelInfo.react"
import CampaignsReportFilters from "./Campaigns.ReportFilters.react"
import { campaignService, justificativeService, statsService, imageService } from "../_services";

import PanelsMap from '../components/Map/PanelsMap.react'
import StreetView from "../components/Map/StreetView.react"
import Gallery from "../components/Gallery/Gallery.react"
import DownloaderPolling from "../components/DownloaderPolling/DownloaderPolling.react"
import "./Campaigns.Report.scss"

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class CampaignsPhoto extends React.Component {
    constructor(props){
        super(props)
        this.state = ({
            id: props.match.params.id,
            campaignInfo: {},
            campaignPanels: [],
            campaignPanelsCert:[],
            campaignPanelsCertAll: [],
            panelsAll: [],
            campaignAudit: [],
            campaignAuditAll: [],
            campaignSummary: {
                citiesNum: 0,
                districtNumber: 0,
                moPanelList: [],
                regionNumber: 0
            },

            pSelected:{
                idk: -1,
                idp: -1
            },

            pIdSelected: -1,
            lng: null, lat: null,

            activePage: 1,
            itemsCountPerPage: 5,
            totalItemsCount: 40,
            maxPageRangeDisplayed: 5,
            offsetQuery: 0,
            sendingQuery: false,
            error: null,
            isLoading: false,

            moWithCounts: [],
            totalCert:{
                "totalPanels": 0,
                "trackingPanels": 0,
                "correctPanels": 0,
                "wrongPanels": 0,
                "notCheckedPanels": 0
            },

            photoIndex: 0,
            creativityIsOpen: false,
            creativities : [],

            slideIndex:0,
            slideShowIsOpen: false,
            slideShow : [],
            statusFilter: -1,

            forbidden: false
        })

        this.handlerMOWithCounts = this.handlerMOWithCounts.bind(this)
        this.handlerStatusFiltered = this.handlerStatusFiltered.bind(this)
        this.getCampaignReport = this.getCampaignReport.bind(this)
        this.getCampaignZipReport = this.getCampaignZipReport.bind(this)
        this.handlerSegment = this.handlerSegment.bind(this)
    }

    componentDidMount(){
        this.getLocalUserInfo()
        this.loadCampaignInfo(this.state.id)
        this.loadCampaignSummary(this.state.id)
        this.loadCampaignCertification(this.state.id)
    }

    getLocalUserInfo(){
      const type = localStorage.getItem("type")

      switch(type){
        case "admin":
        case "user":
        case "mediaOwner":
        case "companyleader":
        case "companyuser":
        case "mediaOwner":
          this.setState({
            isAdvertiser: false,
            forbidden: false
          })
          break;
        case "advertiser":
          this.setState({
            isAdvertiser: true,
            forbidden: false
          })
          break;
        default:
          this.setState({
            forbidden: true
          })
      }
    }

    handlerMOWithCounts(moCounts){
        if(moCounts.length>0){
            this.setState({
                moWithCounts: moCounts
            })
        }
    }

    passPropsFilters = (nProps) => {
        this.setState({
            pIdSelected: 0,
            pSelected:{
                idk: -1,
                idp: -1 //da modificare in state.campaignPanels[i].idPanel
            },
            auditImages: [],
            lat: null,
            lng: null,
            selectedRegL: nProps.selectedRegL.trim().toLowerCase(),
            selectedDis: nProps.selectedDis.trim().toLowerCase(),
            selectedCitL: nProps.selectedCitL.trim().toLowerCase(),
            selectedMedOwn: nProps.selectedMedOwn.trim().toLowerCase(),
            selectedFrmt: nProps.selectedFrmt.trim().toLowerCase()
        })

        let idR = nProps.selectedRegL.trim().toLowerCase()
        let prov = nProps.selectedDis.trim().toLowerCase()
        let idC = nProps.selectedCitL.trim().toLowerCase()
        let mo = nProps.selectedMedOwn.trim().toLowerCase()
        let frmt = nProps.selectedFrmt.trim().toLowerCase()
        let status = this.state.statusFilter

        this.loadCampaignInfoFiltered(idR, prov, idC, mo, frmt, status)
    }



    loadCampaignInfoFiltered(idR, prov, idC, mo, idF, st){
        let appoggio = this.state.campaignPanels
        let panelsAll = this.state.panelsAll
        let filteredPanels = panelsAll.filter(function(item) {
            let region = item.region || ""
            let district = item.district || ""
            let municipality = item.municipality || ""
            let mediaOwner = item.mediaOwner || ""
            let panelType = item.panelType || ""
            let status = item.resultKinId

            let condReg = region.trim().toLowerCase() == idR || !idR
            let condProv = district.trim().toLowerCase() == prov || !prov
            let condCity = municipality.trim().toLowerCase() == idC || !idC
            let condMO = mediaOwner.trim().toLowerCase() == mo || !mo
            let condFormat = panelType.trim().toLowerCase() == idF || !idF
            let condStatus = (status == st || st < 0) && status != 3

            if (condReg && condProv && condCity && condMO && condFormat && condStatus) {
                return item;
            }
        })
        appoggio = filteredPanels
        this.setState({
            campaignPanels: appoggio
        }, ()=> this.loadCampaignInfoFilteredCert(idR, prov, idC, mo, idF))
    }

    loadCampaignInfoFilteredCert(idR, prov, idC, mo, idF){
        let appoggio = this.state.campaignPanelsCert
        let panelsAll = this.state.campaignPanelsCertAll
        let filteredPanels = panelsAll.filter(function(item) {
            let region = item.region || ""
            let district = item.district || ""
            let municipality = item.municipality || ""
            let mediaOwner = item.mediaOwner || ""
            let panelType = item.panelType || ""
            let status = item.resultKinId

            let condReg = region.trim().toLowerCase() == idR || !idR
            let condProv = district.trim().toLowerCase() == prov || !prov
            let condCity = municipality.trim().toLowerCase() == idC || !idC
            let condMO = mediaOwner.trim().toLowerCase() == mo || !mo
            let condFormat = panelType.trim().toLowerCase() == idF || !idF
            // let condStatus = status != 3

            if (condReg && condProv && condCity && condMO && condFormat) {
                return item;
            }
        })
        appoggio = filteredPanels
        this.setState({
            campaignPanelsCert: appoggio
        })
    }

    passPropsMap = (nProps, index) => {
        // let lng = nProps.lng()
        // let lat = nProps.lat()
        // nessun azione prevista al momento
    }


    passPropsPhoto = (index, seqId) => {
        let lat = this.state.campaignPanels[index].lat
        let lng = this.state.campaignPanels[index].lng

        let lastIndex = this.state.pIdSelected

        this.setState((state)=>({
            pIdSelected: lastIndex != index ? index : -1,
            pSelected:{
                idk: state.campaignInfo.idKinCampaign,
                idp: seqId //da modificare in state.campaignPanels[i].idPanel
            },
            lng: lng,
            lat: lat,
        }))
    }

    loadAudit(id){
        justificativeService.getAuditFindByCampaignFiltered(id).then(
            result => {
                let filteredResult = result.filter(function(item) {
                    let resultKinId = item.resultKinId
                    let condStatus = resultKinId != 3

                    if (condStatus) {
                        return item;
                    }
                })

                this.setState({
                    campaignPanels: filteredResult,
                    panelsAll: filteredResult,
                    campaignPanelsCert: result,
                    campaignPanelsCertAll: result,
                }, ()=> this.loadCreativities())

                this.loadSlideShow(filteredResult)
            },
            error => {
                console.error(error);
            }
        )
    }

    loadSlideShow(audits){
        let arrayPhotos = []

        audits.map((item, index) => {
            let title = item.mediaOwner
            title += item.panelType ? " - " + item.panelType : ""
            title += item.placeNames ? " - " + item.placeNames : ""
            title += item.address ? " " + item.address: ""
            title += item.municipality ? " - " + item.municipality : ""
            title += item.checkDate ? " - " + item.checkDate : ""

            const isTransitPanel = item.groupTypeId === "-1"

            let images = (item.kinPhotoCheck ? [item.kinPhotoCheck]
                : item.bmePhotoCheck ? (item.bmePhotoCheck.length > 0 || isTransitPanel ? item.bmePhotoCheck : []) : [])

            let arr = images.map((photo, index) => {
              let titlePhoto = title + " ("+ (index + 1) +" of "+  images.length +")"
              return {
                photo: this.getValidateUrl(photo),
                title: titlePhoto
              }
            })
            arrayPhotos = [...arrayPhotos, ...arr]
        })

        this.setState({
            slideShow: arrayPhotos
        })
    }

    getValidateUrl(img, isCreativity = false){
      return imageService.getUrlPhoto(img, isCreativity)
    }

    loadCreativities(){
      let creativity = _.groupBy(this.state.panelsAll, "photosAds")
      creativity = _.keys(creativity)
      creativity = creativity.filter(function(item) {
        return item != "null" && item
      });

      if(creativity.length<=0){
        creativity = _.groupBy(this.state.panelsAll, "creativityDec")
        creativity = _.keys(creativity)
        creativity = creativity.filter(function(item) {
            return item != "null" && item
        });
      }

      for(let i = 0; i< creativity.length; i++){
        creativity[i] = this.getValidateUrl(creativity[i], true)
      }

      this.setState({
        creativities: creativity ? creativity : []
      })
    }

    loadCampaignInfo(id){
        campaignService.getCampaignInfo(id).then(
          result => {
            this.setState({
              campaignInfo: result,
            }, () => this.loadAudit(this.state.campaignInfo.idKinCampaign))
          },
          error => {
            this.setState({ forbidden: true })
            console.error(error);
          }
        );
    }

    loadCampaignCertification(id){
        statsService.getStatsCertificationByCampaign(id).then(
          result => {
            this.setState({
                totalCert: result
            })
          },
          error => {
            console.error(error);
          }
        );
    }

    loadCampaignSummary(id){
        campaignService.getCampaignSummary(id).then(
          result => {
            this.setState({
              campaignSummary: result
            })
          },
          error => {
            console.error(error);
          }
        );
    }

    loadAuditCampaign(id){
        justificativeService.getAuditCampaign(id).then(
          result => {
            this.setState(({
              campaignAudit: result,
              campaignAuditAll: result
            }))
          },
          error => {
            console.error(error);
          }
        );
    }

    getCampaignReport(){
        let id = this.state.campaignInfo.idKinCampaign
        if(id){
            campaignService.getXlsCampaign(id);
        }
    }

    getCampaignZipReport(){
        let id = this.state.campaignInfo.idKinCampaign
        if(id){
            campaignService.getZipCampaign(id);
        }
    }

    handlePageChange = (pageNumber) => {
        let offset = pageNumber-1
        this.setState({
          activePage: pageNumber,
          offsetQuery: offset,
        //   isLoading: true
        });
    }

    handlerStatusFiltered(status){
        this.setState({
            pIdSelected: 0,
            pSelected:{
                idk: -1,
                idp: -1 //da modificare in state.campaignPanels[i].idPanel
            },
            auditImages: [],
            lat: null,
            lng: null,
            statusFilter: status
        })

        let idR = this.state.selectedRegL
        let prov = this.state.selectedDis
        let idC = this.state.selectedCitL
        let mo = this.state.selectedMedOwn
        let frmt = this.state.selectedFrmt

        this.loadCampaignInfoFiltered(idR, prov, idC, mo, frmt, status)
    }

    handlerSegment(obj){
        // console.log("SEGMENT GGNO")
        // console.log(obj)
    }

    render() {
        if(this.state.forbidden){
          return <Redirect
              to={{ pathname: "/404", state: { from: this.props.location } }}
          />
        }
        const { photoIndex, creativityIsOpen } = this.state;
        const { slideIndex, slideShowIsOpen } = this.state;
        let lastUpdate = this.state.campaignInfo.lastUpdate ? this.state.campaignInfo.lastUpdate : '-'

        let title;
        if(this.state.isAdvertiser) {
            title = (
                <React.Fragment>
                    <h1 className="page-title mb-0 mt-0 p-0">{this.state.campaignInfo.advertiser} - {this.state.campaignInfo.campaign}</h1>
                    <h4><small className="mb-0 mt-0 p-0">Panels: {this.state.panelsAll.length} - On Air: {this.state.campaignInfo.startDate} - {this.state.campaignInfo.endDate}</small></h4>
                </React.Fragment>
            )
        }
        else{
            title = (
                <React.Fragment>
                    <h1 className="page-title mb-0 mt-0 p-0">{this.state.campaignInfo.advertiser} - {this.state.campaignInfo.campaign}</h1>
                    <h4><small className="mb-0 mt-0 p-0">Panels: {this.state.panelsAll.length} - On Air: {this.state.campaignInfo.startDate} - {this.state.campaignInfo.endDate} - Last Update: {lastUpdate}</small></h4>
                </React.Fragment>
            )
        }

        const options = (
            <React.Fragment>
                <Button color="link" RootComponent="a" href="/campaigns/">
                    Back to Campaigns
                </Button>
                <Button color="primary" onClick={() => this.setState({ creativityIsOpen: true, photoIndex: 0 })} disabled={this.state.creativities.length<=0}>
                    <Icon prefix="fa" name="image" /> View Creativity
                </Button>
                {creativityIsOpen && (
                    <Lightbox
                        mainSrc={this.state.creativities[photoIndex]}
                        nextSrc={this.state.creativities[(photoIndex + 1) % this.state.creativities.length]}
                        prevSrc={this.state.creativities[(photoIndex + this.state.creativities.length - 1) % this.state.creativities.length]}
                        onCloseRequest={() => this.setState({ creativityIsOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + this.state.creativities.length - 1) % this.state.creativities.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % this.state.creativities.length,
                            })
                        }
                    />
                )}
            </React.Fragment>
        );

        let navItems;
        if(this.state.isAdvertiser) {
            navItems = (
                <React.Fragment>
                    <Nav className="bg-white">
                        <Nav.Item to={"/campaigns/"+this.state.id+"/map"} value="Map" icon="map" />
                        <Nav.Item to={"/campaigns/"+this.state.id+"/photo"} value="Photo" icon="camera" active />
                        <Nav.Item value="Audience" icon="users" disabled />
                        {/* <Nav.Item hasSubNav value="Download" icon="download">
                            <a href="#" className="dropdown-item" onClick={() => this.getCampaignReport()}>Xls format</a>
                        </Nav.Item> */}
                        <Nav.Item to={"#"} value="Download Xls" icon="download" onClick={() => this.getCampaignReport()}/>
                        <DownloaderPolling classButton="nav-link" labelButton=" Download Zip" classIcon={"fe fe-download fa-fw pr-1"} idkc={this.state.campaignInfo.idKinCampaign}/>
                    </Nav>
                </React.Fragment>
            )
        }
        else{
            navItems = (
                <React.Fragment>
                    <Nav className="bg-white">
                        <Nav.Item to={"/campaigns/"+this.state.id+"/map"} value="Map" icon="map" />
                        <Nav.Item to={"/campaigns/"+this.state.id+"/photo"} value="Photo" icon="camera" active />
                        <Nav.Item value="Audience" icon="users" disabled />
                        <Nav.Item to={"/campaigns/"+this.state.id+"/justificative"} value="Manage" icon="edit" />
                        <Nav.Item to={"#"} value="Download Xls" icon="download" onClick={() => this.getCampaignReport()}/>
                        <DownloaderPolling classButton="nav-link" labelButton=" Download Zip" classIcon={"fe fe-download fa-fw pr-1"} idkc={this.state.campaignInfo.idKinCampaign}/>
                    </Nav>
                </React.Fragment>
            )
        }

        const dataMap = [
            { lng: this.state.lng, lat: this.state.lat }
        ];

        return (
        <SiteWrapper {...this.state}>
            <Page.Content>
                <Page.Header
                    title={title}
                    options={options}
                />
                <StickyContainer>
                    <Sticky relative={false}>
                    {({ style }) => (
                        <div style={style} className="z-index-1">
                            <Grid.Row cards deck >
                                <Grid.Col sm={12}>
                                    <Card className="bg-light">
                                        <Container>
                                            {navItems}
                                            <CampaignsReportFilters
                                                idC={this.state.id}
                                                handleState={this.passPropsFilters}
                                                idkc={this.state.campaignInfo.idKinCampaign}
                                                panels={this.state.campaignPanels}
                                                panelsAll={this.state.panelsAll}
                                            />
                                        </Container>
                                    </Card>
                                </Grid.Col>
                            </Grid.Row>
                        </div>
                    )}
                    </Sticky>
                    <Grid.Row>
                        <Grid.Col sm={12} className="card-p-0">
                            <TabbedCard initialTab="Summary">
                                <Tab title="Summary">
                                    {(this.state.campaignInfo != {}) ?
                                        <CampaignsSummary data={this.state.campaignSummary} panels={this.state.panelsAll} handlerMOWithCounts={this.handlerMOWithCounts} handlerSegment={this.handlerSegment}/>
                                        : <Dimmer active={true} loader={true}><div className="p-4"></div></Dimmer>
                                    }
                                </Tab>
                                <Tab title="Certification">
                                    <CampaignsCertification all={this.state.campaignPanelsCertAll} filtered={this.state.campaignPanelsCert} handlerStatusFiltered={this.handlerStatusFiltered}/>
                                </Tab>
                                <Tab title="Trend" disabled>
                                    <h1 className="text-center p-9">Not Available</h1>
                                </Tab>
                            </TabbedCard>
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row cards deck>
                        <Grid.Col sm={12}>
                            <Card className="text-center">
                                <Card.Header>
                                    {/* <Card.Title>Card title</Card.Title> */}
                                    <Card.Options>
                                        <Button color="primary" pill size="sm" icon="play" onClick={() => this.setState({ slideShowIsOpen: true, slideIndex: 0 })} disabled={this.state.slideShow<1}>Slideshow</Button>
                                        {slideShowIsOpen && (
                                            <Lightbox
                                                mainSrc={this.state.slideShow[slideIndex].photo}
                                                nextSrc={this.state.slideShow[(slideIndex + 1) % this.state.slideShow.length].photo}
                                                prevSrc={this.state.slideShow[(slideIndex + this.state.slideShow.length - 1) % this.state.slideShow.length].photo}
                                                onCloseRequest={() => this.setState({ slideShowIsOpen: false })}
                                                onMovePrevRequest={() =>
                                                    this.setState({
                                                        slideIndex: (slideIndex + this.state.slideShow.length - 1) % this.state.slideShow.length,
                                                    })
                                                }
                                                onMoveNextRequest={() =>
                                                    this.setState({
                                                        slideIndex: (slideIndex + 1) % this.state.slideShow.length,
                                                    })
                                                }
                                                imageTitle={this.state.campaignInfo.advertiser + " - " +this.state.campaignInfo.campaign + " - Slideshow"}
                                                imageCaption={(slideIndex+1) + ". "+this.state.slideShow[slideIndex].title}
                                            />
                                        )}
                                    </Card.Options>
                                </Card.Header>
                                <Card.Body>
                                    <Container>
                                        {
                                            (typeof this.state.campaignPanels !== "undefined")
                                            ? <Gallery handleState={this.passPropsPhoto} data={this.state.campaignPanels} images/>
                                            : ''
                                        }
                                    </Container>
                                </Card.Body>
                            </Card>
                        </Grid.Col>
                    </Grid.Row>

                    <Drawer
                        placement="bottom"
                        closable={false}
                        height={450}
                        onClose={()=>this.setState({lat: null, lng: null})}
                        visible={this.state.lat!==null && this.state.lng!==null && this.state.pIdSelected>=0}
                    >
                        <Grid.Row>
                            <Grid.Col sm={12} className="card-p-0">
                                <Grid.Row>
                                    <Grid.Col md={4} className="border-right pr-sm-0 pt-0">
                                        <div className="bg-light report-title">
                                            <h4 className="pl-3 pr-3 pb-2 pt-2">
                                                <small>Panel Info</small>
                                            </h4>
                                        </div>
                                        <Grid.Row>
                                            <Grid.Col md={12} className="text-left pt-2">
                                                {
                                                    (typeof this.state.campaignPanels !== "undefined")
                                                    ? <CampaignsPanelInfo data={this.state.campaignPanels[this.state.pIdSelected]} />
                                                    : ''
                                                }
                                            </Grid.Col>
                                        </Grid.Row>
                                    </Grid.Col>
                                    <Grid.Col md={4} className="card-counters pr-sm-0 pl-sm-0 pt-0">
                                        <div className="bg-light">
                                            <h4 className="pl-3 pr-3 pb-2 pt-2">
                                                <small>Map</small>
                                            </h4>
                                        </div>
                                        <Grid.Row>
                                            <Grid.Col md={12} className="text-center">
                                                <Card.Map className="border rounded card-streetview">
                                                    <PanelsMap handleState={this.passPropsMap} data={dataMap} minimal={true} zoom={17}/>
                                                </Card.Map>
                                            </Grid.Col>
                                        </Grid.Row>
                                    </Grid.Col>
                                    <Grid.Col md={4} className="card-counters pl-sm-0 pt-0">
                                        <div className="bg-light">
                                            <h4 className="pl-3 pr-3 pb-2 pt-2">
                                                <small>Street View</small>
                                            </h4>
                                        </div>
                                        <Grid.Row>
                                            <Grid.Col md={12} className="text-center">
                                                <Card.Map className="border p-0 card-streetview">
                                                    <StreetView lat={this.state.lat} lng={this.state.lng} />
                                                </Card.Map>
                                            </Grid.Col>
                                        </Grid.Row>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid.Col>
                        </Grid.Row>
                    </Drawer>
                </StickyContainer>
            </Page.Content>
        </SiteWrapper>
        );
    }
}

export default CampaignsPhoto;
