import React, { useEffect, useState } from 'react';
import { Button } from 'tabler-react'
import { Drawer, List, Avatar, Badge} from 'antd';
import { DesktopOutlined } from '@ant-design/icons';

const DoohDrawer: React.FC = (props: AppProps) => {
  const [visible, setVisible] = useState(false);
  const [cartData, setCartData] = useState([]);

  useEffect(() => {
    setCartData(props.cartData);
  }, [props.cartData])

  const showDrawer = () => {
    if(!props.loading){
      setVisible(true);
    }
  };
  const onClose = () => {
    setVisible(false);
  };
  
  const footer = (
    <React.Fragment>
      <Button.List>
        <Button block color="primary" onClick={()=>props.handleChangeStep(1)}>
          ORGANIZE
        </Button>
      </Button.List>
    </React.Fragment>
  )

  return (
    <>
      {
        props.children ?
          <>{ React.cloneElement( props.children, { onClick: showDrawer } ) }</>
        :
          <Button color="primary" onClick={showDrawer}>
            Open
          </Button>
      }
      <Drawer
        title="Cart"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        width={400}
        footer={footer}
      >
        <List
          itemLayout="vertical"
          size="small"
          dataSource={cartData}
          renderItem={panel => {

            const extra = panel && (
              <React.Fragment>
                <div className="text-right">
                  <Badge count={'OTS: '+panel.ots} style={{ backgroundColor: "#fff", color: "#467fcf", boxShadow: "0 0 0 1px #467fcf inset"}}/>
                  <br/>
                  <Button color="link" className="text-danger mt-5" icon="x" onClick={()=>props.onRemove(panel.id)} />
                </div>
              </React.Fragment>
            )

            const avatar = (
              <React.Fragment>
                {
                  (panel.modality === 'network' && panel.childrenCount) ?
                  <>
                    <Badge count={panel.childrenCount} size="small" overflowCount={99999999} style={{ backgroundColor: "#fff", color: "#cf467f", boxShadow: "0 0 0 1px #cf467f inset"}}>
                      <Avatar size="large" shape="square" icon={<DesktopOutlined />} style={{ color: '#cf467f', backgroundColor: "#fff" }} />
                    </Badge>
                    <p style={{color: '#cf467f'}}>
                      <small>NETWORK</small>
                    </p>
                  </>
                  :
                  <Avatar size="large" shape="square" icon={<DesktopOutlined />} style={{ color: '#467fcf', backgroundColor: "#fff" }} />
                }

              </React.Fragment>
            )

            return(
              <List.Item
                extra={extra}
                >
                <p className="pl-6 ml-4">
                  <strong>{panel && (panel.region.toUpperCase() + (panel.city !== panel.district ? (' - ' +panel.district) : ''))}</strong>
                </p>
                <List.Item.Meta
                  avatar={avatar}
                  title={<a href="#">{panel && panel.publisher} <small className="pl-1">{panel && panel.installation}</small></a>}
                  description={panel && (panel.type + ' of ' + panel.city)}
                />
              </List.Item>
            )
          }}
        />
      </Drawer>
    </>
  );
};


export default DoohDrawer;
