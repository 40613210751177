import React, { createContext, useEffect, useState } from 'react';
import { shareService, userService } from '../_services';
import PlannerPlan from '../Planner/Planner.Plan.react';
import { Spin } from 'antd';
import DoohPlannerGuest from '../Dooh/Dooh.Plan.Guest.react';

const ReadOnlyContext = createContext(null);

export default function ShareViewer(props){
    const {resourceType, resourceId, token: guestToken} = props.match.params;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        tokenAuth();
    }, [])

    const performLoginGuest = (accessToken, guestToken) => {
        userService.loginGuest(accessToken, guestToken).then(
            result => {
                setTimeout(() => setLoading(false), 1000);
            },
            error => {

            }
        )
    }

    const tokenAuth = () => {
        shareService.tokenAuth(resourceId, resourceType, guestToken).then(
            result => {
                const {auth: accessToken} = result;
                performLoginGuest(accessToken, guestToken);
            },
            error => {
                console.error("Error: "+ error);
            }
        )
    }

    return(
        <ReadOnlyContext.Provider value={"true"}>
            {loading && 
                <div className="col-12 text-center p-5" style={{minHeight: 150}}>
                    <h3 className="mt-3 mb-5">Loading...</h3>
                    <Spin/>
                </div>
            }
            {!loading && (
                <>
                    {resourceType === "planner" && <PlannerPlan id={resourceId} readOnly={true} />}
                    {resourceType === "sightline" && <DoohPlannerGuest id={resourceId} readOnly={true} />}
                </>
            )}
        </ReadOnlyContext.Provider>
    )
}