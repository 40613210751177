import React from "react"
import { Button, Form } from "tabler-react";
const { compose, withProps, lifecycle } = require("recompose");
const {
  withScriptjs,
} = require("react-google-maps");
const { StandaloneSearchBox } = require("react-google-maps/lib/components/places/StandaloneSearchBox");
const apiKey = "AIzaSyDk2tsrjgOei53e0A5X8J7nFfj-MiDymIY"
const PlacesWithStandaloneSearchBox = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key="+apiKey+"&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
  }),
  lifecycle({
    componentWillMount() {
      const refs = {}
      this.setState({
        keywords: "",
        places: this.props.places ? this.props.places: [],
        bounds: this.props.bounds,
        lastResponse: null,
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          const isEmpty = places.length<=0
          this.setState((prev)=>({
            places,
            keywords: isEmpty ? prev.keywords: "",
            lastResponse: isEmpty ? 0 : places.length
          }));

          this.props.handlerCenterPosition(places)
        },
        onClickResetSearch: () => {
          this.props.onReset();
          this.setState((prev)=>({
            lastResponse: null
          }));
        },
        onChangeKeyWords: (ev) => {
          let value = ev.target.value
          this.setState({
            keywords: value,
            lastResponse: null
          });
        }
      })
    }
  }),
  withScriptjs
)((props) =>
  <div data-standalone-searchbox="" className="ml-0 mr-0 pb-0 pt-0">
    {props.lastResponse===null
      ?
      <StandaloneSearchBox
        ref={props.onSearchBoxMounted}
        bounds={props.bounds}
        onPlacesChanged={props.onPlacesChanged}
        controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
      >
        <Form.Group>
          <Form.Input
            icon="search"
            position="append"
            className={(props.lastResponse===null ? "" : props.lastResponse > 0 ? "" : "border-danger") + " mb-3"}
            placeholder={props.placeholder ? props.placeholder : "Search"}
            value={props.keywords}
            onChange={props.onChangeKeyWords}
          />
        </Form.Group>
      </StandaloneSearchBox>
     : props.lastResponse > 0
        ? <p style={{height: `38px`, paddingTop: '6px'}} className="alert alert-success text-center border rounded">
            <small>Search successful</small>
            <Button className="py-0 pull-right mt-1 ml-1" size="sm" onClick={props.onClickResetSearch} color="dark" outline icon="rotate-ccw">Restart</Button>
          </p>
        : <p style={{height: `38px`, paddingTop: '6px'}} className="alert alert-danger text-center border rounded">
            <small>The search did not find any POIs.</small>
            <Button className="py-0 pull-right mt-1 ml-1" size="sm" onClick={props.onClickResetSearch} color="dark" outline icon="rotate-ccw">Retry</Button>
          </p>
    }
    <ol hidden className={"shadow bg-white list-unstyled px-2 m-0" + (props.places.length > 0 ? " border": "")} style={{"fontSize": ".9em","max-height":"200px", "max-width":"100%","overflow":"auto", "position": "absolute", "margin": "1em"}}>
      {props.places.map(({ place_id, formatted_address, geometry: { location } }) =>
        <li key={place_id} className="bg-white border-bottom list-unstyled p-1">
          {formatted_address}
          {" at "}
          ({location.lat()}, {location.lng()})
        </li>
      )}
    </ol>
  </div>
);

export default PlacesWithStandaloneSearchBox;
