import { userService } from "./"
import { defineConfig } from '../config'

export const geoIsolineService = {
    getApiKeyGeoApify,
    getIsoline,
};

const { apiGeoApify, apiKeyGeoApify } = defineConfig.GEOISOLINE_SERVICE

function getApiKeyGeoApify() {
    return apiKeyGeoApify;
}

function getIsoline(lat, lon, type = "time", mode = "drive", range = 0, traffic = "free_flow", routeType = "balanced") {
    const requestOptions = {
        method: "GET",
    };
    return fetch(`${apiGeoApify}?apiKey=${getApiKeyGeoApify()}`+
        `&lat=${lat}&lon=${lon}`+
        `&type=${type}`+
        `&mode=${mode}`+
        `&range=${range}`+
        `&traffic=${traffic}`+
        `&routeType=${routeType}`,
    requestOptions)
        .then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
