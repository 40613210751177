export function segmentDictionary(key) {
    let segments = [
        {key: 'Dinamica', label: 'Transit', color: '#8C8C8C', icon: 'Default_8C8C8C.png', iconSelected: 'Default_8C8C8C_selected.png'},
        {key: 'Grandi Formati', label: 'Mega site', color: '#8C8C8C', icon: 'Default_8C8C8C.png', iconSelected: 'Default_8C8C8C_selected.png'},
        {key: 'Stazioni Ferroviarie', label: 'Railway station', color: '#8C8C8C', icon: 'Default_8C8C8C.png', iconSelected: 'Default_8C8C8C_selected.png'},
        {key: 'Underground', label: 'Underground', color: '#8C8C8C', icon: 'Default_8C8C8C.png', iconSelected: 'Default_8C8C8C_selected.png'},
        {key: 'Affissione Classica', label: 'Billboards', color: '#8C8C8C', icon: 'Default_8C8C8C.png', iconSelected: 'Default_8C8C8C_selected.png'},
        {key: 'Aeroporto', label: 'Airport', color: '#8C8C8C', icon: 'Default_8C8C8C.png', iconSelected: 'Default_8C8C8C_selected.png'},
        {key: 'Digital', label: 'DOOH', color: '#8C8C8C', icon: 'Default_8C8C8C.png', iconSelected: 'Default_8C8C8C_selected.png'},
        {key: 'Arredo Urbano', label: 'Street Furniture', color: '#8C8C8C', icon: 'Default_8C8C8C.png', iconSelected: 'Default_8C8C8C_selected.png'},
        {key: 'Altri', label: 'Others', color: '#8C8C8C', icon: 'Default_8C8C8C.png', iconSelected: 'Default_8C8C8C_selected.png'},
        {key: 'Medi Formati', label: 'Big Impact', color: '#8C8C8C', icon: 'Default_8C8C8C.png', iconSelected: 'Default_8C8C8C_selected.png'},
        {key: 'Affissione Speciale', label: 'Small Format', color: '#8C8C8C', icon: 'Default_8C8C8C.png', iconSelected: 'Default_8C8C8C_selected.png'},
    ];

    let found = segments.find(function(element) {
        return element.key.toUpperCase() == key.toUpperCase();
    });

    if(!found){
        found = {key: 'Altri', label: '', color: '#5f5f5f', icon: '9-others-01.png', iconSelected: '9-others-02.png'}
        // console.log("NOT FOUND! -> ", key)
    }

    return found;
}
