// @flow

import * as React from "react";

import {
  Page,
  Grid,
  Card,
  Container,
  Nav,
  Table,
  Button,
  Dimmer,
  Tag
} from "tabler-react";


import SiteWrapper from "../SiteWrapper.react";

import {toast} from 'react-toastify'

import UploadFile from "../components/UploadData/upload/Upload"
import '../components/UploadData/UploadData.scss'
import { companyService, campaignService } from "../_services";
import { Button as ButtonAntd, Select, Spin } from 'antd';
const { Option } = Select;

const DELAY_POLLING = 50;

class CampaignsMap extends React.Component {
    constructor(props){
        super(props)
        this.state = ({
            campaignInfo: [
                {
                    id: -1,
                    advertiser: '',
                    campaign: '',
                    startDate: '',
                    endDate: '',
                    numPanels: 0
                }
            ],
            advertisers: [],
            companies: [],
            selectedCompanies: [],
            companiesSaved: false,
            isUploaded: false,
            isNewAdvertiser: false,
            isLoading: false,
            error: "",
            idKinCampaign: null,
            progressMessage: "",
            progressCount: 0
        })
        this.handlerUploadProgress = this.handlerUploadProgress.bind(this)
        this.handlerResponse = this.handlerResponse.bind(this)
    }

    componentDidMount(){
      this.getAllAdvertisers()
    }

    handlerUploadProgress(progress) {
        this.getIdKinCampaignAndCountAudits();
        this.setState({
          isUploaded: progress,
          isLoading: true
        })
        console.log('[IMPORT] Caricamento effettuato!')
    }

    handlerResponse(res){
        console.log('[IMPORT] response....')
        console.log(res)
        if(res.readyState == 4){
            switch(res.status){
              case 201:
                console.log("[IMPORT] Status 201")
                console.log(JSON.parse(res.response))
                if(JSON.parse(res.response).campaign){
                  this.setState({
                      campaignInfo: JSON.parse(res.response).campaign
                  }, () => this.checkAdvertiser(this.state.campaignInfo.advertiser))
                }
                break;
              case 422:
                console.log("[IMPORT] Unprocessable Content!", res)
                this.setState({
                    error: "Unprocessable Content: duplicate campaign name, start date and end date.",
                    isLoading: false
                })
                toast.error('Duplicate campaign name, start date and end date.')
                break;
              case 409:
                console.log("[IMPORT] Conflict!", res)
                this.setState({
                    error: "Conflict: file not valid. Fix it and retry.",
                    isLoading: false
                })
                toast.error('Conflict: file not valid. Fix it and retry.')
                break;
              default:
                console.log("[IMPORT] Generic error!", res)
                this.setState({
                    error: "Import not available now. Please wait and retry again.",
                    isLoading: false
                })
                toast.error('Import not available now. Please wait and retry again.')
            }
            
            this.setState({
                isLoading: false
            })
        }
        else{
            console.log("[IMPORT] readystate "+ res.readyState)
        }
    }

    getAllAdvertisers = () => {
      campaignService.getAllAdvertisers().then(
        advertisers => {
          this.setState({
            advertisers
          })
        }
      )
    }
    
    getIdKinCampaignAndCountAudits = () => {
      campaignService.getLastCampaignIdKin().then(
        result =>{
          const {idKinCampaign} = result;
          this.setState({
            idKinCampaign: idKinCampaign + 1
          }, () => this.getAuditsCountById(this.state.idKinCampaign))
        },
        error => {
          toast.error("Sorry progress not available")
        }
      )
    }

    getAuditsCountById = (idKinCampaign) => {
      const {isLoading} = this.state;
      if(!idKinCampaign)
        return;

      if(isLoading){
        campaignService.getAuditsCountByIdKinCampaign(idKinCampaign).then(
          result => {
            const {count, message} = result;
            this.setState({
              progressCount: count,
              progressMessage: message,
            }, () => {
              setTimeout(() => this.getAuditsCountById(idKinCampaign), DELAY_POLLING);
            })
          },
          error => {
            console.error("Lost count information")
          }
        )
      }
      
    }




    checkAdvertiser = (advertiser) => {
      const {advertisers} = this.state
      if(advertisers.indexOf(advertiser) === -1){
        this.setState({
          isNewAdvertiser: true
        })

        this.getCompanies()
      }
    }

    getCompanies = () => {
      companyService.readCompanyAll().then(
        companies => {
          this.setState({
            companies
          })
        }
      )
    }

    onChangeCompanies = (selectedCompanies) => {
      this.setState({selectedCompanies})
    }

    onSaveCompanies = () => {
      const {selectedCompanies, campaignInfo} = this.state
      const {advertiser} = campaignInfo

      if(selectedCompanies.length > 0){
        companyService.setAdvertiserToCompanies(advertiser, selectedCompanies).then(
          result => {
            this.setState({
              companiesSaved: true
            })
          }
        )
      }
    }

    render() {
        const {isNewAdvertiser, companies, selectedCompanies, companiesSaved, error} = this.state

        const title = (
            <React.Fragment>
                <h1 className="page-title mb-0 mt-0 p-0">Import Campaign</h1>
            </React.Fragment>
        )

        return (
        <SiteWrapper {...this.state}>
            <Page.Content>
                <Page.Header
                    title={title}
                    // options={options}
                />
                <Grid.Row cards deck>
                    <Grid.Col sm={12}>
                        <Card className="bg-light">
                            <Container>
                                <Nav className="bg-white">
                                    <Nav.Item value="Upload file" active />
                                </Nav>
                            </Container>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
                {
                  (!isNewAdvertiser && error === "" && !this.state.isLoading ) &&
                  <Grid.Row>
                      <Grid.Col sm={12} className="card-p-0">
                          <Card className="bg-light">
                              <Container>
                                  <UploadFile handlerUploadProgress={this.handlerUploadProgress} handlerResponse={this.handlerResponse} type="new" />
                              </Container>
                          </Card>

                      </Grid.Col>
                  </Grid.Row>
                }

                {
                  this.state.isLoading &&
                    <Card className="bg-light p-5">
                      <h3>Imported {this.state.progressCount} audits <Spin className="ml-3" /></h3>
                      <h3 className="text-muted">{this.state.progressMessage}</h3>
                    </Card>
                }

                {(this.state.isUploaded && error === "" ) &&
                    <Dimmer active={this.state.isLoading} loader={this.state.isLoading}>
                        <Grid.Row cards deck>
                            <Grid.Col sm={12}>
                                <Card className="bg-light" title="Imported campaign">
                                    <Table striped={true} responsive>
                                        <Table.Header>
                                            <Table.ColHeader>Advertiser</Table.ColHeader>
                                            <Table.ColHeader>Campaign</Table.ColHeader>
                                            <Table.ColHeader>Start Date</Table.ColHeader>
                                            <Table.ColHeader>End Date</Table.ColHeader>
                                            <Table.ColHeader>N° Panels</Table.ColHeader>
                                        </Table.Header>
                                        <Table.Body>
                                            {[this.state.campaignInfo] ? [this.state.campaignInfo].map((c, index) => {
                                                    return <Table.Row key={index}>
                                                        <Table.Col>{c.advertiser} {isNewAdvertiser && <Tag color="success">NEW</Tag>}</Table.Col>
                                                        <Table.Col>{c.campaign}</Table.Col>
                                                        <Table.Col>{c.startDate}</Table.Col>
                                                        <Table.Col>{c.endDate}</Table.Col>
                                                        <Table.Col><strong>{c.numberOfPanels}</strong></Table.Col>
                                                    </Table.Row>
                                                }) : <div>Campaign not be able to load ({this.state.error})</div>
                                            }
                                        </Table.Body>
                                    </Table>
                                    {/* {this.state.campaignInfo.erased
                                        ? <div className="bg-blue p-4 text-white">Campaign erased and reloaded!</div>: ""
                                    } */}
                                </Card>
                            </Grid.Col>
                        </Grid.Row>
                        {
                          isNewAdvertiser &&
                          <Grid.Row>
                              <Grid.Col sm={12} className="card-p-0">
                                  <Card className={companiesSaved ? "bg-light" : "bg-white"} title="Assign new advertiser to companies">
                                    <div className="row m-3">
                                      <div className="col p-3">
                                        <Select
                                          disabled={companiesSaved}
                                          className="w-100"
                                          mode="multiple"
                                          placeholder="Select companies"
                                          onChange={this.onChangeCompanies}
                                          optionLabelProp="label"
                                        >
                                          {
                                            companies.map( company =>
                                              <Option value={company.id} label={company.name}>
                                                {company.name}
                                              </Option>
                                            )
                                          }
                                        </Select>
                                      </div>
                                      <div className="col p-3">
                                        <ButtonAntd type="primary" disabled={selectedCompanies.length <= 0 || companiesSaved} onClick={this.onSaveCompanies}>Confirm</ButtonAntd>
                                      </div>
                                    </div>
                                  </Card>
                              </Grid.Col>
                          </Grid.Row>
                        }
                        <Grid.Row>
                          <Grid.Col className="text-right">
                            <Button color="primary" RootComponent="a" href={"/campaigns/import/id/"+this.state.campaignInfo.campaignId} disabled={!companiesSaved && selectedCompanies.length > 0}>Next step</Button>
                          </Grid.Col>
                        </Grid.Row>
                    </Dimmer>
                }

                {
                  error !== "" &&
                    <div className="alert alert-danger">
                      <strong>Error</strong> <br/>{this.state.error}
                      <br/>
                      <div className="text-right">
                        <Button color="secondary" RootComponent="a" href="/campaigns/import">Upload another file</Button>
                      </div>
                    </div>
                }
            </Page.Content>
        </SiteWrapper>
        );
    }
}

export default CampaignsMap;
