import React, { useEffect, useState } from 'react';
import { Button, Icon } from 'tabler-react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Select, Switch, Form, Tabs } from 'antd';
import { campaignService, userService } from '../_services';
import { toast } from 'react-toastify';
import { result } from 'lodash';
const { Option } = Select;

function CampaignNotificationConfig(props) {
    const { idCampaign } = props;
    const [isOpenModal, toggleModal] = useState(false);
    const [activeNotification, setActiveNotification] = useState(false);
    const [activeCustomNotification, setActiveCustomNotification] = useState(false);
    const [mails, setMails] = useState([]);
    const [customMails, setCustomMails] = useState([]);
    const [availableMails, setAvailableMails] = useState([]);

    useEffect(() => {
        getAvailableMails();
    }, []);

    useEffect(() => {
        if (!isOpenModal) {
            setMails([]);
        }
        else {
            loadCampaignInfo(idCampaign);
        }
    }, [isOpenModal]);

    const getAvailableMails = () => {
        userService.readUserAll().then(
            result => {
                const options = result.map(({ username }) => <Option key={username}>{username}</Option>);
                setAvailableMails(options);
            },
            error => {
                toast.error("Error: " + error);
            }
        )
    }

    const loadCampaignInfo = (idCampaign) => {
        campaignService.getCampaignInfo(idCampaign).then(
            result => {
                const { mails: mailsResult, activeNotification: activeNotificationResult } = result;
                setMails(mailsResult !== null ? mailsResult : []);
                setActiveNotification(activeNotificationResult !== null ? activeNotificationResult : false);

                const { customMailAuditMails: customMailAuditMailsResult, customMailAuditNotification: customMailAuditNotificationResult } = result;
                setCustomMails(customMailAuditMailsResult !== null ? customMailAuditMailsResult : []);
                setActiveCustomNotification(customMailAuditNotificationResult !== null ? customMailAuditNotificationResult : false);
            },
            error => {
                toast.error("Error: " + error);
            }
        );
    }

    const onSave = () => {
        campaignService.setCampaignMailsList(idCampaign, activeNotification, mails).then(
            result => {
                toast.success('Successfully saved!');
                toggleModal(false);
            },
            error => {
                toast.error("Error: " + error);
            }
        )
    }

    const onCustomMailSave = () => {
        campaignService.setCustomCampaignMailsList(idCampaign, activeCustomNotification, customMails).then(
            result => {
                toast.success('Successfully saved!');
                toggleModal(false);
            },
            error => {
                toast.error("Error: " + error);
            }
        )
    }

    return (
        <>
            <Button color="info" onClick={() => toggleModal(true)} disabled={idCampaign >= 1000}>
                <Icon prefix="fa" name="bell" /> Notifications
            </Button>
            <Modal isOpen={isOpenModal} toggle={() => toggleModal(!isOpenModal)}>
                <ModalHeader toggle={() => toggleModal(!isOpenModal)}>
                    <Icon prefix="fa" name="bell" /> Notifications
                </ModalHeader>
                <ModalBody>
                    <Tabs defaultActiveKey='1'>
                        <Tabs.TabPane tab="Notification" key="1">
                            <div className="row">
                                <div className="col">
                                    <Form
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 14 }}
                                        layout="horizontal"
                                    // initialValues={{ size: componentSize }}
                                    // onValuesChange={onFormLayoutChange}
                                    >
                                        <Form.Item label="Send email" name="email" extra="The newsletter communicates the new erroneous results (wrongs and anomalies) of the campaign coming from BME.">
                                            <Switch name="email" checked={activeNotification} onChange={setActiveNotification} />
                                        </Form.Item>
                                        <Form.Item label="Recipient emails" name="emails">
                                            <Select
                                                disabled={!activeNotification}
                                                mode="tags"
                                                style={{
                                                    width: '100%',
                                                }}
                                                placeholder="Insert emails"
                                                onChange={setMails}
                                                // defaultValue={mails}
                                                key={idCampaign}
                                                value={mails}
                                            >
                                                {availableMails}
                                            </Select>
                                            <div className='d-none'>{mails}</div>
                                        </Form.Item>

                                        <Form.Item label="Cc email address" name="cc-email">
                                            <p className='mt-1'>controlli@kineticplatform.it</p>
                                        </Form.Item>
                                    </Form>
                                    <div className='text-right border-top '>
                                        <Button className='mt-2' color="secondary" onClick={() => toggleModal(false)}>Cancel</Button>
                                        <Button className='mx-2 mt-2' color="primary" disabled={activeNotification && mails.length === 0} onClick={onSave}>Save</Button>
                                    </div>
                                </div>
                            </div>

                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Custom Notification" key="2">
                            <div className="row">
                                <div className="col">
                                    <Form
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 14 }}
                                        layout="horizontal"
                                    // initialValues={{ size: componentSize }}
                                    // onValuesChange={onFormLayoutChange}
                                    >
                                        <Form.Item label="Send email" name="email" extra="The newsletter export campaign report for a custom mailing list">
                                            <Switch name="email" checked={activeCustomNotification} onChange={setActiveCustomNotification} />
                                        </Form.Item>
                                        <Form.Item label="Recipient emails" name="emails">
                                            <Select
                                                disabled={!activeCustomNotification}
                                                mode="tags"
                                                style={{
                                                    width: '100%',
                                                }}
                                                placeholder="Insert emails"
                                                onChange={setCustomMails}
                                                // defaultValue={mails}
                                                key={idCampaign}
                                                value={customMails}
                                            >
                                                {availableMails}
                                            </Select>
                                            <div className='d-none'>{customMails}</div>
                                        </Form.Item>

                                    </Form>
                                    <div className='text-right border-top '>
                                        <Button className='mt-2' color="secondary" onClick={() => toggleModal(false)}>Cancel</Button>
                                        <Button className='mx-2 mt-2' color="primary" disabled={activeCustomNotification && customMails.length === 0} onClick={onCustomMailSave}>Save</Button>
                                    </div>
                                </div>
                            </div>
                        </Tabs.TabPane>

                    </Tabs>

                </ModalBody>
            </Modal>
        </>
    )
}

export default CampaignNotificationConfig;