// @flow

import * as React from "react";

import {
  Page,
  Grid,
  Card,
  Dropdown,
  Table,
  Form,
  Container,
  Tab,
  TabbedCard,
  Nav,
  Icon,
  Button,
  Dimmer,
  colors
} from "tabler-react";

import CircularProgressbar from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./Campaigns.Certification.scss"

class CampaignsCertification extends React.Component {
    constructor(props){
        super(props)
        this.state = ({
            id: '',
            statusActive: -1,
            all: [],
            filtered: [],
            totalPanelsFilt: 0,
            trackingPanelsFilt: 0,
            correctPanelsFilt: 0,
            wrongPanelsFilt: 0,
            notCheckedPanelsFilt: 0,
            toBeVerifiedPanelsFilt: 0,
            trackingPercFilt: 0,
            correctPercFilt: 0,
            wrongPercFilt: 0,
            toBeVerifPercFilt: 0,
            notCheckedPercFilt: 0,

            totalPanelsAll: 0,
            trackingPanelsAll: 0,
            correctPanelsAll: 0,
            wrongPanelsAll: 0,
            notCheckedPanelsAll: 0,
            toBeVerifiedPanelsAll: 0,
            trackingPercAll: 0,
            correctPercAll: 0,
            wrongPercAll: 0,
            toBeVerifPercAll: 0,
            notCheckedPercAll: 0,

            isLoading: true,
        })

        this.toggleFilter = this.toggleFilter.bind(this)
    }

    componentDidMount() {
        this.setState({
            filtered: this.props.filtered,
            isLoading: true
        }, ()=>this.loadDataFiltered())

        this.setState({
            all: this.props.all,
            isLoading: true
        }, ()=>this.loadDataAll())
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.filtered!==this.props.filtered){
            this.setState({
                filtered: nextProps.filtered,
                isLoading: true
            }, ()=>this.loadDataFiltered())
        }

        if(nextProps.all!==this.props.all){
            this.setState({
                all: nextProps.all,
                isLoading: true
            }, ()=>this.loadDataAll())
        }
    }

    formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }

    loadDataAll(){
        // console.log("all state")
        // console.log(this.state.all)
        let items = this.state.all || []
        const totalPanelsAll = items.length //? this.formatNumber(this.props.data.totalPanels) : 0
        const trackingPanelsAll = items.filter(function(item) {
            return item.resultKinId >=0 && item.resultKinId < 3
        }).length
        const correctPanelsAll = items.filter(function(item) {
            return item.resultKinId == 0
        }).length
        const wrongPanelsAll = items.filter(function(item) {
            return item.resultKinId == 1
        }).length
        const notCheckedPanelsAll = items.filter(function(item) {
            return item.resultKinId == 3
        }).length
        const toBeVerifiedPanelsAll = totalPanelsAll - (correctPanelsAll + wrongPanelsAll + notCheckedPanelsAll)

        const trackingPercAll = totalPanelsAll>0 ? ((trackingPanelsAll/totalPanelsAll) * 100).toFixed(1) : 0
        const correctPercAll = trackingPanelsAll>0 ? Math.round(((correctPanelsAll/trackingPanelsAll) * 100)) : 0
        const wrongPercAll = trackingPanelsAll>0 ? Math.round(((wrongPanelsAll/trackingPanelsAll) * 100)) : 0
        const toBeVerifPercAll = trackingPanelsAll>0 ? Math.round(((toBeVerifiedPanelsAll/trackingPanelsAll) * 100)) : 0
        const notCheckedPercAll = totalPanelsAll>0 ?  ((notCheckedPanelsAll/totalPanelsAll) * 100).toFixed(1) : 0

        this.setState({
            totalPanelsAll,
            trackingPanelsAll,
            correctPanelsAll,
            wrongPanelsAll,
            notCheckedPanelsAll,
            toBeVerifiedPanelsAll,
            trackingPercAll,
            correctPercAll,
            wrongPercAll,
            toBeVerifPercAll,
            notCheckedPercAll,
            isLoading: false
        })
    }

    loadDataFiltered(){
        let items = this.state.filtered || []
        const totalPanelsFilt = items.length //? this.formatNumber(this.props.data.totalPanels) : 0
        const trackingPanelsFilt = items.filter(function(item) {
            return item.resultKinId >=0 && item.resultKinId < 3
        }).length
        const correctPanelsFilt = items.filter(function(item) {
            return item.resultKinId == 0
        }).length
        const wrongPanelsFilt = items.filter(function(item) {
            return item.resultKinId == 1
        }).length
        const notCheckedPanelsFilt = items.filter(function(item) {
            return item.resultKinId == 3
        }).length
        const toBeVerifiedPanelsFilt = totalPanelsFilt - (correctPanelsFilt + wrongPanelsFilt + notCheckedPanelsFilt)

        const trackingPercFilt = totalPanelsFilt>0 ? ((trackingPanelsFilt/totalPanelsFilt) * 100).toFixed(1) : 0
        const correctPercFilt = trackingPanelsFilt>0 ? Math.round(((correctPanelsFilt/trackingPanelsFilt) * 100)) : 0
        const wrongPercFilt = trackingPanelsFilt>0 ? Math.round(((wrongPanelsFilt/trackingPanelsFilt) * 100)) : 0
        const toBeVerifPercFilt = trackingPanelsFilt>0 ? Math.round(((toBeVerifiedPanelsFilt/trackingPanelsFilt) * 100)) : 0
        const notCheckedPercFilt = totalPanelsFilt>0 ?  ((notCheckedPanelsFilt/totalPanelsFilt) * 100).toFixed(1) : 0

        this.setState({
            totalPanelsFilt,
            trackingPanelsFilt,
            correctPanelsFilt,
            wrongPanelsFilt,
            notCheckedPanelsFilt,
            toBeVerifiedPanelsFilt,
            trackingPercFilt,
            correctPercFilt,
            wrongPercFilt,
            toBeVerifPercFilt,
            notCheckedPercFilt,
            isLoading: false
        })
    }

    classesStatus(status){
        let classes = ""
        let currentStatus = this.state.statusActive
        if(currentStatus >= 0){
            if(currentStatus == status) {
                classes = "certification-filter-active"
            }
            else{
                classes = "certification-filter-disabled"
            }
        }
        return classes
    }

    toggleFilter(status){
        this.setState((state)=>({
            statusActive: state.statusActive == status ? -1 : status
        }), ()=>this.props.handlerStatusFiltered(this.state.statusActive))
    }

    render() {
        // const totalPanels = this.props.data.totalPanels //? this.formatNumber(this.props.data.totalPanels) : 0
        // const trackingPanels = this.props.data.trackingPanels
        // const correctPanels = this.props.data.correctPanels
        // const wrongPanels = this.props.data.wrongPanels
        // const notCheckedPanels = this.props.data.notCheckedPanels
        // const toBeVerifiedPanels = totalPanels - (correctPanels + wrongPanels + notCheckedPanels)

        // const trackingPerc = ((trackingPanels/totalPanels) * 100).toFixed(1)
        // const correctPerc = Math.round(((correctPanels/trackingPanels) * 100))
        // const wrongPerc = Math.round(((wrongPanels/trackingPanels) * 100))
        // const toBeVerifPerc = Math.round(((toBeVerifiedPanels/trackingPanels) * 100))
        // const notCheckedPerc = ((notCheckedPanels/totalPanels) * 100).toFixed(1)

        const totalPanels = this.state.totalPanelsAll
        const trackingPanels = this.state.trackingPanelsAll
        const correctPanels = this.state.correctPanelsAll
        const wrongPanels = this.state.wrongPanelsAll
        const notCheckedPanels = this.state.notCheckedPanelsAll
        const toBeVerifiedPanels = this.state.toBeVerifiedPanelsAll

        const trackingPerc = this.state.trackingPercAll
        const correctPerc = this.state.correctPercAll
        const wrongPerc = this.state.wrongPercAll
        const toBeVerifPerc = this.state.toBeVerifPercAll

        return (
            <div>
                <Grid.Row>
                    <Grid.Col sm={12} lg={6} className="border-right pr-sm-0 pt-0">
                        <div className="bg-light report-title">
                            <h4 className="pl-3 pr-3 pb-2 pt-2">
                                <small>Total</small>
                            </h4>
                        </div>
                        <Grid.Row>
                            <Grid.Col col={12} lg={12} className="pt-0 text-center">
                                <div className="row border-bottom mr-1 ml-1">
                                    <div className="card-counters-report col-4">
                                        <p>N° Panels</p>
                                        <h2 className="text-center">{totalPanels}</h2>
                                    </div>
                                    <div className="card-counters-report col-4 bg-light">
                                        <p>% of Tracking</p>
                                        <h2 className="text-center">{trackingPerc.toString().replace('.',',')}%</h2>
                                    </div>
                                    <div className="card-counters-report col-4">
                                        <p>Tracked panels</p>
                                        <h2 className="text-center">{trackingPanels}</h2>
                                    </div>
                                </div>
                            </Grid.Col>
                            <Grid.Col col={12} lg={4} className="text-center pl-7 pr-7">
                                <div className="pl-2 pr-2 pb-2 pt-5">
                                    <CircularProgressbar
                                        percentage={correctPerc}
                                        text={`${correctPerc}%`}
                                        strokeWidth={7}
                                        initialAnimation={"0%"}
                                        styles={{
                                        path: {
                                            // Path color
                                            stroke: `#8ecf4d`,
                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                            strokeLinecap: "round",
                                            // Customize transition animation
                                            transition: "stroke-dashoffset 0.5s ease 0s",
                                        },
                                        text: {
                                            fill: "#495057",
                                            fontSize: "1.9em",
                                            fontWeight: "bold",
                                        },
                                        }}
                                    />
                                </div>
                                <p>Correct Display</p>
                            </Grid.Col>
                            <Grid.Col lg={4} className="text-center pl-7 pr-7">
                                <div className="pl-2 pr-2 pb-2 pt-5">
                                    <CircularProgressbar
                                        percentage={wrongPerc}
                                        text={`${wrongPerc}%`}
                                        strokeWidth={7}
                                        initialAnimation={"0%"}
                                        styles={{
                                        path: {
                                            // Path color
                                            stroke: `#ee8277`,
                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                            strokeLinecap: "round",
                                            // Customize transition animation
                                            transition: "stroke-dashoffset 0.5s ease 0s",
                                        },
                                        text: {
                                            fill: "#495057",
                                            fontSize: "1.9em",
                                            fontWeight: "bold",
                                        },
                                        }}
                                    />  
                                </div>
                                <p>Wrong Display</p>
                            </Grid.Col>
                            <Grid.Col lg={4} className="text-center pl-7 pr-7">
                                <div className="pl-2 pr-2 pb-2 pt-5">
                                    <CircularProgressbar
                                        percentage={toBeVerifPerc}
                                        text={`${toBeVerifPerc}%`}
                                        strokeWidth={7}
                                        initialAnimation={"0%"}
                                        styles={{
                                            path: {
                                                // Path color
                                                stroke: `#cdcf4d`,
                                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                strokeLinecap: "round",
                                                // Customize transition animation
                                                transition: "stroke-dashoffset 0.5s ease 0s",
                                            },
                                            text: {
                                                fill: "#495057",
                                                fontSize: "1.9em",
                                                fontWeight: "bold",
                                            },
                                        }}
                                    />  
                                </div>
                                <p>To be verified</p>
                            </Grid.Col>
                        </Grid.Row>
                    </Grid.Col>



                    
                    <Grid.Col sm={12} lg={6} className="pl-sm-0 pt-0">
                        <Dimmer active={this.state.isLoading} loader={this.state.isLoading}>
                            
                            <div className="bg-light report-title">
                                <h4 className="pl-3 pr-3 pb-2 pt-2">
                                    <small>Filtered</small>
                                    <span className="certification-filter-btn float-right">
                                        {this.state.statusActive >= 0 ? <a href="#" hidden onClick={()=>this.toggleFilter(-1)}>RESET</a> : ''}
                                    </span>
                                </h4>
                                
                            </div>
                            {this.state.filtered.length > 0 ?
                                <Grid.Row>
                                    <Grid.Col col={12} lg={12} className="pt-0 text-center">
                                        <div className="row border-bottom mr-1 ml-1">
                                            <div className="card-counters-report col-4">
                                                <p>N° Panels</p>
                                                <h2 className="text-center">{this.state.totalPanelsFilt}</h2>
                                            </div>
                                            <div className="card-counters-report col-4 bg-light">
                                                <p>% of Tracking</p>
                                                <h2 className="text-center">{this.state.trackingPercFilt.toString().replace('.',',')}%</h2>
                                            </div>
                                            <div className="card-counters-report col-4">
                                                <p>Tracked panels</p>
                                                <h2 className="text-center">{this.state.trackingPanelsFilt}</h2>
                                            </div>
                                        </div>
                                    </Grid.Col>

                                    <Grid.Col col={12} lg={4} className={"text-center pl-7 pr-7 certification-filter " + this.classesStatus(0)}>
                                        <div className="pl-2 pr-2 pb-2 pt-5">
                                            <CircularProgressbar
                                                percentage={this.state.correctPercFilt}
                                                text={`${this.state.correctPercFilt}%`}
                                                strokeWidth={7}
                                                initialAnimation={"0%"}
                                                styles={{
                                                path: {
                                                    // Path color
                                                    stroke: `#8ecf4d`,
                                                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                    strokeLinecap: "round",
                                                    // Customize transition animation
                                                    transition: "stroke-dashoffset 0.5s ease 0s",
                                                },
                                                text: {
                                                    fill: "#495057",
                                                    fontSize: "1.9em",
                                                    fontWeight: "bold",
                                                },
                                                }}
                                            />
                                        </div>
                                        <Button color={'secondary'} size="sm" onClick={()=>this.toggleFilter(0)}>Correct Display</Button>
                                    </Grid.Col>
                                    <Grid.Col lg={4} className={"text-center pl-7 pr-7 certification-filter " + this.classesStatus(1)}>
                                        <div className="pl-2 pr-2 pb-2 pt-5">
                                            <CircularProgressbar
                                                percentage={this.state.wrongPercFilt}
                                                text={`${this.state.wrongPercFilt}%`}
                                                strokeWidth={7}
                                                initialAnimation={"0%"}
                                                styles={{
                                                path: {
                                                    // Path color
                                                    stroke: `#ee8277`,
                                                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                    strokeLinecap: "round",
                                                    // Customize transition animation
                                                    transition: "stroke-dashoffset 0.5s ease 0s",
                                                },
                                                text: {
                                                    fill: "#495057",
                                                    fontSize: "1.9em",
                                                    fontWeight: "bold",
                                                },
                                                }}
                                            />  
                                        </div>
                                        <Button color={'secondary'} size="sm" onClick={()=>this.toggleFilter(1)}>Wrong Display</Button>
                                    </Grid.Col>
                                    <Grid.Col lg={4} className={"text-center pl-7 pr-7 certification-filter " + this.classesStatus(2)}>
                                        <div className="pl-2 pr-2 pb-2 pt-5">
                                            <CircularProgressbar
                                                percentage={this.state.toBeVerifPercFilt}
                                                text={`${this.state.toBeVerifPercFilt}%`}
                                                strokeWidth={7}
                                                initialAnimation={"0%"}
                                                styles={{
                                                    path: {
                                                        // Path color
                                                        stroke: `#cdcf4d`,
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: "round",
                                                        // Customize transition animation
                                                        transition: "stroke-dashoffset 0.5s ease 0s",
                                                    },
                                                    text: {
                                                        fill: "#495057",
                                                        fontSize: "1.9em",
                                                        fontWeight: "bold",
                                                    },
                                                }}
                                            />  
                                        </div>
                                        <Button color={'secondary'} size="sm" onClick={()=>this.toggleFilter(2)}>To be verified</Button>
                                    </Grid.Col>
                                </Grid.Row>
                                : <h3 className="p-5 text-center">No results</h3>}
                        </Dimmer>
                    </Grid.Col>
                </Grid.Row>
            </div>
        )
    }
}
export default CampaignsCertification;