// @flow

import * as React from "react";

import {
  Page,
  Grid,
  Card,
  Container,
  Nav,
  Table,
  Button,
  Dimmer
} from "tabler-react";

import SiteWrapper from "../SiteWrapper.react";

import { campaignService, justificativeService } from "../_services";

import UploadFile from "../components/UploadData/upload/Upload"
import '../components/UploadData/UploadData.scss'

class CampaignsImportAppend extends React.Component {
    constructor(props){
        super(props)
        this.state = ({
            id: props.match.params.id,
            campaignInfo: {
                id: props.match.params.id,
                advertiser: '',
                campaign: '',
                startDate: '',
                endDate: '',
                createDate: '',
                numPanels: 0,
                idKinCampaign: -1
            },

            uploadInfo: [
                {
                    id: -1,
                    auditList: []
                }
            ],

            campaignPanels: [],
            campaignPanelsAll: [],

            isUploaded: false,
            isLoading: false,
            error: ""
        })
        this.handlerUploadProgress = this.handlerUploadProgress.bind(this)
        this.handlerResponse = this.handlerResponse.bind(this)
    }

    componentDidMount(){
        this.loadCampaignInfo(this.state.campaignInfo.id)
    }

    loadCampaignInfo(id){
        campaignService.getCampaignInfo(id).then(
          result => {
            this.setState({
                campaignInfo: result
            }, ()=> this.getCampaignPanels(this.state.campaignInfo.idKinCampaign))
          },
          error => {
            console.error(error);
          }
        );
    }

    getCampaignPanels(idk){
        justificativeService.getAuditFindByCampaign(idk).then(
          result => {
            this.setState({
                campaignPanels: result,
                campaignPanelsAll: result,
                isLoaded: true
            })
          },
          error => {
            console.error(error);
          }
        );
    }
    
    handlerUploadProgress(progress) {
        this.setState({
          isUploaded: progress,
          isLoading: true
        })
        console.log('[IMPORT APPEND] Caricamento effettuato!')
    }

    handlerResponse(res){
        console.log('[IMPORT APPEND] response....')
        console.log(res)
        if(res.readyState == 4){
            if(res.status == 200){
                console.log("[IMPORT APPEND] Status 200")
                console.log(JSON.parse(res.response))
                this.setState({
                    uploadInfo: JSON.parse(res.response).auditList
                },()=>console.log(this.state))
            }
            else{
                console.log("[IMPORT APPEND] Errore!")
                console.log(JSON.parse(res.response))
                this.setState({
                    error: res.response,
                    isLoading: false
                })
            }
            this.setState({
                isLoading: false
            })
        }
        else{
            console.log("[IMPORT APPEND] readystate "+ res.readyState)
        }
    }

    render() {
        const title = (
            <React.Fragment>
                <h1 className="page-title mb-0 mt-0 p-0">{this.state.campaignInfo.advertiser} - {this.state.campaignInfo.campaign}</h1>
                <h4><small className="mb-0 mt-0 p-0">Panels: {this.state.campaignPanelsAll.length} - On Air: {this.state.campaignInfo.startDate} - {this.state.campaignInfo.endDate} - Last Update: {this.state.campaignInfo.lastUpdate ? this.state.campaignInfo.lastUpdate : "-"}</small></h4>
            </React.Fragment>
        )

        return (
        <SiteWrapper {...this.state}>
            <Page.Content>
                <Page.Header
                    title={title}
                    // options={options}
                />
                <Grid.Row cards deck>
                    <Grid.Col sm={12}>
                        <Card className="bg-light">
                            <Container>
                                <Nav className="bg-white">
                                    <Nav.Item value="Upload file" />
                                    <Nav.Item value="Edit Campaign / Upload Ad" to={"/campaigns/import/id/"+this.props.match.params.id} />
                                    <Nav.Item value="Add transit/dynamic panels" to={"/campaigns/importAppend/id/"+this.props.match.params.id} active />
                                </Nav>
                            </Container>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
                <Dimmer active={this.state.campaignInfo.idKinCampaign < 0} loader={this.state.campaignInfo.idKinCampaign < 0}>
                    <Grid.Row>
                        <Grid.Col sm={12} className="card-p-0">
                            <Card className="bg-light">
                                <Container>
                                    <UploadFile handlerUploadProgress={this.handlerUploadProgress} handlerResponse={this.handlerResponse} type="append" idKC={this.state.campaignInfo.idKinCampaign} />
                                    {/* <h2 className="p-7 text-center">Not Available</h2> */}
                                </Container>
                            </Card>
                        </Grid.Col>
                    </Grid.Row>
                </Dimmer>
                {this.state.isUploaded &&
                    <Dimmer active={this.state.isLoading} loader={this.state.isLoading}>
                        <Grid.Row cards deck>
                            <Grid.Col sm={12}>
                                <Card className="bg-light" title="Imported transit/dynamic panels">
                                    <Table striped={true} responsive>
                                        <Table.Header>
                                            <Table.ColHeader>Advertiser</Table.ColHeader>
                                            <Table.ColHeader>Campaign</Table.ColHeader>
                                            <Table.ColHeader>Total Before</Table.ColHeader>
                                            <Table.ColHeader>N° Panels Uploaded</Table.ColHeader>
                                            <Table.ColHeader>Total After</Table.ColHeader>
                                        </Table.Header>
                                        <Table.Body>
                                            {[this.state.uploadInfo] ? [this.state.uploadInfo].map((c, index) => {
                                                let rowsUploaded = c ? c.length : 0
                                                    return <Table.Row key={index}>
                                                        <Table.Col>{this.state.campaignInfo.advertiser}</Table.Col>
                                                        <Table.Col>{this.state.campaignInfo.campaign}</Table.Col>
                                                        <Table.Col><strong>{this.state.campaignPanelsAll.length}</strong></Table.Col>
                                                        <Table.Col><strong>{rowsUploaded}</strong></Table.Col>
                                                        <Table.Col><strong>{rowsUploaded + this.state.campaignPanelsAll.length}</strong></Table.Col>
                                                    </Table.Row>
                                                }) : <div>Campaign not be able to load ({this.state.error})</div>
                                            }
                                        </Table.Body>
                                    </Table>
                                </Card>
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col className="text-right">
                                <Button color="primary" RootComponent="a" href={'/campaigns/'+this.state.id+'/justificative'}>Jump to Manage</Button>
                            </Grid.Col>
                        </Grid.Row>
                    </Dimmer>
                }
            </Page.Content>
        </SiteWrapper>
        );
    }
}

export default CampaignsImportAppend;
