// @flow

import * as React from "react";

import {
  Grid,
  Dimmer,
  colors
} from "tabler-react";

import C3Chart from "react-c3js";
import _ from "lodash";

import { segmentDictionary } from "../_helpers"

class CampaignsSummary extends React.Component {
    constructor(props){
        super(props)
        this.state = ({
            id: '',
            regionNum: -1,
            districtNum: 0,
            citiesNum: 0,
            moPanelNum : 0,
            moPanelList :[],
            topMO: [],
            topMOLabel: [],
            otherValuesMO: 0,
            topSE: [],
            topSELabel: [],
            otherValuesSE: 0,
        })
    }

    componentDidMount(){
        this.loadNumbers(this.props.data);
        this.initSegment(this.props.panels);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.data!==this.props.data){
            this.loadNumbers(nextProps.data);
        }

        if(nextProps.panels!==this.props.panels){
            this.initSegment(nextProps.panels)
        }
    }

    loadNumbers(data){
        if(typeof data.moPanelList !== "undefined" && data.moPanelList != null){
            this.setState({
                regionNum: data.regionNumber,
                districtNum: data.districtNumber,
                citiesNum: data.citiesNum,
                moPanelList: data.moPanelList,
                moPanelNum: data.moPanelList!= null ? data.moPanelList.length : 0
            }, () => this.loadGroupBy())
        }
    }

    initSegment(panels){
        console.log('Carico da parent i segment raggruppati')
        let segments = _.groupBy(panels, "segmentDesc")
        let segmentsLab = _.keys(segments);
        let arSegments = []
        for(let i=0; i<segmentsLab.length; i++){
            console.log(segmentsLab[i] + ' -> ' +segments[segmentsLab[i]].length)
            arSegments.push({
                "segment": segmentsLab[i],
                "panelNumber": segments[segmentsLab[i]].length
            })
        }
        this.loadPanelsGroupBySegment(arSegments);
    }

    loadGroupBy(panels){
        this.loadPanelsGroupByMO()
    }

    loadPanelsGroupByMO(){
        let valuesMO = []
        let labelsMO = []
        if(this.state.moPanelList != null){
            this.state.moPanelList.map((item, key) => (
                valuesMO.push([item.mediaOwner,item.panelNumber])
            ))
            valuesMO = _.orderBy(valuesMO, [1]).reverse()

            this.props.handlerMOWithCounts(valuesMO)

            this.loadTopPanels(valuesMO)
        }
    }

    loadPanelsGroupBySegment(segments){
        let valuesSE = []
        let labelsSE = []
        if(segments.length>0){
            segments.map((item, key) => (
                valuesSE.push([item.segment,item.panelNumber])
            ))
            valuesSE = _.orderBy(valuesSE, [1]).reverse()

            this.loadTopPanelsSeg(valuesSE)
        }
    }

    loadTopPanels(panels){
        let topMO = []
        let topMOLabel = []
        let otherValuesMO = 0;
        for(let i=0; i<panels.length; i++){
            if(i>2){
                otherValuesMO += panels[i][1]
            }
            else{
                topMO.push(["data"+(i+1), panels[i][1]])
                topMOLabel.push(["data"+(i+1), panels[i][0]])
            }
        }

        console.log('Tuttiiiiii')
        console.log(panels)
        console.log('Solo Top')
        console.log(topMO)
        console.log('Gli altri')
        console.log(otherValuesMO)
        console.log('---------------')

        if(panels.length>3){
           topMO.push(['Altri', otherValuesMO])
        }

        this.setState({topMO, otherValuesMO, topMOLabel})
    }

    loadTopPanelsSeg(panels){
        let topSE = []
        let topSELabel = []
        let otherValuesSE = 0;
        for(let i=0; i<panels.length; i++){
            if(i>3){
                otherValuesSE += panels[i][1]
            }
            else{
                topSE.push(["data"+(i+1), panels[i][1]])
                topSELabel.push(["data"+(i+1), panels[i][0]])
            }
        }

        console.log('Tuttiiiiii i Segment')
        console.log(panels)
        console.log('Solo Top')
        console.log(topSE)
        console.log('Gli altri')
        console.log(otherValuesSE)
        console.log('---------------')

        if(panels.length>4){
           topSE.push(['OTHERS', otherValuesSE])
        }

        this.setState({topSE, otherValuesSE, topSELabel})
        this.props.handlerSegment({topSE, otherValuesSE, topSELabel})
    }

    initChartMO(columns, columnsLabel){
        var names = '{'
        for(var i=0; i<columnsLabel.length; i++){
            names += '"data'+(i+1) + '" : "'+ columnsLabel[i][1] + '"'
            if(i<columnsLabel.length-1){
                names += ','
            }
        }
        names+= '}'

        return {
            title: "Media Owner",
            interaction: {
                enabled: false
            },
            data: {
              columns: columns,
              type: "pie", // default type of chart
              colors: {
                data1: colors["blue-darker"],
                data2: colors["blue"],
                data3: colors["blue-light"],
                data4: colors["blue-lighter"]
              },
              names: JSON.parse(names),
            },
            axis: {},
            
        }
    }

    initChartSE(columns, columnsLabel){
        var names = '{'
        for(var i=0; i<columnsLabel.length; i++){
            let label = columnsLabel[i][1] ? segmentDictionary(columnsLabel[i][1]).label.toUpperCase() : ""
            if(label == ""){
                label = columnsLabel[i][1]
            }

            names += '"data'+(i+1) + '" : "'+ label + '"'
            if(i<columnsLabel.length-1){
                names += ','
            }
        }
        names+= '}'

        return{
            title: "Segment",
            interaction: {
                enabled: false
            },
            data: {
              columns: columns,
              type: "pie",
              colors: {
                data1: columnsLabel[0] ? segmentDictionary(columnsLabel[0][1]).color: "#333",
                data2: columnsLabel[1] ? segmentDictionary(columnsLabel[1][1]).color: "#333",
                data3: columnsLabel[2] ? segmentDictionary(columnsLabel[2][1]).color: "#333",
                data4: columnsLabel[3] ? segmentDictionary(columnsLabel[3][1]).color: "#333"
              },
              names: JSON.parse(names),
            },
            axis: {},
        }
    }

    render() {
        let regionNum = this.state.regionNum
        let districtNum = this.state.districtNum
        let citiesNum = this.state.citiesNum
        let moPanelNum = this.state.moPanelNum
        
        const dataMO = this.initChartMO(this.state.topMO, this.state.topMOLabel)
        const dataSE = this.initChartSE(this.state.topSE, this.state.topSELabel)

        return (
            <div>
                <Grid.Row>
                    <Grid.Col sm={12} md={4} className="card-counters border-right pr-sm-0 pt-0">
                        <div className="bg-light">
                            <h4 className="pl-3 pr-3 pb-2 pt-2">
                                <small>Details</small>
                            </h4>
                        </div>
                        <Dimmer active={regionNum==0} loader={regionNum==0}>
                            <Grid.Row>
                                <Grid.Col md={6} className="text-center border-right mt-4">
                                    <h2>{regionNum}</h2>
                                    <p>Regions</p>
                                    <hr className="mt-3 mb-3" />
                                    <h2>{citiesNum}</h2>
                                    <p>N° Cities</p>
                                </Grid.Col>
                                <Grid.Col md={6} className="text-center mt-4">
                                    <h2>{districtNum}</h2>
                                    <p>N° District</p>
                                    <hr className="mt-3 mb-3" />
                                    <h2>{moPanelNum}</h2>
                                    <p>Media Owner</p>
                                </Grid.Col>
                            </Grid.Row>
                        </Dimmer>
                    </Grid.Col>
                    <Grid.Col sm={12} md={4} className="card-counters pl-sm-0 pr-sm-0 border-right">
                        <div className="bg-light">
                            <h4 className="pl-3 pr-3 pb-2 pt-2">
                                <small>Media Owner</small>
                            </h4>
                        </div>
                        <Dimmer active={this.state.topMO.length<=0} loader={this.state.topMO.length<=0}>
                            <Grid.Row>
                                <Grid.Col md={12} className="text-center">
                                    <C3Chart
                                        data={dataMO.data}
                                        axis={dataMO.axis}
                                        legend={{
                                            show: true, //hide legend
                                            position: 'right'
                                        }}
                                        padding={{
                                            bottom: 0,
                                            top: -5
                                        }}
                                        size={{
                                            height: 160
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Row>
                        </Dimmer>
                    </Grid.Col>
                    <Grid.Col sm={12} md={4} className="card-counters pl-sm-0">
                        <div className="bg-light">
                            <h4 className="pl-3 pr-3 pb-2 pt-2">
                                <small>Segment</small>
                            </h4>
                        </div>
                        <Grid.Row>
                            <Grid.Col md={12} className="text-center">
                                <C3Chart
                                    data={dataSE.data}
                                    axis={dataSE.axis}
                                    legend={{
                                        show: true, //hide legend
                                        position: 'right'
                                    }}
                                    padding={{
                                        bottom: 0,
                                        top: -5
                                    }}
                                    size={{
                                        height: 160
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Row>
                    </Grid.Col>
                </Grid.Row>
            </div>
        )
    }
}
export default CampaignsSummary;