// @flow

import * as React from "react";

import {
  Tabs,
  Tab,
  Table,
  Tag,
  Dimmer,
  Avatar,
  Button} from "tabler-react";

import {Popconfirm} from 'antd'

import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import { companyService, userService } from "../_services";
import "./CompanyManage.scss"
import Pagination from '../components/Pagination/Pagination.react'
import CompanyManageForm from "./CompanyManage.form.react"

import LogoDefault from '../static/images/kinetic.png'

import {toast} from "react-toastify"
import CompanyTransferUsers from "./CompanyTransferUsers.react";

const INIT_PAGINATION = {
  activePage: 1,
  itemsCountPerPage: 7,
  totalItemsCount: 0,
  maxPageRangeDisplayed: 5,
}

class CompanyManage extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      tab: "Open",
      errorMessage: "",
      groupList: [],
      locationSearch: null,
      previewId: null,
      previewAdvertisers: [],

      isEditRequest: false,
      editCompanyId: null,
      editCompanyName: null,
      editCompanyAdvertisers: [],
      editCompanyLogoUrl: null,

      isCloneRequest: false,
      cloneCompanyId: null,
      cloneCompanyName: null,
      cloneAdvertisers: [],
      cloneCompanyLogoUrl: null,

      selectedUsers: [],

      ...INIT_PAGINATION,
      offsetQuery: 0,
      sendingQuery: false,
      isLoading: false,
      isOpenModal: false
    }
    this.deleteCompany = this.deleteCompany.bind(this)
    this.editCompany = this.editCompany.bind(this)
    this.cloneCompany = this.cloneCompany.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handlePageChangeCallBack = this.handlePageChangeCallBack.bind(this)
    this.getAllCompany = this.getAllCompany.bind(this)
    this.resetFormClone = this.resetFormClone.bind(this)
    this.addAdvertisersFromCompany = this.addAdvertisersFromCompany.bind(this)
  }

  componentDidMount(){
    this.getAllCompany();
    if(this.props.tab){
      this.setTabToShow(this.props.tab)
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.tab !== this.props.tab){
      let {tab} = nextProps
      if(tab){
        this.setTabToShow(tab)
      }
    }
  }

  setTabToShow(tab){
    this.setState({
      tab,
      ...INIT_PAGINATION,
      isLoading: true
    }, ()=> this.resetForm())
  }

  resetForm(forced = false){
    const {tab} = this.state
    if(tab!== "Edit" || forced === true){
      this.setState({
        isEditRequest: false,
        editCompanyId: null,
        editCompanyName: null,
        editCompanyAdvertisers: [],
        editCompanyLogoUrl: null,
      }, ()=> this.getAllCompany())
    }
  }

  resetFormClone(){
    this.setState({
      isCloneRequest: false,
      cloneCompanyId: null,
      cloneCompanyName: null,
      cloneAdvertisers: [],
      cloneCompanyLogoUrl: null,
    }, ()=> this.getAllCompany())
  }

  getAllCompany(){
    this.setState({isLoading: true})
    companyService.readCompanyAll().then(
      result => {
        result = result.filter(el => el.name !== "")
        this.setState({
          groupList: result,
          activePage: 1,
          totalItemsCount: result.length,
          offsetQuery: 0,
          isLoading: false
        }, () => this.setUsersToCompanies())
      }
    )
  }

  setUsersToCompanies = () => {
    var {groupList} = this.state
    groupList.map( (company, indexCompany ) => {
      const {id} = company
      userService.getUsersByCompanyId(id).then(
        users => {
          if(users.length <= 0){
            groupList[indexCompany].users = []
          }
          else {
            groupList[indexCompany].users = users
          }

          this.setState({groupList})
        }
      )


    })
  }

  editCompany(id){
    this.setState({
      isEditRequest: false,
      editCompanyId: null,
      editCompanyName: null,
      editCompanyAdvertisers: [],
      editCompanyLogoUrl: null,
    })
    if(id){
      companyService.readCompany(id).then(
        result => {
          const {id, name, advertisers, logoUrl} = result
          this.setState({
            isEditRequest: true,
            editCompanyId: id,
            editCompanyName: name,
            editCompanyAdvertisers: advertisers,
            editCompanyLogoUrl: logoUrl
          }, ()=> this.setTabToShow("Edit"))
        }
      )
    }
  }

  cloneCompany(id, name){
    this.setState({
      isCloneRequest: false,
      cloneCompanyId: null,
      cloneCompanyName: null,
      cloneCompanyAdvertisers: [],
    })
    if(id){
      companyService.readCompany(id).then(
        result => {
          const {id, name, advertisers, logoUrl} = result
          this.setState({
            isCloneRequest: true,
            cloneCompanyId: id,
            cloneCompanyName: name,
            cloneCompanyAdvertisers: advertisers,
            cloneCompanyLogoUrl: logoUrl
          }, ()=> this.setTabToShow("New"))
        }
      )
    }
  }

  deleteCompany(id){
    if(id){
      companyService.deleteCompany(id)
      const groupList = this.state.groupList.filter(el => el.id !== id)
      this.setState({
          groupList
      }, ()=> toast.success('Company deleted successful!'))
    }
  }

  getCompanyListPreview() {
    const {previewAdvertisers} = this.state
    return(
      <React.Fragment>

        {previewAdvertisers && previewAdvertisers.map((item, index) => {
            const {name, comune, address, brand, type, location, note} = item
            return (
              <div className="row mb-2">
                <div className="col-sm-auto">
                  Empty
                </div>
                <div className="col-9 p-1">
                  <div className={index%2 ? "px-3 py-2 rounded" : "px-3 py-2 rounded"} title={note}>
                    <p className="mb-0 text-truncate"><strong>Name:</strong> {name ? name : "-"}</p>
                    <p className="mb-0 text-truncate"><strong>Address:</strong> {address ? address : "-"}</p>
                    <p className="mb-0 text-truncate"><strong>City:</strong> {comune ? comune : "-"}</p>
                    <p className="mb-0 text-truncate"><strong>Type:</strong> {type ? type : "-"}</p>
                    <p className="mb-0 text-truncate"><strong>Brand:</strong> {brand ? brand : "-"}</p>
                    {location ?
                      <p className="mb-0 text-truncate"><strong>Location:</strong> Lat: {location.y ? location.y : "-"}, Long: {location.x ? location.x : "-"}</p>
                      : <p className="alert alert-danger">Location: corrupted data (please remove this item)</p>
                    }
                  </div>
                </div>
              </div>
            )
        })}
        {this.state.previewAdvertisers.length===0 && <p className="mb-0 text-truncate"><em>No Advertisers</em></p>}

      </React.Fragment>
    )
  }

  addAdvertisersFromCompany(idCompany){
    this.props.onSelect(idCompany)
  }

  handlePageChange = (pageNumber) => {
    let offset = pageNumber-1

    this.setState({
        isLoading: true
    },()=>setTimeout(this.handlePageChangeCallBack, 300, pageNumber, offset));
  }

  handlePageChangeCallBack(pageNumber, offset){
      this.setState({
          isLoading: false,
          activePage: pageNumber,
          offsetQuery: offset,
      })
  }

  toggleModalUsersAssign = (companyId = null) => {
    this.setState(state => ({
      isOpenModal: !state.isOpenModal,
      companyId
    }), () => this.getUsersByCompanyId(this.state.companyId))
  }

  getUrlLogoById(id){
    return companyService.getUrlLogoById(id)
  }

  getUsersByCompanyId = (id) => {
    if(id !== null){
      userService.getUsersByCompanyId(id).then(
        user => {
          this.setState({selectedUsers: user})
        }
      )
    }
    else{
      this.setState({selectedUsers: []})
    }
  }

  onCloseUserAssign = () => {
    this.toggleModalUsersAssign(null)
  }

  onSaveUserAssign = (moveKeys) => {
    const {companyId} = this.state
    const user = { companyId }

    moveKeys.map( (userId, index) => {
      setTimeout(
        () => userService.updateUser(userId, user),
        150 * index
      )
    })

    // Refresh workaround
    setTimeout(
      this.getAllCompany,
      (moveKeys.length * 150) + 1000
    )

    this.toggleModalUsersAssign(null)

  }

  render(){
    const {tab, editCompanyId, isCloneRequest, selectedUsers, isOpenModal} = this.state
    let {groupList} = this.state
    groupList = groupList.slice(
      this.state.itemsCountPerPage*this.state.offsetQuery,
      this.state.itemsCountPerPage*(this.state.offsetQuery+1))

    const modalUsersAssign = (
      <React.Fragment>
        <Modal size="lg" unmountOnClose={false} isOpen={isOpenModal} toggle={()=>this.toggleModalUsersAssign(null)}>
          <ModalHeader className="p-3" toggle={()=>this.toggleModalUsersAssign(null)}>Assign users</ModalHeader>
          <ModalBody className="pt-0">
            <div className="p-4">
              {isOpenModal && <CompanyTransferUsers selected={selectedUsers} onSave={this.onSaveUserAssign} onClose={this.onCloseUserAssign} />}
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )

    const getAvatarsByUsers = users => {
      if(users.length > 0){
        return (
          <Avatar.List stacked>
            {
              users.map((item, index) => {
                let abbrName = 'U';
                if(item.name && item.surname){
                  abbrName = item.name.slice(0, 1).toUpperCase() + item.surname.slice(0, 1).toUpperCase();
                }
                const maxAvatar = 3
                if(index < maxAvatar){
                  return (
                    item.avatarUrl ? <Avatar imageURL={companyService.getUrlLogoById(item.avatarUrl)}/>
                    : <Avatar color={userService.getColorByType(item.type)}>{abbrName}</Avatar>
                  )
                }
                else if(index === maxAvatar) {
                  return <Avatar>+{users.length - maxAvatar}</Avatar>
                }
              })
            }
          </Avatar.List>
        )
      }
      else{
        return <Tag>No users</Tag>
      }
    }

      return(
        <div className="tabs-company-manage">
          <Button.List>
            <Button color="link" size="lg" className={tab==="Open" && "btn-tab-active"} onClick={()=>this.setTabToShow('Open')}>Open</Button>
            <Button color="link" size="lg" className={tab==="New" && "btn-tab-active"} onClick={()=>this.setTabToShow('New')}>New</Button>
            <Button color="link" size="lg" className={tab==="Edit" && "btn-tab-active"} disabled={!this.state.isEditRequest} onClick={()=>this.setTabToShow('Edit')}>Edit</Button>
          </Button.List>
          <Tabs initialTab={tab} key={tab+'-'+editCompanyId}>
            <Tab title="Open">
              <Dimmer active={this.state.isLoading} loader={this.state.isLoading}>
                <Table striped={true} className="table-transition-height">
                    <Table.Header>
                      <Table.ColHeader>Logo</Table.ColHeader>
                      <Table.ColHeader>Company Name</Table.ColHeader>
                      <Table.ColHeader>Users</Table.ColHeader>
                      <Table.ColHeader alignContent="right">
                        Action{' '}<Button className="ml-1" color="link" icon="refresh-ccw" size="sm" onClick={this.getAllCompany}/>
                      </Table.ColHeader>
                    </Table.Header>
                    <Table.Body>
                      {groupList.length>0
                        ? groupList.map((item) => {
                            return(
                              <>
                                <Table.Row className={(this.state.previewId !== null && this.state.previewId === item.id) && "tr-transition border border-primary"}>
                                  <Table.Col>
                                    <img src={item.logoUrl ? this.getUrlLogoById(item.logoUrl) : LogoDefault} alt={item.name} className="h-5"/>
                                  </Table.Col>
                                  <Table.Col>
                                    <div className={(this.state.previewId !== null && this.state.previewId === item.id) ? "font-weight-bold mb-2" : "mb-1"}>{item.name}</div>
                                  </Table.Col>
                                  <Table.Col className="pt-3">
                                    {item.users ? getAvatarsByUsers(item.users) : <Dimmer active={true} loader={true}>-</Dimmer>}
                                  </Table.Col>
                                  <Table.Col alignContent="right">
                                    <Button.List className="d-inline-flex">
                                      <Button color="info" title="Edit" outline size="sm" icon="edit" onClick={()=>this.editCompany(item.id)}></Button>
                                      <Button color="primary" title="Assign users" outline size="sm" icon="users" onClick={()=>this.toggleModalUsersAssign(item.id)}></Button>
                                      {/* <Button color="info" title="Clone" outline size="sm" icon="copy" onClick={()=>this.cloneCompany(item.id)}></Button> */}
                                      {
                                        this.props.onSelect &&
                                        <Button color="primary" title="Add to search" outline size="sm" icon="plus" onClick={()=>this.addAdvertisersFromCompany(item.id)}/>
                                      }
                                      <Popconfirm placement="top" title="Are you sure you want to delete it?" onConfirm={()=>this.deleteCompany(item.id)} okText="Delete" cancelText="No">
                                        <Button color="danger" title="Delete" outline size="sm" icon="trash"></Button>
                                      </Popconfirm>
                                    </Button.List>
                                  </Table.Col>
                                </Table.Row>
                                { // Preview Advertisers
                                  (this.state.previewId !== null && this.state.previewId === item.id) &&
                                  <Table.Row>
                                    <Table.Col colSpan={5}>
                                      {this.getCompanyListPreview()}
                                    </Table.Col>
                                  </Table.Row>
                                }
                              </>
                            )})
                        : <p className="p-2"><em>No Company found</em></p>
                      }
                    </Table.Body>
                </Table>
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.itemsCountPerPage}
                  totalItemsCount={this.state.totalItemsCount}
                  maxPageRangeDisplayed={this.state.maxPageRangeDisplayed}
                  handlePageChange={this.handlePageChange}
                />
              </Dimmer>
            </Tab>

            <Tab title="New">
              <CompanyManageForm
                isEditRequest={isCloneRequest}
                groupId={null}
                groupName={isCloneRequest && ('Copy of ' + this.state.cloneCompanyName)}
                advertisers={isCloneRequest && this.state.cloneAdvertisers}
                companyLogoUrl={this.state.cloneCompanyLogoUrl}
                resetForm={this.resetFormClone}
                callbackOnSave={this.props.onSelect ? this.props.onSelect : null}
              />
            </Tab>

            {
              this.state.isEditRequest &&
              <Tab title="Edit">
                <CompanyManageForm
                  isEditRequest={this.state.isEditRequest}
                  companyId={this.state.editCompanyId}
                  companyName={this.state.editCompanyName}
                  companyAdvertisers={this.state.editCompanyAdvertisers}
                  companyLogoUrl={this.state.editCompanyLogoUrl}
                  resetForm={()=>this.setTabToShow('Open')}
                  callbackOnSave={this.props.onSelect ? this.props.onSelect : null}
                />
              </Tab>
            }
          </Tabs>

          {modalUsersAssign}
        </div>
      )
  }
}

export default CompanyManage;
