// @flow

import { Result } from "antd";
import * as React from "react";

type Props = {||};

function Error404(props: Props): React.Node {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
    />
  );
}

export default Error404;
