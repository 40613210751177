// @flow

import * as React from "react";
import {Redirect} from "react-router-dom";

import {
  Page,
  Card,
  Button,
  Badge,
  Dropdown,
  Icon,
  Dimmer,
  Form,
  Alert
} from "tabler-react";

import SiteWrapper from "../SiteWrapper.react";
import NavBarFilters from "./Filters/NavBarFilters.react";
import PlannerResultList from "./Planner.ResultList.react";
import iconPOI from "../components/Map/icons/poi.png"

import _ from "lodash"

import {searchService, imageService, audienceService} from "../_services"

import "./Planner.scss";
import 'react-toastify/dist/ReactToastify.css'
import { message } from "antd";

class PlannerPlan extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            id: props.match ? props.match.params.id : props.id,
            idKinSearch: null,
            name: null,
            size: null,
            owner: null,
            results: null,
            distance: 0,
            createdAt: null,
            updatedAt: null,
            lat: "",
            lng: "",
            radius: "",
            POIs: [],
            poiFiltered: [],

            poiFilters: null,
            geoFilters: null,
            mediaOfferFilters: null,

            proximityMode: "radius",
            travelType: "time",
            travelMode: "drive",
            travelTraffic: "approximated",
            travelRouteType: "balanced",

            idsKinFace: [],
            assets: [],
            assetsAll: [],
            audiences: null,
            audienceFilter: null,
            loading: true,
            isNewPlan: false,
            jsonLoading: false,
            xlsLoading: false,
            xlsError: null,
            xlsRequesting: false,
            xlsEnabled: false,
            isTooMuch: false,
            tabActive: 0,
            prevTabActive: null,
            isCollapsed: false,
            zoom: 6,
            isKineticUser: true
        };
        this.onChangeSideBar = this.onChangeSideBar.bind(this);
        this.passPropsFiltersPOI = this.passPropsFiltersPOI.bind(this);
        this.callExportXls = this.callExportXls.bind(this)
        this.callSearchJSON = this.callSearchJSON.bind(this)
        this.handleXlsLoaded = this.handleXlsLoaded.bind(this)
        this.handleXlsEnabled = this.handleXlsEnabled.bind(this)
        this.onClickPOIFilter = this.onClickPOIFilter.bind(this)
        this.handleCollapseResults = this.handleCollapseResults.bind(this)
    }

    componentWillMount(){
      this.getLocalUserInfo()
    }

    componentDidMount(){
      this.readSearch(this.state.id)
    }

    getLocalUserInfo(){
      if(localStorage.getItem("type") === "admin" || localStorage.getItem("type") === "user" || localStorage.getItem("type") == "companyleader" || localStorage.getItem("type") == "companyuser" || this.props.readOnly){
        this.setState({
          isKineticUser: true
        })
      }
      else{
        this.setState({
          isKineticUser: false,
        })
      }
    }

    readSearch(id){
        searchService.readSearch(id).then(
            result => {
                let {
                  idKinSearch,
                  audienceId,
                  owner,
                  name,
                  size,
                  results,
                  distance,
                  createdAt,
                  updatedAt,
                  poiFilters,
                  geoFilters,
                  mediaOfferFilters,
                  searchQuery,
                  area: polygons,
                  proximityMode,
                  travelType,
                  travelMode,
                  travelTraffic,
                  travelRouteType,
                } = result

                const resultsMapped = !results ? [] :results.map((item, index) => {
                    return item.asset
                })

                const idsKinFace = this.mapIdKinFacesWithParents(resultsMapped);

                this.setState({
                  idKinSearch,
                  owner,
                  name,
                  size,
                  assets: resultsMapped,
                  assetsAll: resultsMapped,
                  distance,
                  createdAt,
                  updatedAt,
                  poiFilters,
                  geoFilters,
                  idsKinFace,
                  mediaOfferFilters,
                  searchQuery,
                  polygons,
                  proximityMode: proximityMode ? proximityMode : "radius",
                  travelType: travelType ? travelType : "time",
                  travelMode: travelMode ? travelMode : "drive",
                  travelTraffic: travelTraffic ? travelTraffic : "approximated",
                  travelRouteType: travelRouteType ? travelRouteType : "balanced",
                  error: null,
                  loading: false,
                  isNewPlan: (size === null)
                },
                  // init poi Filtered
                  () => this.setState({
                    poiFiltered: this.getPOIDistinct().map((item, index) => { return item.idKinPoi })
                  }, () => this.getIndexesAudienceByIds(idsKinFace, audienceId))
                )
            },
            error => {
                this.setState({error:true})
                console.error(error);
            }
        );
    }

    mapIdKinFacesWithParents = (resultsMapped) => {
      const idsKinFace = resultsMapped.map((item, index) => {
        if(item.parent){
          return item.parent
        }
        return item.idKinFace;
      })
      return idsKinFace;
    }

    getIndexesAudienceByIds = (ids, audienceId) => {
      const {assets} = this.state

      if(!audienceId){
        console.log('No Audience filter selected!')
        const assetsWithAudienceNull = assets.map(a => {
          a.index = null;
          return a;
        })
        this.setState({
          audiences: [],
          assets: assetsWithAudienceNull
        })
        return null;
      }

      this.getAudienceDetails(audienceId);

      audienceService.getIndexesAudienceByIds(ids, audienceId, 'ooh').then(
        result => {
          const assetsWithAudience = assets.map(a => {
            const audienceFound = result.find(r => a.parent ? (r.faceId===a.parent) : (r.faceId === a.idKinFace))
            a.index = audienceFound ? audienceFound.index : null;
            a.isIndexParent = (a.parent !== null);
            return a;
          })

          this.setState({
            assets: assetsWithAudience,
            audiences: result
          })
        },
        error => {
          message.error('Error: ' + error);
          const assetsWithAudienceNull = assets.map(a => {
            a.index = null;
            return a;
          })
          this.setState({
            audiences: [],
            assets: assetsWithAudienceNull
          })
        }
      )
    }

    getAudienceDetails = (audienceId) => {
      audienceService.getAudienceDetailsById(audienceId).then(
        result => {
          this.setState({
            audienceFilter: result
          })
        },
        error => {
          message.error('Error: ' + error);
        }
      )
    }

    onClickPOIFilter(item, empty = false){
        const {poiFiltered} = this.state
        let poiToFilter = [];

        if(!empty){
          poiToFilter = this.getPOIDistinct().map((item, index) => { return item.idKinPoi }) // init poi filtered con tutti i pois
          if(item!==null){
              if(poiFiltered.indexOf(item) === -1){
                poiToFilter = [...poiFiltered, item]
              }
              else{
                poiToFilter = poiFiltered.filter(el => el !== item)
              }
          }
        }

        this.setState((state)=>({
            poiFiltered: poiToFilter,
            //TO DO ....
            // jsonLoading: true,
            prevTabActive: state.tabActive,
            tabActive: 0,
        }),
          ()=> this.filterList('poi', this.state.poiFiltered)
        )
    }

    filterList(type, ids){
        let {assetsAll} = this.state
        let assets
        switch(type){
            case 'poi':
                assets = assetsAll.filter(function(item) {
                    let condPOI = ids.indexOf(item.poi.idKinPoi) !== -1
                    return condPOI
                })
                break;
            default:
                assets = assetsAll
        }
        this.setState((state) => ({
            assets,
            tabActive: state.prevTabActive,
        }),()=>setTimeout(() => {
            this.setState({
                jsonLoading: false
            });
        }, 1000))
    }

    getitemsFilterPOI(){
        const POIs = this.getPOIDistinct()
        const {poiFiltered} = this.state
        const isAllActive = poiFiltered !== null && poiFiltered.length === POIs.length
        const isAllDisactive = poiFiltered === null || poiFiltered.length === 0

        const itemFilterOptions = (
          <React.Fragment>
            <Dropdown.Item className="link-nopointer">
              <div className={"row align-items-center " + (isAllActive ? "text-success" : "text-dark")} onClick={() => this.onClickPOIFilter(null)}>
                  <div className="col-1">
                      <span className="align-middle fa-lg line-height-2">
                          <Icon name="check-square"/>
                      </span>
                  </div>
                  <div className="col-10">
                      <strong className="line-height-2">Select All</strong>
                  </div>
                  <div className="col-1 p-0 text-center">
                      <span className="align-middle fa-lg line-height-2 text-success">
                          {isAllActive && <Icon name="check" />}
                      </span>
                  </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Item className="link-nopointer">
              <div className={"row align-items-center " + (isAllDisactive ? "text-danger" : "text-dark")} onClick={() => this.onClickPOIFilter(null, true)}>
                  <div className="col-1">
                      <span className="align-middle fa-lg line-height-2">
                          <Icon name="x-square"/>
                      </span>
                  </div>
                  <div className="col-10">
                      <strong className="line-height-2">Deselect All</strong>
                  </div>
                  <div className="col-1 p-0 text-center">
                      <span className="align-middle fa-lg line-height-2">
                          {isAllDisactive && <Icon name="check" />}
                      </span>
                  </div>
              </div>
            </Dropdown.Item>
            <Dropdown.ItemDivider/>
          </React.Fragment>
        )

        const itemPOIs = POIs.map((item, index) => {
            const isActive = poiFiltered.indexOf(item.idKinPoi) !== -1
            const isDisabled = poiFiltered === null || poiFiltered.length <= 0
            const {marker} = item
            return (
              <React.Fragment>
                <Dropdown.Item className={"link-nopointer " + (index%2 ? "bg-light" : "bg-white")}>
                  <div className={"row align-items-center " + (isActive ? "text-primary" : isDisabled ? "text-dark" : "text-muted")}>
                      <div className="col-1 align-self-center text-center">
                        <Form.Group className="m-0">
                            <Form.Checkbox
                              label={' '}
                              checked={isActive}
                              onChange={() => this.onClickPOIFilter(item.idKinPoi)}
                            />
                        </Form.Group>
                      </div>
                      {/* <div className="col-1" onClick={() => this.onClickPOIFilter(item.idKinPoi)}>
                        <img className="img-fluid" src={marker ? marker.fileImgix ? marker.fileImgix : imageService.getUrlMarkerIcon(marker) : iconPOI} alt={'poi'}/>
                      </div> */}
                      <div className="col-11" onClick={() => this.onClickPOIFilter(item.idKinPoi)}>
                        <strong>{item.name}</strong>
                        <br/>
                        <div className="text-truncate">
                            <strong>{item.comune}</strong>{' - ' + item.address}
                        </div>
                      </div>
                  </div>
                </Dropdown.Item>
              </React.Fragment>
            )
        })

        // const itemsFilterPOI = _.union(itemFilterOptions, itemPOIs);
        const itemsFilterPOI = (
          <React.Fragment>
            {itemFilterOptions}
            {itemPOIs}
          </React.Fragment>
        )

        if(this.state.countPOIs != POIs.length){
            this.setState((state)=>({
                countPOIs: POIs.length
            }))
        }

        return itemsFilterPOI
    }

    getPOIDistinct(){
        const {assetsAll} = this.state
        const POIs = _.uniqWith(_.map(assetsAll, 'poi'), _.isEqual);
        return _.compact(POIs);
    }

    onChangeSideBar(tabActive){
        this.setState({
            tabActive
        })
    }

    passPropsFiltersPOI(assets, size, isTooMuch, distance, audienceFilter, polygons = null){
      const idsKinFace = this.mapIdKinFacesWithParents(assets);

      this.setState({
        assets,
        assetsAll: assets,
        size,
        polygons,
        isTooMuch,
        isNewPlan: size === null,
        tabActive: 0,
        distance,
        audienceFilter
      }, () => this.getIndexesAudienceByIds(idsKinFace, audienceFilter && audienceFilter.id))
      
      this.handleJSONLoaded()
    }

    callExportXls(){
        this.setState({
            xlsLoading: true
        },()=>this.setState({xlsRequesting: true}))
    }

    callSearchJSON(){
      this.setState({
          jsonLoading: true
      },()=>this.setState({jsonRequesting: true}))
    }

    handleXlsLoaded(){
        this.setState({
            xlsRequesting: false,
        },()=>this.setState({xlsLoading: false}))
    }

    handleJSONLoaded(){
        this.setState({
          jsonRequesting: false,
        },()=>this.setState({jsonLoading: false}))
    }

    handleXlsEnabled(xlsEnabled){
        this.setState({
            xlsEnabled
        })
    }

    handleCollapseResults(isCollapsed){
      this.setState({isCollapsed})
    }

    render() {

      if(this.state.error || !this.state.isKineticUser){
        return <Redirect
            to={{ pathname: "/404", state: { from: this.props.location } }}
        />
      }
      const options = (
          <React.Fragment>
              {!this.props.readOnly && <Button.List>
                  {/* <Button color="link" RootComponent="a" href="/planner/plan">
                      Back to Planner
                  </Button> */}
                  {/* <Button color="primary" loading={this.state.jsonLoading} onClick={this.callSearchJSON}>Search</Button> */}
                  <Button color="primary" icon="download" loading={this.state.xlsLoading} disabled={!this.state.xlsEnabled} onClick={this.callExportXls}>Export in Xls</Button>
              </Button.List>}
          </React.Fragment>
      );

      const title = (readOnly = false) => (
          <React.Fragment>
              <h1 className="page-title mb-0 mt-0 p-0">{this.state.name}</h1>
              {!readOnly && <h4><small className="mb-0 mt-0 p-0">{!this.state.isNewPlan ? ("Frames: " +this.state.size) : <Badge color="success">NEW PLAN</Badge> } - Owner: {this.state.owner} - Created: {this.state.createdAt} - Last Update: {this.state.updatedAt}</small></h4>}
          </React.Fragment>
      )

      const {poiFiltered, poiFilters} = this.state
      const itemsFilterPOI = this.getitemsFilterPOI();

      const {readOnly} = this.props;

      return (
          <SiteWrapper {...this.state} noFooter={true} readOnly={readOnly}>
              <Page.Content className="page-content-wide page-content-wide-fluid-s planner-page">
                  <Dimmer active={this.state.loading} loader={this.state.loading}>
                      <Page.Header title={title(readOnly)} options={options}/>
                      <div className="row">
                        <div className="col">
                          <div className="z-index-1">
                            {!readOnly && <NavBarFilters
                              id={this.state.id}
                              handleCollapseResults={this.handleCollapseResults}
                              handleStateAssets={this.passPropsFiltersPOI}
                              xlsRequesting={this.state.xlsRequesting}
                              handleXlsLoaded={this.handleXlsLoaded}
                              handleXlsEnabled={this.handleXlsEnabled}
                              POIs={this.state.poiFilters}
                              radius={this.state.distance}
                              // geoFilters={this.state.geoFilters}
                              // mediaOfferFilters={this.state.mediaOfferFilters}
                              travelMode={this.state.travelMode}
                              travelRouteType={this.state.travelRouteType}
                              travelType={this.state.travelType}
                              travelTraffic={this.state.travelTraffic}
                              proximityMode={this.state.proximityMode}
                              polygons={this.state.polygons}
                              audienceFilter={this.state.audienceFilter}
                              searchQuery={this.state.searchQuery}
                              isNewPlan={this.state.size===null}
                            />}
                          </div>
                        </div>
                      </div>
                      <div className="row" hidden={(this.state.isCollapsed || this.state.size===null)}>
                        <div className="col">
                            <Card className="mb-0 card-planner-plan-results">
                                <Card.Header>
                                    <Card.Title className="font-weight-600">
                                      {"Your search results: " + (this.state.assetsAll.length > 0 ? this.state.assetsAll.length : "-")} frames {this.state.audienceFilter && ("| " + this.state.audienceFilter.label) }
                                      {this.state.assetsAll.length >= 13000 &&
                                        <span class="text-warning pl-2">
                                          (Notice: The search may not be saved as it is greater than 13000 frames.)
                                        </span>
                                      }
                                    </Card.Title>
                                    <Card.Options>
                                          <div className="row">
                                              <div className="col">
                                                  <Dimmer className="text-right" active={this.state.jsonLoading} loader={this.state.jsonLoading}>
                                                      {
                                                          (this.state.countPOIs>1) &&
                                                          <Dropdown
                                                            className={"planner-results-filter ml-2 " + (poiFiltered===null ? "text-secondary" : "text-primary")}
                                                            key="1"
                                                            type="primary"
                                                            color={(poiFiltered===null ? "secondary" : "secondary")}
                                                            disabled={this.state.countPOIs < 2}
                                                            icon="filter"
                                                            size="sm"
                                                            triggerContent={poiFiltered!==null && <span className="badge badge-primary badge-pill font-weight-bold">{this.state.assets.length}</span>}
                                                            // itemsObject={itemsFilterPOI}
                                                            items={itemsFilterPOI}
                                                          />
                                                      }
                                                  </Dimmer>
                                              </div>
                                              <div className="col text-right">
                                                  <Button.List>
                                                      <Button color={this.state.tabActive===0 ? "primary" : "secondary"} icon="list" active={this.state.tabActive === 0} onClick={() => this.onChangeSideBar(0)}>
                                                          LIST
                                                      </Button>
                                                      <Button color={this.state.tabActive===1 ? "primary" : "secondary"} icon="map" active={this.state.tabActive === 1} onClick={() => this.onChangeSideBar(1)}>
                                                          MAP
                                                      </Button>
                                                  </Button.List>
                                              </div>
                                          </div>
                                    </Card.Options>
                                </Card.Header>
                                <Card.Body className={"p-0 " + (this.state.tabActive===1 ? "card-body-map" : "")}>
                                    <Dimmer active={this.state.jsonLoading} loader={this.state.jsonLoading}>
                                        <PlannerResultList
                                          assets={this.state.assets}
                                          isTooMuch={this.state.isTooMuch}
                                          size={this.state.size}
                                          tabActive={this.state.tabActive}
                                          countPOIs={this.state.countPOIs}
                                          distance={this.state.distance}
                                          polygons={this.state.polygons}
                                          audienceFilter={this.state.audienceFilter}
                                        />
                                    </Dimmer>
                                </Card.Body>
                            </Card>
                        </div>
                      </div>
                  </Dimmer>
              </Page.Content>
          </SiteWrapper>
        )
    }
}


export default PlannerPlan;
