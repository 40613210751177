// @flow

import * as React from "react";

import {
  Form,
  Alert,
  Dimmer,
  Button,
} from "tabler-react";

import "./CompanyManage.scss"
import { campaignService, companyService, statsService } from "../_services";
import { toast } from 'react-toastify';

import LogoDefault from '../static/images/kinetic.png'
import CompanyTransfer from "./CompanyTransferAdvertisers.react";

class CompanyManageForm extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      errorMessage: "",
      success: false,
      loading: false,
      companyId: null,
      companyName: '',
      companyLogoUrl: null,
      advertisersList: [],
      advertisersSelected: [],
      isEditRequest: false,
      issetFile: false,
    }
  }

  componentDidMount(){
    const {isEditRequest, companyName, companyId, companyAdvertisers, companyLogoUrl} = this.props
    if(isEditRequest){
      this.setState({isEditRequest, companyName, companyId, advertisersSelected: companyAdvertisers, companyLogoUrl})
    }

    this.setState({
      loading: true
    }, this.loadAdvertisers)
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.isEditRequest!==this.props.isEditRequest){
      const {isEditRequest, companyName, companyId, advertisers, companyLogoUrl} = nextProps
      this.setState({
        isEditRequest, companyName, companyId, advertisersSelected: advertisers, companyLogoUrl
      })
    }
  }

  loadAdvertisers(){
    campaignService.getAllAdvertisers().then(
      result => {
        this.setState({
          advertisersList: result,
          loading:false
        })
      },
      error => {
        console.error(error);
      }
    )
  }

  handleChangeLogo = (ev) => {
    this.setState({
      issetFile: ev.target.files
    })
  }

  handleChangeAdvertisers = (advertisersSelected) => {
    this.setState({
      advertisersSelected
    })
  }

  onChangeCompanyName = (ev) => {
    const {value} = ev.target
    this.setState({
      companyName: value
    })
  }

  uploadLogo = (e) => {
    e.preventDefault() // Stop form submit
    this.setState({
      buttonAssignActive: false
    })

    let formData = new FormData()
    const file = this.state.issetFile[0]

    formData.append("logo", file, file.name);
    formData.append("name", file.name);

    companyService.uploadLogo(formData).then(
        result => {
          const companyLogoUrl = result.id
          this.setState({
            companyLogoUrl
          })
          toast.success("Logo uploaded!")
        },
        error => {
          console.error(error);
        }
    );
  }

  onClickSave = (callbackAfterFinish = false) => {
    const {companyId, companyName, companyLogoUrl, advertisersSelected, isEditRequest} = this.state
    if(isEditRequest && (companyId !== null)){
      this.updateCompany(companyId, companyName, companyLogoUrl, advertisersSelected, callbackAfterFinish)
    }
    else{
      this.createCompany(companyName, companyLogoUrl, advertisersSelected, callbackAfterFinish)
    }
  }

  createCompany = (companyName, companyLogoUrl, advertisersSelected, callbackAfterFinish = false) => {
    this.setState({
      errorMessage: "",
      success: false,
      loading: true
    })

    const company = {
      name: companyName,
      logoUrl: companyLogoUrl,
      advertisers: advertisersSelected
    }

    companyService.createCompany(company).then(
      result => {
        toast.success('Company ' +companyName+ ' created successful!');
        if(callbackAfterFinish){
          const id = result[0].id ? result[0].id : null
          callbackAfterFinish(id)
        }
        else{
          this.props.resetForm();
        }
        this.setState({
          success: true,
          loading: false,
          companyName: '',
          companyLogoUrl,
          advertisersSelected: [],
        })
      },
      error => {
        toast.error("Si è verificato un errore: " + error.toString());
        this.setState({
          errorMessage: error,
          success: false,
          loading: false
        })
        return false;
      }
    );
  }

  updateCompany = (companyId, companyName, companyLogoUrl, advertisersSelected, callbackAfterFinish = false) => {
    this.setState({
      errorMessage: "",
      success: false,
      loading: true
    })

    const company = {
      name: companyName,
      logoUrl: companyLogoUrl,
      advertisers: advertisersSelected
    }

    companyService.updateCompany(companyId, company).then(
      result => {
        toast.success('Company ' +companyName+ ' saved successful!');
        if(callbackAfterFinish){
          const id = result[0].id ? result[0].id : null
          callbackAfterFinish(id)
        }
        else{
          this.props.resetForm();
        }
        this.setState({
          success: true,
          loading: false,
          companyName: '',
          companyLogoUrl,
          advertisersSelected: [],
        })
      },
      error => {
        toast.error("Si è verificato un errore: " + error.toString());
        this.setState({
          errorMessage: error,
          success: false,
          loading: false
        })
        return false;
      }
    );
  }

  getUrlLogoById(id){
    return companyService.getUrlLogoById(id)
  }

  onClickResetForm = () => {
    const {isEditRequest, companyId} = this.state
    if(isEditRequest && (companyId !== null)){
      this.props.resetForm();
    }
    else{
      this.setState({
        companyId: null,
        companyName: '',
        companyLogoUrl: null,
        advertisersSelected: [],
      })
    }
  }

  render(){
    const {companyName, advertisersList, advertisersSelected, isEditRequest, companyLogoUrl, issetFile, errorMessage} = this.state

    const buttonListSave = (
      <React.Fragment>
        <div className="row">
          <div className="col-12 text-right align-self-center">
            <Button.List>
              { isEditRequest &&
                <Button
                    color="danger"
                    onClick={this.onClickResetForm}
                    disabled={this.state.loading}
                  >
                    Cancel
                </Button>
              }
              <Button
                color="primary"
                onClick={()=>this.onClickSave(false)}
                disabled={this.state.companyName === ""}
                loading={this.state.loading}
                outline={!!this.props.callbackOnSave}
              >
                {isEditRequest ? "Save changes" : "Save"}
              </Button>{' '}
            </Button.List>
          </div>
        </div>
      </React.Fragment>
    )

    return(
      <div className="pt-2">
        <div className="row">
          <div className="col-4">
            <Form.Group
              label="Company Name"
            >
              <Form.Input className="mb-3" placeholder="Insert name" value={companyName} required onChange={this.onChangeCompanyName} />
            </Form.Group>
            <Form.Group
              label="Logo"
            >
              {companyLogoUrl && <Button className="pull-right position-absolute text-danger" color="link" icon="trash-2" onClick={()=>this.setState({companyLogoUrl: ''})} disabled={!companyLogoUrl} style={{right: "10px"}}/>}
              <div className="bg-light border py-4 mb-3 text-center">
                <img src={companyLogoUrl ? this.getUrlLogoById(companyLogoUrl) : LogoDefault} alt={companyName} className="h-8 mb-3 border bg-white p-2" style={(companyLogoUrl ? {} : {opacity: .5})}/>
                <br/>
                <span className="text-muted">{companyLogoUrl ? 'Preview logo' : 'Default logo'}</span>
              </div>

              <Form.Group>
                <div className="row">
                  <div className="col-9 pr-1">
                    <Form.FileInput accept=".png" label="Choose file png" onChange={this.handleChangeLogo}/>
                  </div>
                  <div className="col-3 pl-1">
                    <Button color="primary" block onClick={this.uploadLogo} disabled={!issetFile}>Apply</Button>
                  </div>
                </div>
                <p className="mt-2 p-2 alert alert-light">
                  Allowed extension: <code>png</code>
                  <br/>
                  Maximum size: <code>1MB</code>
                  <br/>
                  Recommended width / height: <code>532x146</code>px
                </p>
              </Form.Group>
            </Form.Group>
          </div>
          <div className="col-8">
            <div className="row">
              <div className="col-12">
                <Form.Group
                  label="Advertisers"
                >
                  <CompanyTransfer items={advertisersList} selected={advertisersSelected} onChange={this.handleChangeAdvertisers}/>
                </Form.Group>
              </div>
            </div>

          </div>
        </div>
        <Dimmer className="dimmer-opacity" active={companyName.trim()===""} loader={false}>
          <div className="row">
            <div className="col-8 offset-2 text-center">
              {
                errorMessage!=="" &&
                <Alert type="danger">
                  {errorMessage}
                </Alert>
              }
            </div>
          </div>
          {buttonListSave}
        </Dimmer>
      </div>
    )
  }

}

export default CompanyManageForm;
