
import React, { useState } from 'react';
import { Modal, Popconfirm, Tabs} from 'antd';

import {
  Button,
  Form
} from "tabler-react";
import { assetService, audienceService, doohService } from '../_services';

import {toast} from 'react-toastify'
import ButtonGroup from 'antd/lib/button/button-group';

function PanelDataTools(props) {
  const [normalizeLoading, setNormalizeLoading] = useState(false)
  const [archiveLoading, setArchiveLoading] = useState(false)
  const [reverseLoading, setReverseLoading] = useState(false)
  const [reversePostalLoading, setReversePostalLoading] = useState(false)
  const [normalizeSimsLoading, setNormalizeSimsLoading] = useState(false)
  const [syncDbLoading, setSyncDbLoading] = useState(false)

  const onNormalize = () => {
    if(!normalizeLoading){
      setNormalizeLoading(true)
      assetService.normalizeAssetPlanner().then(
        result => {
          setNormalizeLoading(false)
          toast.success('All active asset normalized!');
          console.log(result);
        },
        error => {
          setNormalizeLoading(false)
          toast.error("Error: " + error)
        }
      )
    }
  }

  const onArchiveInactive = () => {
    if(!archiveLoading){
      setArchiveLoading(true)
      assetService.archiveInactiveAssetPlanner().then(
        ({result}) => {
          setArchiveLoading(false);
          toast.success(`${result} inactive assets archived!`);
        },
        error => {
          setArchiveLoading(false)
          toast.error("Error: " + error)
        }
      )
    }
  }

  const onSyncDb = () => {
    if(!syncDbLoading){
      setSyncDbLoading(true)
      audienceService.syncDatabases().then(
        result => {
          setSyncDbLoading(false)
          toast.success('Database syncing started, you will be notified by email when the procedure is over!');
          console.log(result);
        },
        error => {
          setSyncDbLoading(false)
          toast.error("Error: " + error)
        }
      )
    }
  }

  const onReverse = () => {
    console.log("onReverse")
    if(!reverseLoading){
      setReverseLoading(true)
      assetService.parseNewReverseGeocode().then(
        result => {
          setReverseLoading(false)
          toast.success('All active asset reversed!');
          console.log(result);
        },
        error => {
          setReverseLoading(false)
          toast.error("Error: " + error)
        }
      )
    }
  }

  const onReversePostalCode = () => {
    if(!reversePostalLoading){
      setReversePostalLoading(true);
      toast.info("Reverse DOOH Postal Codes started, waiting before upload again...");
      doohService.reversePostalCodeAsset().then(
        result => {
          setReversePostalLoading(false);
          toast.success("Reverse DOOH Postal Codes successful!");
        },
        error => {
          setReversePostalLoading(false);
          toast.error("Error: " + error);
        }
      )
    }
  }

  const getCountDoohWithoutPC = () => {
    doohService.getCountAssetWithoutPostalCode().then(
      ({count}) => {
        Modal.info({
          title: 'DOOH Asset without postal code',
          content: `${count} records without postal code`,
        });
      },
      error => {
        toast.error("Error: " + error);
      }
    )
  }

  const onNormalizeSimilar = (type = "dooh") => {
    if(normalizeSimsLoading){
      return;
    }

    toast.info(`Starting normalize similar children asset ${type.toUpperCase()}!`);
    setNormalizeSimsLoading(type);

    audienceService.normalizeSimilarAssetByType(type).then(
      result => {
        setNormalizeSimsLoading(false)
        toast.success(`Asset ${type.toUpperCase()} children normalized!`);
      },
      error => {
        setNormalizeSimsLoading(false)
        toast.error("Error: " + error)
      }
    )
  }

  return (
    <Form.Group label="Advanced settings">
      <Tabs>
        <Tabs.TabPane tab="Global" key="Global">
          <div className="row">
            <div className="col-12">
              <Popconfirm title="Do you want to import Mongo collections to MySQL?" onConfirm={onSyncDb} disabled={syncDbLoading}>
                <Button color="danger" icon="database" loading={syncDbLoading}> Sync database MySQL </Button>
              </Popconfirm>
              <p className="mt-1 text-muted">This operation synchronizes MongoDB collections to MySQL tables.</p>
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="OOH" key="OOH">
          <div className="row">
            <div className="col-12">
              <Popconfirm title="Do you want launch it for OOH?" onConfirm={onNormalize} disabled={normalizeLoading}>
                <Button color="danger" loading={normalizeLoading}>Start Normalization</Button>
              </Popconfirm>
              <p className="mt-1 text-muted">This operation cleans the asset fields from double spaces and any special characters.</p>
            </div>
            <div className="col-12">
              <Popconfirm title="Do you want launch it for OOH?" onConfirm={() => onNormalizeSimilar("ooh")} disabled={normalizeSimsLoading}>
                <Button color="danger" loading={normalizeSimsLoading}>Start Normalize Children OOH</Button>
              </Popconfirm>
            </div>
            <div className="col-12">
              <p className="mt-1 text-muted">Launch this operation if you need to link similars OOH asset children by location.</p>
            </div>
            <div className="col-12">
              <Popconfirm title="Do you want launch start Reverse Geoding?" onConfirm={onReverse} disabled={normalizeSimsLoading}>
                <Button color="primary" loading={reverseLoading}>Start Reverse Geocode</Button>
              </Popconfirm>
              <p className="mt-1 text-muted">Launch this operation if you need to verify the veracity of the address fields with respect to the geographic coordinates. It acts only on records not yet reversed.</p>
            </div>
            <div className="col-12">
              <Popconfirm title="Do you want archive inactive OOH assets?" onConfirm={onArchiveInactive} disabled={archiveLoading}>
                <Button color="danger" icon="inbox" loading={archiveLoading}>Archive inactive OOH assets</Button>
              </Popconfirm>
              <p className="mt-1 text-muted">This operation moves the inactive assets to the "assetNotActive" collection.</p>
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="DOOH" key="DOOH">
          <div className="row">
            <div className="col-12">
              <Popconfirm title="Do you want launch it for DOOH?" onConfirm={() => onNormalizeSimilar("dooh")} disabled={normalizeSimsLoading}>
                <Button color="danger" loading={normalizeSimsLoading}>Start Normalize Children DOOH</Button>
              </Popconfirm>
            </div>
            <div className="col-12">
              <p className="mt-1 text-muted">Launch this operation if you need to link similars DOOH asset children by location.</p>
            </div>
            <div className="col-6">
              <Popconfirm title="Do you want launch postal reverse code for DOOH assets?" onConfirm={onReversePostalCode} disabled={reversePostalLoading}>
                <Button color="primary" loading={reversePostalLoading}>Start Reverse Postal Codes DOOH assets</Button>
              </Popconfirm>
              <p className="mt-1 text-muted">Launch this operation if you need to assign postal codes by geographic coordinates. It acts only on records without postal codes field.</p>
            </div>
              
            <div className="col-6">
              <Button color="link" onClick={getCountDoohWithoutPC}>How many records without postal code?</Button>
            </div>
          </div>
        </Tabs.TabPane>
      </Tabs>

    </Form.Group>
  )

}

export default PanelDataTools;
