// @flow

import * as React from "react";
import _ from "lodash"
import {
  Grid,
  Dimmer,
  StampCard
} from "tabler-react";

class MediaReportCounters extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            data: [],
            correct: 0,
            wrongs: 0,
            anomaly: 0,
            tracked: 0,
            total: 0,
            numberCampaigns: -1,
            correctPerc: 0,
            wrongsPerc: 0,
            anomalyPerc: 0,
            trackedPerc: 0,
            timestamp: Date.now().toString(),
            isLoading: true
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.numberCampaigns!==this.props.numberCampaigns){
            this.setState({
                numberCampaigns: nextProps.numberCampaigns,
                timestamp: Date.now().toString()
            })
        }
        if(nextProps.data!==this.props.data){
            const data = nextProps.data
            if(typeof data != "undefined"){
                let trackedPerc = data.tracked!= 0 ? ((data.tracked/data.total) * 100) : 0
                let correctPerc = data.state0!= 0 ? ((data.state0/data.tracked) * 100) : 0
                let wrongsPerc = data.state1!= 0 ? ((data.state1/data.tracked) * 100) : 0
                let anomalyPerc = (100-correctPerc-wrongsPerc)

                trackedPerc = trackedPerc!= 0 ? trackedPerc.toFixed(1).replace('.', ',') : 0
                correctPerc = correctPerc!= 0 ? correctPerc.toFixed(1).replace('.', ',') : 0
                wrongsPerc = wrongsPerc!= 0 ? wrongsPerc.toFixed(1).replace('.', ',') : 0
                anomalyPerc = anomalyPerc!= 0 ? anomalyPerc.toFixed(1).replace('.', ',') : 0

                this.setState({
                    data: data,
                    correct: data.state0,
                    wrongs: data.state1,
                    anomaly:  data.state2,
                    tracked:  data.tracked,
                    total:  data.total,
                    correctPerc: correctPerc,
                    wrongsPerc: wrongsPerc,
                    anomalyPerc: anomalyPerc,
                    trackedPerc: trackedPerc,
                    timestamp: Date.now().toString(),
                    isLoading: false
                })
            }
            else{
                this.setState({
                    data: data,
                    correct: 0,
                    wrongs: 0,
                    anomaly:  0,
                    tracked:  0,
                    total:  0,
                    correctPerc: 0,
                    wrongsPerc: 0,
                    anomalyPerc: 0,
                    trackedPerc: 0,
                    timestamp: Date.now().toString(),
                    isLoading: false
                })
            }
        }
    }

    formatNumber(num) {
        return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') : 0
    }

    render(){
        const {
            correct,
            wrongs,
            anomaly,
            tracked,
            total,
            numberCampaigns,
            correctPerc,
            wrongsPerc,
            anomalyPerc,
            trackedPerc
        } = this.state;
        return (
            <Grid.Row cards deck>
                <Grid.Col sm={12}>
                    <Dimmer active={this.state.isLoading} loader={this.state.isLoading} className="">
                        <Grid.Row  cards deck key={this.state.timestamp}>
                            <Grid.Col>
                                <Dimmer active={numberCampaigns == -1} loader={numberCampaigns == -1}>
                                    <StampCard
                                    color="info"
                                    icon="layers"
                                    header={
                                        <span>
                                        {this.formatNumber(numberCampaigns)} <br/><small>Campaigns</small>
                                        </span>
                                    }
                                    />
                                </Dimmer>
                            </Grid.Col>
                            <Grid.Col>
                                <StampCard
                                color="blue"
                                icon="map-pin"
                                header={
                                    <span>
                                    {this.formatNumber(total)} <br/><small>Total panels</small>
                                    </span>
                                }
                                />
                            </Grid.Col>
                            <Grid.Col>
                                <StampCard
                                color="blue"
                                icon="camera"
                                header={
                                    <span>
                                    {this.formatNumber(tracked)}
                                    </span>
                                }
                                footer={trackedPerc+ "% Tracked"}
                                />
                            </Grid.Col>
                            <Grid.Col>
                                <StampCard
                                color="green"
                                icon="eye"
                                header={
                                    <span>
                                        {this.formatNumber(correct)}
                                    </span>
                                }
                                footer={correctPerc+ "% Correct"}
                                />
                            </Grid.Col>
                            <Grid.Col>
                                <StampCard
                                color="red"
                                icon="eye"
                                header={
                                    <span>
                                        {this.formatNumber(wrongs)}
                                    </span>
                                }
                                footer={wrongsPerc + "%  Wrong"}
                                />
                            </Grid.Col>
                            <Grid.Col>
                                <StampCard
                                color="yellow"
                                icon="eye"
                                header={
                                    <span>
                                    {this.formatNumber(anomaly)}
                                    </span>
                                }
                                footer={anomalyPerc + "% Anomaly"}
                                />
                            </Grid.Col>
                        </Grid.Row>
                    </Dimmer>
                </Grid.Col>
            </Grid.Row>
        )
    }
}

export default MediaReportCounters;