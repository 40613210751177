// @flow

import * as React from "react";

import {Result} from 'antd';

type Props = {||};

function Error403(props: Props): React.Node {
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
    />
  );
}

export default Error403;
