import React, {useState} from 'react';
import {Card} from 'tabler-react';
import {Slider, Spin, Switch, InputNumber} from 'antd';

function MapFilterSlider(props){
  const {onChange, hideExcluded, setHideExcluded, defaultMin, defaultMax, value, count, isInsideMap = true, loading} = props
  const [min, setMin] = useState(value[0] || defaultMin || 0);
  const [max, setMax] = useState(value[1] || defaultMax || 11);

  const onChangeValues = values => {
    setMin(values[0])
    setMax(values[1])
    onChange && onChange(...values)
  };

  const onChangeMin = value => {
    if(value > max)
      return;
    setMin(value)
    onChange && onChange(value, max)
  }

  const onChangeMax = value => {
    if(value < min)
      return;
    setMax(value)
    onChange && onChange(min, value)
  }

  return (
    <Card className={(isInsideMap ? "border shadow-sm panelsmap-card" : "p-5 m-0 border-0 shadow-0")}>
      <h5 className="mb-0">Filter by index</h5>
      <Slider
        range={{ draggableTrack: true }}
        defaultValue={[defaultMin, defaultMax]}
        value={[min, max]} step={0.01}
        min={defaultMin || 0}
        max={defaultMax || 11}
        onChange={onChangeValues}
      />
      {/* <p className="text-center">{min + " - " + max}</p> */}

      <div className="row mt-1 mb-2">
        <div className="col-6 p-0">
          <InputNumber size="small" min={defaultMin || 0} max={defaultMax || 11} value={min} step={0.01} onChange={onChangeMin} style={{width: 68}} />
        </div>
        <div className="col-6 text-right">
          <InputNumber size="small" min={defaultMin || 0} max={defaultMax || 11} value={max} step={0.01} onChange={onChangeMax} style={{width: 68}} />
        </div>
      </div>

      {loading ? <Spin size="small"/> : <h4 className="mt-1">{count} found</h4>}

      <div className='row'>
        <div className='col'>
          <Switch size="small" defaultChecked className='mr-1' onChange={setHideExcluded} checked={hideExcluded}/> Hide excluded
        </div>
      </div>
    </Card>
  )
}

export default MapFilterSlider;
