import React from "react"
import cn from "classnames";
import {
  Grid,
  Card,
  Dimmer
} from "tabler-react";

const { compose, withProps } = require("recompose");

const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  StreetViewPanorama,
  OverlayView,
} = require("react-google-maps");

const containerClasses = cn("GoogleMapContainer");

const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2),
})

const defaultMapOptions = {
  linksControl: false,
  panControl: false,
  enableCloseButton: false,
  addressControl: false
};

const minimalMapOptions = {
  linksControl: false,
  panControl: false,
  enableCloseButton: false,
  addressControl: false,
  fullscreenControl: true,
  zoomControl: false,
};

const apiKey = "AIzaSyDk2tsrjgOei53e0A5X8J7nFfj-MiDymIY"
const StreetViewPanormaWithAnOverlayView = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key="+apiKey+"&v=3.exp&libraries=geometry,drawing,places",
    loadingElement : <div style={{ height: `100%`, minHeight: 'inherit', paddingTop:"20%"}}><Dimmer active loader className={"gmaps-custom-component gmaps-custom-loading"} ></Dimmer><br/></div>,
    containerElement: <div className={containerClasses} />,
    mapElement: <div style={{ height: `100%` }} />,
    // center: { lng: this.props.lng, lat: this.props.lat },
    // center: { lat: 45.44086267178177, lng: 9.2120361328125 }
  }),
  withScriptjs,
  withGoogleMap
)(props =>
    <GoogleMap defaultZoom={8} defaultPosition={{ lng: props.lng, lat: props.lat }} key={{ lng: props.lng, lat: props.lat }}>
      <StreetViewPanorama
        defaultPosition={{ lng: props.lng, lat: props.lat }}
        onStatusChanged={ev => console.log(ev)}
        options={props.minimal ? minimalMapOptions : defaultMapOptions}
        visible>
      </StreetViewPanorama>
    </GoogleMap>
);

class StreetView extends React.PureComponent {
  constructor(props){
    super(props)
    this.state = {
      lat: null,
      lng: null,
      session: "session_initial",
      loading: true
    }
  }

  componentDidMount() {
    this.delayedShowMap()
    this.setState({
      lat: this.props.lat,
      lng: this.props.lng
    })
  }

  componentWillReceiveProps(nProps){
    if( nProps.lat !== this.props.lat && nProps.lng !== this.props.lng ){
      this.setState({
        lat: nProps.lat,
        lng: nProps.lng,
        session: "session_"+nProps.lat+"-"+nProps.lng,
        loading: true
      })
      this.delayedShowMap()
    }
  }

  delayedShowMap = () => {
    setTimeout(() => {
      this.setState({ loading: false })
    }, 1000)
  }

  render() {
    const {minimal} = this.props
    if(!this.state.loading && this.state.lat !== null && this.state.lng !== null){
      return (
        <StreetViewPanormaWithAnOverlayView key={this.state.session} lat={this.state.lat} lng={this.state.lng} minimal={minimal} />
      )
    }
    else{
      return(
        // <Grid.Row cards deck>
        //   <Grid.Col sm={12}>
            <Card className={(minimal ? "map-details-marker-streetview pt-8" : "card-streetview p-8")}>
              <Dimmer active={this.state.loading} loader={this.state.loading}></Dimmer>
            </Card>
        //   </Grid.Col>
        // </Grid.Row>
      )
    }
  }
}

export default StreetView;
