// @flow

import * as React from "react";

import {
    Grid,
    Icon,
    Dropdown,
    Form,
    Button
} from "tabler-react";

import {Checkbox, Drawer} from 'antd'
import {CarOutlined} from '@ant-design/icons';

import _ from "lodash"

import { geoIsolineService, poiService, searchService } from "../../_services";

import PoiManage from "./PoiManage.react"
import {toast} from "react-toastify"
import ButtonGroup from "antd/lib/button/button-group";

class PoiFilter extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      lat: "",
      lng: "",
      radius: 0,
      POIs: [],
      assets: [],
      size: 0,
      groupName: "",
      defaultRadius: 0,
      defaultTravelRadius: 10,
      proximityMode: "radius",
      travelType: "time",
      travelMode: "drive",
      travelTraffic: "approximated",
      travelRouteType: "balanced",
      polygons: [],
      isTooMuch: false,
      jsonLoading: false,
      xlsLoading: false,
      xlsError: null,
      isOpenModal: false,
      tabModal: "Open",
      groupList: [],
      issetFile: false,
      errorMessage: "",
      locationSearch: null
    };

    this.handleChangeRadius = this.handleChangeRadius.bind(this);
    this.toggleModalPOI = this.toggleModalPOI.bind(this);
    this.emptyPOIs = this.emptyPOIs.bind(this);
    this.removeChild = this.removeChild.bind(this);
    this.addPOIByGroup = this.addPOIByGroup.bind(this);
    this.onChangeValueByfield = this.onChangeValueByfield.bind(this);
  }

  componentDidMount(){
    const {
      POIs,
      radius,
      proximityMode,
      travelType,
      travelMode,
      travelTraffic,
      travelRouteType
    } = this.props

    this.setState((state)=>({
      POIs,
      defaultRadius: state.defaultRadius === 0 ? radius : state.defaultRadius,
      radius,
      proximityMode,
      travelType,
      travelMode,
      travelTraffic,
      travelRouteType,
    }))
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.proximityMode !== this.props.proximityMode){
      const proximityMode = nextProps.proximityMode
        this.setState({
          proximityMode
        })
        this.props.handleChangeTravelProp("proximityMode", proximityMode)
    }
    if(nextProps.travelType !== this.props.travelType){
        const travelType = nextProps.travelType
        this.setState({
          travelType
        })
        this.props.handleChangeTravelProp("travelType", travelType)
    }
    if(nextProps.travelMode !== this.props.travelMode){
        const travelMode = nextProps.travelMode
        this.setState({
          travelMode
        })
        this.props.handleChangeTravelProp("travelMode", travelMode)
    }
    if(nextProps.travelTraffic !== this.props.travelTraffic){
        const travelTraffic = nextProps.travelTraffic
        this.setState({
          travelTraffic
        })
        this.props.handleChangeTravelProp("travelTraffic", travelTraffic)
    }
    if(nextProps.travelRouteType !== this.props.travelRouteType){
        const travelRouteType = nextProps.travelRouteType
        this.setState({
          travelRouteType
        })
        this.props.handleChangeTravelProp("travelRouteType", travelRouteType)
    }

    if(nextProps.polygons !== this.props.polygons){
        const polygons = nextProps.polygons
        this.setState({
          polygons
        })
        this.props.handleChangePolygons(polygons)
    }
    if(nextProps.POIs !== this.props.POIs){
        const POIs = nextProps.POIs
        this.setState({
          POIs
        })
        this.props.handleChangePOIs(POIs)
        this.props.handleStateCount(POIs.length)
    }
    if(nextProps.radius !== this.props.radius){
      const radius = nextProps.radius
      this.setState((state)=>({
        defaultRadius: (state.defaultRadius === 0 || nextProps.radius === 0) ? radius : state.defaultRadius,
        radius
      }))
      this.props.handleChangePOIRadius(radius)
    }
  }

  checkValidationForm(){
    const {POIs, radius} = this.state;
    let condLatLng = POIs.length;
    let condRadius = radius>=1 && radius<=10000;

    return condLatLng && condRadius;
  }

  handleChangeLat(ev){
    let lat = ev.target.value
    this.setState({ lat });
  }

  handleChangeLng(ev){
    let lng = ev.target.value
    this.setState({ lng });
  }

  handleChangeRadius(ev){
    let radius = ev.target.value
    this.setState({ radius });
    this.props.handleChangePOIRadius(radius)
    return radius
  }

  handleChangePolygons(polygons){
    this.setState({ polygons });
    this.props.handleChangePolygons(polygons)
    return polygons
  }

  emptyPOIs(ev){
    this.props.handleChangePOIs([])
    this.setState({
      POIs: [],
    },()=>this.props.handleStateCount(0))
  }

  removeChild(el){
    let POIs = this.state.POIs.filter((item)=>item.id !== el)
    this.props.handleChangePOIs(POIs)
    this.setState({
      POIs
    }, ()=>this.props.handleStateCount(this.state.POIs.length))
  }

  toggleModalPOI(tabModal) {
    this.setState(prevState => ({
      isOpenModal: !prevState.isOpenModal,
      tabModal
    }));
  }

  callbackCreatePOIs(){
    let {POIs} = this.state
    POIs = this.removeDuplicate(POIs)
    this.props.handleStateCount(POIs.length)
    this.props.handleChangePOIs(POIs)
  }

  removeDuplicate(elements){
    const elementsNoDuplicates = _.uniqBy(elements, 'id');
    const hasDuplicates =  elements.length !== elementsNoDuplicates.length
    if(hasDuplicates){
      toast.warning('Removed POIs duplicate')
    }
    return elementsNoDuplicates
  }

  addPOIByGroup(id){
    if(id){
        poiService.readPOIsByGroup(id).then(
            result => {
              toast.info('POIs added to plan!');
              this.setState(prevState => ({
                  isOpenModal: false,
                  POIs: [...prevState.POIs, ...result.pois]
              }), ()=> this.callbackCreatePOIs())
            }
        )
    }
  }

  onChangeValueByfield(field, value){
    this.setState({
      [field]: value
    })

    switch (field) {
      case "radius":
      case "proximityMode":
      case "travelType":
      case "travelMode":
      case "travelTraffic":
      case "travelRouteType":
          this.props.handleChangeTravelProp(field, value);
        break;
        
      default:
        break;
    }
  }

  render(){
      const hasMoreThanOnePOITravel = (this.state.proximityMode === "travel" && this.state.POIs.length > 1);

      const selectedPOIs = (
        <React.Fragment>
            {this.state.POIs.length===0 ?
              <Dropdown.Item><em>No POIs chosen</em></Dropdown.Item>
              :
              <Dropdown.Item icon="trash" onClick={this.emptyPOIs}>Deselect all</Dropdown.Item>
            }
        </React.Fragment>
      )

      const dropdownSelected = (
        <React.Fragment>
          <div className="p-3">
            <strong className={hasMoreThanOnePOITravel && "text-danger"}>
              <Icon name="map-pin"/> {"Selected " + this.state.POIs.length + " POIs"}
              {
                hasMoreThanOnePOITravel &&
                <span className="ml-4 alert alert-danger">Proximity Travel Mode with multiple POIs is not available yet!</span>
              }
            </strong>
          </div>
          {/* <Dropdown
            type="button"
            className="dropdown-block dropdown-menu-overflow-y p-3"
            color="secondary"
            outline
            arrow
            icon="map-pin"
            triggerContent={"Selected " + this.state.POIs.length + " POIs"}
            items={
              selectedPOIs
            }
          /> */}
        </React.Fragment>
      )

      const modalManagePOI = (
        <React.Fragment>
          <Drawer
            title="POI Manager"
            placement="right"
            closable={true}
            onClose={()=>this.toggleModalPOI('New')}
            visible={this.state.isOpenModal}
            width={"100vw"}
            // getContainer={false}
          >
            <PoiManage tab={this.state.tabModal} onSelect={this.addPOIByGroup} />
          </Drawer>
        </React.Fragment>
      )

      const buttonTravelModeProps = (type) => {
        return {
          color: (this.state.travelMode === type) ? "primary" : "secondary",
          className: "text-center",
          onClick: () => this.onChangeValueByfield("travelMode", type),
        }
      }

      return(
        <Grid.Row>
          <Grid.Col lg={12}>
            {modalManagePOI}
            <Grid.Row className="mt-3">
              <Grid.Col sm="3">
                <div className="row">
                  <div className="col-12 mb-1 row p-3">
                    <div className="col-6">
                      <Button color="primary" block className="text-center" onClick={()=>this.toggleModalPOI('New')}>New</Button>
                    </div>
                    <div className="col-6">
                      <Button color="secondary" block className="text-center mr-1" onClick={()=>this.toggleModalPOI('Open')}>Open</Button>
                    </div>
                  </div>
                </div>
              </Grid.Col>
              <Grid.Col sm="9">
                <div className="row">
                  <div className="col-12 mb-1 row p-3">
                    {dropdownSelected}
                  </div>
                </div>
              </Grid.Col>
              
              {/* HIDDEN TYPE
              {
                this.state.proximityMode === "travel" &&
                <Grid.Col sm="3" className="align-self-center">
                  <strong>Choose travel type</strong>
                  <ButtonGroup className="ml-4">
                    <Button color={this.state.travelType === "time" ? "primary" : "secondary"} className="text-center" onClick={()=>this.onChangeValueByfield("travelType", "time")}>Time</Button>
                    <Button color={this.state.travelType === "distance" ? "primary" : "secondary"} className="text-center" onClick={()=>this.onChangeValueByfield("travelType", "distance")}>Distance</Button>
                  </ButtonGroup>
                </Grid.Col>
              } */}
            </Grid.Row>

            <Grid.Row>
              <Grid.Col sm={this.state.proximityMode === "travel" ? "3" : "6"} className="align-self-center">
                <strong>Choose proximity mode</strong>
                <ButtonGroup className="ml-4">
                  <Button
                    color={this.state.proximityMode === "radius" ? "primary" : "secondary"}
                    className="text-center"
                    onClick={()=>{
                      this.onChangeValueByfield("radius", 50)
                      this.onChangeValueByfield("proximityMode", "radius")
                    }}
                  >
                    Radius
                  </Button>
                  <Button
                    color={this.state.proximityMode === "travel" ? "primary" : "secondary"}
                    className="text-center"
                    onClick={()=>{
                      this.onChangeValueByfield("radius", 10)
                      this.onChangeValueByfield("proximityMode", "travel")
                    }}
                  >
                    Travel
                  </Button>
                </ButtonGroup>
              </Grid.Col>
              {
                this.state.proximityMode === "travel" &&
                <Grid.Col sm="3" className="align-self-center">
                  <strong>Travel Mode</strong>
                  <ButtonGroup className="ml-2">
                    <Button {...buttonTravelModeProps("drive")}>
                      {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M171.3 96H224v96H111.3l30.4-75.9C146.5 104 158.2 96 171.3 96zM272 192V96h81.2c9.7 0 18.9 4.4 25 12l67.2 84H272zm256.2 1L428.2 68c-18.2-22.8-45.8-36-75-36H171.3c-39.3 0-74.6 23.9-89.1 60.3L40.6 196.4C16.8 205.8 0 228.9 0 256V368c0 17.7 14.3 32 32 32H65.3c7.6 45.4 47.1 80 94.7 80s87.1-34.6 94.7-80H385.3c7.6 45.4 47.1 80 94.7 80s87.1-34.6 94.7-80H608c17.7 0 32-14.3 32-32V320c0-65.2-48.8-119-111.8-127zM434.7 368a48 48 0 1 1 90.5 32 48 48 0 1 1 -90.5-32zM160 336a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/></svg> */}
                      Drive</Button>
                    <Button {...buttonTravelModeProps("bicycle")}>Bicycle</Button>
                    <Button {...buttonTravelModeProps("walk")}>Walk</Button>
                  </ButtonGroup>
                </Grid.Col>
              }
              <Grid.Col sm="2">
                <div className="row mb-5">
                  <div className="col-12 mb-1 text-center">&nbsp;</div>
                  <div className="col-12 pt-3">
                    <strong>
                      Geofencing {
                      (this.state.proximityMode === "radius") ?  "Radius in meters"
                        : (
                          this.state.travelType === "distance" ? "Travel in meters" : "Travel in minutes"
                        )
                      }
                    </strong>
                  </div>
                </div>
              </Grid.Col>
              {
                this.state.proximityMode === "radius" &&
                <Grid.Col sm="3" className="pl-6 mt-0">
                  <Form.Group label="&nbsp;" className="mb-0">
                    <Form.Ratio
                      defaultValue={this.state.defaultRadius}
                      value={this.state.radius}
                      min={100}
                      max={10000}
                      step={100}
                      onChange={ev => this.handleChangeRadius(ev)}
                      readOnly={false}
                    />
                  </Form.Group>
                </Grid.Col>
              }
              {
                this.state.proximityMode === "travel" &&
                <Grid.Col sm="3" className="pl-6 mt-0">
                  <Form.Group label="&nbsp;" className="mb-0">
                    <Form.Ratio
                      defaultValue={this.state.defaultTravelRadius}
                      value={this.state.radius}
                      min={1}
                      max={60}
                      step={1}
                      onChange={ev => this.handleChangeRadius(ev)}
                      readOnly={false}
                    />
                  </Form.Group>
                </Grid.Col>
              }
              

              {/* HIDDEN ROUTE
              {
                this.state.proximityMode === "travel" &&
                <Grid.Col sm="3" className="align-self-center">
                  <strong>Travel Route</strong>
                  <ButtonGroup className="ml-2">
                    <Button color={this.state.travelRouteType === "balanced" ? "primary" : "secondary"} className="text-center" onClick={()=>this.onChangeValueByfield("travelRouteType", "balanced")}>Balanced</Button>
                    <Button color={this.state.travelRouteType === "short" ? "primary" : "secondary"} className="text-center" onClick={()=>this.onChangeValueByfield("travelRouteType", "short")}>Short</Button>
                    <Button color={this.state.travelRouteType === "less_maneuvers" ? "primary" : "secondary"} className="text-center" onClick={()=>this.onChangeValueByfield("travelRouteType", "less_maneuvers")}>Simple</Button>
                  </ButtonGroup>
                </Grid.Col>
              } */}

              {/* HIDDEN TRAFFIC
              {
                this.state.proximityMode === "travel" &&
                <Grid.Col sm="1" className="align-self-center">
                  <Checkbox 
                    // key={item.field} 
                    // checked={item.visible} 
                    // disabled={item.disabled} 
                    onChange={(ev) => this.onChangeValueByfield("travelTraffic", ev.target.checked ? "approximated" : "free_flow")}
                  >
                    Traffic
                  </Checkbox>
                </Grid.Col>
              } */}

            </Grid.Row>
            
            {/* {
              this.state.proximityMode === "travel" &&
              <Grid.Row>
                <Grid.Col className="text-right mb-2">
                  <Button onClick={()=>this.getIsoline()}>Get Polygons</Button>
                </Grid.Col>
              </Grid.Row>
            } */}

          </Grid.Col>
        </Grid.Row>
      )
  }
}

export default PoiFilter;
