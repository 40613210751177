import { authHeader } from "../_helpers";
import { userService } from "./"
import {defineConfig} from '../config'

export const justificativeService = {
  getAuditFindByCampaign,
  getAuditFindByCampaignFiltered,
  getAuditFindByCampaignOrdered,
  getAuditCampaign,
  getPhotoCheckBySeq,
  setKinResId,
  setFileUploadPhotoCheck,
  setFileUploadPhotoCheckDynamic,
  setKinValidationStatus,
  setBmePhotoVisibility
};

const {apiUrl, apiVer} = defineConfig.AUDIT_SERVICE

function getAuditFindByCampaign(idkc) {
  const requestOptions = {
      method: "GET",
      headers: authHeader(),
  };
  return fetch(apiUrl+apiVer+"/audit/findByCampaign/idkc/"+idkc, requestOptions).then(handleResponse);
}

function getAuditFindByCampaignFiltered(idkc) {
  const requestOptions = {
      method: "GET",
      headers: authHeader(),
  };
  return fetch(apiUrl+apiVer+"/audit/findByCampaign/idkc/"+idkc+"/filter/true", requestOptions).then(handleResponse);
}

function getAuditFindByCampaignOrdered(idkc, sort, direction) {
  sort = sort ? sort : "checkDate"
  direction = direction ? direction : "desc"

  const requestOptions = {
      method: "GET",
      headers: authHeader(),
  };
  return fetch(apiUrl+apiVer+"/audit/findByCampaign/idkc/"+idkc+"?sort="+sort+"&direction="+direction, requestOptions).then(handleResponse);
}

function getAuditCampaign(id) {
  const requestOptions = {
      method: "GET",
      // headers: authHeader(),
  };
  return fetch(apiUrl+apiVer+"/audit/findPhotoCheck/idkc/"+id, requestOptions).then(handleResponse);
}

function getPhotoCheckBySeq(seqId) {
  const requestOptions = {
      method: "GET",
      // headers: authHeader(),
  };
  return fetch(apiUrl+apiVer+"/audit/findPhotoCheckBySeq/seqId/"+seqId, requestOptions).then(handleResponse);
}

function setKinResId(seqId, status) {
  const requestOptions = {
      method: "GET",
      // headers: authHeader(),
  };
  return fetch(apiUrl+apiVer+"/audit/setKinResId/seqId/"+seqId+"/st/"+status, requestOptions).then(handleResponse);
}


function setKinValidationStatus(status, seqIds){
  const bodyJson = {
    "seqIds": seqIds,
    "status": status
  }
  const requestOptions = {
    method: "POST",
    headers:{
      'content-type': "application/json"
    },
    body: JSON.stringify(bodyJson),
    // headers: authHeader(),
  };
  // console.log(requestOptions)
  return fetch(apiUrl+apiVer+"/audit/setKinValidationStatus", requestOptions).then(handleResponse);
}

function setFileUploadPhotoCheck(formData, seqId) {
  const url = apiUrl+apiVer+"/audit/uploadJustify";

  const requestOptions = {
    method: "POST",
    headers:{
      seqId: seqId
    },
    body: formData
  };

  // console.log(requestOptions)

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      // login successful if there's a user in the response
      if (res) {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        // console.log(res)
      }
      return res;
    });
}

function setFileUploadPhotoCheckDynamic(formData, seqId) {
  const url = apiUrl+apiVer+"/audit/uploadJustifyBme";

  const requestOptions = {
    method: "POST",
    headers:{
      seqId: seqId
    },
    body: formData
  };

  // console.log(requestOptions)

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      // login successful if there's a user in the response
      // if (res) {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        // console.log(res)
      // }
      return res;
    });
}

function setBmePhotoVisibility(seqId, statuses) {
  const url = apiUrl+apiVer+"/audit/hideBmePhotoCheck";

  let bodyJson = {
    "seqId": seqId,
    "bmePhotoCheckVisibility": statuses
  }

  const requestOptions = {
    method: "POST",
    headers:{
      'content-type': "application/json"
    },
    body: JSON.stringify(bodyJson)
  };

  // console.log(requestOptions)

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      // login successful if there's a user in the response
      // if (res) {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        // console.log(res)
      // }
      return res;
    });
}
function handleResponse(response) {
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          userService.logout();
          window.location.reload(true);
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      return data;
    });
}
