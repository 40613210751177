import { authHeader } from "../_helpers";
import { userService } from "."
import {defineConfig} from '../config'

export const companyService = {
  getApiPathCompany,
  createCompany,
  readCompany,
  readCompanyAll,
  updateCompany,
  deleteCompany,
  setAdvertiserToCompanies,
  getUrlLogoById,
  getCompaniesAllowed,
  getUserAllowedModule,
  uploadLogo
};

const {apiUrl, apiVer} = defineConfig.COMPANY_SERVICE

function getApiPathCompany(){
  return (apiUrl+apiVer)
}

function createCompany(company) {
  const url = apiUrl+apiVer+"/company/create";
  const body = JSON.stringify(company)

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: body
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function readCompany(id) {
  const url = apiUrl+apiVer+"/company/get/id/"+id;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function readCompanyAll() {
  const url = apiUrl+apiVer+"/company/get/all";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function updateCompany(id, company) {
  const url = apiUrl+apiVer+"/company/update/id/"+id;

  const body = JSON.stringify(company)

  const requestOptions = {
    method: "PUT",
    headers: authHeader("application/json"),
    body: body
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}


function deleteCompany(id){
  const url = apiUrl+apiVer+"/company/delete/id/"+id;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function setAdvertiserToCompanies(advertiser, companiesIds) {
  const url = apiUrl+apiVer+"/company/add/advertiser/"+advertiser;

  const body = JSON.stringify(companiesIds)

  const requestOptions = {
    method: "PUT",
    headers: authHeader("application/json"),
    body: body
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getUrlLogoById(id) {
  return apiUrl+apiVer+"/images/company/"+id;
}

function uploadLogo(formData) {
  const url = apiUrl+apiVer+"/images/upload";

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      // request successful if there's a user in the response
      if (res) {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        // console.log(res)
      }
      return res;
    });
}

function getCompaniesAllowed(module){
  // Workaround REALDOOH for companies
  switch(module){
    case 'realdooh':
      return ["Xaxis", "PBU", "Groupm Nexus"];
    default:
      return [];
  }
}

function getUserAllowedModule(module, user = null){
  const allowedCompanies = getCompaniesAllowed(module)
  if(user === null){
    user = JSON.parse(localStorage.getItem("user"))
  }

  if(!user)
    return false;

  if(user.company){
    const result = allowedCompanies.find(company => company === user.company.name)
    if(result){
      return true;
    }
  }
  return false;
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {

      switch(response.status){
        case 401:
          // auto logout if 401 response returned from api
          userService.logout();
          window.location.reload(true);
          break;
        case 422:
          return data;
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
