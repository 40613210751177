import React, { useState, useEffect } from 'react';
import { Table, Switch, Badge } from 'antd';
import { isArray } from 'lodash';

const AmbiencesTable = (props) => {

  const selectItem = (key, selectedRowKeys) => {
    let selected = (selectedRowKeys === null || key !== selectedRowKeys ) ? key : null
    props.onSelectItem(selected)
  }

  const {selectedRowKeys} = props
  const [loading, setLoading] = useState(false)
  const [ambiences, setAmbiences] = useState([])

  const columns = [
    {
      title: 'Selection name',
      dataIndex: 'name',
      render: (text, record, index)=> <a onClick={()=>selectItem(record.key, selectedRowKeys)}><strong>{text}</strong></a>,
    },
    {
      title: 'Item',
      dataIndex: 'doohs',
      render: el => el ? (
        <Badge count={el.length} overflowCount={999} size="default" style={{backgroundColor: "#fff", color: "#999", boxShadow: "0 0 0 1px #d9d9d9 inset"}}/>
      ) : '-'
    },
    {
      title: 'Active',
      dataIndex: 'visible',
      render: (visible, record) => (
        <Switch
          defaultChecked
          size="small"
          checked={visible}
          onChange={()=>props.onChangeItemVisibity(record.key, !visible)}
        />
      )
    }
  ];

  const getCurrentPage = (selectedRowKeys) => {
    const page = isArray(selectedRowKeys) ? Math.ceil((selectedRowKeys[0] + 1)/10) : Math.ceil((selectedRowKeys + 1)/10)
    return page
  }

  const [currentPage, setCurrentPage] = useState(1);

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    hideSelectAll: true,
    onChange: (nextSelectedRowKeys, selectedRows) => {
      let selected = null
      if(nextSelectedRowKeys.length <= 0){
        selected = null
      }
      else if(nextSelectedRowKeys.length === 1){
        selected = nextSelectedRowKeys
      }
      else if(nextSelectedRowKeys.length > 1){
        const index = nextSelectedRowKeys.indexOf(isArray(selectedRowKeys) ? selectedRowKeys[0] : selectedRowKeys);
        if (index > -1) {
          nextSelectedRowKeys.splice(index, 1);
        }
        selected = nextSelectedRowKeys
      }

      props.onSelectItem(selected)
      setCurrentPage(getCurrentPage(selected))
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled element', // Column configuration not to be checked
      name: record.name,
    }),
    selectedRowKeys: isArray(selectedRowKeys) ? selectedRowKeys : [selectedRowKeys]  // workaround for init integer values
  };

  useEffect(() => { setAmbiences(props.ambiences) }, [props.ambiences]);

  useEffect(() => { setLoading(props.loading) }, [props.loading]);

  useEffect(() => { setCurrentPage(getCurrentPage(props.selectedRowKeys)) }, [props.selectedRowKeys])

  const pagination = {
    current: currentPage,
    onChange: (page) => {
      setCurrentPage(page)
    }
  }

  return (
    <div>
      <Table
        rowSelection={{
          // type: 'radio',
          ...rowSelection,
        }}
        columns={columns}
        dataSource={ambiences}
        size="small"
        loading={loading}
        expandable={props.expandable}
        pagination={pagination}
      />
    </div>
  );
};

export default AmbiencesTable;
