import { authHeader } from "../_helpers";
import { userService } from "."
import FileSaver from 'file-saver';
import {defineConfig} from '../config'

export const poiService = {
  getApiPathPOI,
  getFileFromUrl,
  getPOITemplate,
  createPOIGroup,
  updatePOIGroup,
  readGroups,
  deleteGroup,
  readPOIsByGroup,
  importPOIByXls,
  readMarkerIcon,
  readMarkersIcons,
  createMarkerIcon,
  getImageMarkerByName
};

const {apiUrl, apiVer} = defineConfig.POI_SERVICE

function getApiPathPOI(){
  return (apiUrl+apiVer)
}

function getPOITemplate(){
  const requestUrl = apiUrl+apiVer+'/poi/template/download';
  this.getFileFromUrl(requestUrl, "templatePOI.xlsm");
}

function createPOIGroup(poiGroupName, pois) {
  const url = apiUrl+apiVer+"/poi/import?groupName="+poiGroupName;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(pois)
  };

  console.log(requestOptions)

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function updatePOIGroup(poiGroupId, poiGroupName, pois) {
  const url = apiUrl+apiVer+"/poi/group/"+poiGroupId;

  const bodyJson = {
    "name": poiGroupName,
    "pois": pois
  }

  const requestOptions = {
    method: "PUT",
    headers: authHeader("application/json"),
    body: JSON.stringify(bodyJson)
  };

  console.log(requestOptions)

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function importPOIByXls(formData, poiGroupName) {
  const url = apiUrl+apiVer+"/poi/import";

  formData.append("poiGroupName", poiGroupName);

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function deleteGroup(id) {
  const url = apiUrl+apiVer+"/poi/group/id/"+id;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function readGroups() {
  const url = apiUrl+apiVer+"/poi/group/get";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function readMarkersIcons() {
  const url = apiUrl+apiVer+"/marker/get/all";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function readMarkerIcon(id) {
  const url = apiUrl+apiVer+"/marker//get/id/"+id;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}




function readPOIsByGroup(id) {
  const url = apiUrl+apiVer+"/poi/group/id/"+id+"/get";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function createMarkerIcon(formData) {
  const url = apiUrl+apiVer+"/marker/upload";

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      // login successful if there's a user in the response
      if (res) {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        // console.log(res)
      }
      return res;
    });
}


function getImageMarkerByName(name){
  return apiUrl+apiVer+"/marker/get/" + name
}


function getFileFromUrl(url, fileName = null) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  if(fileName === null){
    fileName = url.split('/')
    fileName = fileName[fileName.length-1]
  }

  return fetch(url, requestOptions)
    .then(response => {
      console.log("scarico....")
      return response.blob()
    })
    .then(function(blob) {
      FileSaver.saveAs(blob, fileName)
      return true;
    })
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {

      switch(response.status){
        case 401:
          // auto logout if 401 response returned from api
          userService.logout();
          window.location.reload(true);
          break;
        case 422:
          return data;
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
