/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Dropdown, Form, Input, List, Menu, Modal, Space, Switch, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { shareService } from '../_services';
import { toast } from 'react-toastify';
import {CalendarOutlined, DeleteOutlined, DownOutlined, PlusOutlined, SendOutlined} from '@ant-design/icons';

const INIT_SHARE = {
    "resourceId": "",
    "resourceType": "",
    "name": "",
    "email": "",
    "expirationDate": null,
    "active": true,
}

const layout = {
    layout: "inline",
};

const {origin} = window.location;

function ShareManage({children, id, name, resourceType}) {
    const [isModalOpen, setModalOpen] = useState(false)
    const [shares, setShares] = useState([])
    const [form] = Form.useForm();

    useEffect( () => {
        if(isModalOpen){
            getShares();
        }
    }, [isModalOpen])

    const getShares = () => {
        shareService.getSharesByResource(id, resourceType).then(
            result => {
                setShares(result);
            },
            error => {
                toast.error("Error: " + error);
            }
        )
    }

    const createShare = (values) => {
        const formObj = {
            ...INIT_SHARE,
            ...values,
            resourceId: id,
            resourceType,
        }
        shareService.createShare(formObj).then(
            result => {
                toast.success("Token created!")
                getShares();
                form.resetFields();
            },
            error => {
                toast.error("Error: " + error);
            }
        )
    }

    const deleteShare = (idShare) => {
        shareService.deleteShare(idShare).then(
            result => {
                toast.success("Token deleted!")
                getShares();
            },
            error => {
                toast.error("Error: " + error);
            }
        )
    }

    const updateShare = (shareObj) => {
        shareService.updateShare(shareObj).then(
            result => {
                getShares();
            },
            error => {
                toast.error("Error: " + error);
            }
        )
    }

    const FormShare = (
        <Form {...layout} form={form} name={"form-share-" + id} onFinish={(values) => createShare(values)} className='mt-2'>
            <Form.Item
                name="name"
                // label="Name"
                rules={[
                    {
                        required: true,
                    },
                ]}

            >
                <Input type="text" placeholder='Name guest'/>
            </Form.Item>
            <Form.Item
                name="email"
                // label="Email"
                className='w-50'
                rules={[
                    {
                        required: true,
                        type: "email"
                    },
                ]}
            >
                <Input type="text" placeholder='Email'/>
            </Form.Item>
            <Form.Item className='m-0'>
                <Button type="text" htmlType="submit" icon={<PlusOutlined/>}></Button>
            </Form.Item>
        </Form>
    )

    const menuShare = (item) => (
        <Menu>
          <Menu.Item disabled icon={<SendOutlined />}>
            <span>
                Send link
            </span>
          </Menu.Item>
          <Menu.Item disabled icon={<CalendarOutlined />}>
            <span>
                Add expiration
            </span>
          </Menu.Item>
          <Menu.Item danger icon={<DeleteOutlined />} onClick={() => deleteShare(item.id)}>
            <span>
              Delete access
            </span>
          </Menu.Item>
        </Menu>
    );

    const ListShares = (
        <List
            // header={<strong>Users guest allowed</strong>}
            // footer={<div>Footer</div>}
            // bordered
            dataSource={shares}
            renderItem={(item) => (
                <List.Item
                    actions={[
                        <Typography.Text copyable={{ text: `${origin}/resource/${item.resourceType}/${item.resourceId}/token/${item.token}/view` }}/>,
                        <Dropdown overlay={menuShare(item)}>
                            <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                    <DownOutlined />
                                </Space>
                            </a>
                        </Dropdown>,
                        <Switch size="small" checked={item.active} onChange={(value) => updateShare({...item, active: value})}/>,
                    ]}
                >
                    <Typography.Text>{item.name}</Typography.Text> <Typography.Text className='text-muted ml-3' ellipsis style={{maxWidth: 180}}>{item.email}</Typography.Text>
                </List.Item>
            )}
        />
    )

    return (
        <>
            { React.cloneElement( children, { onClick: () => setModalOpen(!isModalOpen) } ) }
            <Modal
                title={`Sharing "${name}"`}
                open={isModalOpen}
                onCancel={() => setModalOpen(false)}
                cancelText="Close"
                okButtonProps={{className: "d-none"}}
            >
                {ListShares}
                {FormShare}
            </Modal>
        </>
    );
}

export default ShareManage;