/* eslint-disable default-case */
import React, { useState } from "react";
import { Dimmer } from "tabler-react";
import { Tabs} from "antd";
import CompareUsers from "./Planner.CompareUsers";
import CompareDays from "./Planner.CompareDays";
import CustomCompare from "./Planner.CustomCompare";
import _ from "lodash";
const { TabPane } = Tabs;

function SyncingChartsAudience(props) {
  const {type} = props;
  const [tabSelected, setTabSelected] = useState("1"); 

  return (
    <>
      <div className="row">
        <div className="col">
          {/* {loadingTop || loadingBot || optionsTopCustom.series.length > 0 ? ( */}
            <Tabs defaultActiveKey={"1"} activeKey={tabSelected} type="card" onChange={(active) => setTabSelected(active)}>
              <TabPane tab="Compare Users" key="1">
                <CompareUsers
                  allData={props.allData}
                  audienceFilter={props.audienceFilter}
                  assetType={type}
                />
              </TabPane>
              <TabPane tab="Compare Days" key="2">
                <CompareDays
                  allData={props.allData}
                  audienceFilter={props.audienceFilter}
                  assetType={type}
                />
              </TabPane>
              <TabPane tab="Custom Charts" key="3">
                <CustomCompare
                  allData={props.allData}
                  audienceFilter={props.audienceFilter}
                  assetType={type}
                />
              </TabPane>
            </Tabs>
        </div>
      </div>
    </>
  );
}

export default SyncingChartsAudience;
