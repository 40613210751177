// @flow

import * as React from "react";
import { FormCard, FormTextInput, StandaloneFormPage } from "../../../"

class ForgotPasswordPageSetCode extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            isLoading: false,
            checkCode: "",
            password: "",
            checkPassword: ""
        }
    }

    render(){
        const {
            action,
            method,
            onSubmit,
            onChange,
            onBlur,
            values,
            email,
            errorMessages,
            errorCheckCode,
            isLoading,
        } = this.props;

        // console.log('errorCheckCode')
        // console.log(errorCheckCode)
        return (
            <StandaloneFormPage imageURL={"/demo/brand/kinetic.png"}>
                <FormCard
                    buttonText={"Next"}
                    buttonLoading={isLoading}
                    title={"Check your email"}
                    onSubmit={onSubmit}
                    action={action}
                    method={method}
                >
                {/* <p className="text-muted">
                    {"Check your email" + email}
                </p> */}
                <FormTextInput
                    name="code"
                    label={"Security code"}
                    placeholder={"Code sent to " + email}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={values && values.code}
                    error={errorMessages && errorMessages.code}
                    className="form-control-lg"
                />
                {errorCheckCode ?
                    <div className="p-2 text-center text-danger">Wrong code!</div>
                    : ""
                }
                </FormCard>
            </StandaloneFormPage>
        )
    }
}

export default ForgotPasswordPageSetCode;



