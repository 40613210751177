// @flow

import * as React from "react";

import {
  Card,
  Badge,
  Button,
  Tag,
  Nav,
  Dimmer
} from "tabler-react";

import { searchService, geoInfoService, assetService, imageService, geoIsolineService } from "../../_services";
import PoiFilters from "./Poi.filters.react"
import SearchBuilder from "./SearchBuilder/SearchBuilder.react"
import {Table as TableAntd} from 'antd'
import iconPOI from "../../components/Map/icons/poi.png"
import _ from "lodash"
import AudienceFilter from "./Audience.filters.react";
import { toast } from "react-toastify";

const fieldsQueryBuilder = {
  region: {
    label: 'Region',
    type: 'select',
    valueSources: ['value'],
    listValues: [],
  },
  district: {
    label: 'District',
    type: 'select',
    valueSources: ['value'],
    listValues: [],
  },
  city: {
    label: 'City',
    type: 'select',
    valueSources: ['value'],
    listValues: [],
  },
  segment: {
    label: 'Segment',
    type: 'select',
    valueSources: ['value'],
    listValues: [],
  },
  dashboardType: {
    label: 'Type',
    type: 'select',
    valueSources: ['value'],
    listValues: [],
  },
  format: {
    label: 'Format',
    type: 'select',
    valueSources: ['value'],
    listValues: [],
  },
  mediaOwner: {
    label: 'Media Owner',
    type: 'select',
    valueSources: ['value'],
    listValues: [],
  },
  circuit: {
    label: 'Circuit',
    type: 'select',
    valueSources: ['value'],
    listValues: [],
  },
  digital: {
    label: 'Digital',
    type: 'boolean',
    operators: ['equal'],
    valueSources: ['value'],
  },
}

class SideBarFilters extends React.Component {

    constructor(props){
        super(props)

        this.state = {
          id: null,
          countElActivePOI: 0,
          countElActiveGeo: 0,
          countElActiveMedia: 0,
          countElActiveAudience: 0,
          POIs: [],
          radius: 0,
          geoFilters: null,
          mediaOfferFilters: null,
          polygons: [],
          proximityMode: "radius",
          travelType: "time",
          travelMode: "drive",
          travelTraffic: "approximated",
          travelRouteType: "balanced",

          jsonLoading: false,
          xlsLoading: false,
          xlsRequesting: false,
          resetLoading: false,
          isValidate: false,

          selRegions: [],
          selDistricts: [],
          selCities: [],
          selCap: [],

          selSegments: [],
          selDashboardType: [],
          selSizes: [],
          selMediaOwners: [],
          selCircuits: [],

          jsonLogic: null,
          jsonMongo: null,

          searchQuery: null,
          searchQuerySaved: null,
          searchEdited: false,
          QBEdited: false,
          fieldsQueryBuilder,


          audienceFilter: null,

          regions: [],
          segments: [],
          dashboardType: [],
          size: [],
          mediaOwner: [],
          circuits: [],
          tab: 0,
          isCollapsedTab: true,
          isNewPlan: true
        }
        this.handleStateCountPOI = this.handleStateCountPOI.bind(this)
        this.handleStateCountGeo = this.handleStateCountGeo.bind(this)
        this.handleStateCountMedia = this.handleStateCountMedia.bind(this)
        this.handleStateRadius = this.handleStateRadius.bind(this)
        this.handleStatePolygons = this.handleStatePolygons.bind(this)
        this.handleStateTravelProp = this.handleStateTravelProp.bind(this)
        this.handleStatePOIs = this.handleStatePOIs.bind(this)
        this.handleStateGeo = this.handleStateGeo.bind(this)
        this.handleStateMedia = this.handleStateMedia.bind(this)
        this.handleStateAudience = this.handleStateAudience.bind(this)
        

        this.handleJson = this.handleJson.bind(this)
        this.handleMongo = this.handleMongo.bind(this)

        this.onClickSearch = this.onClickSearch.bind(this)
        this.onClickReset = this.onClickReset.bind(this)
        this.onChangeTab = this.onChangeTab.bind(this)

        this.handleQBChanged = this.handleQBChanged.bind(this)
    }

    componentDidMount(){
        const {id, audienceFilter, polygons, proximityMode, travelType, travelMode, travelTraffic, travelRouteType} = this.props
        this.setState({id})

        this.setState((state) => ({
          isNewPlan: this.props.isNewPlan ? this.props.isNewPlan : true,
          tab: this.props.isNewPlan ? 0 : state.tab,
          audienceFilter,
          polygons,
          proximityMode,
          travelType,
          travelMode,
          travelTraffic,
          travelRouteType
        }))

        // this.getRegions()
        this.getMediaDistinct('region', 'regions')
        this.getMediaDistinct('district', 'districts')
        this.getMediaDistinct('city', 'cities')
        this.getMediaDistinct('segment', 'segments')
        this.getMediaDistinct('dashboardType', 'dashboardType')
        this.getMediaDistinct('format', 'size')
        this.getMediaDistinct('mediaOwner', 'mediaOwner')
        this.getMediaDistinct('circuit', 'circuits')
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.xlsRequesting !== this.props.xlsRequesting){
            let {xlsRequesting} = nextProps
            this.setState({
                xlsRequesting
            },()=>this.callBackExportXlsFromParent())
        }
        if(nextProps.jsonRequesting !== this.props.jsonRequesting){
            let {jsonRequesting} = nextProps
            this.setState({
              jsonRequesting
            },()=>this.callBackJSONFromParent())
        }
        if(nextProps.id !== this.props.id){
            const {id} = nextProps
            this.setState({id})
        }

        if(nextProps.POIs !== this.props.POIs){
          const POIs = nextProps.POIs
          this.setState({
            POIs,
            countElActivePOI: POIs.length
          })
        }
        if(nextProps.radius !== this.props.radius){
            const radius = nextProps.radius
            this.setState({
              radius
            })
        }

        if(nextProps.audienceFilter !== this.props.audienceFilter){
            const {audienceFilter} = nextProps
            this.setState({
              audienceFilter
            })
        }

        if(nextProps.isNewPlan !== this.props.isNewPlan){
          const isNewPlan = nextProps.isNewPlan
          this.setState({
            isNewPlan,
            tab: isNewPlan ? 0 : null,
            isCollapsedTab: !isNewPlan
          })
        }

        if(nextProps.searchQuery !== this.props.searchQuery){
          const searchQuery = nextProps.searchQuery
          this.setState({
            searchQuery,
            searchQuerySaved: searchQuery
          })
        }
        if(nextProps.proximityMode !== this.props.proximityMode){
          const proximityMode = nextProps.proximityMode
            this.setState({
              proximityMode
            })
        }
        if(nextProps.travelType !== this.props.travelType){
          const travelType = nextProps.travelType
            this.setState({
              travelType
            })
        }
        if(nextProps.travelMode !== this.props.travelMode){
            const travelMode = nextProps.travelMode
            this.setState({
              travelMode
            })
        }
        if(nextProps.travelTraffic !== this.props.travelTraffic){
            const travelTraffic = nextProps.travelTraffic
            this.setState({
              travelTraffic
            })
        }
        if(nextProps.travelRouteType !== this.props.travelRouteType){
            const travelRouteType = nextProps.travelRouteType
            this.setState({
              travelRouteType
            })
        }
    
        if(nextProps.polygons !== this.props.polygons){
            const polygons = nextProps.polygons
            this.setState({
              polygons
            })
        }
    }

    callBackExportXlsFromParent(){
        if(this.state.xlsRequesting!==false){
          this.setState({
              xlsRequesting: false
          },()=>this.onClickSearch('xls'))
        }
    }

    callBackJSONFromParent(){
      if(this.state.jsonRequesting!==false){
        this.setState({
          jsonRequesting: false
        },()=>this.onClickSearch('map'))
      }
    }


    handleStateCountPOI(count){
        this.setState({
            countElActivePOI: count
        })
    }

    handleStateCountGeo(count){
        this.setState({
            countElActiveGeo: count
        })
    }

    handleStateCountMedia(count){
        this.setState({
            countElActiveMedia: count
        })
    }

    handleStateRadius(radius){
        this.setState({
            radius,
            searchEdited: true
        })
    }
    handleStatePOIs(POIs){
      this.setState({
        POIs,
        searchEdited: true
      })
    }

    handleStatePolygons(polygons){
      this.setState(prevState => ({
        polygons,
        searchEdited: true
      }));
    }

    handleStateTravelProp(travelField, value){
      this.setState({
        [travelField]: value,
        searchEdited: true
      });
    }

    handleStateAudience(audienceFilter){
      this.setState({
        audienceFilter
      })
    }

    handleJson(jsonLogic){
      this.setState({
        jsonLogic,
        searchEdited: true
      })
    }

    handleMongo(jsonMongo){
      this.setState({
        jsonMongo
      })
    }

    handleStateGeo(geos){
        let {selRegions, selDistricts, selCities, selCap} = geos

        selRegions = selRegions.map((item)=>item.label)
        selDistricts = selDistricts.map((item)=>item.value)
        selCities = selCities.map((item)=>item.value)

        this.setState({selRegions, selDistricts, selCities, selCap})
    }

    handleStateMedia(medias){
        let {selSegments, selDashboardType, selSizes, selMediaOwners, selCircuits} = medias

        selSegments = selSegments.map((item)=>item.label)
        selDashboardType = selDashboardType.map((item)=>item.label)
        selSizes = selSizes.map((item)=>item.label)
        selMediaOwners = selMediaOwners.map((item)=>item.label)
        selCircuits = selCircuits.map((item)=>item.label)

        this.setState({selSegments, selDashboardType, selSizes, selMediaOwners, selCircuits})
    }

    onClickReset(){
      this.setState({
          resetLoading: true
      }, ()=>
      this.setState({
        POIs: [],
        radius: 0,
        jsonLogic: null,
        jsonMongo: null,
        searchQuery: null,
        QBEdited: true,
        audienceFilter: null,
        tab: 0
      }, () =>
      this.setState({
        resetLoading: false
      })))
    }

    onClickSearch(mode){
        // if(!this.checkValidationForm()) {
        //     return;
        // }

        let format = 0;
        if(mode=="map"){
            format = 0;
        }
        else if(mode=="xls"){
            format = 1;
        }

        const {proximityMode, POIs, radius, id, audienceFilter, polygons, travelType, travelMode, travelTraffic, travelRouteType} = this.state;
        const idPOIs = POIs.map(poi => poi.publicId ? poi.publicId : poi.id); //Workaround....

        const {jsonLogic, jsonMongo, searchQuery, QBEdited} = this.state

        const searchQueryApp =
          !QBEdited ? searchQuery
          : (!jsonMongo) ? null
          : (jsonLogic) ? {
            value: jsonMongo ? jsonMongo : "",
            logic: jsonLogic
          }
          : searchQuery ? searchQuery
          : null;

        // console.log('jsonLogic, jsonMongo, searchQuery, QBEdited', jsonLogic, jsonMongo, searchQuery, QBEdited, searchQueryApp)

        if(proximityMode === "travel"){
          this.setState({
            jsonLoading: true
          })
          this.getIsoline();

          const travelConfig = {
            proximityMode,
            travelType,
            travelMode,
            travelTraffic,
            travelRouteType,
          }

          // TODO: remove workaround timeout after getIsoline
          setTimeout(()=>this.startAndSaveSearch(id, idPOIs, radius, format, searchQueryApp, audienceFilter, this.state.polygons, travelConfig), 4000);
          return;
        }
        
        this.startAndSaveSearch(id, idPOIs, radius, format, searchQueryApp, audienceFilter, polygons);
    }

    getIsoline(){
      const {POIs, travelMode, travelRouteType, travelTraffic, travelType, radius} = this.state;
      POIs.map(({location}) => {
        const {x, y} = location;
        geoIsolineService.getIsoline(y, x, travelType, travelMode, radius * 60, travelTraffic, travelRouteType).then(
          polygons => {
            this.handleStatePolygons(polygons);
          },
          error => {
            toast.error("Error: " + error);
          }
        )
      })
    }

    checkValidationForm(){
        const {POIs, radius, searchQuery, jsonLogic, proximityMode} = this.state;
        const arraySearchQuery = jsonLogic ? _.values(JSON.parse(jsonLogic).children1) : searchQuery ? _.values(JSON.parse(searchQuery.logic).children1) : []
        const countElActiveQB = arraySearchQuery.length

        let condLatLng = POIs.length;
        let condRadius = radius>=1 && radius<=20000;
        let condPOI = condLatLng && condRadius

        const hasMoreThanOnePOITravel = (proximityMode === "travel" && POIs.length > 1);

        const isValidate = (condPOI || countElActiveQB) && !hasMoreThanOnePOITravel;

        const prevIsValidate = this.state.isValidate
        if(prevIsValidate !== isValidate){
            this.setState({
                isValidate
            }, ()=>this.props.handleXlsEnabled(this.state.isValidate))
        }

        return isValidate;
    }

    checkEditedForm(){
        const {POIs, radius, searchQuery, jsonLogic} = this.state;
        const arraySearchQuery = jsonLogic ? _.values(JSON.parse(jsonLogic).children1) : searchQuery ? _.values(JSON.parse(searchQuery.logic).children1) : []
        const countElActiveQB = arraySearchQuery.length

        let condLatLng = POIs.length;
        let condRadius = radius>=1 && radius<=20000;

        const isEdited = condLatLng || condRadius || countElActiveQB;

        return isEdited;
    }

    searchByRadius(id, idPOIs, radius, searchQuery, audienceFilter){
      searchService.getJsonSearch(id, idPOIs, radius, searchQuery, (audienceFilter && audienceFilter.id)).then(
        result => {
            this.setState({
                assets: result.assets,
                size: result.size,
                isTooMuch : result.assets.length === 0 && result.size > 0,
                jsonLoading: false,
                isCollapsedTab: true,
                tab: null
            },()=>this.props.handleStateAssets(this.state.assets, this.state.size, this.state.isTooMuch, radius, audienceFilter))

            this.props.handleCollapseResults(false)
        },
        error => {
            this.setState({
                jsonLoading: false
            })
            console.error(error);
        }
      )
    }

    searchByTravel(id, idPOIs, radius, searchQuery, audienceFilter, polygons, travelConfig = {}){
      searchService.getJsonSearchByPolygons(id, idPOIs, radius, searchQuery, (audienceFilter && audienceFilter.id), polygons, travelConfig).then(
        result => {
            this.setState({
                assets: result.assets,
                size: result.size,
                isTooMuch : result.assets.length === 0 && result.size > 0,
                jsonLoading: false,
                isCollapsedTab: true,
                tab: null
            },()=>this.props.handleStateAssets(this.state.assets, this.state.size, this.state.isTooMuch, radius, audienceFilter, polygons))

            this.props.handleCollapseResults(false)
        },
        error => {
            this.setState({
                jsonLoading: false
            })
            console.error(error);
        }
      )
    }

    startAndSaveSearch(id, idPOIs, radius, format, searchQuery = null, audienceFilter = null, polygons = [], travelConfig = {}){
        if(format === 1){
            this.setState({
              xlsLoading: true
            })
            searchService.getXlsSearch(id, idPOIs, radius, searchQuery, (audienceFilter && audienceFilter.id), polygons, travelConfig).then(
                result => {
                    this.setState({
                      xlsLoading: false
                    },()=>this.props.handleXlsLoaded())
                },
                error => {
                    this.setState({
                        xlsLoading: false,
                        xlsError: "No results: try another range!"
                    },()=>this.props.handleXlsLoaded())
                    console.error(error);
                }
            )
        }
        else if(format === 0){
            this.setState({
              jsonLoading: true,
              searchEdited: false,
              isCollapsedTab: false
            })
            this.props.handleCollapseResults(true)

            switch(this.state.proximityMode){
              case "radius":
                this.searchByRadius(id, idPOIs, radius, searchQuery, audienceFilter)
                break;
              case "travel":
                this.searchByTravel(id, idPOIs, radius, searchQuery, audienceFilter, polygons, travelConfig)
                break;
              default:
            }
        }
    }

    getRegions(){
      geoInfoService.getAllRegions().then(
        result => {
          const regions = result.map((r, index) => {
            return {value: r.regione, title: r.regione }
          })
          this.setState((state) => ({
            regions
          }))
        },
        error => {
          this.setState({
              regions: [],
              error,
              isLoading: false
          })
        }
      );
    }

    getMediaDistinct(field, stateName){
      assetService.getDistinctValuesOf('asset', field).then(
          result => {
            result = result.map((r, index) => {
              return {value: r, title: r }
            })

            this.setState({
              [stateName]: result
            },()=>this.setFieldsQueryBuilder())
          },
          error => {
              this.setState({
                  error
              })
          }
      );
    }

    setFieldsQueryBuilder() {
      const fieldsQueryBuilder = {
        region: {
          label: 'Region',
          type: 'select',
          valueSources: ['value'],
          listValues: this.state.regions,
        },
        district: {
          label: 'District',
          type: 'select',
          valueSources: ['value'],
          listValues: this.state.districts,
        },
        city: {
          label: 'Cities',
          type: 'select',
          valueSources: ['value'],
          listValues: this.state.cities,
        },
        reverse_cap: {
          label: 'CAP',
          type: 'text',
          valueSources: ['value'],
          excludeOperators: ['proximity', 'is_empty', 'is_not_empty', 'like', 'not_like'],
          listValues: [],
          allowCustomValues: true,
        },
        segment: {
          label: 'Segment',
          type: 'select',
          valueSources: ['value'],
          listValues: this.state.segments,
        },
        dashboardType: {
          label: 'Type',
          type: 'select',
          valueSources: ['value'],
          listValues: this.state.dashboardType,
        },
        format: {
          label: 'Format',
          type: 'select',
          valueSources: ['value'],
          listValues: this.state.size,
        },
        mediaOwner: {
          label: 'Media Owner',
          type: 'select',
          valueSources: ['value'],
          listValues: this.state.mediaOwner,
        },
        circuit: {
          label: 'Circuit',
          type: 'select',
          valueSources: ['value'],
          listValues: this.state.circuits,
        },
        digital: {
          label: 'Digital',
          type: 'boolean',
          operators: ['equal'],
          valueSources: ['value'],
        },
      }

      this.setState({
        fieldsQueryBuilder,
        searchQuery: null
      },()=>this.setState((state)=>({
        searchQuery: state.searchQuerySaved
      })))
    }

    onChangeTab(tab){
      this.setState({tab, isCollapsedTab: tab===null})
      this.props.handleCollapseResults(tab!==null)
    }

    handleQBChanged(){
      // console.log('handleQBChanged!')
      this.setState({
        QBEdited: true
      })
    }

    removePOI = (el) => {
      let {POIs} = this.state
      POIs = POIs.filter((item)=>item.id !== el)
      this.handleStatePOIs(POIs)
    }

    render(){
        const {countElActivePOI, radius, searchQuery, jsonLogic, audienceFilter, isCollapsedTab, isNewPlan, tab} = this.state;

        const arraySearchQuery = jsonLogic ? _.values(JSON.parse(jsonLogic).children1) : searchQuery ? _.values(JSON.parse(searchQuery.logic).children1) : []
        const countElActiveQB = arraySearchQuery.length

        const columns = [
          {
            dataIndex: 'marker',
            key: 'marker',
            render: marker => <img className="h-5" src={marker ? marker.fileImgix ? marker.fileImgix : imageService.getUrlMarkerIcon(marker) : iconPOI} alt={'poi'}/>
          },
          {
            title: 'POI name',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'City',
            dataIndex: 'comune',
            key: 'comune',
          },
          {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
          },
          {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: type => <Tag>{type}</Tag>
          },
          {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand',
          },
          {
            title: (this.state.proximityMode === "radius") ? 'Geofencing Radius' : 'Geofencing Travel ',
            render: () => {
              const measure = (this.state.proximityMode === "radius") ? 'm' : 'minutes'
              return radius ? (radius + " " + measure) : ('0 ' + measure)
            }
          },
          // {
          //   title: 'Location',
          //   dataIndex: 'location',
          //   key: 'location',
          // },
          {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: id => <Button color="link" size="sm" icon="x" className="text-danger" onClick={() => this.removePOI(id)}/>
          }
        ];

        const titlePOI = (
          <React.Fragment>
            PROXIMITY
              <span className="pull-right">
                {countElActivePOI > 0 && <Badge color="primary rounded-circle">{countElActivePOI}</Badge>}
              </span>
          </React.Fragment>
        )

        const titleAudience = (
          <React.Fragment>
            AUDIENCE
              <span className="pull-right">
                {audienceFilter !== null && <Badge color="primary rounded-circle">ON</Badge>}
              </span>
          </React.Fragment>
        )

        const titleFilters = (
          <React.Fragment>
            ADVANCED SEARCH&nbsp;&nbsp;&nbsp;
              <span className="pull-right">
                {(countElActiveQB) > 0 && <Badge color="primary rounded-circle">{countElActiveQB}</Badge>}
              </span>
          </React.Fragment>
        )

        const searchButtons = (
          <React.Fragment>
            <div className="row">
              <div className="col text-right">
                <Button.List>
                  {/* TO DO... attualmente nascosto poichè non funzionante */}
                  <Button color="link" size="sm" className="pt-2 pb-2 text-center text-danger" icon="delete" loading={this.state.resetLoading} disabled={!this.checkEditedForm()} onClick={this.onClickReset} title="Reset filters">Reset all</Button>
                  <Button color="primary" size="sm" className="pt-2 pb-2" icon="search" loading={this.state.jsonLoading} disabled={!this.checkValidationForm()} onClick={() => this.onClickSearch('map')} title="Apply filters">Search</Button>
                </Button.List>
              </div>
            </div>
          </React.Fragment>
        )

        const navCard = (
          <React.Fragment>
            <Nav>
              <Nav.Item value={titlePOI} active={((!isCollapsedTab || isNewPlan) && this.state.tab === 0)} onClick={()=>this.onChangeTab(0)}></Nav.Item>
              <Nav.Item value={titleFilters} active={(!isCollapsedTab || isNewPlan) && this.state.tab === 1} onClick={()=>this.onChangeTab(1)}></Nav.Item>
              <Nav.Item
                className={this.checkValidationForm() ? "" : "opacity-3"}
                value={titleAudience}
                active={((!isCollapsedTab || isNewPlan || this.checkValidationForm()) && this.state.tab === 2)}
                onClick={()=>{this.checkValidationForm() && this.onChangeTab(2)}}
              />
            </Nav>
          </React.Fragment>
        )

        return(
          <>
            <div>
              <Card className="planner-nav-card mb-1" key={isCollapsedTab + '-' + this.state.tab} isCollapsed={this.state.tab === null}>
                <Card.Header>
                  <Card.Title>{navCard}</Card.Title>
                  <Card.Options>
                    {
                      !isNewPlan &&
                      <Button color="secondary" size="sm" className="ml-2" icon={tab===null ? "sliders" : "rotate-ccw"} onClick={()=>this.onChangeTab(tab===null ? 0 : null)}>
                        {tab===null ? "Edit plan" : "Show previous results"}
                      </Button>
                    }
                  </Card.Options>
                </Card.Header>
                <Card.Body className="py-0 pr-3">
                  <Dimmer>{/*active loader*/}
                    {
                      this.state.tab === 0 &&
                        <div className="row">
                          <div className="col align-self-center">
                            <PoiFilters
                              handleStateAssets={this.props.handleStateAssets}
                              handleStateCount={this.handleStateCountPOI}
                              handleChangePOIs={this.handleStatePOIs}
                              handleChangePOIRadius={this.handleStateRadius}
                              handleChangePolygons={this.handleStatePolygons}
                              handleChangeTravelProp={this.handleStateTravelProp}
                              POIs={this.state.POIs}
                              radius={this.state.radius}
                              {...this.state}
                            />
                          </div>
                        </div>
                    }
                    {
                      this.state.tab === 1 &&
                        <div className="row">
                          <div className="col align-self-center">
                            <SearchBuilder
                              fields={this.state.fieldsQueryBuilder}
                              handleJson={this.handleJson}
                              handleMongo={this.handleMongo}
                              searchQuery={this.state.searchQuery}
                              jsonLogic={this.state.jsonLogic}
                              handleChange={this.handleQBChanged}
                            />
                          </div>
                        </div>
                    }
                    {
                      this.state.tab === 2 &&
                        <div className="row">
                          <div className="col align-self-center mt-5">
                            <AudienceFilter onChange={this.handleStateAudience} value={audienceFilter}/>
                          </div>
                        </div>
                    }

                  </Dimmer>
                </Card.Body>
                <Card.Footer className="py-2">
                  {searchButtons}
                </Card.Footer>
              </Card>
            </div>

            {/* Overview filters */}
            <div className="row mt-2" hidden={this.state.tab !== 0}>
              <div className="col">
                <Card>
                  <Card.Header>
                    <Card.Title className="text-primary">
                      Selected POIs
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <TableAntd dataSource={this.state.POIs} columns={columns} size="small" />
                  </Card.Body>
                </Card>
              </div>
            </div>
          </>
        )
    }
}

export default SideBarFilters;
