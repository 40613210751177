import React from 'react'
import { Transfer, Table, Tag } from 'antd';
import { Button } from 'tabler-react';
import difference from 'lodash/difference';

import {userService} from '../_services'

// Customize Table Transfer
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <Transfer {...restProps} showSelectAll={false}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns;

      const rowSelection = {
        getCheckboxProps: item => ({ disabled: listDisabled || item.disabled }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter(item => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected);
        },
        selectedRowKeys: listSelectedKeys,
      };

      return (
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          size="small"
          style={{ pointerEvents: listDisabled ? 'none' : null }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return;
              onItemSelect(key, !listSelectedKeys.includes(key));
            },
          })}
        />
      );
    }}
  </Transfer>
);

const leftTableColumns = [
  {
    dataIndex: 'username',
    title: 'Username',
  },
  {
    dataIndex: 'company',
    title: 'Company',
    render: company => company ? <Tag>{company.name}</Tag> : "-",
  }
];
const rightTableColumns = [
  {
    dataIndex: 'username',
    title: 'Username',
  },
  {
    dataIndex: 'company',
    title: 'Company',
    render: company => company ? <Tag>{company.name}</Tag> : "-",
  }
];

class CompanyTransferUsers extends React.Component {
  state = {
    targetKeys: [],
    dataSource: [],
    moveKeys: [],
    loading: true
  };

  componentWillReceiveProps(nextProps){
    if(nextProps.selected !== this.props.selected){
        let {selected} = nextProps
        this.setTargetKeys(selected)
    }
  }

  componentDidMount(){
    this.getAllUsers()
  }

  setTargetKeys = (selected) => {
    selected = selected.map(user => {
        return user.id
    })
    this.setState({
        targetKeys: selected,
        loading: false
    })
  }

  getAllUsers(){
    userService.readUserAll().then(
        dataSource => {
            dataSource = dataSource.map(user => {
                user.key = user.id
                return user
            })
            this.setState({
                dataSource,
            }, () => this.setTargetKeys(this.props.selected))
        }
    )
  }

  onChange = (nextTargetKeys, direction, moveKeys) => {
    console.log(nextTargetKeys, direction, moveKeys)
    this.setState({ targetKeys: nextTargetKeys, moveKeys });
  };

  onUndo = (close = false) => {
    const {selected} = this.props

    this.setState({
      moveKeys: []
    }, () => this.setTargetKeys(selected))

    if(close){
      this.props.onClose()
    }
  }

  onSave = () => {
    const {moveKeys} = this.state
    console.log(moveKeys)
    this.props.onSave(moveKeys)
  }

  render() {
    const { targetKeys, dataSource, moveKeys, loading } = this.state;
    return (
      <>
        <TableTransfer
          dataSource={dataSource}
          targetKeys={targetKeys}
          showSearch={true}
          disabled={loading}
          onChange={this.onChange}
          oneWay
          filterOption={(inputValue, item) =>
            item.username.indexOf(inputValue) !== -1 || (item.company && item.company.name.indexOf(inputValue) !== -1)
          }
          leftColumns={leftTableColumns}
          rightColumns={rightTableColumns}
        />
        <Button.List className="mt-4 text-right">
          <Button color="secondary" onClick={() => this.onUndo(true)}>Cancel</Button>
          <Button color="danger" disabled={moveKeys.length <= 0} onClick={() => this.onUndo(false)}>Undo changes</Button>
          <Button color="primary" disabled={moveKeys.length <= 0} onClick={this.onSave}>Save changes</Button>
        </Button.List>
      </>
    );
  }
}

export default CompanyTransferUsers;
