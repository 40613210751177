export function authHeader(contentType = null) {
  // return authorization header with basic auth credentials
  let user = JSON.parse(localStorage.getItem("user"));
  let typeUser = localStorage.getItem("type");
  let auth = localStorage.getItem("auth");
  let guest = localStorage.getItem("guest");
  let groupUser = localStorage.getItem("group") ? localStorage.getItem("group").toUpperCase() : "";
  let check = localStorage.getItem("check") || 0;

  // if (user && user.access_token) {
  if (user && typeUser && auth && check) {

    let header = {};

    if(contentType){
      header = {
        'Content-Type': contentType
      };
    }

    header = {
      ...header,
      'Authorization': auth,
      'X_user': user.username,
      'X_role': typeUser,
      'X_group': groupUser,
      'guest': guest,
      // 'ngrok-skip-browser-warning': true
    };

    // For Ngrok dev..
    // header = {
    //   ...header,
    //   "ngrok-skip-browser-warning": true
    // }

    return header;

  } else {
    console.log("User or password wrong")
    window.location.replace("/logout?error=wronguser"); //schifo vergogna....
  }
}
