/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { Card,} from "antd";
import SyncingChart from "./SynchingCharts.Chart";
import SyncingFilters from "./SynchingCharts.Filters";
import _ from "lodash";

function CustomCompare(props) {
  const {assetType} = props;

  const defaultDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ] 

  const [mediaOwners, setMediaOwners] = useState([])
  const [dashboardTypes, setDashboardTypes] = useState([])
  const [circuits, setCircuits] = useState([])
  const [currentMediaOwnerTop, setCurrentMediaOwnerTop] = useState('')
  const [currentDashboardTypeTop, setCurrentDashboardTypeTop] = useState('')
  const [currentCircuitTop, setCurrentCircuitTop] = useState('')
  const [currentMediaOwnerBot, setCurrentMediaOwnerBot] = useState('')
  const [currentDashboardTypeBot, setCurrentDashboardTypeBot] = useState('')
  const [currentCircuitBot, setCurrentCircuitBot] = useState('')
  const [selectedDays, setSelectedDays] = useState(defaultDays);


  useEffect(()=> {
    const mediaOwners = _.uniq(props.allData.map(value=> value && value.mediaOwner))
    setMediaOwners(mediaOwners)
    const dashboardTypes = _.uniq(props.allData.map(value=> value && value.dashboardType))
    setDashboardTypes(dashboardTypes)
    const circuits = _.uniq(props.allData.map(value=> value && value.circuit))
    setCircuits(circuits)
  },[])

  const onChangeDays = (mode, value) => {
      setSelectedDays([...value])
  };

  const onResetFiltersTop = () => {
    setCurrentCircuitTop('')
    setCurrentMediaOwnerTop('')
    setCurrentDashboardTypeTop('')
  }

  const onResetFiltersBot = () => {
    setCurrentCircuitBot('')
    setCurrentMediaOwnerBot('')
    setCurrentDashboardTypeBot('')
  }

  const onChangeMediaOwner = (mode, value) => {
    if(mode==='top'){
      setCurrentMediaOwnerTop(value)
      const sameMediaOwner = currentMediaOwnerBot
      setCurrentMediaOwnerBot(sameMediaOwner)
    } else {
      setCurrentMediaOwnerBot(value)
      const sameMediaOwner = currentMediaOwnerTop
      setCurrentMediaOwnerTop(sameMediaOwner)
    }
  }

  const onChangeCircuit = (mode, value) => {
    if(mode==='top'){
      setCurrentCircuitTop(value)
      setCurrentCircuitBot(currentCircuitBot)
    } else {
      setCurrentCircuitTop(currentCircuitTop)
      setCurrentCircuitBot(value)
    }
  }

  const onChangeDashboardType = (mode, value) => {
    if(mode==='top'){
      setCurrentDashboardTypeTop(value)
      setCurrentDashboardTypeBot(currentDashboardTypeBot)
    } else {
      setCurrentDashboardTypeTop(currentDashboardTypeTop)
      setCurrentDashboardTypeBot(value)
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <p className="text-center mt-2 mb-2">
            <strong>Time when audience is the most active.</strong> Hourly Users distribution per selected days. Value expressed in %. Lookback window 12 weeks.
          </p>
        </div>
      </div>
      <SyncingFilters
        titleSelectDays1="Select days"
        allData={props.allData}
        filters={['firstDays']}
        firstDays={selectedDays}
        onChangeDays={onChangeDays}
      />
      <Card
        className="mt-3"
        key={'chartTop' + JSON.stringify(selectedDays) }
        size="small"
        headStyle={{background:  "rgb(250, 250, 250)"}}
        bodyStyle={{marginLeft:'-20px'}}
        title={
          <SyncingFilters
            allData={props.allData}
            filters={['mediaOwner', 'dashboardType', 'circuit']}
            mediaOwners={mediaOwners}
            circuits={circuits}
            dashboardTypes={dashboardTypes}
            currentMediaOwner={currentMediaOwnerTop}
            currentCircuit={currentCircuitTop}
            currentDashboardType={currentDashboardTypeTop}
            onChangeMediaOwner={(e)=>onChangeMediaOwner('top',e)}
            onChangeDashboardType={(e)=>onChangeDashboardType('top',e)}
            onChangeCircuit={(e)=>onChangeCircuit('top',e)}
            onReset={onResetFiltersTop}
          />
        }
      >
        <SyncingChart
          chartKey={'customChart'}
          group="customChartGroup"
          allData={props.allData}
          audienceFilter={props.audienceFilter}
          assetType={assetType}
          filters={
            {circuit: currentCircuitTop,
              dashboardType: currentDashboardTypeTop,
              mediaOwner: currentMediaOwnerTop
            }
          }
          days={selectedDays}
        />
      </Card>
      <Card
        key={'chartBot' + JSON.stringify(selectedDays) }
        className="mt-2"
        headStyle={{background: "rgb(250, 250, 250)"}}
        size="small"
        title={
          <SyncingFilters
            allData={props.allData}
            filters={['mediaOwner', 'dashboardType', 'circuit']}
            mediaOwners={mediaOwners}
            circuits={circuits}
            dashboardTypes={dashboardTypes}
            currentMediaOwner={currentMediaOwnerBot}
            currentCircuit={currentCircuitBot}
            currentDashboardType={currentDashboardTypeBot}
            onChangeMediaOwner={(e)=>onChangeMediaOwner('bot',e)}
            onChangeDashboardType={(e)=>onChangeDashboardType('bot',e)}
            onChangeCircuit={(e)=>onChangeCircuit('bot',e)}
            onReset={onResetFiltersBot}
            className={'mt-2 '}
          />
        }
        bodyStyle={{marginLeft:'-20px'}}
      >
        <SyncingChart
          chartKey={'customChart'}
          group="customChartGroup"
          allData={props.allData}
          audienceFilter={props.audienceFilter}
          assetType={assetType}
          filters={
            {circuit: currentCircuitBot,
              dashboardType: currentDashboardTypeBot,
              mediaOwner: currentMediaOwnerBot
            }
          }
          days={selectedDays}
        />
      </Card>
    </>
  );
}

export default CustomCompare;
