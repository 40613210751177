// @flow

import * as React from "react";

import {
  Tabs,
  Tab,
  Form,
  Table,
  Tag,
  Dimmer,
  Icon,
  Avatar,
  Button} from "tabler-react";


import {Popconfirm} from 'antd';

import { companyService, userService } from "../_services";
import "./UserManage.scss"
import Pagination from '../components/Pagination/Pagination.react'
import UserManageForm from "./UserManage.form.react"

import {toast} from "react-toastify"

const INIT_EDIT_USER = {
  editUserId: null,
  editUsername: null,
  editName: null,
  editSurname: null,
  editCompanyId: null,
  editType: null,
  editUserAvatarUrl: null,
}

const INIT_CLONE_USER = {
  cloneUserId: null,
  cloneUsername: null,
  cloneName: null,
  cloneSurname: null,
  cloneCompanyId: null,
  cloneType: null,
  cloneUserAvatarUrl: null,
}

const INIT_PAGINATION = {
  activePage: 1,
  itemsCountPerPage: 10,
  totalItemsCount: 0,
  maxPageRangeDisplayed: 5,
}

class UserManage extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      tab: "Open",
      errorMessage: "",
      userList: [],
      locationSearch: null,
      previewId: null,
      previewAdvertisers: [],

      search: '',

      isEditRequest: false,
      isCloneRequest: false,
      ...INIT_EDIT_USER,
      ...INIT_CLONE_USER,

      ...INIT_PAGINATION,
      offsetQuery: 0,
      sendingQuery: false,
      isLoading: false
    }
    this.deleteUser = this.deleteUser.bind(this)
    this.editUser = this.editUser.bind(this)
    this.cloneUser = this.cloneUser.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handlePageChangeCallBack = this.handlePageChangeCallBack.bind(this)
    this.getAllUser = this.getAllUser.bind(this)
    this.resetFormClone = this.resetFormClone.bind(this)
    this.addAdvertisersFromUser = this.addAdvertisersFromUser.bind(this)
  }

  componentDidMount(){
    this.getAllUser();
    if(this.props.tab){
      this.setTabToShow(this.props.tab)
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.tab !== this.props.tab){
      let {tab} = nextProps
      if(tab){
        this.setTabToShow(tab)
      }
    }
  }

  setTabToShow(tab){
    this.setState({
      tab,
      ...INIT_PAGINATION,
      isLoading: true
    }, ()=> this.resetForm())
  }

  resetForm(forced = false){
    const {tab} = this.state
    if(tab!== "Edit" || forced === true){
      this.setState({
        isEditRequest: false,
        ...INIT_EDIT_USER
      }, ()=> this.getAllUser())
    }
  }

  resetFormClone(){
    this.setState({
      isCloneRequest: false,
      ...INIT_CLONE_USER
    }, ()=> this.getAllUser())
  }

  getAllUser(){
    this.setState({isLoading: true})
    userService.readUserAll().then(
      result => {
        result = result.filter(el => el.name !== "")
        this.setState({
          userList: result,
          activePage: 1,
          totalItemsCount: result.length,
          offsetQuery: 0,
          isLoading: false
        })
      }
    )
  }

  editUser(id){
    this.setState({
      isEditRequest: false,
      ...INIT_EDIT_USER
    })
    if(id){
      userService.readUser(id).then(
        result => {
          const {id, username, name, surname, companyId, type, avatarUrl } = result
          this.setState({
            isEditRequest: true,
            editUserId: id,
            editUsername: username,
            editName: name,
            editSurname: surname,
            editCompanyId: companyId,
            editType: type,
            editUserAvatarUrl: avatarUrl,
          }, ()=> this.setTabToShow("Edit"))
        }
      )
    }
  }

  cloneUser(id, name){
    this.setState({
      isCloneRequest: false,
      ...INIT_CLONE_USER
    })
    if(id){
      userService.readUser(id).then(
        result => {
          const {id, username, name, surname, companyId, type, avatarUrl } = result
          this.setState({
            isCloneRequest: true,
            cloneUserId: id,
            cloneUsername: username,
            cloneName: name,
            cloneSurname: surname,
            cloneCompanyId: companyId,
            cloneType: type,
            cloneUserAvatarUrl: avatarUrl,
          }, ()=> this.setTabToShow("New"))
        }
      )
    }
  }

  deleteUser(id){
    if(id){
      userService.deleteUser(id)
      const userList = this.state.userList.filter(el => el.id !== id)
      this.setState({
          userList
      }, ()=> toast.success('User deleted successful!'))
    }
  }

  getUserListPreview() {
    const {previewAdvertisers} = this.state
    return(
      <React.Fragment>

        {previewAdvertisers && previewAdvertisers.map((item, index) => {
            const {name, comune, address, brand, type, location, note} = item
            return (
              <div className="row mb-2">
                <div className="col-sm-auto">
                  Empty
                </div>
                <div className="col-9 p-1">
                  <div className={index%2 ? "px-3 py-2 rounded" : "px-3 py-2 rounded"} title={note}>
                    <p className="mb-0 text-truncate"><strong>Name:</strong> {name ? name : "-"}</p>
                    <p className="mb-0 text-truncate"><strong>Address:</strong> {address ? address : "-"}</p>
                    <p className="mb-0 text-truncate"><strong>City:</strong> {comune ? comune : "-"}</p>
                    <p className="mb-0 text-truncate"><strong>Type:</strong> {type ? type : "-"}</p>
                    <p className="mb-0 text-truncate"><strong>Brand:</strong> {brand ? brand : "-"}</p>
                    {location ?
                      <p className="mb-0 text-truncate"><strong>Location:</strong> Lat: {location.y ? location.y : "-"}, Long: {location.x ? location.x : "-"}</p>
                      : <p className="alert alert-danger">Location: corrupted data (please remove this item)</p>
                    }
                  </div>
                </div>
              </div>
            )
        })}
        {this.state.previewAdvertisers.length===0 && <p className="mb-0 text-truncate"><em>No Advertisers</em></p>}

      </React.Fragment>
    )
  }

  addAdvertisersFromUser(idUser){
    this.props.onSelect(idUser)
  }

  handlePageChange = (pageNumber) => {
    let offset = pageNumber-1

    this.setState({
        isLoading: true
    },()=>setTimeout(this.handlePageChangeCallBack, 300, pageNumber, offset));
  }

  handlePageChangeCallBack(pageNumber, offset){
      this.setState({
          isLoading: false,
          activePage: pageNumber,
          offsetQuery: offset,
      })
  }

  onChangeSearch = (ev) => {
    const {value} = ev.target
    this.setState({
      search: value
    })
  }

  getUrlAvatarById(id){
    return companyService.getUrlLogoById(id)
  }

  getColorByType(type){
    return userService.getColorByType(type)
  }

  render(){
    const {tab, editUserId, search, isCloneRequest} = this.state
    const {userList} = this.state
    let userListFiltered = search ? userList.filter(item => item.username.indexOf(search) !== -1) : userList

    if(!search){
      userListFiltered = userList.slice(
        this.state.itemsCountPerPage*this.state.offsetQuery,
        this.state.itemsCountPerPage*(this.state.offsetQuery+1))
    }

    return(
      <div className="tabs-user-manage">
        <Button.List>
          <Button color="link" size="lg" className={tab==="Open" && "btn-tab-active"} onClick={()=>this.setTabToShow('Open')}>Open</Button>
          <Button color="link" size="lg" className={tab==="New" && "btn-tab-active"} onClick={()=>this.setTabToShow('New')}>New</Button>
          <Button color="link" size="lg" className={tab==="Edit" && "btn-tab-active"} disabled={!this.state.isEditRequest} onClick={()=>this.setTabToShow('Edit')}>Edit</Button>
        </Button.List>
        <Tabs initialTab={tab} key={tab+'-'+editUserId}>
          <Tab title="Open">
            <Dimmer active={this.state.isLoading} loader={this.state.isLoading}>
              <Table striped={true} className="table-transition-height">
                  <Table.Header>
                    <Table.ColHeader></Table.ColHeader>
                    <Table.ColHeader>
                      <div className="row">
                        <div className="col-auto align-self-end">Username</div>
                        <div className="col-7">
                          <Form.Input name="search-username" placeholder="Search..." className="form-control-sm" icon="search" position="append" onChange={this.onChangeSearch}/>
                        </div>
                      </div>
                    </Table.ColHeader>
                    <Table.ColHeader>Name</Table.ColHeader>
                    <Table.ColHeader>Type</Table.ColHeader>
                    <Table.ColHeader>Company</Table.ColHeader>
                    <Table.ColHeader alignContent="right">
                      Action{' '}<Button className="ml-1" color="link" icon="refresh-ccw" size="sm" onClick={this.getAllUser}/>
                    </Table.ColHeader>
                  </Table.Header>
                  <Table.Body>
                    {userListFiltered.length>0
                      ? userListFiltered.map((item) => {
                          let abbrName = 'U';
                          if(item.name && item.surname){
                            abbrName = item.name.slice(0, 1).toUpperCase() + item.surname.slice(0, 1).toUpperCase();
                          }
                          const fullName = (item.surname && item.name) ? item.surname + ' ' + item.name : '-'
                          const company = item.company ? item.company.name : ''
                          // const logoUrl = item.company ? this.getUrlAvatarById(item.company.logoUrl) : ''
                          return(
                            <>
                              <Table.Row className={(item.companyId===null && "text-muted")}>
                                <Table.Col className="width-50 pb-0 pt-2">
                                  <span className={item.companyId===null && 'opacity-2'}>
                                  {
                                    item.avatarUrl ? <Avatar imageURL={this.getUrlAvatarById(item.avatarUrl)}/>
                                    : <Avatar color={this.getColorByType(item.type)}>{abbrName}</Avatar>
                                  }
                                  </span>
                                </Table.Col>
                                <Table.Col>
                                  <div className="mb-1">{item.username}</div>
                                </Table.Col>
                                <Table.Col>
                                  <div className="mb-1">{fullName}</div>
                                </Table.Col>
                                <Table.Col>
                                  <Tag color={userService.getColorByType(item.type)} className={item.companyId===null && 'opacity-2'}>
                                    {item.type ? userService.getLabelByType(item.type) : "?"}
                                  </Tag>
                                </Table.Col>
                                <Table.Col>
                                  {
                                    item.companyId ?
                                    <Tag>
                                      {company}
                                    </Tag>
                                    :
                                    <small className="text-danger">
                                      User disabled
                                    </small>
                                  }
                                </Table.Col>
                                <Table.Col alignContent="right">
                                  {item.type !== "guest" ?
                                    <Button.List className="d-inline-flex">
                                      <Button color="info" title="Edit" outline size="sm" icon="edit" onClick={()=>this.editUser(item.id)}></Button>
                                      {/* <Button color="info" title="Clone" outline size="sm" icon="copy" onClick={()=>this.cloneUser(item.id)}></Button> */}
                                      {
                                        this.props.onSelect &&
                                        <Button color="primary" title="Add to search" outline size="sm" icon="plus" onClick={()=>this.addAdvertisersFromUser(item.id)}/>
                                      }
                                      <Popconfirm placement="top" title="Are you sure you want to delete it?" onConfirm={()=>this.deleteUser(item.id)} okText="Delete" cancelText="No">
                                        <Button color="danger" title="Delete" outline size="sm" icon="trash"></Button>
                                      </Popconfirm>
                                    </Button.List>
                                    :
                                    <span className="text-muted">User system for share</span>
                                  }
                                </Table.Col>
                              </Table.Row>
                              { // Preview Advertisers
                                (this.state.previewId !== null && this.state.previewId === item.id) &&
                                <Table.Row>
                                  <Table.Col colSpan={5}>
                                    {this.getUserListPreview()}
                                  </Table.Col>
                                </Table.Row>
                              }
                            </>
                          )})
                      : <p className="p-2"><em>No User found</em></p>
                    }
                  </Table.Body>
              </Table>
              {
                search === '' &&
                  <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.itemsCountPerPage}
                    totalItemsCount={this.state.totalItemsCount}
                    maxPageRangeDisplayed={this.state.maxPageRangeDisplayed}
                    handlePageChange={this.handlePageChange}
                  />
              }
            </Dimmer>
          </Tab>

          <Tab title="New">
            <UserManageForm
              isEditRequest={isCloneRequest}
              userId={null}
              username={isCloneRequest && ('copy-' + this.state.cloneUsername)}
              name={isCloneRequest && this.state.cloneName}
              surname={isCloneRequest && this.state.cloneSurname}
              companySelected={isCloneRequest && this.state.cloneCompanyId}
              typeSelected={isCloneRequest && this.state.cloneType}
              userAvatarUrl={isCloneRequest && this.state.cloneUserAvatarUrl}
              resetForm={this.resetFormClone}
              callbackOnSave={this.props.onSelect ? this.props.onSelect : null}
            />
          </Tab>

          {
            this.state.isEditRequest &&
            <Tab title="Edit">
              <UserManageForm
                isEditRequest={this.state.isEditRequest}
                userId={this.state.editUserId}
                username={this.state.editUsername}
                name={this.state.editName}
                surname={this.state.editSurname}
                companySelected={this.state.editCompanyId}
                typeSelected={this.state.editType}
                userAvatarUrl={this.state.editUserAvatarUrl}
                resetForm={()=>this.setTabToShow('Open')}
                callbackOnSave={this.props.onSelect ? this.props.onSelect : null}
              />
            </Tab>
          }
        </Tabs>
      </div>
    )
  }
}

export default UserManage;
