/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { Dimmer } from "tabler-react";
import { Select, Button,  Form } from "antd";
import { CalendarOutlined, LineChartOutlined, UndoOutlined } from "@ant-design/icons";
import _ from "lodash";

const { Option } = Select;

function SyncingFilters(props) {
  const {
    titleSelectDays1 = "Period 1",
    titleSelectDays2 = "Period 2",
    params = ["mediaOwner", "dashboardType", "circuit"],
    labels = ["Media Owner", "Type", "Circuit"],
    hiddenPeriodFilters = false
  } = props;

  const [selectedFirstDays, setSelectedFirstDays] = useState(props.firstDays)
  const [selectedSecondDays, setSelectedSecondDays] = useState(props.secondDays)

  const hasAllFilters = _.includes(props.filters, params[0]) && _.includes(props.filters, 'firstDays')

  return (
    <>
      <Form layout="horizontal" className="my-auto" wrapperCol={{span:'auto'}} labelCol={{span: 'auto'}}>
        <div className="row text-left pt-2">
          {_.includes(props.filters, params[0]) && 
            <>
              <div className="col-auto py-2">
                <h5 className="ml-3 mb-0">{labels[0]}:</h5>
              </div>
              <div className="col" style={{display:'flex', justifyContent:'flex-start'}}>
                <Form.Item className="mb-0">
                  <Select style={{minWidth: 172}} onChange={props.onChangeMediaOwner} value={props.currentMediaOwner} disabled={props.currentCircuit!=='' || props.currentDashboardType!==''} >
                    <Option value=''>Choose a {labels[0]}</Option>
                    {props.mediaOwners.length > 0 && props.mediaOwners.map(value => <Option value={value} > {value} </Option>)}
                  </Select>
                </Form.Item>
              </div>
            </>
          }
          {_.includes(props.filters, params[1]) && 
            <>
              <div className="col-auto py-2">
                <h5 className="ml-3 mb-0">{labels[1]}:</h5>
              </div>
              <div className="col" style={{display:'flex', justifyContent:'flex-start'}}>
                <Form.Item className="mb-0">
                  <Select style={{minWidth: 172}} onChange={props.onChangeDashboardType} value={props.currentDashboardType} disabled={props.currentCircuit!=='' || props.currentMediaOwner!==''}>
                    <Option value=''>Choose a {labels[1]}</Option>

                    {props.dashboardTypes.length > 0 && props.dashboardTypes.map(value => <Option value={value} > {value} </Option>)}
                  </Select>
                </Form.Item>
              </div>
            </>
          }
          {_.includes(props.filters, params[2]) && 
            <>
              <div className="col-auto py-2">
                <h5 className="ml-3 mb-0">{labels[2]}:</h5>
              </div>
              <div className="col" style={{display:'flex', justifyContent:'flex-start'}} >
                <Form.Item className="mb-0">
                  <Select style={{minWidth: 172}} onChange={props.onChangeCircuit} value={props.currentCircuit} disabled={(props.currentMediaOwner!=='' || props.currentDashboardType!=='')}>
                    <Option value=''>Choose a {labels[2]}</Option>

                    {props.circuits.length > 0 && props.circuits.map(value => <Option value={value} > {value} </Option>)}
                  </Select>
                  
                </Form.Item>

                { ((_.includes(props.filters, params[2]) || _.includes(props.filters, params[0]) || _.includes(props.filters, params[1]))&& (props.currentMediaOwner!=='' || props.currentCircuit!=='' || props.currentDashboardType!=='')) &&
                    <div className="col-auto">
                      <Button
                        type="link" 
                        onClick={props.onReset}
                      >
                        <UndoOutlined></UndoOutlined>
                      </Button>
                    </div>
                  }
              </div>
            </>
          }
          
        </div>

        {!hiddenPeriodFilters &&
          <div className={"row " + (hasAllFilters && "mt-3")} style={{display:'flex', justifyContent:'flex-start'}}>
          {_.includes(props.filters, 'firstDays') && <>
            <div className="col-auto py-2">
              <h5 className="ml-3 mb-0">{titleSelectDays1}</h5>
            </div>
            <div className="col-auto">
              <Form.Item
                // label='Select Days: '
                className="mb-0"
              >
                <Select
                  defaultValue={props.days ? props.days : props.firstDays}
                  className="text-left"
                  removeIcon={null}
                  placeholder={
                    <>
                      <CalendarOutlined /> Pick a Day{" "}
                    </>
                  }
                  style={{ minWidth: "20%" }}
                  mode={"multiple"}
                  onChange={(value) => setSelectedFirstDays(value)}
                  onBlur={() => props.onChangeDays('top',selectedFirstDays)}
                >
                  <Option value="Monday">Monday</Option>
                  <Option value="Tuesday">Tuesday</Option>
                  <Option value="Wednesday">Wednesday</Option>
                  <Option value="Thursday">Thursday</Option>
                  <Option value="Friday">Friday</Option>
                  <Option value="Saturday">Saturday</Option>
                  <Option value="Sunday">Sunday</Option>
                </Select>
              </Form.Item>
            </div>
            {/* <div className="col-auto">
              <Button className={_.includes(props.filters, 'secondDays') ? 'd-none' : ''} onClick={() => props.onChangeDays('top',selectedFirstDays)}>
                <LineChartOutlined></LineChartOutlined>
              </Button>
            </div> */}
          </>}
          {_.includes(props.filters, 'secondDays') && <>
            <div className="col-auto py-2">
              <h5 className="ml-3 mb-0">{titleSelectDays2}</h5>
            </div>
            <div className="col-auto ">
              <Form.Item className="mb-0">
                <Select
                  defaultValue={props.secondDays} 
                  removeIcon={null}
                  className="text-left"
                  placeholder={
                    <>
                      <CalendarOutlined /> Pick a Day{" "}
                    </>
                  }
                  style={{ minWidth: "20%" }}
                  mode={"multiple"}
                  onChange={(value) => setSelectedSecondDays(value)}
                  onBlur={() => props.onChangeDays('bot',selectedSecondDays)}
                >
                  <Option value="Monday">Monday</Option>
                  <Option value="Tuesday">Tuesday</Option>
                  <Option value="Wednesday">Wednesday</Option>
                  <Option value="Thursday">Thursday</Option>
                  <Option value="Friday">Friday</Option>
                  <Option value="Saturday">Saturday</Option>
                  <Option value="Sunday">Sunday</Option>
                </Select>
              </Form.Item>
            </div>
            {/* <div className="col-auto">
              <Button onClick={() => props.onChangeDays('bot',selectedSecondDays)}>
                <LineChartOutlined/>
              </Button>
            </div> */}
          </>
          }
        </div>}
      </Form>
    </>
  );
}

export default SyncingFilters;
