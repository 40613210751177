import { authHeader } from "../_helpers";
import {defineConfig} from '../config'

export const userService = {
  login,
  loginGuest,
  profile,
  checkProfile,
  logout,
  setInStorage,
  sendMailCodeReset,
  setNewPassword,
  createUser,
  readUser,
  readUserAll,
  updateUser,
  deleteUser,
  getUsersByCompanyId,
  getUrlAvatarById,
  uploadAvatar,
  getTypesList,
  getColorByType,
  getLabelByType
};

const {apiUrl, apiVer} = defineConfig.USER_SERVICE

function login(username, password) {
  const bodyJson = {
    "username": username,
    "password": password
  }
  const requestOptions = {
    "method": "POST",
    "headers": {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(bodyJson)
  }

  return fetch(
    apiUrl+apiVer+'/user/auth',
    requestOptions
  )
    .then(handleResponse)
    .then(response => {
      // login successful if there's a user in the response
      if (response) {
        // console.log(response)
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        const auth = response.tokenType + " " +response.accessToken
        localStorage.setItem("auth", auth);

        return userService.profile(auth).then(
          user => {
            // if(!user.companyId || !user.company){
            //   console.error("No company!")
            //   window.location.replace("/logout/401");
            // }
            const resUser = this.setInStorage(user)
            return resUser
          },
          error => {
            console.log(error);
            return error;
          }
        );
      }


    })
}

function loginGuest(accessToken, guestToken) {
    const auth = "Bearer " + accessToken
    localStorage.setItem("auth", auth);
    localStorage.setItem("guest", guestToken);

    return userService.profile(auth).then(
      user => {
        const resUser = this.setInStorage(user)
        return resUser
      },
      error => {
        console.log(error);
        return error;
      }
    );
}

function setInStorage(user){
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("type", user.type);
  localStorage.setItem("group", user.group);
  localStorage.setItem("check", 1);
  return user
}


function sendMailCodeReset(username) {
  const url = apiUrl+apiVer+"/user/password/edit/code";

  const body = {
    "username": username
  }

  const requestOptions = {
    method: "POST",
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  // console.log(requestOptions)

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      // login successful if there's a user in the response
      // if (res) {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
      //   console.log(res)
      // }
      return res;
    });
}



function setNewPassword(username, password, resetCode) {
  const url = apiUrl+apiVer+"/user/password/edit";
  const body = {
    "username": username,
    "newPassword": password,
    "resetCode": resetCode
  }

  const requestOptions = {
    method: "POST",
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      // login successful if there's a user in the response
      // if (res) {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        // console.log(res)
      // }
      return res;
    });
}


function profile(auth) {
  let header = {
    'token': auth
  }
  const requestOptions = {
    "method": "GET",
    "headers": header
  };

  return fetch(
    apiUrl+apiVer+'/user/profile',
    requestOptions
  ).then(handleResponse);
}

function checkProfile() {
  const auth = localStorage.getItem("auth");
  userService.profile(auth).then(
    user => {
      if(!user.companyId || !user.company){
        console.error("No company!")
        window.location.replace("/logout/401");
      }
      return this.setInStorage(user)
    },
    error => {
      console.log(error);
      return error;
    }
  );
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  localStorage.removeItem("type");
  localStorage.removeItem("group");
  localStorage.removeItem("auth");
}

function createUser(user) {
  const url = apiUrl+apiVer+"/user/create";
  const body = JSON.stringify(user)

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: body
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function readUser(id) {
  const url = apiUrl+apiVer+"/user/get/id/"+id;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function readUserAll() {
  const url = apiUrl+apiVer+"/user/get/all";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function updateUser(id, user) {
  const url = apiUrl+apiVer+"/user/update/id/"+id;

  const body = JSON.stringify(user)

  const requestOptions = {
    method: "PUT",
    headers: authHeader("application/json"),
    body: body
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}


function deleteUser(id){
  const url = apiUrl+apiVer+"/user/delete/id/"+id;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getUsersByCompanyId(id){
  const url = apiUrl+apiVer+"/user/find/cid/"+id;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });

}

function getUrlAvatarById(id) {
  return apiUrl+apiVer+"/images/company/"+id;
}



function uploadAvatar(formData) {
  const url = apiUrl+apiVer+"/images/upload";

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      // request successful if there's a user in the response
      if (res) {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        // console.log(res)
      }
      return res;
    });
}

function getTypesList(){
  return [
    {
      label: "Super Admin",
      value: "admin",
      desc: "Tutti i privilegi",
      color: "red"
    },
    {
      label: "Admin (Ex Kinetic)",
      value: "user",
      desc: "Tutti gli advertiser, planner e DOOH, no import campaign, no user manager",
      color: "orange"
    },
    {
      label: "Company Leader",
      value: "companyleader",
      desc: "Advertiser abilitati, planner, no import campaign, no user manager",
      color: "yellow"
    },
    {
      label: "Company User",
      value: "companyuser",
      desc: "Advertiser abilitati, planner, no import campaign, no user manager",
      color: "lime"
    },
    {
      label: "Advertiser",
      value: "advertiser",
      desc: "Advertiser abilitati, no planner, no import campaign, no campaign manage, no user manager",
      color: "green"
    },
    {
      label: "Media Owner",
      value: "mediaowner",
      desc: "Media Owner Admin Demo",
      color: "teal"
    },
    {
      label: "Guest user",
      value: "guest",
      desc: "Guest user (role system)",
      color: "gray"
    }
  ]
}

function getColorByType(type){
  const types = getTypesList()
  let objType = types.filter(t => type === t.value)
  const color = objType.length === 1 ? objType[0].color : null
  return color
}

function getLabelByType(type){
  const types = getTypesList()
  let objType = types.filter(t => type === t.value)
  const label = objType.length === 1 ? objType[0].label : null
  return label
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
