/*global google*/
import React from "react"
import "./Map.scss";
const { compose, withProps } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} = require("react-google-maps");
const {HeatmapLayer} = require("react-google-maps/lib/components/visualization/HeatmapLayer");
const stylesMap = require("./styles/default.json");
const minimalMapOptions = {
    fullscreenControl: true,
    streetViewControl: false,
    zoomControl: true,
    mapTypeControl: false,
    styles: stylesMap
};
const gradient = [
    'rgba(0, 255, 255, 0)',
    '#467FCF',
    '#1D65B2',
    '#004C95',
    '#003579',
    '#001F5E',
]
const apiKey = "AIzaSyDk2tsrjgOei53e0A5X8J7nFfj-MiDymIY"

const MapWithAHeatmapLayer = compose(
  withProps({
    googleMapURL:
        `https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3.exp&libraries=visualization`,
        loadingElement: <div style={{ height: `100%`, minHeight: 'inherit'}} />,
        containerElement: <div style={{ height: `100%`, minHeight: 'inherit' }} />,
        mapElement: <div style={{ height: `100%`, minHeight: 'inherit' }} />,
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={6}
    defaultCenter={{ lat: 41.397, lng: 10.644 }}
    options={minimalMapOptions}
    >
    <HeatmapLayer
        data={props.data.map((p) => {
            return {
                "location": new google.maps.LatLng(p.lat, p.lng ),
                "weight": props.weight === false ? 10 : (p.weight ? p.weight : 1)
            }
        })}
        options={{
            radius: 15,
            opacity: 1,
            // gradient: gradient,
            // dissipating: false
        }}
    />
  </GoogleMap>
));

class HeatMapWrapper extends React.PureComponent {
    constructor(props){
        super(props)
        this.state = {
            heatmapData: [],
            weight: true
        }
    }

    componentDidMount(){
        if(typeof this.props.weight !== "undefined"){
            const {weight} = this.props
            this.setState({
                weight
            })
        }
    }

    componentWillReceiveProps(nProps){
        if(nProps.heatmapData != this.props.heatmapData){
            const {heatmapData} = nProps
            this.setState({
                heatmapData
            })
        }
        if(nProps.weight != this.props.weight){
            const {weight} = nProps
            this.setState({
                weight
            })
        }
    }

    render() {
        const {heatmapData, weight} = this.state
        return (
            <div className="gmaps-custom card-planner-plan-results">
                <MapWithAHeatmapLayer
                    data={heatmapData}
                    weight={weight}
                />
            </div>
        )
    }
}

export default HeatMapWrapper;
