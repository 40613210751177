import { userService } from "./"
import {defineConfig} from '../config'

export const geoInfoService = {
  getAllRegions,
  getDistrictsByRegion,
  getCitiesByDistrict,
  getApiKeyGMaps,
  getGMapsInfoFromQuery,
  getArcGisInfoFromQuery
};

const {apiUrl, apiVer, apiKey, apiGMaps, apiArcGis} = defineConfig.GEO_SERVICE

function getApiKeyGMaps() {
  return apiKey
}

function getGMapsInfoFromQuery(query) {
  const requestOptions = {
    method: "GET",
  };
  return fetch(apiGMaps + "?address="+query+"&key=" + getApiKeyGMaps(), requestOptions)
  .then(handleResponse);
}

function getArcGisInfoFromQuery(lat, lng) {
  const requestOptions = {
    method: "GET",
  };

  const query = lng + ',' + lat

  return fetch(apiArcGis + "/reverseGeocode?f=json&location=" + query, requestOptions)
  .then(handleResponse);
}

function getAllRegions() {
    const requestOptions = {
        method: "GET",
        // headers: authHeader(),
    };
    return fetch(apiUrl+apiVer+"/geoInfo/region/find/all", requestOptions)
    .then(handleResponse);
}

function getDistrictsByRegion(idr = 0){
    const requestOptions = {
        method: "GET",
        // headers: authHeader(),
    };
    return fetch(apiUrl+apiVer+"/geoInfo/district/find/idr/"+idr, requestOptions)
    .then(handleResponse);
}

function getCitiesByDistrict(idp = ""){
    const requestOptions = {
        method: "GET",
        // headers: authHeader(),
    };
    return fetch(apiUrl+apiVer+"/geoInfo/cities/find/idp/"+idp, requestOptions)
    .then(handleResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          userService.logout();
          window.location.reload(true);
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      return data;
    });
}
