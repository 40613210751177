// @flow

import * as React from "react";
import { NavLink, withRouter } from "react-router-dom";
import {ToastContainer} from "react-toastify"

import {
  Site,
  Icon,
  Tag,
  Grid,
  RouterContextProvider,
  Nav
} from "tabler-react";
import LogoDefault from './static/images/kinetic.png'
import LogoDefaultGuest from './static/images/nexus.png'
import IconDropdown from './components/IconDropdown/IconDropdown.react'

import type { NotificationProps } from "tabler-react";
import { companyService, userService } from "./_services";

const { REACT_APP_ENV } = process.env;

type Props = {|
  +children: React.Node,
|};
type State = {|
  notificationsObjects: Array<NotificationProps>,
  user: Object
|};

type subNavItem = {|
  +value: string,
  +to?: string,
  +icon?: string,
  +LinkComponent?: React.ElementType,
|};

type navItem = {|
  +value: string,
  +to?: string,
  +icon?: string,
  +active?: boolean,
  +LinkComponent?: React.ElementType,
  +subItems?: Array<subNavItem>,
  +useExact?: boolean,
|};

const devMode = () => {
  if(REACT_APP_ENV ==='prod'){
    return null
  }
  else{
    return (
      <Tag color="danger" addOn={<Icon name="server" />}>
        {REACT_APP_ENV}
      </Tag>
    )
  }
}

const navBarItems: Array<navItem> = [
  {
    value: "Home",
    to: "/",
    icon: "home",
    LinkComponent: withRouter(NavLink),
    useExact: true,
  },
  {
    value: "Interface",
    icon: "box",
    subItems: [
      {
        value: "Cards Design",
        to: "/cards",
        LinkComponent: withRouter(NavLink),
      },
      { value: "Charts", to: "/charts", LinkComponent: withRouter(NavLink) },
      {
        value: "Pricing Cards",
        to: "/pricing-cards",
        LinkComponent: withRouter(NavLink),
      },
    ],
  },
  {
    value: "Components",
    icon: "calendar",
    subItems: [
      { value: "Maps", to: "/maps", LinkComponent: withRouter(NavLink) },
      { value: "Icons", to: "/icons", LinkComponent: withRouter(NavLink) },
      { value: "Store", to: "/store", LinkComponent: withRouter(NavLink) },
      { value: "Blog", to: "/blog", LinkComponent: withRouter(NavLink) },
    ],
  },
  {
    value: "Pages",
    icon: "file",
    subItems: [
      { value: "Profile", to: "/profile", LinkComponent: withRouter(NavLink) },
      { value: "Login", to: "/login", LinkComponent: withRouter(NavLink) },
      {
        value: "Register",
        to: "/register",
        LinkComponent: withRouter(NavLink),
      },
      {
        value: "Forgot password",
        to: "/forgot-password",
        LinkComponent: withRouter(NavLink),
      },
      { value: "400 error", to: "/400", LinkComponent: withRouter(NavLink) },
      { value: "401 error", to: "/401", LinkComponent: withRouter(NavLink) },
      { value: "403 error", to: "/403", LinkComponent: withRouter(NavLink) },
      { value: "404 error", to: "/404", LinkComponent: withRouter(NavLink) },
      { value: "500 error", to: "/500", LinkComponent: withRouter(NavLink) },
      { value: "503 error", to: "/503", LinkComponent: withRouter(NavLink) },
      { value: "Email", to: "/email", LinkComponent: withRouter(NavLink) },
      {
        value: "Empty page",
        to: "/empty-page",
        LinkComponent: withRouter(NavLink),
      },
      { value: "RTL", to: "/rtl", LinkComponent: withRouter(NavLink) },
    ],
  },
  {
    value: "Forms",
    to: "/form-elements",
    icon: "check-square",
    LinkComponent: withRouter(NavLink),
  },
  {
    value: "Gallery",
    to: "/gallery",
    icon: "image",
    LinkComponent: withRouter(NavLink),
  },
  {
    icon: "file-text",
    value: "Documentation",
    to:
      process.env.NODE_ENV === "production"
        ? "https://tabler.github.io/tabler-react/documentation"
        : "/documentation",
  },
];

class SiteWrapper extends React.Component<Props, State> {

  state = {
    notificationsObjects: [
      {
        unread: true,
        // avatarURL: "demo/faces/male/41.jpg",
        message: (
          <React.Fragment>
            <a href="/">
              <Icon prefix="fe" name="home" /> Home
            </a>
          </React.Fragment>
        ),
        // time: "10 minutes ago",
      },
      {
        unread: true,
        // avatarURL: "demo/faces/female/1.jpg",
        message: (
          <React.Fragment>
            <a href="/campaigns">
              <Icon prefix="fa" name="bullhorn" /> Campaign Report
            </a>
          </React.Fragment>
        ),
        // time: "1 hour ago",
      },
      {
        unread: true,
        // avatarURL: "demo/faces/female/18.jpg",
        message: (
          <React.Fragment>
            <a href="/mediaowner">
              <Icon prefix="fa" name="tv" /> Media Owner Report
            </a>
          </React.Fragment>
        ),
        // time: "2 hours ago",
      },
    ],
    isAdminUser: false
  };

  componentDidMount(){
    this.getLocalUserInfo()
  }

  getLocalUserInfo(){
    if(localStorage.getItem("type") == "admin"){
      this.setState({
        isAdminUser: true
      })
    }
    else{
      this.setState({
        isAdminUser: false
      })
    }
  }

  render(): React.Node {
    const {isAdminUser} = this.state
    const notificationsObjects = this.state.notificationsObjects || [];
    const unreadCount = this.state.notificationsObjects.reduce(
      (a, v) => a || v.unread,
      false
    );

    const options = (
      isAdminUser ? [
          {
            icon: "user",
            value: "Profile",
            to: "/profile",
            LinkComponent: withRouter(NavLink),
          },
          { isDivider: true },
          {
            icon: "users",
            value: "Users",
            to: "/users",
            LinkComponent: withRouter(NavLink),
          },
          {
            icon: "briefcase",
            value: "Companies",
            to: "/companies",
            LinkComponent: withRouter(NavLink),
          },
          { isDivider: true },
          {
            icon: "log-out",
            value: "Sign out",
            to: "/logout",
            LinkComponent: withRouter(NavLink),
          },
        ]
      :
      [
        {
          icon: "user",
          value: "Profile",
          to: "/profile",
          LinkComponent: withRouter(NavLink),
        },
        { isDivider: true },
        {
          icon: "log-out",
          value: "Sign out",
          to: "/logout",
          LinkComponent: withRouter(NavLink),
        },
      ]
    )

    var user = {email: ''};
    var type = ""
    var objType = null

    const typesList = userService.getTypesList();

    if(localStorage.getItem("user")){
      user = JSON.parse(localStorage.getItem("user"));
      objType = typesList.filter(t => t.value === user.type)
      objType = objType.length === 1 ? objType[0] : 'Unknown type'
      type = objType.label
    }

    let abbrName = 'U';
    if(user.name && user.surname){
      abbrName = user.name.slice(0, 1).toUpperCase() + user.surname.slice(0, 1).toUpperCase();
    }
    let logoCompanyUrl = LogoDefault;
    let companyName = ""
    if(user.company){
      logoCompanyUrl = user.company.logoUrl ? companyService.getUrlLogoById(user.company.logoUrl) : LogoDefault
      companyName = user.company.name
    }

    const noFooter = false;
    const accountDropdownProps = {
      avatarURL: user.avatarUrl ? companyService.getUrlLogoById(user.avatarUrl) : "#",
      name: user.username,
      // description: type,
      description: companyName,
      options,
      children: !user.avatarUrl && abbrName,
      color: objType.color
    };

    return (
      <Site.Wrapper
        headerProps={{
          href: "/",
          alt: companyName,
          imageURL: this.props.readOnly ? LogoDefaultGuest : logoCompanyUrl,
          navItems: (
            //<Nav.Item type="div" className="d-none d-md-flex  ">
            !this.props.readOnly ? <IconDropdown/> : (
              <>
              <Nav.Item type="div" className="d-none d-md-flex">
                {this.props.title}
              </Nav.Item>
              <Nav.Item type="div" className="d-none d-md-flex">
                {this.props.extra}
              </Nav.Item>
              </>
            )
            //</Nav.Item>
          ),
          accountDropdown: !this.props.readOnly && accountDropdownProps,
        }}
        // navProps={{ itemsObjects: navBarItems }} //BARRA MENU TAB
        routerContextComponentType={withRouter(RouterContextProvider)}
        footerProps={{
          // links: [
          //   <a href="#">First Link</a>,
          //   <a href="#">Second Link</a>,
          //   <a href="#">Third Link</a>,
          //   <a href="#">Fourth Link</a>,
          //   <a href="#">Five Link</a>,
          //   <a href="#">Sixth Link</a>,
          //   <a href="#">Seventh Link</a>,
          //   <a href="#">Eigth Link</a>,
          // ],
          // note:
          //   "Kinetic",
          copyright: (
             this.props.noFooter!==true &&
              <React.Fragment>
                © <a href="https://kineticww.com/it/" target="_blank">Kineticww</a> 2019 | A WPP Company - All rights Reserved
              </React.Fragment>
          ),
          nav: (
            <React.Fragment>
              <ToastContainer position="bottom-center"/>
              {
                devMode() !== null &&
                <>
                  {/* <Grid.Col auto={true}>
                    <List className="list-inline list-inline-dots mb-0">
                      <List.Item className="list-inline-item">
                        <a href="http://tabler-react.com/documentation" target="_blank">Documentation UI Kit</a>
                      </List.Item>
                    </List>
                  </Grid.Col> */}
                  <Grid.Col auto={true}>
                    {devMode()}
                  </Grid.Col>
                </>
              }
            </React.Fragment>
          ),
        }}
      >
        {this.props.children}
      </Site.Wrapper>
    );
  }
}

export default SiteWrapper;
