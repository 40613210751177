import React, { Component } from "react";
import Dropzone from "../dropzone/Dropzone";
import "./Upload.css";
import Progress from "../progress/Progress";
import iconUploadSVG from "../baseline-check_circle_outline-24px.svg";
import {
  Button
} from "tabler-react";

import { assetService, doohService } from "../../../_services";

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      normalizing: false,
      uploadClearable: false, //if true activate clear button
      filename: null,
      result: null,
      response: null,
      error: null
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.normalizeAsset = this.normalizeAsset.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  onFilesAdded(files) {
    this.setState(prevState => ({
      files: prevState.files.concat(files)
    }));
  }

  async uploadFiles() {
    this.setState({ uploadProgress: {}, uploading: true });
    const promises = [];
    this.state.files.forEach(file => {
      console.log('[UPLOAD] Carico file')
      console.log(file)

      let uploadProgress = { ...this.state.uploadProgress };
      uploadProgress[file.name] = {
        state: "pending",
        percentage: 20
      };
      this.setState({
        uploadProgress
      }, () => promises.push(this.sendRequest(file)))

    });
    try {
      await Promise.all(promises);
      this.setState({ successfullUploaded: true, uploading: false });
      this.props.handlerUploadProgress(true)
      console.log('[UPLOAD] handlerUploadProgress FILE UPPATO!')
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: true, uploading: false });
    }
  }

  sendRequest(file) {
    return new Promise((resolve, reject) => {
      const {type} = this.props
      console.log('type', type)
      if(type !=='dooh' && type !== 'planner')
        return;

      const isDooh = type==='dooh'

      const formData = new FormData();
      formData.append("file", file, file.name);

      assetService.loadFileAsset(formData, isDooh).then(
        result => {
          let uploadProgress = { ...this.state.uploadProgress };
          uploadProgress[file.name] = {
            state: "done",
            percentage: 100
          };
          this.setState({
            filename: file.name,
            uploadProgress,
            normalizing: true,
            result: isDooh ? result : 'OK'
          }, () => this.normalizeAsset() )

          resolve(result);
        },
        error => {
          this.props.handlerResponse('error import', this.state.filename)
          this.setState({
              error
          })
        }
      );
    })
  }

  normalizeAsset = () => {
    const {type} = this.props
    if(type === "dooh"){
      doohService.normalizeAsset().then(
        res => {
          this.props.handlerResponse(this.state.result, this.state.filename)
          this.setState({
            normalizing: 'Done!'
          })
        },
        error => {
          this.setState({
            error
          })
        }
      )
    }
    else{
      assetService.parseNewReverseGeocode().then(
        res => {
          this.props.handlerResponse(this.state.result, this.state.filename)
          this.setState({
            normalizing: 'Reversed done!'
          })
        },
        error => {
          this.props.handlerResponse('error reverse geocode', this.state.filename)
          this.setState({
            error
          })
        }
      )
    }
  }

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    if (this.state.uploading || this.state.successfullUploaded) {
      return (
        <div>
          <div className="ProgressWrapper">
            <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
            <img
              className="CheckIcon"
              alt="done"
              src={iconUploadSVG}
              style={{
                opacity:
                  uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
              }}
            />
          </div>
          <p
            className="text-success"
            style={{
                opacity:
                  uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
              }}
          >Import Successful</p>
        </div>

      );
    }
  }

  renderActions() {
    if (this.state.successfullUploaded && this.state.uploadClearable) {
      return (
        <Button
          onClick={() =>
            this.setState({ files: [], successfullUploaded: false })
          }
        >
          Clear
        </Button>
      );
    } else if(this.state.files.length > 0 && !this.state.uploading) {
      return (
        <Button
          disabled={this.state.files.length < 0 || this.state.uploading || this.state.successfullUploaded}
          onClick={this.uploadFiles}
        >
          Upload
        </Button>
      );
    }
  }

  render() {
    const {normalizing, error} = this.state
    const {type} = this.props

    return (
      <div className="Upload">
        <span className="Title"></span>
        <div className="Content">
          <div className={this.state.files.length<=0 ? "center_upload" : ""}>
            <Dropzone
              onFilesAdded={this.onFilesAdded}
              disabled={this.state.uploading || this.state.successfullUploaded}
            />
          </div>
          <div className="Files">
            {this.state.files.map(file => {
              return (
                <div key={file.name} className="Row">
                  <span className="Filename">{file.name}</span>
                  {this.renderProgress(file)}
                </div>
              );
            })}
          </div>
        </div>
        <div className="Actions p-3">{this.renderActions()}</div>

          {(normalizing && !error) && <h4 className="ml-2">Normalizing Asset in progress... <span className="text-success">{normalizing}</span></h4>}
          {error && <h4 className="ml-2 text-danger">{error}</h4>}
      </div>
    );
  }
}

export default Upload;
