import React, { useState, useEffect } from "react";
import packageJson from "../package.json";
import moment from "moment";

const buildDateGreaterThan = (latestDate, currentDate, localDate) => {
  if(!localDate || latestDate != localDate )
    return true;

  const momLatestDateTime = moment(latestDate);
  const momLocalDateTime = moment(localDate)

  if (momLatestDateTime.isAfter(momLocalDateTime)) {
    return true;
  } else {
    return false;
  }
};

function withClearCache(Component) {
  function ClearCacheComponent(props) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

    useEffect(() => {
      fetch("/meta.json", {cache: "no-store"})
        .then((response) => response.json())
        .then((meta) => {
          const localVersionDate = localStorage.getItem('version')
          const latestVersionDate = meta.buildDate;
          const currentVersionDate = packageJson.buildDate;

          const shouldForceRefresh = buildDateGreaterThan(
            latestVersionDate,
            currentVersionDate,
            localVersionDate
          );
          if (shouldForceRefresh && localVersionDate) {
            localStorage.clear();
            console.log('New version:', localVersionDate, '->', currentVersionDate, '->', latestVersionDate, '[Clearing cache...]')
            localStorage.setItem('version', latestVersionDate)
            setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } else {
            console.log('Version is Up To Date:', localVersionDate)
            setIsLatestBuildDate(true);
          }
        });
    }, []);

    const refreshCacheAndReload = () => {
      if (caches) {
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name);
          }
        });
      }
      // delete browser cache and hard reload
      // window.location.reload(true);

      //redirect to logout
      window.location.replace('/logout')

    };

    return (
      <React.Fragment>
        {isLatestBuildDate ? <Component {...props} /> : null}
      </React.Fragment>
    );
  }

  return ClearCacheComponent;
}

export default withClearCache;
