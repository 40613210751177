// @flow

import * as React from "react";
import _ from "lodash"
import {
  Grid,
  Form,
  Card,
  Dimmer
} from "tabler-react";

import { geoInfoService, justificativeService, campaignService } from "../_services";

class CampaignsReportFilters extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            idkc: -1,
            user: {},
            regions: [],
            districts: [],
            cities: [],
            mediaowners: [],
            formats: [],
            selectedReg: "",
            selectedRegL: "",
            selectedDis: "",
            selectedCit: "",
            selectedCitL: "",
            selectedMedOwn: "",
            selectedFrmt: "",
            error: null,
            isLoading: true,
            regionsFiltered: [],
            districtsFiltered: [],
            citiesFiltered: [],
            moFiltered: [],
            formatsFiltered: [],

            panels: [],
            panelsAll: [],
            panelsFiltGeo: []
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.panelsAll!==this.props.panelsAll){
            this.setState({
                panelsAll: nextProps.panelsAll,
                panelsFiltGeo: nextProps.panelsAll,
                isLoading: false
            }, ()=>this.loadRegionCampaign(this.state.panelsAll))
        }
    }

    loadRegionCampaign(items){
        let regions = _.groupBy(items, "region")
        regions = _.keys(regions);

        let districts = _.groupBy(items, "district")
        districts = _.keys(districts);

        let cities = _.groupBy(items, "municipality")
        cities = _.keys(cities);

        let mediaowners = _.groupBy(items, "mediaOwner")
        mediaowners = _.keys(mediaowners);

        let formats = _.groupBy(items, "panelType")
        formats = _.keys(formats);

        this.setState({
            regionsFiltered: regions,
            districtsFiltered: districts,
            citiesFiltered: cities,
            moFiltered: mediaowners.sort(),
            mediaowners: mediaowners.sort(),
            formatsFiltered: formats.sort(),
            formats: formats.sort()
        }, ()=> this.loadRegions())
    }

    loadRegions(){
        if(this.state.regionsFiltered){
            var regexFromMyArray = new RegExp(this.state.regionsFiltered.join("|"), 'gi');
            geoInfoService.getAllRegions().then(
                result => {
                    this.setState((state) => ({
                        // regions: _.intersection(result,state.regionsFiltered),
                        regions: result.filter(item => {
                            return item.regione.match( regexFromMyArray );
                        }),
                        districts: [],
                        cities: [],
                        selectedDis: "",
                        selectedCit: "",
                        isLoading: false,
                        error: null,
                    }))
                },
                error => {
                    this.setState({
                        error,
                        isLoading: false
                    })
                }
            );
        }
    }

    loadDistricts(idr){
        if(idr!=""){
            var regexFromMyArray = new RegExp(this.state.districtsFiltered.join("|"), 'gi');

            geoInfoService.getDistrictsByRegion(idr).then(
                result => {
                    this.setState({
                        districts: result.filter(item => {
                            return item.sigla.match( regexFromMyArray );
                        }),
                        cities: [],
                        isLoading: false,
                        error: null,
                    })
                },
                error => {
                    this.setState({
                        error,
                        isLoading: false
                    })
                }
            );
        }
        else{
            this.setState({
                districts: []
            })
        }
        this.setState({
            isLoading: false,
            error: null,
        })
    }

    loadCities(prov){
        if(prov!=""){
            let arrCities = this.state.citiesFiltered.sort();
            var regexFromMyArray = new RegExp(arrCities.join("$\\b|\\b^"), 'gi');
            console.log(this.state.citiesFiltered.join("$\\b|\\b^") )
            geoInfoService.getCitiesByDistrict(prov).then(
                result => {
                    this.setState({
                        cities: result.filter(item => {
                            return item.comune.match(regexFromMyArray );
                        }),
                        isLoading: false,
                        error: null,
                    })
                },
                error => {
                    this.setState({
                        error,
                        isLoading: false
                    })
                }
            );
        }
        this.setState({
            isLoading: false,
            error: null,
        })
    }

    handleChangeRegion(ev) {
        let val = ev.target.value
        let index = ev.nativeEvent.target.selectedIndex;
        let label = ev.nativeEvent.target[index].label;
        label = (val=='') ? '' : label
        this.setState({
            selectedReg: val,
            selectedRegL: label,
            selectedDis: "",
            selectedCit: "",
            selectedCitL: "",
            selectedMedOwn: "",
            selectedFrmt: "",
            isLoading: true
        }, ()=>this.handleChangeToParent(this.state, true))
        this.loadDistricts(val);
    }

    handleChangeDistrict(ev) {
        let val = ev.target.value
        this.setState({
            selectedDis: val,
            selectedCit: "",
            selectedCitL: "",
            selectedMedOwn: "",
            selectedFrmt: "",
            isLoading: true
        }, ()=>this.handleChangeToParent(this.state, true))
        this.loadCities(val);
    }

    handleChangeCity(ev) {
        let val = ev.target.value
        let index = ev.nativeEvent.target.selectedIndex;
        let label = ev.nativeEvent.target[index].label;
        label = (val=='') ? '' : label
        this.setState({
            selectedCit: val,
            selectedCitL: label,
            selectedMedOwn: "",
            selectedFrmt: "",
        }, ()=>this.handleChangeToParent(this.state, true))
    }

    handleChangeMediaOwner(ev) {
        let val = ev.target.value
        this.setState({
            selectedMedOwn: val,
        }, ()=>this.handleChangeToParent(this.state, false))
    }

    handleChangeFormat(ev) {
        let val = ev.target.value
        this.setState({
            selectedFrmt: val,
        }, ()=>this.handleChangeToParent(this.state, false))
    }
    
    handleChangeToParent(state, isGeo = false){
        this.props.handleState(state)
        if(isGeo){
            this.setState({
                panelsFiltGeo: this.initFilterPanelByGeo()
            }, ()=> this.loadMOAndFormatsFiltered(this.state.panelsFiltGeo))
        }
    }

    loadMOAndFormatsFiltered(items){
        let mediaowners = _.groupBy(items, "mediaOwner")
        mediaowners = _.keys(mediaowners);

        let formats = _.groupBy(items, "panelType")
        formats = _.keys(formats);

        this.setState({
            mediaowners: mediaowners.sort(),
            formats: formats.sort()
        })
    }

    initFilterPanelByGeo(){
        let idR = this.state.selectedRegL.trim().toLowerCase()
        let prov = this.state.selectedDis.trim().toLowerCase()
        let idC = this.state.selectedCitL.trim().toLowerCase()

        return this.filterPanelByGeo(idR, prov, idC)
    }

    filterPanelByGeo(idR, prov, idC){
        let appoggio = this.state.panelsFiltGeo
        let campaignPanelsAll = this.state.panelsAll
        let filteredPanels = campaignPanelsAll.filter(function(item) {
            let region = item.region || ""
            let district = item.district || ""
            let municipality = item.municipality || ""

            let condReg = region.trim().toLowerCase() == idR || !idR
            let condProv = district.trim().toLowerCase() == prov || !prov
            let condCity = municipality.trim().toLowerCase() == idC || !idC

            if (condReg && condProv && condCity) {
                return item;
            }
        })
        appoggio = filteredPanels
        // this.state.campaignPanels = filtered

        return appoggio;
    }

    errorLoad(){
        if(this.state.error !== null){
          return(
            <Card.Alert color="danger">
              Error: "{this.state.error.toString()}"
            </Card.Alert>
          )
        }
    }

    render() {
        return(
            <Dimmer active={this.state.isLoading} loader={this.state.isLoading}>
                <Grid.Row className="mt-3">
                    <Grid.Col>
                        <Form.Group label="Region">
                            <Form.Select name="region" disabled={this.state.regions.length<=0} onChange={ev => this.handleChangeRegion(ev)}>
                                <option value="" label="All" selected={this.state.selectedReg == ""}>All</option>
                                {this.state.regions.map((r, index) => {
                                    {/* if(this.state.regionsFiltered.find(element => element.toUpperCase() == r.region.toUpperCase())){ */}
                                    return <option key={index} value={r.idRegione} label={r.regione} selected={this.state.selectedReg == r.idRegione}>{r.regione}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    </Grid.Col>
                    <Grid.Col>
                        <Form.Group label="District">
                            <Form.Select name="district" disabled={this.state.districts.length<=0} onChange={ev => this.handleChangeDistrict(ev)}>
                                <option value="" label="All" selected={this.state.selectedDis == ""}>All</option>
                                {this.state.districts.map((r, index) => {
                                    return <option key={index} value={r.sigla} label={r.provincia} selected={this.state.selectedDis == r.provincia}>{r.provincia}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    </Grid.Col>
                    <Grid.Col>
                        <Form.Group label="City">
                            <Form.Select name="cities" disabled={this.state.cities.length<=0} onChange={ev => this.handleChangeCity(ev)}>
                                <option value="" label="All" selected={this.state.selectedCit == ""}>All</option>
                                {this.state.cities.map((r, index) => {
                                    return <option key={index} value={r.comune} label={r.comune} selected={this.state.selectedCit == r.istatCode}>{r.comune}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    </Grid.Col>
                    <Grid.Col>
                        <Form.Group label="Media Owner">
                            <Form.Select name="mediaowner" onChange={ev => this.handleChangeMediaOwner(ev)}>
                                <option value="" label="All" selected={this.state.selectedMedOwn == ""}>All</option>
                                {this.state.mediaowners && this.state.mediaowners.map((r, index) => {
                                    return <option key={index} value={r} label={r} selected={this.state.selectedMedOwn == r }>{r}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    </Grid.Col>
                    <Grid.Col>
                        <Form.Group label="Format">
                            <Form.Select name="format" onChange={ev => this.handleChangeFormat(ev)}>
                                <option value="" label="All" selected={this.state.selectedFrmt == ""}>All</option>
                                {this.state.formats && this.state.formats.map((r, index) => {
                                    return <option key={index} value={r} label={r} selected={this.state.selectedFrmt == r}>{r}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    </Grid.Col>
                </Grid.Row>
                {/* {this.errorLoad()} */}
            </Dimmer>
        )
    }
}

export default CampaignsReportFilters;