export const imageService = {
  getUrlPhoto,
  getUrlMarkerIcon,
  getQueryStringAPI
};

const debugMode = false

function getUrlPhoto(url, isCreativity = false){
  if(url){
    if(debugMode) {
      console.log('prima -> ', url)
    }
    switch(true){
      //kineticplatform.imgix.net
      case url.indexOf("kineticplatform.s3") !== -1:
        url = "https://kineticplatform.imgix.net" + url.replace(/^.*\/\/[^\/]+/, '')
        break;
      //kineticplatform.imgix.net
      case url.startsWith("s3.eu-central-1.amazonaws.com/kineticplatform"):
      case url.startsWith("https://s3.eu-central-1.amazonaws.com/kineticplatform"):
      case url.startsWith("//s3.eu-central-1.amazonaws.com/kineticplatform"):
        if(url.startsWith('s3')){
          url = url.replace('s3.eu-central-1.amazonaws.com/kineticplatform', '//kineticplatform.imgix.net')
        }
        else{
          url = url.replace('s3.eu-central-1.amazonaws.com/kineticplatform', 'kineticplatform.imgix.net')
        }
        break;
      //kineticplatformpanels.imgix.net
      case url.indexOf("kin.foto.impianti.s3") !== -1:
        // TO DO.. attualmente non utilizzate
        break;
      //kineticplatformphotos.imgix.net/
      case url.startsWith("s3-eu-west-1.amazonaws.com/submitted-media/"):
        url = 'https://kineticplatformphotos.imgix.net/' + url.split('/').pop()
        break;
      //kineticplatformphotos.imgix.net
      case url.indexOf("kinetic.foto.certificazione.s3") !== -1:
        // TO DO.. attualmente non utilizzate
        break;
      //kineticplatformcreative.imgix.net (solo file name - vecchie campagne)
      case (url.indexOf("/") === -1) && isCreativity:
      case url.indexOf("kin.itob.creativita.s3") !== -1:
        url = 'https://kineticplatformcreative.imgix.net/' + url
        break;
      //kineticplatformphotos.imgix.net (solo file name - vecchie campagne e foto kin)
      case (url.indexOf("/") === -1) && !isCreativity:
        url = 'https://kineticplatformphotos.imgix.net/' + url
        break;
      // TO DO... workaround 1
      case (url.indexOf("https://kineticplatformphotos.imgix.net/https://kineticplatformphotos.imgix.net/")!== -1):
          url = url.replace("https://kineticplatformphotos.imgix.net/https://kineticplatformphotos.imgix.net/", "")
        break;
      // TO DO... workaround 2
      case (url.indexOf("https://kineticplatformphotos.imgix.net/https://") !== -1):
        url = url.replace("https://kineticplatformphotos.imgix.net/https://", "https://")
        break;
      default:
        // url = 'https://kineticplatformphotos.imgix.net/' + url
    }

    if(debugMode) {
      console.log('dopo -> ', url)
    }
  }
  else{
    url = null
  }

  return url
}

// Non funziona se estensione diversa da png
function getUrlMarkerIcon(id){
  return "https://kineticplatformicons.imgix.net/markers/" + id + ".png"
}

function getQueryStringAPI(width = 200, height = 200, fit = true, quality = 25){
  let queryString = "?w="+width+"&h="+height+"&q="+quality
  if(fit){
    queryString += "&fit=crop"
  }
  return queryString
}
