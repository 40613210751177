const SEGMENTS_DICT = {
    ooh: [
        {key: 'Dinamica', label: 'Transit', color: '#8C8C8C', icon: 'Default_8C8C8C.png', iconSelected: 'Default_8C8C8C_selected.png'},
        {key: 'Grande Formato', label: 'Mega site', color: '#8C8C8C', icon: 'Default_8C8C8C.png', iconSelected: 'Default_8C8C8C_selected.png'},
        {key: 'Railway', label: 'Railway station', color: '#8C8C8C', icon: 'Default_8C8C8C.png', iconSelected: 'Default_8C8C8C_selected.png'},
        {key: 'Metropolitana', label: 'Underground', color: '#8C8C8C', icon: 'Default_8C8C8C.png', iconSelected: 'Default_8C8C8C_selected.png'},
        {key: 'Classica', label: 'Billboards', color: '#8C8C8C', icon: 'Default_8C8C8C.png', iconSelected: 'Default_8C8C8C_selected.png'},
        {key: 'Aeroporto', label: 'Airports', color: '#8C8C8C', icon: 'Default_8C8C8C.png', iconSelected: 'Default_8C8C8C_selected.png'},
        {key: 'Digital', label: 'DOOH', color: '#8C8C8C', icon: 'Default_8C8C8C.png', iconSelected: 'Default_8C8C8C_selected.png'},
        {key: 'Arredo Urbano', label: 'Street Furniture', color: '#8C8C8C', icon: 'Default_8C8C8C.png', iconSelected: 'Default_8C8C8C_selected.png'}, 
        {key: 'Altri', label: 'Others', color: '#8C8C8C', icon: 'Default_8C8C8C.png', iconSelected: 'Default_8C8C8C_selected.png'},
        {key: 'Medi', label: 'Big Impact', color: '#8C8C8C', icon: 'Default_8C8C8C.png', iconSelected: 'Default_8C8C8C_selected.png'},
        {key: 'Speciale', label: 'Small Format', color: '#8C8C8C', icon: 'Default_8C8C8C.png', iconSelected: 'Default_8C8C8C_selected.png'},
    ],
    dooh: [
        {key: 'Airport', label: 'Airport', color: '#FA541C', icon: 'Airport_FA541C.png', iconSelected: 'Airport_FA541C_selected.png'},
        {key: 'Highway - Service Area', label: 'Highway - Service Area', color: '#1890FF', icon: 'Highway_1890FF.png', iconSelected: 'Highway_1890FF_selected.png'},
        {key: 'Downtown', label: 'Downtown', color: '#434343', icon: 'Downtown_434343.png', iconSelected: 'Downtown_434343_selected.png'},
        {key: 'Malls', label: 'Mall', color: '#CF1322', icon: 'Malls_CF1322.png', iconSelected: 'Malls_CF1322_selected.png'},
        {key: 'Pharma', label: 'Pharma', color: '#52C41A', icon: 'Pharma_52C41A.png', iconSelected: 'Pharma_52C41A_selected.png'},
        {key: 'Station', label: 'Station', color: '#1D39C4', icon: 'Station_1D39C4.png', iconSelected: 'Station_1D39C4_selected.png'},
        {key: 'Subway', label: 'Subway', color: '#722ED1', icon: 'Subway_722ED1.png', iconSelected: 'Subway_722ED1_selected.png'},
    ]
};

export function segmentAssetDictionary(key, type = "ooh") {

    let found = SEGMENTS_DICT[type].find(function(element) {
        if(!key)
          return false;
        return element.key.toUpperCase() == key.toUpperCase();
    });

    if(!found){
        found = {key: 'Altri', label: '', color: '#8C8C8C', icon: 'Default_8C8C8C.png', iconSelected: 'Default_8C8C8C_selected.png'}
        // console.log("NOT FOUND! -> ", key)
    }

    return found;
}
