import React from "react"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { compose, withHandlers } from "recompose"
import jsonStyles from "./styles/style-01.json"
import iconPOI from "./icons/poi.png"

const apiKey = "AIzaSyDk2tsrjgOei53e0A5X8J7nFfj-MiDymIY";

const minimalMapOptions = {
  fullscreenControl: false,
  streetViewControl: false,
  zoomControl: false,
  mapTypeControl: false,
  styles: jsonStyles
};

const minimalMapOptionsWithZoom = {
  fullscreenControl: false,
  streetViewControl: false,
  zoomControl: true,
  mapTypeControl: false,
  styles: jsonStyles
};

const MapWithAMarker = compose(
  withHandlers({
    // onDrag: () => (ev) => {
    //   const {latLng} = ev
    //   console.log('onDrag',latLng.lat(), latLng.lng())
    // },
  }),
  withScriptjs,
  withGoogleMap
)(props =>
  <GoogleMap
    defaultZoom={props.lat ? 15 : 5}
    defaultCenter={{ lat: props.lat || 41.8919300, lng: props.lng || 12.5113300}}
    options={props.zoomControl ? minimalMapOptionsWithZoom : minimalMapOptions}
    key={props.session}
    // ref={(map)=>console.log('map',map)}
  >
      <Marker
        position={{ lat: props.lat, lng: props.lng }}
        icon={props.icon()}
        draggable={props.draggable}
        onDragEnd={props.onMarkerDragEnd}
        // label={props.session + 'random->' + Math.random().toString()}
      />
  </GoogleMap>
);


class MiniMap extends React.PureComponent {
  constructor(props){
    super(props)
    this.state = {
      session: null,
      position: {
        lat: null,
        lng: null
      },
      internalPos: {
        lat: null,
        lng: null
      }
    }

    this.onMarkerDragEnd = this.onMarkerDragEnd.bind(this)
  }

  componentDidMount(){
    const session = this.props.lat+'-'+this.props.lng+'-'+this.props.draggable
    const {lat, lng} = this.props
    const newPosition = {
      lat,
      lng
    }
    this.setState({
      session,
      position: newPosition,
      internalPos: newPosition
    })
  }

  componentWillReceiveProps(nextProps) {
    const {lat, lng} = nextProps
    const {position} = this.state

    if(lat !== position.lat || lng !== position.lng){
      const newPosition = {
        lat,
        lng
      }
      this.setState({
        position: newPosition,
        internalPos: newPosition
      })
    }
  }

  getIcon = () => {
    const {icon} = this.props
    let sizeIcon;
    let iconUrl = iconPOI
    if(icon){
      console.log('icon.fileImgix', icon)
      iconUrl = icon
    }
    sizeIcon = new window.google.maps.Size(35,35)
    return {url: iconUrl, scaledSize: sizeIcon}
  }

  onMarkerDragEnd = (coord, index) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    this.setState({
      internalPos: {
        lat,
        lng
      }
    },()=>this.props.parentChangePosition(this.state.internalPos));
  }

  render(){
    const {lat, lng} = this.state.internalPos
    const session = lat+'-'+lng+'-'+this.props.draggable
    return(
      <MapWithAMarker
        googleMapURL={"https://maps.googleapis.com/maps/api/js?key="+apiKey+"&v=3.exp&libraries=geometry,drawing,places"}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        lat={lat}
        lng={lng}
        draggable={this.props.draggable}
        onMarkerDragEnd={this.onMarkerDragEnd}
        icon={this.getIcon}
        zoomControl={this.props.zoomControl ? this.props.zoomControl : false}
        session={session}
        key={session}
      />
    )
  }
}

export default MiniMap;
