// @flow

import * as React from "react";

import { ForgotPasswordPage as TablerForgotPasswordPage } from "tabler-react";
import ForgotPasswordPageStep2 from "./ForgotPasswordPage.setCode.react";
import ForgotPasswordPageStep3 from "./ForgotPasswordPage.setPassword.react";
import ForgotPasswordPageSuccess from "./ForgotPasswordPage.success.react";

import _ from "lodash"
import { userService } from "../_services";

class ForgotPasswordPage extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      step: 0,
      isLoading: false,
      email: "",
      checkCode: "",
      errorCheckCode: false,
      password: "",
      checkPassword: "",
      errorsStep0: {},
      errorsStep1: {},
      errorsStep2: {},
      isLoading0: false,
      isLoading1: false,
      isLoading2: false,
    }
    this.onSubmitResetPassword = this.onSubmitResetPassword.bind(this)
    this.onSubmitSecurityCode = this.onSubmitSecurityCode.bind(this)
    this.onSubmitNewPassword = this.onSubmitNewPassword.bind(this)
  }

  onSubmitResetPassword(ev){
    ev.preventDefault()
    const val = ev.target.email.value
    const errors = this.validateEmail(val)
    console.log("submit")
    console.log(errors)
    if(_.isEmpty(errors)){
      console.log("corretto")
      this.setState({
        isLoading0: true
      })
      userService.sendMailCodeReset(val).then(
        result => {
          console.log(result)
          this.setState({
            step: 1,
            email: val,
            errorsStep0: {}
          })
        },
        error => {
          console.error(error);
        }
      );
    }
    else{
      this.setState({
        errorsStep0: errors
      })
    }
  }

  onSubmitSecurityCode(ev){
    ev.preventDefault()
    const val = ev.target.code.value
    const errors = this.validateCode(val)
    console.log(errors)
    if(_.isEmpty(errors)){
        this.setState({
          step: 2,
          checkCode: val,
          isLoading1: true
        })
    }
    else{
      this.setState({
        errorsStep1: errors
      })
    }
  }

  onSubmitNewPassword(ev){
    ev.preventDefault()
    const val = ev.target.password.value
    const checkVal = ev.target.checkPassword.value
    const errors = this.validatePassword(val, checkVal)
    const username = this.state.email
    const resetCode = this.state.checkCode
    if(_.isEmpty(errors)){
      this.setState({
        isLoading2: true
      })
      userService.setNewPassword(username, val, resetCode).then(
        result => {
          this.setState({
            step: 3,
            checkCode: val,
          })
          console.log(result)
        },
        error => {
          console.log("ERRORE!")
          console.log(error);
          this.setState({
            step: 1,
            isLoading1: false,
            isLoading2: false,
            errorCheckCode: true
          })
          //this.setState({ error, loading: false })
        }
      )
    }
    else{
      this.setState({
        errorsStep2: errors
      })
    }
  }

  validateEmail(email){
    let errors = {};
    if (!email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
    ) {
      errors.email = "Invalid email address";
    }
    return errors;
  }

  validateCode(code){
    let errors = {};
    if (!code) {
      errors.code = "Required";
    } else if (
      isNaN(code)
    ) {
      errors.code = "Invalid security code";
    }
    return errors;
  }

  validatePassword(pass, check){
    let errors = {};
    if (!pass) {
      errors.pass = "Required";
    } else if(!check){
      errors.check = "Required";
    }
    else if(pass != check){
      errors.pass = "Passwords are not equal"
    }
    else if(pass.length < 8){
      errors.pass = "Password not secure"
    }
    console.log("errors")
    console.log(errors)
    return errors;
  }

  render(){
    let rend = ""
    console.log(this.state.step)
    switch(this.state.step){
      case 0:
        rend = <TablerForgotPasswordPage onSubmit={this.onSubmitResetPassword} errorMessages={this.state.errorsStep0} isLoading={this.state.isLoading0} />
        break;
      case 1:
        rend = <ForgotPasswordPageStep2 onSubmit={this.onSubmitSecurityCode} email={this.state.email} errorMessages={this.state.errorsStep1} isLoading={this.state.isLoading1} errorCheckCode={this.state.errorCheckCode}/>
        break;
      case 2:
          rend = <ForgotPasswordPageStep3 onSubmit={this.onSubmitNewPassword} username={this.state.email} code={this.state.checkCode} errorMessages={this.state.errorsStep2} isLoading={this.state.isLoading2}/>
          break;
      case 3:
        rend = <ForgotPasswordPageSuccess/>
    }
    return rend
  }
}

export default ForgotPasswordPage;
