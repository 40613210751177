// @flow

import * as React from "react";

import {
  Page,
  Grid,
  Card,
  Dropdown,
  Table,
  Form,
  Icon,
  Container,
  Dimmer,
  Button,
  Progress,
  Text
} from "tabler-react";

import CircularProgressbar from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import "./Mediaowners.scss"

import _ from "lodash"

import { JsonToExcel } from 'react-json-excel';

import SiteWrapper from "../SiteWrapper.react";
import MediaOwnersFilters from "./Mediaowners.ReportFilters.react"
import MediaOwnersCounters from "./Mediaowners.Counters.react"
import { campaignService, statsService } from "../_services";

class Mediaowners extends React.Component {
  constructor(props) {
    super(props);

    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();

    this.state = {
        isLoading: false,
        campaigns: [],
        mediaowners: [],
        regions: [],
        segments: [],
        advertisersLabels: [],
        mediaownersLabels: [],
        mediaownersTotals: [],
        campaignsNumbers: [],
        selectedMedOwn: "",
        selectedAdvertiser: "",
        selectedPeriod: currentYear,
        dateSearch: "",
        isLoadingCampaign: true,
        isLoadingMediaOwner: true,
        isLoadingRegion: true,
        isLoadingSegment: true,
        isLoadingCampaignCounters: true
    }
  }

  componentDidMount() {
    this.getLocalUserInfo()
  }

  getLocalUserInfo(){
    let group = "";
    if(localStorage.getItem("type") == "admin" || localStorage.getItem("type") == "user" || localStorage.getItem("type") == "mediaOwner"){

      if(localStorage.getItem("type") == "mediaOwner"){
        group = localStorage.getItem("group") ? localStorage.getItem("group").toUpperCase() : "";

        this.setState({
            selectedMedOwn: group
        })
      }

      this.setState({
          isAdvertiser: false
      },()=> this.loadReportMain(group))
    }
    else{
      group = localStorage.getItem("group") ? localStorage.getItem("group").toUpperCase() : "";
      this.setState({
        isAdvertiser: true,
        selectedAdvertiser: group
      },()=> this.loadReportMain(group))
    }
  }

  loadReportMain(group){
    if(!this.state.isAdvertiser){
      this.loadAdvertisers()
    }
    else{
      const user = JSON.parse(localStorage.getItem("user"))
      let advertisersLabels = []

      if(user.company){
        advertisersLabels = user.company.advertisers
      }

      this.setState({
        advertisersLabels
      })

    }
    // this.loadMediaOwners(this.state.selectedPeriod, group)
    // this.loadRegions(this.state.selectedPeriod, group)
    // this.loadSegments(this.state.selectedPeriod, group)
    // this.loadCampaigns(this.state.selectedPeriod, group, group)
    this.loadDataFiltered(this.state.selectedPeriod, this.state.selectedMedOwn, this.state.selectedAdv)
  }

  loadAdvertisers(){
    statsService.getAllAdvertiser().then(
      result => {
        this.setState({
          advertisersLabels: result,
        })
      },
      error => {
        console.error(error);
      }
    )
  }

  loadDataFiltered(period, mo, adv){
    this.loadMediaOwners(period, adv)
    this.loadRegions(period, mo, adv)
    this.loadSegments(period, mo, adv)
    this.loadCampaigns(period, mo, adv)
  }

  initCounters(source, useKeyId = false){
    let repGBy = null;

    if(useKeyId){
      repGBy = _.groupBy(source, "elementId")
    }
    else{
      repGBy = _.groupBy(source, "element")
    }

    let repGByValues = _.values(repGBy)
    let repGByLabels = _.keys(repGBy)
    var report = []

    //inizializzo tutto a zero
    for(let i=0; i<repGByValues.length; i++){
      report[i] = {name: repGByLabels[i], desc: "", advertiser:"", numberCampaigns: 0, state0: 0, state1: 0, state2: 0, state3: 0, tracked:0, total:0}
    }

    //parso e setto i count
    for(let i=0; i<repGByValues.length; i++){
      for(let s=0; s<repGByValues[i].length; s++){
        if(useKeyId){
          report[i].desc = repGByValues[i][s].element
          report[i].advertiser = repGByValues[i][s].advertiser
        }
        report[i].numberCampaigns = repGByValues[i][s].numberCampaigns
        report[i].total += repGByValues[i][s].count

        let isValidate = (repGByValues[i][s].kinValidationCheck == 1 || !this.state.isAdvertiser)
        if(isValidate){
          switch(repGByValues[i][s].resultKinId){
            case 0:
              report[i].state0 += repGByValues[i][s].count
              report[i].tracked += repGByValues[i][s].count
              break;
            case 1:
              report[i].state1 += repGByValues[i][s].count
              report[i].tracked += repGByValues[i][s].count
              break;
            case 2:
              report[i].state2 += repGByValues[i][s].count
              report[i].tracked += repGByValues[i][s].count
              break;
            case 3:
              report[i].state3 += repGByValues[i][s].count
              break;
            default:
              return;
          }
        }
        else{
          report[i].state3 += repGByValues[i][s].count
        }
      }
    }
    report = _.reverse(_.sortBy(report, "total"))
    return report
  }

  initCountersTotals(source){
    let repGBy = _.groupBy(source, "element")
    let repGByValues = _.values(repGBy)
    var report = {numberCampaigns: 0, state0: 0, state1: 0, state2: 0, state3: 0, tracked:0, total:0}
    //parso e setto i count
    for(let i=0; i<repGByValues.length; i++){
      for(let s=0; s<repGByValues[i].length; s++){
        report.numberCampaigns = repGByValues[i][s].numberCampaigns
        report.total += repGByValues[i][s].count

        let isValidate = (repGByValues[i][s].kinValidationCheck === 1 || !this.state.isAdvertiser)
        if(isValidate){
          switch(repGByValues[i][s].resultKinId){
            case 0:
              report.state0 += repGByValues[i][s].count
              report.tracked += repGByValues[i][s].count
              break;
            case 1:
              report.state1 += repGByValues[i][s].count
              report.tracked += repGByValues[i][s].count
              break;
            case 2:
              report.state2 += repGByValues[i][s].count
              report.tracked += repGByValues[i][s].count
              break;
            case 3:
              report.state3 += repGByValues[i][s].count
              break;
            default:
              return;
          }
        }
        else{
          report.state3 += repGByValues[i][s].count
        }

      }
    }
    return report
  }

  loadMediaOwners(period, adv){
    statsService.getMediaReportByMediaOwner(period, adv).then(
        result => {
          let repGBy = _.groupBy(result, "element")
          let repGByLabels = _.keys(repGBy)
          if(this.state.selectedMedOwn == ""){
            this.setState({
              mediaowners: this.initCounters(result),
              mediaownersLabels: repGByLabels,
              mediaownersTotals: this.initCountersTotals(result),
              isLoadingMediaOwner: false,
            }, ()=>this.initCampaignsNumbers(period, adv))
          }
          else{
            let mediaowners = this.initCounters(result)
            let mediaownersTotals = _.find(mediaowners, {name: this.state.selectedMedOwn})
            this.setState((state)=>({
              mediaowners: mediaowners,
              mediaownersLabels: repGByLabels,
              mediaownersTotals: mediaownersTotals,
              isLoadingMediaOwner: false,
            }), ()=>this.initCampaignsNumbers(period, adv))
          }
        },
        error => {
          console.error(error);
        }
    );
  }

  initCampaignsNumbers(period, adv){
    let arrayCounts = []
    let arrayCamp = this.state.mediaowners

    for(let i=0; i<arrayCamp.length; i++){
      arrayCounts[i] = {name: arrayCamp[i].name, numberCampaigns: -1}
      statsService.getCampaignNumbersByMO(period, arrayCamp[i].name, adv).then(
        result => {
          arrayCounts[i] = result
          this.setState({
            campaignsNumbers: arrayCounts,
          })
        },
        error => {
          console.error(error);
        }
      );
    }
  }

  loadCampaigns(period, mo, adv){
    statsService.getMediaReportByCampaign(period, mo, adv).then(
        result => {
          this.setState({
            campaigns: this.initCounters(result, true),
            isLoadingCampaign: false,
          })
        },
        error => {
          console.error(error);
        }
    );
  }

  loadSegments(period, mo, adv){
    statsService.getMediaReportBySegment(period, mo, adv).then(
        result => {
          this.setState({
            segments: this.initCounters(result),
            isLoadingSegment: false,
          })
        },
        error => {
          console.error(error);
        }
    );
  }

  loadRegions(period, mo, adv){
    statsService.getMediaReportByRegion(period, mo, adv).then(
        result => {
          this.setState({
            regions: this.initCounters(result),
            isLoadingRegion: false,
          })
        },
        error => {
          console.error(error);
        }
    );
  }

  passPropsFilters = (nProps) => {
    let adv = nProps.selectedAdv
    if(this.state.isAdvertiser){
      adv = this.state.selectedAdvertiser
    }
    let period = nProps.selectedPeriod
    let mo = nProps.selectedMedOwn


    this.setState({
        selectedPeriod: period,
        selectedMedOwn: mo,
        selectedAdv: adv,
        isLoadingMediaOwner: true,
        isLoadingCampaign: true,
        isLoadingRegion: true,
        isLoadingSegment: true,
        mediaownersTotals:[]
    }, ()=> this.loadDataFiltered(this.state.selectedPeriod, this.state.selectedMedOwn, this.state.selectedAdv))
  }

  formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }

  getButtonCSV(type, data){
    const className = 'btn btn-secondary',
    filename = 'Excel-file_'+type+"_"+this.state.selectedPeriod+"_"+Date.now().toString(),
    fields = {
        "name": type,
        "total":"TOTAL",
        "tracked": "TRACKED",
        "state0": "CORRECT",
        "state1": "WRONG",
        "state2": "ANOMALY",
        "state3": "NOT TRACKED",
    },
    style = {padding: "5px"};
    return (
      <React.Fragment>
          <JsonToExcel
              data={data}
              className={className}
              filename={filename}
              fields={fields}
              style={style}
          />
      </React.Fragment>
    )
  }

  render() {
    const buttonMediaOwnerExport = this.getButtonCSV("MEDIAOWNER", this.state.mediaowners)
    const buttonCampaignExport = this.getButtonCSV("CAMPAIGN", this.state.campaigns)
    const buttonSegmentExport = this.getButtonCSV("SEGMENT", this.state.segments)
    const buttonRegionExport = this.getButtonCSV("REGION", this.state.regions)

    return (
      <SiteWrapper {...this.state}>
        <Page.Content>
          <Page.Header
            title="Reporting"
            // subTitle={this.state.totalItemsCount + " Mediaowners found"}
            // options={options}
          />
            <Grid.Row cards deck >
              <Grid.Col sm={12}>
                <Card className="bg-light">
                  <Container>
                    <MediaOwnersFilters handleState={this.passPropsFilters} advertisers={this.state.advertisersLabels} mediaowners={this.state.mediaownersLabels}/>
                  </Container>
                </Card>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
                <Grid.Col>
                    <MediaOwnersCounters
                      data={this.state.mediaownersTotals}
                      numberCampaigns={this.state.isLoadingCampaign ? "-1" : this.state.campaigns.length}
                    />
                </Grid.Col>
            </Grid.Row>

            <Grid.Row cards deck>
                <Grid.Col sm={12}>
                    <Card title="Media Owner" isCollapsible
                      // options={buttonMediaOwnerExport}
                      >

                        <Dimmer active={this.state.isLoadingMediaOwner} loader={this.state.isLoadingMediaOwner}>
                            <div style={{"max-height": "328px", "overflow-y": "auto"}}>
                                <Table className="table-filterable table-fixed-header" highlightRowOnHover={true}>
                                    <Table.Header>
                                        <Table.ColHeader>Name</Table.ColHeader>
                                        <Table.ColHeader alignContent="center">Campaigns</Table.ColHeader>
                                        <Table.ColHeader alignContent="center">Total</Table.ColHeader>
                                        <Table.ColHeader alignContent="center">Tracked</Table.ColHeader>
                                        <Table.ColHeader alignContent="center">Correct</Table.ColHeader>
                                        <Table.ColHeader alignContent="center">Wrong</Table.ColHeader>
                                        <Table.ColHeader alignContent="center">Anomaly</Table.ColHeader>
                                    </Table.Header>
                                    <Table.Body >
                                        {this.state.mediaowners.map((r, index) => {
                                            const total = r.total
                                            const tracked = r.tracked
                                            const correct = r.state0
                                            const wrong = r.state1
                                            const anomaly = r.state2
                                            const notTracked = r.state3

                                            let numberCampaigns = -1
                                            if(this.state.campaignsNumbers.length==this.state.mediaowners.length){
                                              numberCampaigns = this.state.campaignsNumbers[index].numberCampaign
                                            }

                                            let trackedPercPrBar = tracked!= 0 ? ((tracked/total) * 100) : 0
                                            let correctPerc = correct!= 0 ? ((correct/tracked) * 100) : 0
                                            let wrongPerc = wrong!= 0 ? ((wrong/tracked) * 100) : 0
                                            let anomalyPerc = 100-correctPerc-wrongPerc

                                            let trackedPerc = trackedPercPrBar!= 0 ? trackedPercPrBar.toFixed(1).replace('.', ',') : 0
                                            correctPerc = correctPerc!= 0 ? correctPerc.toFixed(1).replace('.', ',') : 0
                                            wrongPerc = wrongPerc!= 0 ? wrongPerc.toFixed(1).replace('.', ',') : 0
                                            anomalyPerc = (trackedPercPrBar >0 && anomalyPerc>0) ? anomalyPerc.toFixed(1).replace('.', ',') : 0

                                            let isNumCampReady = false
                                            if(numberCampaigns==-1 || typeof numberCampaigns == "undefined") {
                                              isNumCampReady = true;
                                            }

                                            // const trackedColor = trackedPerc <= 49 ? "red" : trackedPerc < 90 ? "yellow" : "green"
                                            const trackedColor = "blue"
                                            if(this.state.selectedMedOwn == "" || r.name == this.state.selectedMedOwn){
                                              return <Table.Row>
                                                  <Table.Col style={{"width": "220px"}}>{r.name}</Table.Col>
                                                  <Table.Col alignContent="center">
                                                    <Dimmer active={isNumCampReady} loader={isNumCampReady}>
                                                      {numberCampaigns}
                                                    </Dimmer>
                                                  </Table.Col>
                                                  <Table.Col alignContent="center">{this.formatNumber(total)}</Table.Col>
                                                  <Table.Col>
                                                    <div className="clearfix">
                                                      <div className="float-left">
                                                        <strong>{trackedPerc}%</strong>
                                                      </div>
                                                      <div className="float-right">
                                                        <Text.Small muted>
                                                          {this.formatNumber(tracked)}
                                                        </Text.Small>
                                                      </div>
                                                    </div>
                                                    <Progress size="xs">
                                                      <Progress.Bar color={trackedColor} width={trackedPercPrBar} />
                                                    </Progress>
                                                  </Table.Col>
                                                  <Table.Col alignContent="center"><strong>{correctPerc}%</strong><br/><small>{this.formatNumber(correct)}</small></Table.Col>
                                                  <Table.Col alignContent="center"><strong>{wrongPerc}%</strong><br/><small>{this.formatNumber(wrong)}</small></Table.Col>
                                                  <Table.Col alignContent="center"><strong>{anomalyPerc}%</strong><br/><small>{this.formatNumber(anomaly)}</small></Table.Col>
                                              </Table.Row>
                                            }
                                            else{
                                              return ""
                                            }
                                        })}
                                    </Table.Body>
                                </Table>
                            </div>
                        </Dimmer>
                    </Card>
                </Grid.Col>
            </Grid.Row>

            <Grid.Row cards deck>
                <Grid.Col sm={12}>
                <Card title="Campaigns" isCollapsible
                      // options={buttonMediaOwnerExport}
                      >
                    <Dimmer active={this.state.isLoadingCampaign} loader={this.state.isLoadingCampaign}>
                    <div style={{"max-height": "328px", "overflow-y": "auto"}}>
                      <Table className="table-filterable table-fixed-header" highlightRowOnHover={true}>
                        <Table.Header>
                          {!this.state.isAdvertiser && <Table.ColHeader>Advertiser</Table.ColHeader>}
                          <Table.ColHeader>Name</Table.ColHeader>
                          <Table.ColHeader alignContent="center">Total</Table.ColHeader>
                          <Table.ColHeader alignContent="center">Tracked</Table.ColHeader>
                          <Table.ColHeader alignContent="center">Correct</Table.ColHeader>
                          <Table.ColHeader alignContent="center">Wrong</Table.ColHeader>
                          <Table.ColHeader alignContent="center">Anomaly</Table.ColHeader>
                        </Table.Header>
                        <Table.Body >
                          {this.state.campaigns.map((r, index) => {
                              const total = r.total
                              const tracked = r.tracked
                              const correct = r.state0
                              const wrong = r.state1
                              const anomaly = r.state2
                              const notTracked = r.state3

                              let trackedPercPrBar = tracked!= 0 ? ((tracked/total) * 100) : 0
                              let correctPerc = correct!= 0 ? ((correct/tracked) * 100) : 0
                              let wrongPerc = wrong!= 0 ? ((wrong/tracked) * 100) : 0
                              let anomalyPerc = 100-correctPerc-wrongPerc

                              let trackedPerc = trackedPercPrBar!= 0 ? trackedPercPrBar.toFixed(1).replace('.', ',') : 0
                              correctPerc = correctPerc!= 0 ? correctPerc.toFixed(1).replace('.', ',') : 0
                              wrongPerc = wrongPerc!= 0 ? wrongPerc.toFixed(1).replace('.', ',') : 0
                              anomalyPerc = (trackedPercPrBar >0 && anomalyPerc>0) ? anomalyPerc.toFixed(1).replace('.', ',') : 0

                              const trackedColor = "blue"

                              return <Table.Row>
                                  {!this.state.isAdvertiser && <Table.Col style={{"max-width": "220px"}}>{r.advertiser ? r.advertiser : "-"}</Table.Col>}
                                  <Table.Col style={{"width": "220px"}}>{r.desc}</Table.Col>
                                  <Table.Col alignContent="center">{this.formatNumber(total)}</Table.Col>
                                  <Table.Col>
                                    <div className="clearfix">
                                      <div className="float-left">
                                        <strong>{trackedPerc}%</strong>
                                      </div>
                                      <div className="float-right">
                                        <Text.Small muted>
                                          {this.formatNumber(tracked)}
                                        </Text.Small>
                                      </div>
                                    </div>
                                    <Progress size="xs">
                                      <Progress.Bar color={trackedColor} width={trackedPercPrBar} />
                                    </Progress>
                                  </Table.Col>
                                  <Table.Col alignContent="center"><strong>{correctPerc}%</strong><br/><small>{this.formatNumber(correct)}</small></Table.Col>
                                  <Table.Col alignContent="center"><strong>{wrongPerc}%</strong><br/><small>{this.formatNumber(wrong)}</small></Table.Col>
                                  <Table.Col alignContent="center"><strong>{anomalyPerc}%</strong><br/><small>{this.formatNumber(anomaly)}</small></Table.Col>
                              </Table.Row>
                          })}
                      </Table.Body>
                      </Table>
                    </div>
                    </Dimmer>
                </Card>
                </Grid.Col>
            </Grid.Row>

            <Grid.Row cards deck>
                <Grid.Col sm={12}>
                <Card title="Segments" isCollapsible
                      // options={buttonMediaOwnerExport}
                      >
                    <Dimmer active={this.state.isLoadingSegment} loader={this.state.isLoadingSegment}>
                    <div style={{"max-height": "328px", "overflow-y": "auto"}}>
                      <Table className="table-filterable table-fixed-header" highlightRowOnHover={true}>
                        <Table.Header>
                          <Table.ColHeader>Name</Table.ColHeader>
                          <Table.ColHeader alignContent="center">Total</Table.ColHeader>
                          <Table.ColHeader alignContent="center">Tracked</Table.ColHeader>
                          <Table.ColHeader alignContent="center">Correct</Table.ColHeader>
                          <Table.ColHeader alignContent="center">Wrong</Table.ColHeader>
                          <Table.ColHeader alignContent="center">Anomaly</Table.ColHeader>
                        </Table.Header>
                        <Table.Body>
                          {this.state.segments.map((r, index) => {
                              const total = r.total
                              const tracked = r.tracked
                              const correct = r.state0
                              const wrong = r.state1
                              const anomaly = r.state2
                              const notTracked = r.state3

                              let trackedPercPrBar = tracked!= 0 ? ((tracked/total) * 100) : 0
                              let correctPerc = correct!= 0 ? ((correct/tracked) * 100) : 0
                              let wrongPerc = wrong!= 0 ? ((wrong/tracked) * 100) : 0
                              let anomalyPerc = 100-correctPerc-wrongPerc

                              let trackedPerc = trackedPercPrBar!= 0 ? trackedPercPrBar.toFixed(1).replace('.', ',') : 0
                              correctPerc = correctPerc!= 0 ? correctPerc.toFixed(1).replace('.', ',') : 0
                              wrongPerc = wrongPerc!= 0 ? wrongPerc.toFixed(1).replace('.', ',') : 0
                              anomalyPerc = (trackedPercPrBar >0 && anomalyPerc>0) ? anomalyPerc.toFixed(1).replace('.', ',') : 0

                              const trackedColor = "blue"

                              return <Table.Row>
                                <Table.Col style={{"width": "220px"}}>{r.name}</Table.Col>
                                <Table.Col alignContent="center">{this.formatNumber(total)}</Table.Col>
                                <Table.Col>
                                  <div className="clearfix">
                                    <div className="float-left">
                                      <strong>{trackedPerc}%</strong>
                                    </div>
                                    <div className="float-right">
                                      <Text.Small muted>
                                        {this.formatNumber(tracked)}
                                      </Text.Small>
                                    </div>
                                  </div>
                                  <Progress size="xs">
                                    <Progress.Bar color={trackedColor} width={trackedPercPrBar} />
                                  </Progress>
                                </Table.Col>
                                <Table.Col alignContent="center"><strong>{correctPerc}%</strong><br/><small>{this.formatNumber(correct)}</small></Table.Col>
                                <Table.Col alignContent="center"><strong>{wrongPerc}%</strong><br/><small>{this.formatNumber(wrong)}</small></Table.Col>
                                <Table.Col alignContent="center"><strong>{anomalyPerc}%</strong><br/><small>{this.formatNumber(anomaly)}</small></Table.Col>
                              </Table.Row>
                          })}
                        </Table.Body>
                      </Table>
                    </div>
                    </Dimmer>
                </Card>
                </Grid.Col>
            </Grid.Row>

            <Grid.Row cards deck>
                <Grid.Col sm={12}>
                <Card title="Regions" isCollapsible
                      // options={buttonMediaOwnerExport}
                      >
                    <Dimmer active={this.state.isLoadingRegion} loader={this.state.isLoadingRegion}>
                    <div style={{"max-height": "328px", "overflow-y": "auto"}}>
                      <Table className="table-filterable table-fixed-header" highlightRowOnHover={true}>
                        <Table.Header>
                          <Table.ColHeader>Name</Table.ColHeader>
                          <Table.ColHeader alignContent="center">Total</Table.ColHeader>
                          <Table.ColHeader alignContent="center">Tracked</Table.ColHeader>
                          <Table.ColHeader alignContent="center">Correct</Table.ColHeader>
                          <Table.ColHeader alignContent="center">Wrong</Table.ColHeader>
                          <Table.ColHeader alignContent="center">Anomaly</Table.ColHeader>
                        </Table.Header>
                        <Table.Body >
                          {this.state.regions.map((r, index) => {
                              const total = r.total
                              const tracked = r.tracked
                              const correct = r.state0
                              const wrong = r.state1
                              const anomaly = r.state2
                              const notTracked = r.state3

                              let trackedPercPrBar = tracked!= 0 ? ((tracked/total) * 100) : 0
                              let correctPerc = correct!= 0 ? ((correct/tracked) * 100) : 0
                              let wrongPerc = wrong!= 0 ? ((wrong/tracked) * 100) : 0
                              let anomalyPerc = 100-correctPerc-wrongPerc

                              let trackedPerc = trackedPercPrBar!= 0 ? trackedPercPrBar.toFixed(1).replace('.', ',') : 0
                              correctPerc = correctPerc!= 0 ? correctPerc.toFixed(1).replace('.', ',') : 0
                              wrongPerc = wrongPerc!= 0 ? wrongPerc.toFixed(1).replace('.', ',') : 0
                              anomalyPerc = (trackedPercPrBar >0 && anomalyPerc>0) ? anomalyPerc.toFixed(1).replace('.', ',') : 0

                              const trackedColor = "blue"

                              return <Table.Row>
                                  <Table.Col style={{"width": "220px"}}>{r.name}</Table.Col>
                                  <Table.Col alignContent="center">{this.formatNumber(total)}</Table.Col>
                                  <Table.Col>
                                    <div className="clearfix">
                                      <div className="float-left">
                                        <strong>{trackedPerc}%</strong>
                                      </div>
                                      <div className="float-right">
                                        <Text.Small muted>
                                          {this.formatNumber(tracked)}
                                        </Text.Small>
                                      </div>
                                    </div>
                                    <Progress size="xs">
                                      <Progress.Bar color={trackedColor} width={trackedPercPrBar} />
                                    </Progress>
                                  </Table.Col>
                                  <Table.Col alignContent="center"><strong>{correctPerc}%</strong><br/><small>{this.formatNumber(correct)}</small></Table.Col>
                                  <Table.Col alignContent="center"><strong>{wrongPerc}%</strong><br/><small>{this.formatNumber(wrong)}</small></Table.Col>
                                  <Table.Col alignContent="center"><strong>{anomalyPerc}%</strong><br/><small>{this.formatNumber(anomaly)}</small></Table.Col>
                              </Table.Row>
                          })}
                        </Table.Body>
                      </Table>
                    </div>
                    </Dimmer>
                </Card>
                </Grid.Col>
            </Grid.Row>

        </Page.Content>
      </SiteWrapper>
    );
  }
}

export default Mediaowners;
