import { authHeader } from "../_helpers";
import { userService } from "./"
import FileSaver from 'file-saver';
import {defineConfig} from '../config'

export const campaignService = {
  getApiPathCampaign,
  getAllCampaigns,
  getCampaignsFiltered,
  getCampaignsNumbers,
  getLastCampaignIdKin,
  getAuditsCountByIdKinCampaign,
  getCampaignsFindQuery,
  getCampaignInfo,
  getCampaignInfoFiltered,
  getCampaignPanels,
  getCampaignSummary,
  getFormatsByIdCampaign,
  deleteCampaign,
  getAllAdvertisers,
  setReportByIdCampaign,
  updateFileUploadAd,
  getXlsBME,
  getZipCampaign,
  pollingZipCampaign,
  getXlsCampaign,
  getZipFromUrl,
  setCampaignMailsList,
  setCustomCampaignMailsList,
  loadFileCampaign,
  loadFileCampaignInAppend
};

const {apiUrl, apiVer} = defineConfig.CAMPAIGN_SERVICE

function getApiPathCampaign(){
  return (apiUrl+apiVer)
}

function getAllCampaigns() {
    const requestOptions = {
      credentials: 'include',
      method: "GET",
      // mode: 'cors',
      // headers: authHeader(),
      // headers: {
      //   'Authorization': 'Bearer b622ef06-bf40-4997-afd2-fe04213badf7',
      //   'Content-Type': 'application/json'
      // }
    };

    return fetch(apiUrl+apiVer+"/campaigns/find/all", requestOptions)
    .then(handleResponse);
}

function getCampaignsFiltered(offset = 0, limit = 10, desc = true) {
  let orderBy = desc ? 'desc' : 'asc'

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(apiUrl+apiVer+"/campaigns/find/sorted/offset/"+offset+"/limit/"+limit+"/order/"+orderBy, requestOptions)
  .then(handleResponse);
}

function getLastCampaignIdKin() {
  const requestOptions = {
    method: "GET",
  };

  return fetch(apiUrl+apiVer+"/file/get/lastcampaign", requestOptions)
  .then(handleResponse);
}

function getAuditsCountByIdKinCampaign(idKinCampaign) {
  const requestOptions = {
    method: "GET",
  };

  return fetch(apiUrl+apiVer+"/file/get/auditsCount/idkc/"+ idKinCampaign, requestOptions)
  .then(handleResponse);
}

function getCampaignsNumbers() {
  const requestOptions = {
    method: "GET",
    // headers: authHeader(),
  };

  return fetch(apiUrl+apiVer+"/campaigns/find/numbers", requestOptions)
  .then(handleResponse);
}

function getCampaignsFindQuery(date = "2019-5", query = "%2A", offset = 0, limit = 10, desc = false) {
  query = query.trim() == '' ? "%2A" : query.trim()

  let orderBy = desc ? 'desc' : 'asc'

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  // console.log(apiUrl+apiVer+"/campaigns/find/date/"+date+"/query/"+query+"/offset/"+offset+"/limit/"+limit+"/order/"+orderBy, requestOptions)
  return fetch(apiUrl+apiVer+"/campaigns/find/date/"+date+"/query/"+query+"/offset/"+offset+"/limit/"+limit+"/order/"+orderBy, requestOptions)
  .then(handleResponse);
}

function getCampaignInfo(id) {
  const requestOptions = {
      method: "GET",
      headers: authHeader(),
  };
  return fetch(apiUrl+apiVer+"/campaigns/findbyId/id/"+id, requestOptions).then(handleResponse);
}

function getCampaignInfoFiltered(id, idR, prov, idC, idMO, idF) {
  idR = idR.trim() == '' ? -1 : idR
  prov = prov.trim() == '' ? "%2A" : prov
  idC = idC.trim() == '' ? "%2A" : idC
  idMO = idMO.trim() == '' ? "%2A" : idMO
  idF = idF.trim() == '' ? "%2A" : idF

  const requestOptions = {
      method: "GET",
      // headers: authHeader(),
  };
  return fetch(apiUrl+apiVer+"/campaigns/findbyGeoData/id/"+id+"/region/"+idR+"/district/"+prov+"/city/"+idC+"/mediaowner/"+idMO+"/format/"+idF+"/", requestOptions).then(handleResponse);
}

function getCampaignPanels(id) {
  const requestOptions = {
      method: "GET",
      // headers: authHeader(),
  };
  return fetch(apiUrl+apiVer+"/campaigns/find/panel/idkc/"+id, requestOptions).then(handleResponse);
}

function getAllAdvertisers() {
  const requestOptions = {
      method: "GET",
      headers: authHeader(),
  };
  return fetch(apiUrl+apiVer+"/campaigns/get/all/advertiser", requestOptions).then(handleResponse);
}

function getCampaignSummary(id) {
  const requestOptions = {
      method: "GET",
      headers: authHeader(),
  };
  return fetch(apiUrl+apiVer+"/campaigns/find/summary/cid/"+id, requestOptions).then(handleResponse);
}

function getFormatsByIdCampaign(id){
  const requestOptions = {
    method: "GET",
    // headers: authHeader(),
  };
  // console.log(apiUrl+apiVer+"/campaigns/getFormatsByIdCampaign/id/"+id)
  return fetch(apiUrl+apiVer+"/campaigns/getFormatsByIdCampaign/id/"+id, requestOptions).then(handleResponse);
}

function deleteCampaign(id){
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(apiUrl+apiVer+"/campaigns/delete/idc/"+id, requestOptions).then(handleResponse);
}

function setReportByIdCampaign(id, st){
  const requestOptions = {
    method: "GET",
    // headers: authHeader(),
  };
  // console.log(apiUrl+apiVer+"/campaigns/setInReport/id/"+id+"/value/"+st)
  return fetch(apiUrl+apiVer+"/campaigns/setInReport/id/"+id+"/value/"+st, requestOptions).then(handleResponse);
}

function updateFileUploadAd(formData, seqIds) {
  const url = apiUrl+apiVer+"/campaigns/uploadCreativity";
  formData.append("seqIds",seqIds)

  const requestOptions = {
    method: "POST",
    body: formData
  };

  // console.log(requestOptions)

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      // login successful if there's a user in the response
      if (res) {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        // console.log(res)
      }
      return res;
    });
}

function loadFileCampaign(req, file) {
  const formData = new FormData();
  formData.append("file", file, file.name);
  const url = apiUrl+apiVer+"/file/uploadCampaignFile";
  req.open("POST", url)
  return req.send(formData)
}

function loadFileCampaignInAppend(req, file, idkc) {
  const formData = new FormData();
  formData.append("file", file, file.name);
  formData.append("cid", idkc);
  const url = apiUrl+apiVer+"/file/addSeqId/";
  req.open("POST", url)
  return req.send(formData)
}

function getXlsBME(idkc){
  const requestOptions = {
    method: "GET",
    // headers: authHeader(),
  };
  // console.log(apiUrl+apiVer+"/file/createBmeFile/idkc/"+idkc)
  return fetch(apiUrl+apiVer+"/file/createBmeFile/idkc/"+idkc, requestOptions).then(res => {
      return res.blob();
    }).then(blob => {
        const href = window.URL.createObjectURL(blob);
        const a = this.current;
        a.download = 'export.xlsx';
        a.href = href;
        a.click();
        a.href = '';
    }).catch(err => console.error(err));
}

function getXlsCampaign(idkc) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(apiUrl+apiVer+'/campaigns/campaignReport/idkc/'+idkc, requestOptions)
    .then(response =>{
      return response.blob()
    })
    .then(function(blob) {
      FileSaver.saveAs(blob, 'exportCampaign_'+idkc+'.xlsx');
    })
}

function getZipCampaign(idkc) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(apiUrl+apiVer+"/campaigns/zipReport/idkc/"+idkc, requestOptions)
      .then(handleResponse)
}

function pollingZipCampaign(idkc) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(apiUrl+apiVer+"/campaigns/getZip/idkc/"+idkc, requestOptions)
      .then(handleResponse)
}

function getZipFromUrl(url) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let fileName = url.split('/')
  fileName = fileName[fileName.length-1]
  // console.log(fileName)

  return fetch(url, requestOptions)
    .then(response =>{
      // console.log("scarico....")
      return response.blob()
    })
    .then(function(blob) {
      FileSaver.saveAs(blob, fileName)
      return true;
    })
}

function setCampaignMailsList(campaignId, activeNotification, mails) {
  const url = apiUrl+apiVer+`/campaigns/setCampaignMailsList?campaignId=${campaignId}&activeNotification=${activeNotification}`;

  const body = JSON.stringify(mails);

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: body
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function setCustomCampaignMailsList(campaignId, activeNotification, mails) {
  const url = apiUrl+apiVer+`/campaigns/setCustomMailsList?campaignId=${campaignId}&activeNotification=${activeNotification}`;

  const body = JSON.stringify(mails);

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: body
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function handleResponse(response) {
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok && response.status !== 409) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          userService.logout();
          window.location.reload(true);
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      return data;
    });
}
