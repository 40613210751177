import React, {Fragment} from "react"
import {Dimmer, Button, Card, Icon} from "tabler-react";
import {Select, Modal} from 'antd'
// import iconsMarker from "./icons/blue-1.png"
import iconPOI from "./icons/poi.png"
import iconCluster from "./icons/cluster-blue.png"
import { segmentAssetDictionary } from "../../_helpers"
import MapCustomControl from "./MapCustomControl.react"
import _ from "lodash"
import "./Map.scss";
// import { func } from "prop-types";
// import { BlogCard, Table } from "tabler-react";
import MapFilterSlider from './MapFilterSlider.react'
import MapDetailsMarker from "./MapDetailsMarker/MapDetailsMarker.react";
// import HeatMapTableAudience from '../../Planner/Planner.HeatMapTableAudience.react';
import SyncingChartsAudience from '../../Planner/Planner.SyncingChartsAudience.react';

import {formatNumber} from "../../_helpers"

import {imageService} from '../../_services'
import { LineChartOutlined } from "@ant-design/icons";
const {Option} = Select
const { compose, withProps, withHandlers, withStateHandlers, lifecycle } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
  CustomControl,
  Polygon
} = require("react-google-maps");


const iconsSegment = require.context('./icons/segment', true);
const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");
// const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox"); // TO DO

const stylesMapStd = require("./styles/style-01.json"); // Standard
const stylesMapLight = require("./styles/style-02.json"); // Light

const clusterStyles = [
  {
    textColor: 'white',
    url: iconCluster,
    height: 35,
    width: 35
  },
  {
    textColor: 'white',
    url: iconCluster,
    height: 45,
    width: 45
  },
  {
    textColor: 'white',
    url: iconCluster,
    height: 55,
    width: 55
  }
];

const minimalMapStdOptions = {
  fullscreenControl: false,
  streetViewControl: true, //pegman
  zoomControl: true,
  mapTypeControl: false,
  styles: stylesMapStd
};

const minimalMapLightOptions = {
  fullscreenControl: false,
  streetViewControl: true, //pegman
  zoomControl: true,
  mapTypeControl: false,
  styles: stylesMapLight
};

const radiusOptions = {
  strokeColor: "#467fcf",
  strokeOpacity: 0.8,
  strokeWeight: 1,
  fillColor: "#467fcf",
  fillOpacity: 0.10,
}

const apiKey = "AIzaSyDk2tsrjgOei53e0A5X8J7nFfj-MiDymIY"
const refs = {}

const getCountLayerPolygons = (value) => {
  return Array.isArray(value) ? 
    1 + Math.max(0, ...value.map(getCountLayerPolygons)) :
    0;
}

const StyledMapWithAnInfoBox = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key="+apiKey+"&v=3.exp&libraries=geometry,drawing,places",
    loadingElement : <div style={{ height: `100%`, minHeight: 'inherit', paddingTop:"20%"}}><Dimmer active loader className={"gmaps-custom-component gmaps-custom-loading"} ></Dimmer><br/></div>,
    containerElement: <div style={{ height: `100%`, minHeight: 'inherit' }} className={"GoogleMapContainer gmaps-custom-component"}/>,
    mapElement: <div style={{ height: `100%`, minHeight: 'inherit'  }} />,
  }),
  lifecycle({
    componentDidMount() {
        this.setState({
          zoomToMarkers: (map, props) => {
            // console.log('markers session',props.session, props.prevSession)
            if(map && !props.isClustered && props.session !== props.prevSession){
                const bounds = new window.google.maps.LatLngBounds();
                map.props.children[0].forEach((child) => {
                    if (child.type === Marker) {
                        bounds.extend(new window.google.maps.LatLng(child.props.position.lat, child.props.position.lng));
                    }
                })
                map.fitBounds(bounds);
            }
          },
          zoomToClusters: (map, props) => {
            // console.log('clusters session',props.session, props.prevSession)
            if(map && props.isClustered && props.session !== props.prevSession){
                const bounds = new window.google.maps.LatLngBounds();
                map.props.children[0].props.children.map((child) => {
                    if (child.type === Marker) {
                        bounds.extend(new window.google.maps.LatLng(child.props.position.lat, child.props.position.lng));
                    }
                })
                map.fitBounds(bounds);
            }
          },
        })
    },
  }),
  withHandlers({
    // onMarkerClustererClick: () => (markerClusterer) => {
    //   const clickedMarkers = markerClusterer.getMarkers()
    // },
    onClusteringBegin: () => (ev, props) => {
      var funCalc = function(markers, numStyles){
        var index = 0;
        var count = markers.length;
        var dv = count;
        while (dv !== 0) {
          dv = parseInt(dv / 10, 10);
          index++;
        }

        index = Math.min(index, numStyles);
        return {
          text: parseInt(count/2), // Workaround GGNO ;)
          index: index
        };
      }
      ev.setCalculator(funCalc)
    },
    calculatorCluster: () => (markers, numStyles) => {
      return function(markers, numStyles){
        var index = 0;
        var count = markers.length;
        var dv = count;
        while (dv !== 0) {
          dv = parseInt(dv / 10, 10);
          index++;
        }

        index = Math.min(index, numStyles);
        return {
          text: parseInt(count/2),
          index: index
        };
      }(markers, numStyles)
    },
    calculateAverageIndex: () => (items) => {
      const average = items.reduce((avg, {index}, _, panels) => {
        const indexed = panels.filter(({index}) => index !== null).length
        if(index != null){
          return avg + index / indexed;
        }
        return avg;
      }, 0);
      return average.toFixed(2).toString().replace('.',',');
    },
    loadCustomControls: () => (props) => {
      const averageIndex = props.calculateAverageIndex(props.positions);
      const controls = (
        // <CustomControl controlPosition={window.google.maps.ControlPosition.RIGHT_BOTTOM}>
        <div>
          <div className="panelsmap-button-group panelsmap-button-group-top p-3">
            <Button.List>
              <Button color="secondary" onClick={props.onClusterSwitchClick} icon={props.isClustered ? 'move' : 'target'}>
                {props.isClustered ? "Disable Cluster" : " Enable Cluster"}
              </Button>

              {
                props.pois.length > 0 &&
                  <Button color={props.isRadius ? 'gray' : 'secondary'} onClick={props.onRadiusSwitchClick} icon='disc' title="POI radius"></Button>
              }

              {
                // props.typeFrame !== "dooh" &&
                (<>
                  <Button
                    color={props.markerMode === "segment" ? "primary" : "secondary"}
                    name="mode"
                    value="segment"
                    onClick={(ev) => props.onSwitchModeClick("segment")}
                  >
                    Segment
                  </Button>
               
                  {
                    props.audienceFilter !== null && 
                    <Button
                      color={props.markerMode === "target" ? "primary" : "secondary"}
                      name="mode"
                      value="target"
                      onClick={(ev) => props.onSwitchModeClick("target")}
                    >
                      Target
                    </Button>
                  }
                </>)
              }

              {
                (props.typeFrame !== 'dooh' && props.audienceFilter !== null && props.markerMode === "target") &&
                <Button
                  color="info"
                  onClick={props.onOpenHeatmap}
                >
                  <LineChartOutlined /> Weekly Trend
                </Button>
              }

              {props.enableSearch &&
                <>
                  <MapCustomControl handlerCenterPosition={(places)=>props.handlerCenterPosition(props, places)} bounds={refs.map && (true || console.log(refs)) ? refs.map.getBounds() : console.log(refs)}/>
                  {props.pois.filter(poi => poi.isCustomPOI).length>0 &&
                      <Button
                        color="secondary"
                        className="bg-white text-danger"
                        name="empty-poi"
                        icon="trash-2"
                        value="empty-poi"
                        title="Delete all POI"
                        onClick={(ev) => props.deletePositionPOI()}
                      />
                  }
                </>
              }
            </Button.List>
            <br/>
            {
              props.typeFrame === 'dooh' &&
              <Card className="panelsmap-numbers border-primary shadow-sm">
                <h4 className="mb-0 text-primary">
                  <Icon name="monitor"/>
                </h4>
                <h6>{props.extra && props.extra.numberFrames}</h6>

                <h4 className="mb-0 text-primary">
                  <Icon name="users"/>
                </h4>
                <h6>{props.extra && props.extra.otsPaidTotal}</h6>
              </Card>
            }
            
            {
              (props.markerMode === 'target') &&
              <div style={{ width: 210, top: 302, right: 0, position: "fixed" }}>
                <MapFilterSlider
                  onChange={props.onFilterIndex}
                  hideExcluded={props.hideExcluded}
                  setHideExcluded={(value) => props.onFilterHideExcluded(value)}
                  defaultMin={_.min(props.positionsAll.map(({index}) => parseFloat(index)).filter(num => !isNaN(num)))}
                  defaultMax={_.max(props.positionsAll.map(({index}) => parseFloat(index)).filter(num => !isNaN(num)))}
                  value={[props.indexMin, props.indexMax]}
                  loading={props.isLoadingFilter}
                  count={props.positions.filter(({index}) => {
                      const isIndexValidate = index !== null && (props.indexMin === null || (index >= props.indexMin)) && (props.indexMax === null || (index <= props.indexMax))  
                      return isIndexValidate
                    }).length
                  }
                />
              </div>
            }
          </div>
          
          {/* TODO: riattivare con marker nuovi anche non dooh */}
          {
            (props.markerMode === 'segment' && props.typeFrame === 'dooh' && props.audienceFilter !== null ) &&
            <div className="panelsmap-button-group panelsmap-button-group-bottom mb-6 ml-3">
              {
                props.typeFrame === 'dooh' &&
                <Card className="panelsmap-numbers border-info shadow-sm py-5">
                  <h6 className="mb-0 text-info">
                    Pen. Rate
                  </h6>
                  <h6>{props.extra && props.extra.penetrationRate && props.extra.penetrationRate.toFixed(2).toString().replace('.',',')} %</h6>
                  <h6 className="mb-0 text-info">
                    Average Index
                  </h6>
                  <h6>{averageIndex}</h6>
                </Card>
              }
              <div className="opacity-8 bg-white border shadow-lg p-2">
                {props.loadLegendSegment(props, props.typeFrame)}
              </div>
            </div>
          }
        </div>
        //</CustomControl>
      )
      return controls;
    },
    loadInfoDialog: () => (props) => {
      const {typeFrame} = props
      return(
        <MapDetailsMarker
          marker={props.selectedMarker}
          type={"horizontal"}
          className={(typeFrame === 'dooh' ? "offset-4 col-4" : "col-6") + " shadow-lg p-0"}
          iconImg={props.getIconSegment(props.selectedMarker, props.typeFrame)}
          iconText={props.getLabelSegment(props.selectedMarker, props.typeFrame)}
          onClose={props.onCloseDetails}
          typeFrame={typeFrame}
          audienceFilter={props.audienceFilter}
        />
      )
    },
    loadLegendSegment: ()=> (props, type = "ooh") => {
      const segmentsLab = props.initSegment(props.positions)
      let listLeg = []
      for(let i=0; i<segmentsLab.length; i++){
        let segment = segmentAssetDictionary(segmentsLab[i], type)
        let label = segment ? segment.label.toUpperCase() : segmentsLab[i]
        label = label === "" ? segmentsLab[i] : label
        let icon = segment ? segment.icon : "9-others-01.png"
        let iconB64 = iconsSegment('./' + icon) || null;

        listLeg.push(<div><img src={iconB64} alt={label} className="legend-icon img-fluid"/> <span className="legend-text">{label}</span></div>)
      }
      return listLeg
    },
    initSegment: ()=> (data) =>{
      let segments = _.groupBy(data, "segment")
      let segmentsLab = _.keys(segments);
      return segmentsLab
    },
    getIconSegment: () => (item, type = "ooh") => {
      let segment = segmentAssetDictionary(item.segment, type)
      let icon = segment ? (segment.icon) : "9-others-01.png"
      return iconsSegment('./' + icon);
    },
    getLabelSegment: () => (item, type = "ooh") => {
      let segment = segmentAssetDictionary(item.segment, type)
      return segment ? segment.label.toUpperCase() : item.segment
    },
    handlerCenterPosition: () => (props, places) => {
      // console.log(places.length + " places trovati")
      // console.log(places)
      let arrayPlaces = []
      for(let i=0; i<places.length;i++){
        let lat = places[i].geometry.location.lat()
        let lng = places[i].geometry.location.lng()
        let name = places[i].name
        let address = places[i].formatted_address
        arrayPlaces.push({lat, lng, name, address})
      }
      props.setPositionPOI(arrayPlaces)
    },
  }),
  withStateHandlers(() => ({
    isOpen: false,
  }), {
    onToggleOpen: ({ isOpen }) => () => ({
      isOpen: !isOpen,
    })
  }),
  withScriptjs,
  withGoogleMap
)(props =>
  <GoogleMap
    defaultCenter={props.firstPosition}
    defaultZoom={6}
    options={props.mapOption}
    ref={(map)=>(!props.isClustered ? props.zoomToMarkers(map, props) : props.zoomToClusters(map, props))}
    key={props.session}
  >
    {
      // ----- CLUSTERED MARKERS -----
      props.isClustered ?
      //<div key={props.session}>
        <MarkerClusterer
          // onClick={props.onMarkerClustererClick}
          onClusteringBegin={(ev)=>props.onClusteringBegin(ev, props)}
          // onClusteringEnd={(ev)=>console.log("onCluster END", ev)}
          minimumClusterSize={10}
          averageCenter
          enableRetinaIcons
          gridSize={80}
          maxZoom={14}
          key={props.session}
          clusterClass={'cluster-markers'}
          styles={clusterStyles}
        >
          {
            props.positions.map((item,index) => {
              const sizeIcon = new window.google.maps.Size(12,12)
              let segment = segmentAssetDictionary(item.segment, props.typeFrame)
              // let label = segment ? segment.label.toUpperCase() : segmentsLab[i]
              let icon = segment ? ((index == props.selected) ? segment.iconSelected : segment.icon): "9-others-01.png"
              const urlIcon = iconsSegment('./' + icon);

              let objIcon = {url: urlIcon, scaledSize: sizeIcon}
              return (
                <Marker
                  id={item.idKinFace}
                  key={index+"-"+item.idKinFace}
                  position={{ lat: item.lat, lng: item.lng }}
                  icon={objIcon}
                  title={item.mediaOwner + " - " + item.dashboardType + " - " + item.format}
                  onClick={()=>props.onClickMarker(item)}
                  // onMouseOver={(ev)=>console.log(ev, this, item)} //TO DO.. mouseover
                  // onMouseOut={(ev)=>console.log(ev)}
                />
              )
            })
          }
        </MarkerClusterer>
      //</div>
      :
      // ----- SEGMENT SIMPLE MARKERS -----
      props.positions.map((item,index) => {
          const sizeIcon = new window.google.maps.Size(12,12)
          let segment = segmentAssetDictionary(item.segment, props.typeFrame)
          // let label = segment ? segment.label.toUpperCase() : segmentsLab[i]

          let icon = (segment && props.markerMode === 'segment')
            ? ((index == props.selected)
              ? segment.iconSelected
              : segment.icon)
            : props.markerMode === 'target'
              ? item.index !== null
                ? "8-arredo-urbano-01.png"
                : "5-affissione-classica-01.png" 
              : "9-others-01.png"

          const urlIcon = iconsSegment('./' + icon);
          const isIndexValidate = item.index !== null && (props.indexMin === null || (item.index >= props.indexMin)) && (props.indexMax === null || (item.index <= props.indexMax))

          let objIcon = {url: urlIcon, scaledSize: sizeIcon}
          return (
              <Marker
                id={item.idKinFace}
                key={index+"-"+item.idKinFace}
                position={{ lat: item.lat, lng: item.lng }}
                icon={objIcon}
                title={"Index: " + item.index + " | " + item.mediaOwner + " - " + item.dashboardType + " - " + item.format}
                onClick={()=>props.onClickMarker(item)}
                opacity={(isIndexValidate || item.index === null || props.markerMode === 'segment') ? 1 : .05}
                zIndex={(isIndexValidate || props.markerMode === 'segment') ? 3 : 2}

                //TODO: mouseover/out
                // onMouseOver={() => props.onClickMarker(item)}
                // onMouseOut={() => props.onClickMarker(null)}
              />
          )
      })
    }

    {
      props.pois.map((item,index) => {
        let sizeIcon = new window.google.maps.Size(32,32)
        const urlIcon = item.marker ? imageService.getUrlMarkerIcon(item.marker) : iconPOI
        let objIcon = {url: urlIcon, scaledSize: sizeIcon}
        let {lat, lng} = item
        lat = lat ? lat : item.location.y
        lng = lng ? lng : item.location.x
        return (
          <Fragment key={item.idKinPoi + '-' + (props.isRadius) + '-' + props.distance}>
            <Marker
              id={item.idKinPoi}
              key={item.idKinPoi}
              position={{ lat, lng }}
              icon={objIcon}
              opacity={.9}
              title={"POI: "+item.name + " - " + item.comune + " - " + item.address + " | Radius: " + props.distance + " meters"}
            />
            {
              (props.polygons.length === 0 && props.isRadius && !item.isCustomPOI) &&
              <Circle
                options={radiusOptions}
                defaultCenter={{
                  lat: parseFloat(lat),
                  lng: parseFloat(lng)
                }}
                radius={parseInt(props.distance) + 50}
                key={item.idKinPoi + '-' + (props.isRadius) + '-' + props.distance}
              />
            }
          </Fragment>
        )
      })
    }

    {/* Travel Time Polygons */}
    {
      (props.polygons.length > 0) &&
      <>
      ({
        getCountLayerPolygons(props.polygons) < 3 &&
        <Fragment key={"fragment-polygons"}>
          {
            <Polygon
            key={"polygons"}
            options={radiusOptions}
            paths={props.polygons}
            />
          }
        </Fragment>
      })

      ({
        getCountLayerPolygons(props.polygons) >= 3 &&
        <Fragment key={"fragment-polygons"}>
          {
            props.polygons.map((subPolygons, index) => (
              <Polygon
                key={"polygons" + index}
                options={radiusOptions}
                paths={subPolygons}
              />
            ))
          }
        </Fragment>
      })
      </>
      
    }

    {props.loadCustomControls(props)}
  </GoogleMap>
);


class MapPlanner extends React.PureComponent {
    constructor(props){
      super(props)

      this.state = {
        session: "initial-session",
        prevSession: "initial-session0",
        data: [],
        pois: [],
        distance: 0,
        poiFilter: null,
        selectedMarker: null,
        isClustered: false,
        isRadius: true,
        polygons: [],
        fullscreen: false,
        markerMode: "segment",
        indexMin: null,
        indexMax: null,
        lockFilter: null,
        lockFilterValues: null,
        filterHideExcluded: true,
        audienceFilter: null,
        isLoadingFilter: false,
        themeLight: true
      }

      this.toggleFullscreen = this.toggleFullscreen.bind(this)
      this.handleChangeFilterIndex = this.handleChangeFilterIndex.bind(this)
      this.handleFilterIndex = this.handleFilterIndex.bind(this)
      this.onChangeTheme = this.onChangeTheme.bind(this)
    }

    componentWillMount() {
      this.setState({
        session: "initial-session",
        prevSession: "initial-session0",
        data: [],
        pois: [],
        polygons: [],
      })
    }

    componentDidMount() {
      const {audienceFilter, data, distance, selectedMarker} = this.props
      this.setDataProps(data)
      this.setState({
        distance,
        audienceFilter,
        selectedMarker
      }, () => this.onChangeAudienceFilter(audienceFilter))
    }

    componentWillReceiveProps(nProps){
      if(nProps.data !== this.props.data && this.props.data.length !== nProps.data.length){
        console.log("data Changes map", nProps.data)
        this.setDataProps(nProps.data)
        this.openInfoMarker(null)
      }
      if(nProps.distance !== this.props.distance){
        const {distance} = nProps
        this.setState({
          distance
        })
      }
      if(nProps.polygons !== this.props.polygons){
        const {polygons} = nProps
        this.setState({
          polygons
        })
      }
      if(nProps.selectedMarker !== this.props.selectedMarker){
        const {selectedMarker} = nProps
        this.setState({
          selectedMarker
        })
      }
      if(nProps.audienceFilter !== this.props.audienceFilter){
        const {audienceFilter} = nProps
        this.setState({
          audienceFilter
        }, () => this.onChangeAudienceFilter(audienceFilter))
        
      }
    }

    onChangeAudienceFilter (audienceFilter) {
      if(audienceFilter !== null && this.props.typeFrame !== "dooh"){
        this.handleSwitchModeClick("target");
      }
    }

    toggleFullscreen(){
      let fullscreen = !this.state.fullscreen
      this.setState({fullscreen})
    }

    onChangeTheme(value){
      this.setState({
        themeLight: value === 'light' ? true : false
      })
    }

    setDataProps(data, indexMin = null, indexMax = null, hideFiltered = true){
      const session = "session_setDataProps_"+Math.random()+"-"+Math.floor(Date.now() / 1000)
      const prevSession = this.state.session
      const {audienceFilter} = this.props

      let dataAll = this.mappingData(data)

      if(hideFiltered && audienceFilter){
        data = data.filter(item => {
          const isIndexValidate = (item.index !== null) && (indexMin === null || (item.index >= indexMin)) && (indexMax === null || (item.index <= indexMax))
          return isIndexValidate
        })
      }

      data = this.mappingData(data)

      //TO DO GROUP BY POI
      const pois = this.getPOIDistinct(data)

      this.setState({
        data, dataAll, session, prevSession, pois
      })
    }

    mappingData(data){
      return data.map((item) => {
        return{
          ...item,
          idKinFace: item.idKinFace,
          lat: item.location.y,
          lng: item.location.x,
          cimasa: item.cimasa,
          poi: item.poi,
          mediaOwner: item.mediaOwner,
          dashboardType: item.dashboardType,
          segment: item.segment,
          format: item.format,
          region: item.region,
          district: item.district,
          city: item.city,
          numberMonitors: item.numberMonitors,
          imageUrl: item.imageUrl,
          ots: item.ots,
          index: (typeof item.index !== 'undefined' && item.index !== null) ? formatNumber((item.index).toFixed(2), false, false, false ) : null
        }
      });
    }

    getPOIDistinct(items){
      const POIs = _.uniqWith(_.map(items, 'poi'), _.isEqual);
      return _.compact(POIs);
    }

    changePoiFilter = (poi) => {
      this.setState((state) => ({
        poiFilter: poi
      }))
    }

    handleSwitchClusterClick = (ev) => {
      const session = "session_handleSwitchClusterClick_"+Math.random()+"-"+Math.floor(Date.now() / 1000)
      const prevSession = this.state.session
      this.setState((state) => ({
        isClustered: !state.isClustered,
        prevSession,
        session
      }), () => this.openInfoMarker(null))
    }

    handleSwitchRadiusClick = (ev) => {
      const session = "session_handleSwitchRadiusClick_"+Math.random()+"-"+Math.floor(Date.now() / 1000)
      const prevSession = this.state.session
      this.setState((state) => ({
        isRadius: !state.isRadius,
        prevSession,
        session
      }))
    }

    openInfoMarker = (selectedMarker) => {
      this.setState((state)=>({
        selectedMarker,
        prevSession: selectedMarker ? state.session : state.prevSession
      }), () => this.props.handleMarkerSelected(this.state.selectedMarker))
    }

    setPositionPOI = (places) => {
      if(places.length > 0){
        let {pois} = this.state
        places[0].isCustomPOI = true
        pois.push(places[0])

        const prevSession = this.state.session
        const session = "session_setPositionPOI_"+Math.random()+"-"+Math.floor(Date.now() / 1000)

        this.setState({ pois, session, prevSession })
      }
    }

    deletePositionPOI = () => {
      let {pois} = this.state
      pois = pois.filter(poi => !poi.isCustomPOI)
      const session = "session_deletePositionPOI_"+Math.random()+"-"+Math.floor(Date.now() / 1000)
      this.setState({pois, session})
    }

    handleSwitchModeClick = (mode) => {
      this.setState((state) => ({
        markerMode: mode,
        autozoom: true,
        session: "session_handleSwitchModeClick_"+Math.random()+"-"+Math.floor(Date.now() / 1000)
      }))
    }

    handleChangeFilterIndex = (indexMin, indexMax) => {
      const {lockFilter} = this.state

      this.setState({
        lockFilterValues: {indexMin, indexMax},
        isLoadingFilter: true
      })

      if(lockFilter) {
        clearTimeout(lockFilter);
      }

      this.setState({
        lockFilter: setTimeout(this.handleFilterIndex, 2000),
        isLoadingFilter: true
      })
    }

    handleFilterIndex = () => {
      const {lockFilterValues, filterHideExcluded, audienceFilter} = this.state

      if(!lockFilterValues){
        this.setDataProps(this.props.data, null, null, filterHideExcluded)
        this.setState({
          isLoadingFilter: false
        }, () => this.setState({session: "session_handleFilterIndexNotLockFilterValues_"+Math.random()+"-"+Math.floor(Date.now() / 1000)}))
        return;
      }

      const {indexMin, indexMax} = lockFilterValues

      this.setDataProps(this.props.data, indexMin, indexMax, filterHideExcluded)

      this.setState({
        indexMin, indexMax, isLoadingFilter: false
      }, () => this.setState({session: "session_handleFilterIndex_"+Math.random()+"-"+Math.floor(Date.now() / 1000)}))
    }

    handleChangeFilterHideExcluded = (value) => {
      this.setState({
        filterHideExcluded: value,
        isLoadingFilter: true
      }, () => setTimeout(this.handleFilterIndex, 1500))
    }

    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }

    handleOpenHeatMap = () => {
      const typeFrame = this.props.typeFrame || 'ooh'
      const faceIds = this.state.data.map(frame => frame.idKinFace)
      Modal.info({
        title: "Weekly Trend - N. Frames: " + formatNumber(faceIds.length) + " - Target: " + (this.state.audienceFilter && this.state.audienceFilter.label),
        width: 1100,
        closable: true,
        style:{
          top: 10,
        },
        content: (
          // <HeatMapTableAudience faceIds={faceIds} type={typeFrame}/>
          <div className="text-center w-100">
            <SyncingChartsAudience allData={this.state.data} faceIds={faceIds} audienceFilter={this.state.audienceFilter} type={typeFrame}/>
          </div>
        )
      });
    }

    mapGeoJSONPolygon = (arr) => {
      if(!arr){
        return [];
      }
      if (arr.length === 2 && !isNaN(arr[0])){
        return {lat: arr[1], lng: arr[0]}
      }
      else if(arr.length === 1 && isNaN(arr[0])){
        return this.mapGeoJSONPolygon(arr[0]);
      } 
      else {
        return arr.map(child => this.mapGeoJSONPolygon(child))
      }
    }
      
    render() {
      console.log("markerMode", this.state.markerMode)
      const minimal = this.props.minimal || false;
      const zoom = this.props.zoom || 6;
      let firstPosition = {lat: null, lng: null};
      const countPOIs = this.props.countPOIs || 0
      const typeFrame = this.props.typeFrame || 'ooh'
      const {enableSearch, extra} = this.props

      console.log("this.state.session", this.state.session)

      if(typeof this.state.data !== "undefined"){
        if(Array.isArray(this.state.data)){
            firstPosition = this.state.data[0]
        }
        else{
            firstPosition = this.state.data
        }

        return (
          this.state.data.length > 0 ?
          (this.props.poiFiltered===null && countPOIs>1) ?
            <div className="col-12"><h4 className="text-center pt-6">To view on the map, filter by POI first</h4></div>
          :
            <div className={(this.state.fullscreen ? "gmaps-custom-fullscreen" : "gmaps-custom")}>
              <Button color="secondary" icon={!this.state.fullscreen ? "maximize":"minimize"} className="gmaps-custom-btn mr-3" onClick={()=>this.toggleFullscreen()}/>
              <Select defaultValue="light" style={{ width: 95, top: 52 }} className="gmaps-custom-btn mr-3" size="small" onChange={this.onChangeTheme}>
                <Option value="standard">Stardard</Option>
                <Option value="light">Light</Option>
              </Select>
              <StyledMapWithAnInfoBox
                key={this.state.session}
                //googleMapURL={"https://maps.googleapis.com/maps/api/js?key="+apiKey+"&v=3.exp&libraries=geometry,drawing,places"}
                //loadingElement={<div style={{ height: `100%`, paddingTop:"20%"}}><Dimmer active loader className={"gmaps-custom-component gmaps-custom-loading"} ></Dimmer><br/></div>}
                //containerElement={<div className={"GoogleMapContainer gmaps-custom-component"}/>} // TO DO -> globalizzare questi parametri
                //mapElement={<div style={{ height: `100%` }} />}
                mapOption={this.state.themeLight ? minimalMapLightOptions : minimalMapStdOptions}
                session={this.state.session}
                prevSession={this.state.prevSession}
                minimal={minimal}
                zoom={zoom}
                positions={this.state.data}
                positionsAll={this.state.dataAll}
                pois={this.state.pois}
                distance={this.state.distance}
                firstPosition={firstPosition}
                markerMode={this.state.markerMode}
                isClustered={this.state.isClustered}
                onClusterSwitchClick={this.handleSwitchClusterClick}
                onSwitchModeClick={this.handleSwitchModeClick}
                onOpenHeatmap={this.handleOpenHeatMap}
                onFilterIndex={this.handleChangeFilterIndex}
                onFilterHideExcluded={this.handleChangeFilterHideExcluded}
                hideExcluded={this.state.filterHideExcluded}
                isLoadingFilter={this.state.isLoadingFilter}
                indexMin={this.state.indexMin}
                indexMax={this.state.indexMax}
                isRadius={this.state.isRadius}
                polygons={this.mapGeoJSONPolygon(this.props.polygons)}
                onRadiusSwitchClick={this.handleSwitchRadiusClick}
                onClickMarker={this.openInfoMarker}
                onCloseDetails={()=>this.openInfoMarker(null)}
                selectedMarker={this.state.selectedMarker}
                typeFrame={typeFrame}
                enableSearch={enableSearch}
                setPositionPOI={this.setPositionPOI}
                deletePositionPOI={this.deletePositionPOI}
                extra={extra ? extra : null}
                audienceFilter={this.state.audienceFilter}
              />
            </div>
            :
            <div className="col-12"><h4 className="text-center pt-6">No result found</h4></div>
        )
      }
      else {
        return (
          <Dimmer active={true} loader={true}>
            <div className="p-9"></div>
          </Dimmer>
        )
      }
    }
  }

  export default React.memo(MapPlanner);
