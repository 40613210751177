// @flow

import React, {useEffect, useState} from "react";
import {Form, Select, Button, Cascader, message} from 'antd';
import _ from 'lodash';
import {audienceService} from '../../_services'
const {Option} = Select;

var targets = [{
  label: "No selected",
  value: ''
}]

const formConfig = {
  labelCol: { span: 24},
  wrapperCol: { span: 24 },
  layout: "vertical"
}

function AudienceFilter(props) {
  const {onChange, value} = props

  const [demographic, setDemographic] = useState(null)
  const [categorySelected, setCategorySelected] = useState(null)

  const [optionsDemographic, setOptionsDemographic] = useState([])
  const [form] = Form.useForm();

  useEffect( () => {
    getAllAudienceType()
  }, [])

  useEffect( () => {
    if(props.value === null){
      onReset();
    }
    else{
      const {value} = props.value;
      setDemographic(value);
      getAllAudienceType(value)
    }
  }, [props.value])

  const getAllAudienceType = (selected = null) => {
    audienceService.getAllAudienceType().then(
      result => {
        setOptionsDemographic(result)
        if(!selected)
          return;
        
        const category = findCategory(result, selected)
        setCategorySelected(category && category.value)
      },
      error => {
        message.error("Error: " + error)
      }
    )
  } 

  const onReset = () => {
    setDemographic(null)
    form.resetFields();
    onChange(null)
  }

  const onChangeDropdown = (category, items) => {
    if(!items)
      return;
      
    const child = items.pop();
    setDemographic(child.value)
    setCategorySelected(category)
    onChange(child)
  }

  const findCategory = (options, value) => {
    const category = options.find(opt => {
      if(opt.value === value){
        return true;
      }

      if(opt.children){
        return findCategory(opt.children, value);
      }
      return null;
    })

    return category;
  }

  

  return(
    <Form className="p-3" form={form} {...formConfig}>
      <div className="row">
          {
            optionsDemographic.map(dropdown => { 
              const selected = ((demographic !== null) && (categorySelected === dropdown.value)) ? [demographic] : null
              
              if(demographic !== null && categorySelected === null){
                return(<>Loading</>)
              }
              else {
                return (
                  <div className="col">
                    <Form.Item name={dropdown.label} label={<>{dropdown.icon && <i class={dropdown.icon}></i>} {" " + dropdown.label}</>}>
                      <Cascader
                        options={dropdown.children}
                        defaultValue={selected}
                        value={selected}
                        onChange={(values, items) => onChangeDropdown(dropdown.value, items)}
                        placeholder={"Select a " + dropdown.label}
                        style={{ maxWidth: 200 }}
                        disabled={categorySelected !== dropdown.value && demographic}
                      />
                    </Form.Item>
                  </div>
                )
              }
            })
          }
      </div>
      <div className="row">
        <div className="col text-right">
          <Button type="link" danger onClick={onReset} disabled={!demographic}>Reset</Button>
        </div>
      </div>
    </Form>
  )

}

export default AudienceFilter;
