import React, { Component } from "react";
import Dropzone from "../dropzone/Dropzone";
import "./Upload.css";
import Progress from "../progress/Progress";
import iconUploadSVG from "../baseline-check_circle_outline-24px.svg";
import {
  Button
} from "tabler-react";

import { campaignService } from "../../../_services";

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      uploadClearable: false, //if true activate clear button
      response: null
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  onFilesAdded(files) {
    this.setState(prevState => ({
      files: prevState.files.concat(files)
    }));
  }

  async uploadFiles() {
    this.setState({ uploadProgress: {}, uploading: true });
    const promises = [];
    this.state.files.forEach(file => {
      console.log('[UPLOAD] Carico file')
      console.log(file)
      promises.push(this.sendRequest(file));
    });
    try {
      await Promise.all(promises);
      this.setState({ successfullUploaded: true, uploading: false });
      this.props.handlerUploadProgress(true)
      console.log('[UPLOAD] handlerUploadProgress FILE UPPATO!')
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: true, uploading: false });
    }
  }

  sendRequest(file) {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener("progress", event => {
        if (event.lengthComputable) {
          console.log('[UPLOAD] progress event.........')
          console.log(event)
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100
          };
          this.setState({ uploadProgress: copy });
        }
      });

      req.upload.addEventListener("load", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "done", percentage: 100 };
        this.setState({ uploadProgress: copy });
        console.log('[UPLOAD] Caricamento completato!')
        // console.log(req)
        resolve(req.response);
      });

      req.upload.addEventListener("error", event => {
        console.log('[UPLOAD] Si è verificato un errore :(')
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "error", percentage: 0 };
        this.setState({ uploadProgress: copy });
        reject(req.response);
      });

      req.addEventListener("load", event => {
        console.log('[UPLOAD] ON LOAD REQUEST POST ########')
        console.log(req)
        console.log(event)
        this.props.handlerResponse(req) //response della post
        // resolve(req.response);
      });

      // const formData = new FormData();
      // formData.append("file", file, file.name);
      // console.log('POST: ' + "http://192.168.1.135:8100/v1/file/uploadCampaignFile")
      // req.open("POST", "http://192.168.1.135:8100/v1/file/uploadCampaignFile")
      // console.log(req.send(formData))
      let response = null
      if(this.props.type == "new"){
        response = campaignService.loadFileCampaign(req, file)
      }
      else if(this.props.type == "append" && this.props.idKC){
        response = campaignService.loadFileCampaignInAppend(req, file, this.props.idKC)
      }
      console.log(response)
    });
  }

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    if (this.state.uploading || this.state.successfullUploaded) {
      return (
        <div>
          <div className="ProgressWrapper">
            <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
            <img
              className="CheckIcon"
              alt="done"
              src={iconUploadSVG}
              style={{
                opacity:
                  uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
              }}
            />
          </div>
          <p
            className="text-success"
            style={{
                opacity:
                  uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
              }}
          >Import Successful</p>
        </div>

      );
    }
  }

  renderActions() {
    if (this.state.successfullUploaded && this.state.uploadClearable) {
      return (
        <Button
          onClick={() =>
            this.setState({ files: [], successfullUploaded: false })
          }
        >
          Clear
        </Button>
      );
    } else if(this.state.files.length > 0 && !this.state.uploading) {
      return (
        <Button
          disabled={this.state.files.length < 0 || this.state.uploading || this.state.successfullUploaded}
          onClick={this.uploadFiles}
        >
          Upload
        </Button>
      );
    }
  }

  render() {
    return (
      <div className="Upload">
        <span className="Title"></span>
        <div className="Content">
          <div className={this.state.files.length<=0 ? "center_upload" : ""}>
            <Dropzone
              onFilesAdded={this.onFilesAdded}
              disabled={this.state.uploading || this.state.successfullUploaded}
            />
          </div>
          <div className="Files">
            {this.state.files.map(file => {
              return (
                <div key={file.name} className="Row">
                  <span className="Filename">{file.name}</span>
                  {this.renderProgress(file)}
                </div>
              );
            })}
          </div>
        </div>
        <div className="Actions p-3">{this.renderActions()}</div>
      </div>
    );
  }
}

export default Upload;
