
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import {
  Button,
  Form
} from "tabler-react";
import { assetService, doohService } from '../_services';

function PanelDataIdKinFaceByLocationReact(props) {

  const [fields, setFields] = useState([])
  const [selected, setSelected] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    setFields(['OOH', 'DOOH'])
  }, [])

  const handleField = (field = '') => {
    if(field !== ''){
      setLoading(true)
      field = field.toLowerCase()

      switch(field){
        case "dooh":
          exportDOOH();
          break;
        case "ooh":
          exportOOH();
          break;
        default:
          return;
      }
    }
  }

  const exportOOH = () => {
    assetService.getIdKinFaceDistinctByLocation().then(
      result => {
        setLoading(false)
      },
      error => {
        setLoading(false)
        toast.error("Error: " + error)
      }
    );
  }

  const exportDOOH = () => {
    doohService.getIdKinFrameDistinctByLocation().then(
      result => {
        setLoading(false)
      },
      error => {
        setLoading(false)
        toast.error("Error: " + error)
      }
    );
  }

  return (
    <Form.Group label="Export IdKinFace/Frame Distinct by Location">
      <div className="row">
        <div className="col-8">
          <Form.Select onChange={ev => setSelected(ev.target.value)} loading={loading}>
            <option selected={selected === ''}>
              Select Asset Type
            </option>
            {fields.map(item => <option value={item} selected={selected===item}>{item.toUpperCase()}</option> )}
          </Form.Select>
        </div>
        <div className="col-4">
          <Button color="primary" className="ml-2" icon="download" disabled={selected === ''} onClick={() => handleField(selected)} loading={loading}>Export {selected === "DOOH" ? "Csv" : "Zip"}</Button>
        </div>
      </div>
    </Form.Group>
  )

}

export default PanelDataIdKinFaceByLocationReact;
