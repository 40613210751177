
import React, { useState, useEffect } from 'react';

import {
  Button,
  Form
} from "tabler-react";
import { assetService, geoInfoService } from '../_services';

function PanelDataAssetByRegion(props) {

  const [regions, setRegions] = useState([])
  const [selected, setSelected] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    setLoading(true)
    assetService.getDistinctValuesOf('asset', 'region').then(
      result =>{
        setRegions(result)
        setLoading(false)
      }
    )
  }, [])

  const handleExportByRegion = (region = '') => {
    if(region !== ''){
      setLoading(true)
      region = region.toUpperCase()
      assetService.getAssetXlsByFieldValue('region', region).then(
        result => {
          setLoading(false)
        },
        error => {
          setLoading(false)
          console.error("Error: " + error)
        }
      )
    }
  }

  return (
    <Form.Group label="Export Asset Planner by region">
      <div className="row">
        <div className="col-8">
          <Form.Select onChange={ev => setSelected(ev.target.value)} loading={loading}>
            <option selected={selected === ''}>
              Select one Region
            </option>
            {regions.map(item => <option value={item} selected={selected===item}>{item}</option> )}
          </Form.Select>
        </div>
        <div className="col-4">
          <Button color="primary" className="ml-2" icon="download" disabled={selected === ''} onClick={() => handleExportByRegion(selected)} loading={loading}>Export Xls</Button>
        </div>
      </div>
    </Form.Group>
  )

}

export default PanelDataAssetByRegion;
