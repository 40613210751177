import * as React from "react";

import { Button, Form} from "tabler-react"
import { Transfer, Table, Tabs, Modal, Card, Badge, Popconfirm, Spin, Button as ButtonAntd, message } from 'antd';
import { DesktopOutlined, OrderedListOutlined, ImportOutlined, ExportOutlined } from '@ant-design/icons';
import styles from 'antd/dist/antd.css';

import AmbiencesTable from './Dooh.Plan.AmbiencesTable.react'
import {SortableTableAmbiences} from './Dooh.Plan.Ambiences.Sorter/'

import difference from 'lodash/difference';
import _, { isArray } from "lodash"

const { TabPane } = Tabs;

// Customize Table Transfer
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <Transfer {...restProps} showSelectAll={true} titles={['AVAILABLE', 'SELECTED']}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns;

      const rowSelection = {
        getCheckboxProps: item => ({
          disabled: listDisabled || item.disabled,
          // id: item.id,
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter(item => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected);
        },
        selectedRowKeys: listSelectedKeys,
      };

      return (
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          size="small"
          style={{ pointerEvents: listDisabled ? 'none' : null }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return;
              onItemSelect(key, !listSelectedKeys.includes(key));
            },
          })}
        />
      );
    }}
  </Transfer>
);

const leftTableColumns = [
  {
    title: <DesktopOutlined />,
    dataIndex: 'childrenCount',
    width: 50,
    render: item => item ? (
      <Badge count={item} overflowCount={1000} style={{backgroundColor: "#cf467f"}} />
    ) : ''
  },
  {
    dataIndex: 'type',
    title: 'Environment'
  },
  {
    dataIndex: 'installation',
    title: 'Type'
  },
  {
    dataIndex: 'publisher',
    title: 'Publisher'
  },
  {
    dataIndex: 'city',
    title: 'City',
  },
  {
    dataIndex: 'ots',
    title: 'OTS',
  },
];
const rightTableColumns = [
  {
    title: <DesktopOutlined />,
    dataIndex: 'childrenCount',
    width: 50,
    render: item => item ? (
      <Badge count={item} overflowCount={1000} style={{backgroundColor: "#cf467f"}} />
    ) : ''
  },
  {
    dataIndex: 'type',
    title: 'Environment'
  },
  {
    dataIndex: 'installation',
    title: 'Type'
  },
  {
    dataIndex: 'publisher',
    title: 'Publisher'
  },
  {
    dataIndex: 'city',
    title: 'City',
  },
  {
    dataIndex: 'ots',
    title: 'OTS',
  },
];

class DoohPlanAmbiences extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      targetKeys: [], //originTargetKeys,
      disabled: false,
      showSearch: false,

      visibleModal: false,
      ambienceName: '',
      selectedAmbience: null,
      editing: false,

      ambiences: [],
      visibleModalSortAmbiences: false,
    }
  }

  componentWillMount(){
    let {ambiences} = this.props

    ambiences = ambiences.map((item, index) => {
      item.key = index
      return item
    })

    const targetKeys = ambiences[0] ? ambiences[0].histories : []

    this.setState({
      ambiences,
      targetKeys
    })
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.ambiences!==this.props.ambiences){

      let {ambiences} = nextProps
      const {selectedAmbience} = this.state

      ambiences = ambiences.map((item, index) => {
        item.key = index
        return item
      })

      const targetKeys = ambiences[selectedAmbience] ? ambiences[selectedAmbience].histories : []

      this.setState({
        ambiences: nextProps.ambiences,
        targetKeys
      })
    }
  }

  onChange = targetKeys => {
    const {ambiences, selectedAmbience} = this.state

    const name = ambiences[selectedAmbience].name // TO DO... potrei far cambiare il nome...

    this.props.handleUpdateAmbience({name,  histories: targetKeys}, selectedAmbience, false)
  };

  onSaveAmbience = () => {
    const {selectedAmbience, ambiences} = this.state
    const ambience = ambiences[selectedAmbience]
    this.props.handleUpdateAmbience(ambience, selectedAmbience, false)
  }


  onChangeAmbienceName  = ev => {
    const { value } = ev.target
    let { ambiences, selectedAmbience } = this.state

    ambiences[selectedAmbience].name = value

    this.setState({
      ambiences
    })
  }

  triggerDisable = disabled => {
    this.setState({ disabled });
  };

  triggerShowSearch = showSearch => {
    this.setState({ showSearch });
  };

  handleSelectTabAmbience = selectedAmbience => {
    const {ambiences} = this.state
    const targetKeys = ambiences[selectedAmbience] ? ambiences[selectedAmbience].histories : []

    this.setState({
      selectedAmbience,
      targetKeys
    })
  }

  handleChangeAmbienceVisibility = (selectedAmbience, visible) => {
    const {ambiences} = this.state
    ambiences[selectedAmbience].visible = visible

    this.setState({
      ambiences,
      loading: true
    }, () => this.onSaveAmbience())
  }

  handleChangeAmbienceName = ev => {
    const {value} = ev.target
    this.setState({
      ambienceName: value
    })
  }

  showModalNewAmbience = () => {
    this.setState({
      visibleModalNew: true,
      ambienceName: ''
    });
  };

  showModalCloneAmbience = () => {
    const {ambiences, selectedAmbience} = this.state

    if(!ambiences[selectedAmbience]){
      message.warning('Choose a selection to duplicate')
      return;
    }

    const {name} = ambiences[selectedAmbience]

    this.setState({
      visibleModalClone: true,
      ambienceName: 'Copy of ' + name
    });
  };

  showModalDeleteAmbience = () => {
    this.setState({
      visibleModalDel: true,
    });
  };

  handleModalOkCreate = e => {
    const {ambienceName} = this.state

    const ambience = {
      name: ambienceName,
      histories: []
    }

    this.setState({
      visibleModalNew: false,
      selectedAmbience: 0
    }, () => this.props.handleAddAmbience(ambience));
  };

  handleModalOkClone = e => {
    let {ambienceName, ambiences, selectedAmbience} = this.state

    let ambience = {
      ...ambiences[selectedAmbience],
      name: ambienceName
    }

    this.setState({
      visibleModalClone: false,
      selectedAmbience: 0
    }, () => this.props.handleAddAmbience(ambience));
  };

  handleModalOkDelete = e => {
    const {selectedAmbience} = this.state

    this.setState({
      selectedAmbience: 0
    })

    this.props.handleDeleteAmbience(selectedAmbience)
  };

  handleModalCancel = e => {
    this.setState({
      visibleModalNew: false,
      visibleModalDel: false,
      visibleModalClone: false,
      ambienceName: ""
    });
  };

  handleFilterInAmbience(item, inputValue){
    inputValue = inputValue.toUpperCase()
    return (
      item.type.toUpperCase().indexOf(inputValue) !== -1
      || item.installation.toUpperCase().indexOf(inputValue) !== -1
      || item.publisher.toUpperCase().indexOf(inputValue) !== -1
      || item.region.toUpperCase().indexOf(inputValue) !== -1
      || item.district.toUpperCase().indexOf(inputValue) !== -1
      || item.city.toUpperCase().indexOf(inputValue) !== -1
    )
  }

  handleChangeSortingAmbiences = ambiences => {
    this.setState({
      ambiences
    })
  }

  handleChangeEstimation = () => {
    const {ambiences} = this.state
    this.props.handleUpdateAmbiences(ambiences)
  }

  showModalSortAmbiences = () => {
    this.setState({
      visibleModalSortAmbiences: true,
    });
  };

  handleOkSortAmbiences = e => {
    this.setState({
      visibleModalSortAmbiences: false,
    }, () => this.handleChangeEstimation());
  };

  handleCancelSortAmbiences = e => {
    let {ambiences} = this.props

    ambiences = ambiences.map((item, index) => {
      item.key = index
      return item
    })

    this.setState({
      ambiences,
      visibleModalSortAmbiences: false,
    });
  };

  onChangeActiveAll = (active = true) => {
    let {ambiences} = this.state

    ambiences = ambiences.map(amb =>{
      amb.visible = active
      return amb
    })

    this.setState({
      ambiences,
      loading: true
    }, () => this.onSaveAmbience())
  }

  render() {
    const { histories } = this.props
    const { ambiences, targetKeys, disabled, selectedAmbience, ambienceName } = this.state;

    const buttonDelete = (
      <Popconfirm placement="top" title={"Delete selection"} onConfirm={this.handleModalOkDelete} okText="Delete" cancelText="No">
        <Button color="link" className="text-muted" icon="trash">DELETE</Button>
      </Popconfirm>
    )

    const ComponentEditAmbience = (
      <div className="col-12 pl-5 pt-1">
        <div className="row mb-2">
          <div className="col-5">
            <Form.Input
              label="Name"
              name="ambienceName"
              placeholder="Name..."
              value={ambiences[selectedAmbience] ? ambiences[selectedAmbience].name : []}
              onChange={this.onChangeAmbienceName}
            />
          </div>
          <div className="col-7">
            <Form.Label className="invisible">Action</Form.Label>
            <Button color="primary" onClick={this.onSaveAmbience}>Save</Button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <TableTransfer
              key={selectedAmbience+'-'+JSON.stringify(ambiences)} //workaround
              dataSource={histories}
              targetKeys={targetKeys}
              disabled={disabled}
              showSearch={true}
              onChange={this.onChange}
              filterOption={(inputValue, item) => this.handleFilterInAmbience(item, inputValue)}
              leftColumns={leftTableColumns}
              rightColumns={rightTableColumns}
            />
          </div>
        </div>
      </div>
    )

    const extra = (
      <>
        <ButtonAntd color="primary" className="mr-2" onClick={()=>this.onChangeActiveAll(false)}>
          <ExportOutlined rotate="180" /> DISABLE ALL
        </ButtonAntd>
        <ButtonAntd className="mr-2" onClick={()=>this.onChangeActiveAll(true)}>
          <ImportOutlined rotate="180" /> ENABLE ALL
        </ButtonAntd>
        <ButtonAntd className="mr-2" onClick={this.showModalSortAmbiences}>
          <OrderedListOutlined /> SORT
        </ButtonAntd>
      </>
    )

    return (
      <div style={styles}>
        <div className="row">
          <div className="col-12">
            <Card
              title={"Your selection"}
              actions={[
                <Button color="link" className="text-muted" icon="plus" onClick={this.showModalNewAmbience}>NEW</Button>,
                <Button color="link" className="text-muted" icon="copy" onClick={this.showModalCloneAmbience}>DUPLICATE</Button>,
                <Button color="link" className="text-muted" onClick={this.showModalSortAmbiences}><OrderedListOutlined/>{'   '}SORT</Button>,
                buttonDelete,
              ]}
              headStyle={{padding: "0 20px"}}
              bodyStyle={{padding: 0}}
              extra={extra}
            >
              <AmbiencesTable
                ambiences={ambiences}
                selectedRowKeys={selectedAmbience}
                onSelectItem={this.handleSelectTabAmbience}
                onChangeItemVisibity={this.handleChangeAmbienceVisibility}
                loading={this.props.loading}
                expandable={{
                  expandedRowRender: record => record.visible ? ComponentEditAmbience : <Spin style={{fontSize: "1.5em", color: "#777"}} indicator={<span/>} tip={"Disabled"}>{ComponentEditAmbience}</Spin>,
                  expandIcon: props => <span></span>,
                  expandedRowKeys: isArray(selectedAmbience) ? selectedAmbience : [selectedAmbience],
                  onExpandedRowsChange: expandedRows => console.log('expandedRows', expandedRows)
                }}
              />
            </Card>

            <Modal
              title="Create new selection"
              visible={this.state.visibleModalNew}
              onOk={this.handleModalOkCreate}
              onCancel={this.handleModalCancel}
            >
              <Form.Group label="Selection name">
                <Form.Input
                  name="ambience-name"
                  placeholder="Selection name"
                  onChange={this.handleChangeAmbienceName}
                  value={ambienceName}
                />
              </Form.Group>
            </Modal>

            <Modal
              title="Duplicate selection"
              visible={this.state.visibleModalClone}
              onOk={this.handleModalOkClone}
              onCancel={this.handleModalCancel}
            >
              <Form.Group label="Selection name">
                <Form.Input
                  name="ambience-name"
                  placeholder="Selection name"
                  onChange={this.handleChangeAmbienceName}
                  value={ambienceName}
                />
              </Form.Group>
            </Modal>

            <Modal
              title="Drag and drop to sort the selections"
              visible={this.state.visibleModalSortAmbiences}
              onOk={this.handleOkSortAmbiences}
              onCancel={this.handleCancelSortAmbiences}
            >
              <SortableTableAmbiences ambiences={ambiences} onSortEnd={this.handleChangeSortingAmbiences} onSave={this.handleChangeEstimation}/>
            </Modal>


          </div>


        </div>


      </div>
    );
  }
}

export default DoohPlanAmbiences;
