import React, {useState, useEffect} from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import { companyService } from './_services'
import { PrivateRoute } from "./_components";

import {
  ForgotPasswordPage,
  LoginPage,
  RegisterPage,
  Error400,
  Error401,
  Error403,
  Error404,
  Error500,
  Error503,
  ProfileData,
} from "./pages";

import HomePage from "./HomePage.react";
import Campaigns from "./Campaigns/Campaigns.react";
import CampaignsMap from "./Campaigns/Campaigns.Map.react";
import CampaignsPhoto from "./Campaigns/Campaigns.Photo.react";
import CampaignsImport from "./Campaigns/Campaigns.Import.react";
import CampaignsImportAppend from "./Campaigns/Campaign.ImportAppend.react";
import CampaignsUploadCreativity from "./Campaigns/Campaigns.UploadCreativity.react";
import Mediaowners from "./Mediaowners/Mediaowners.react";
import Audit from "./Audit/Audit.react";
import Planner from "./Planner/Planner.react"
import PlannerNew from "./Planner/Planner.Plan.new.react"
import PlannerPlan from "./Planner/Planner.Plan.react"
import POIGroup from "./Planner/Planner.PoiManage.react"

// import BigImpact from "./BigImpact/BigImpact.react"
// import BigImpactNew from "./BigImpact/BigImpact.Plan.new.react"
// import BigImpactPlan from "./BigImpact/BigImpact.Plan.react"
// import BigImpactAssetImport from './BigImpact/BigImpact.Asset.Import.react'

import PoiManage from "./Planner/Planner.PoiManage.react"

import TestPlanner from "./Test/Test.react"
import IframeOOH from "./Planner/IframeOOH.react"
import IframeDOOH from "./Dooh/IframeDOOH.react"
import TestPolygons from "./Test/MapPolygon.react"

import DoohPlanner from "./Dooh/Dooh.Plans.react"
import DoohPlannerNew from "./Dooh/Dooh.Plan.New.react"
import DoohPlannerPlan from "./Dooh/Dooh.Plan.react"

import DoohAssetImport from './Dooh/Dooh.Asset.Import.react'

import PlannerAssetImport from './Planner/Planner.Asset.Import.react'


import Companies from "./Companies/Companies.react";
import Users from "./Users/Users.react"

import PanelData from './PanelData/PanelData.react'

import "tabler-react/dist/Tabler.css";

import withClearCache from "./ClearCache";
import ShareViewer from "./Share/ShareViewer";
const ClearCacheComponent = withClearCache(MainApp);

type Props = {||};

function App(props: Props): React.Node {
  return <ClearCacheComponent />;
}

function MainApp(props: Props): React.Node {

  const [hasRealDooh, setHasRealDooh] = useState(false)

  useEffect(()=> {
    const hasRealDoohState = companyService.getUserAllowedModule('realdooh')
    setHasRealDooh(hasRealDoohState)
  }, [])

  // Workaround REALDOOH for companies
  if(hasRealDooh){
    return (
      <React.StrictMode>
        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect to="/dooh/plan" />
            </Route>
            <PrivateRoute exact path="/dooh/plan" component={DoohPlanner} />
            <PrivateRoute exact path="/dooh/plan/new" component={DoohPlannerNew} />
            <PrivateRoute exact path="/dooh/plan/:id/" component={DoohPlannerPlan} />
            <PrivateRoute exact path="/dooh/panel-discovery" component={IframeDOOH} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/logout" component={LoginPage} />
            <Route exact path="/logout/:error/" component={LoginPage} />
            <PrivateRoute exact path="/profile" component={ProfileData} />
            <Route exact path="/400" component={Error400} />
            <Route exact path="/401" component={Error401} />
            <Route exact path="/403" component={Error403} />
            <Route exact path="/404" component={Error404} />
            <Route exact path="/500" component={Error500} />
            <Route exact path="/503" component={Error503} />
            <Route exact path="/forgot-password" component={ForgotPasswordPage} />
            <Route component={Error404} />
          </Switch>
        </Router>
      </React.StrictMode>
    )
  }

  return (
    <React.StrictMode>
      <Router>
        <Switch>
          <PrivateRoute exact path="/" component={HomePage} />
          <Route exact path="/400" component={Error400} />
          <Route exact path="/401" component={Error401} />
          <Route exact path="/403" component={Error403} />
          <Route exact path="/404" component={Error404} />
          <Route exact path="/500" component={Error500} />
          <Route exact path="/503" component={Error503} />
          <Route exact path="/forgot-password" component={ForgotPasswordPage} />
          <Route exact path="/resource/:resourceType/:resourceId/token/:token/view" component={ShareViewer} />

          <PrivateRoute exact path="/campaigns" component={Campaigns} />
          <PrivateRoute exact path="/campaigns/import/" component={CampaignsImport} />
          <PrivateRoute exact path="/campaigns/import/id/:id/" component={CampaignsUploadCreativity} />
          <PrivateRoute exact path="/campaigns/importAppend/id/:id/" component={CampaignsImportAppend} />
          <PrivateRoute exact path="/campaigns/:id/map/" component={CampaignsMap} />
          <PrivateRoute exact path="/campaigns/:id/photo/" component={CampaignsPhoto} />
          <PrivateRoute exact path="/campaigns/:id/justificative/" component={Audit} />

          <PrivateRoute exact path="/mediaowner" component={Mediaowners} />

          <PrivateRoute exact path="/poimanage" component={PoiManage} />

          <PrivateRoute exact path="/asset/import/planner" component={PlannerAssetImport} />
          <PrivateRoute exact path="/planner/plan" component={Planner} />
          <PrivateRoute exact path="/planner/plan/new" component={PlannerNew} />
          <PrivateRoute exact path="/planner/plan/:id/" component={PlannerPlan} />
          <PrivateRoute exact path="/planner/poimanager" component={POIGroup} />
          <PrivateRoute exact path="/planner/panel-discovery" component={IframeOOH} />

          {/* <PrivateRoute exact path="/asset/import/bigimpact" component={BigImpactAssetImport} /> */}
          {/* <PrivateRoute exact path="/bigimpact/plan" component={BigImpact} />
          <PrivateRoute exact path="/bigimpact/plan/new" component={BigImpactNew} />
          <PrivateRoute exact path="/bigimpact/plan/:id/" component={BigImpactPlan} /> */}

          <PrivateRoute exact path="/paneldata" component={PanelData} />

          <PrivateRoute exact path="/asset/import/dooh" component={DoohAssetImport} />
          <PrivateRoute exact path="/dooh/plan/new" component={DoohPlannerNew} />
          <PrivateRoute exact path="/dooh/plan/:id/" component={DoohPlannerPlan} />
          <PrivateRoute exact path="/dooh/plan" component={DoohPlanner} />
          <PrivateRoute exact path="/dooh/panel-discovery" component={IframeDOOH} />

          <PrivateRoute exact path="/test" component={TestPlanner} />
          <PrivateRoute exact path="/test/polygons" component={TestPolygons} />

          <PrivateRoute exact path="/companies" component={Companies} />
          <PrivateRoute exact path="/users" component={Users} />

          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/logout" component={LoginPage} />
          <Route exact path="/logout/:error/" component={LoginPage} />
          <PrivateRoute exact path="/profile" component={ProfileData} />
          <Route exact path="/register" component={RegisterPage} />
          <Route component={Error404} />
        </Switch>
      </Router>
    </React.StrictMode>
  );
}

export default App;
