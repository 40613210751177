import React from "react";
import { Route, Redirect } from "react-router-dom";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const hasToken = localStorage.getItem("user") && localStorage.getItem("type");
  const isGuest = localStorage.getItem("type") === "guest";

  return (
    <Route
      {...rest}
      render={props => {
        if(!hasToken){
          return(
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }

        if(isGuest){
          return(
            <Redirect
              to={{ pathname: "/403", state: { from: props.location } }}
            />
          )
        }
        
        return (
          <Component {...props} />
        )
      }}
    />
  )
};
