/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { Card } from "antd";
import SyncingChart from "./SynchingCharts.Chart";
import SyncingFilters from "./SynchingCharts.Filters";
import _ from "lodash";

function CompareUsers(props) {
  const {assetType} = props;

  const defaultFirstDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
  ] 
  const defaultSecondDays = [
    "Saturday",
    "Sunday",
  ]

  const [mediaOwners, setMediaOwners] = useState([])
  const [dashboardTypes, setDashboardTypes] = useState([])
  const [circuits, setCircuits] = useState([])
  const [currentMediaOwner, setCurrentMediaOwner] = useState('')
  const [currentDashboardType, setCurrentDashboardType] = useState('')
  const [currentCircuit, setCurrentCircuit] = useState('')
  const [selectedDaysTop, setSelectedDaysTop] = useState(defaultFirstDays);
  const [selectedDaysBot, setSelectedDaysBot] = useState(defaultSecondDays);


  useEffect(()=> {
    const mediaOwners = _.uniq(props.allData.map(value=> value && value.mediaOwner))
    setMediaOwners(mediaOwners)
    const dashboardTypes = _.uniq(props.allData.map(value=> value && value.dashboardType))
    setDashboardTypes(dashboardTypes)
    const circuits = _.uniq(props.allData.map(value=> value && value.circuit))
    setCircuits(circuits)
  },[])

  const onChangeDays = (mode, value) => {
    if(mode==='top'){
      setSelectedDaysTop(value)
      setSelectedDaysBot([...selectedDaysBot])
    } else {
      setSelectedDaysBot(value)
      setSelectedDaysTop([...selectedDaysTop])
    }
  };

  const onResetFilters = () => {
    setCurrentMediaOwner('')
    setCurrentDashboardType('')
    setCurrentCircuit('')
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <p className="text-center mt-2 mb-2">
            <strong>Time when audience is the most active.</strong> Hourly Users distribution per selected days. Value expressed in %. Lookback window 12 weeks.
          </p>
          
        </div>
      </div>
      <SyncingFilters
        allData={props.allData}
        filters={['mediaOwner', 'dashboardType', 'circuit']}
        mediaOwners={mediaOwners}
        circuits={circuits}
        dashboardTypes={dashboardTypes}
        currentMediaOwner={currentMediaOwner}
        currentCircuit={currentCircuit}
        currentDashboardType={currentDashboardType}
        onChangeMediaOwner={(e)=>setCurrentMediaOwner(e)}
        onChangeDashboardType={(e)=>setCurrentDashboardType(e)}
        onChangeCircuit={(e)=>setCurrentCircuit(e)}
        onReset={onResetFilters}
      />
      <Card
        size="small"
        className="mt-3"
        headStyle={{background: "rgb(250, 250, 250)"}}
        bodyStyle={{marginLeft:'-20px'}}
        title={<SyncingFilters
          allData={props.allData}
          filters={['firstDays']}
          firstDays={selectedDaysTop}
          onChangeDays={onChangeDays}
        />
        }
      >
        <SyncingChart
          chartKey={'chartTop' + JSON.stringify(selectedDaysTop) }
          groupKey="compareUserGroup"
          allData={props.allData}
          audienceFilter={props.audienceFilter}
          assetType={assetType}
          filters={
            {
              circuit: currentCircuit,
              dashboardType: currentDashboardType,
              mediaOwner: currentMediaOwner
            }
          }
          days={selectedDaysTop}
        />
      </Card>
      <Card
        className="mt-2"
        size="small"
        headStyle={{background: "rgb(250, 250, 250)"}}
        bodyStyle={{marginLeft:'-20px'}}
        title={
          <SyncingFilters
            allData={props.allData}
            filters={['secondDays']}
            secondDays={selectedDaysBot}
            onChangeDays={onChangeDays}
          />
        }>
        <SyncingChart
          chartKey={'chartBot' + JSON.stringify(selectedDaysBot) }
          groupKey="compareUserGroup"
          allData={props.allData}
          audienceFilter={props.audienceFilter}
          assetType={assetType}
          filters={
            { circuit: currentCircuit,
              dashboardType: currentDashboardType,
              mediaOwner: currentMediaOwner
            }
          }
          days={selectedDaysBot}
        />
      </Card>
    </>
  );
}

export default CompareUsers;
