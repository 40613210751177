import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts'
import { audienceService } from '../_services';
import {Dimmer} from 'tabler-react';
import {message, Tabs, Tooltip} from 'antd';
import AudienceFilter from './Filters/Audience.filters.react';
import { InfoCircleOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;


// index, totalUserSeen, targetUserSeen

function HeatMapTableAudience(props){
  const [dataSource, setDataSource] = useState([])
  const [selectedSource, setSelectedSource] = useState(props.partial || "totalUserSeen")
  const [loading, setLoading] = useState(false)

  const {faceIds, audienceFilter, height, type = 'ooh', partial = null} = props;

  useEffect(()=>{
    if(faceIds.length>0){
      setTimeout(()=>{
        getWeeklyAudience(faceIds, selectedSource)
      }, 500)
    }
  },[faceIds, selectedSource])

  const getWeeklyAudience = (ids, valueType) => {
    if(!audienceFilter)
      return;
      
    setLoading(true)
    audienceService.getWeeklyAudienceByIds(ids, audienceFilter.id, 'mon', valueType, type).then(
      res => {
        setDataSource(res.daily)
        setLoading(false)
      },
      error => {
        setDataSource([])
        setLoading(false)
        message.error('No data found!')
      }
    )
  }

  const options = {
    chart: {
      type: 'heatmap',
    },
    dataLabels: {
      enabled: false
    },
    colors: ["#008FFB"],
    // title: {
    //   text: selectedSource + ' Heatmap'
    // }
  }

  return (
    <>
      <div className="row">
        <div className="col text-left">
          {
            partial === null &&
            <Tabs activeKey={selectedSource} defaultActiveKey="Total Users" onChange={(value)=>setSelectedSource(value)} tabBarExtraContent={props.extra}>
              <TabPane tab="Total Users" key="totalUserSeen">{' '}</TabPane>
              <TabPane tab="Target Users" key="targetUserSeen">{' '}</TabPane>
            </Tabs>
          }

          {
            partial === "totalUserSeen" && (
              <strong className='text-muted'>
                Total Users
                <Tooltip title="Info index total users" className='ml-1 mr-2' placement="right">
                    <InfoCircleOutlined />
                </Tooltip>
              </strong>
            )
          }
          {
            partial === "targetUserSeen" && (
              <strong className='text-muted'>
                Target Users
                <Tooltip title="Info index target users" className='ml-1 mr-2' placement="right">
                    <InfoCircleOutlined />
                </Tooltip>
              </strong>
            )
          }

          <Dimmer active={loading} loader={loading}>
            {
              dataSource.length > 0 ?
                <ReactApexChart options={options} series={dataSource} type="heatmap" height={height || 400} />
                :
                <div className='p-6'>
                  <h3 className='text-center'>No data</h3>
                </div>
            }
          </Dimmer>
        </div>
      </div>
    </>
  )
}

export default HeatMapTableAudience;
