import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import {
  Button,
  Form
} from "tabler-react";
import { assetService, geoInfoService } from '../_services';

function handleExportByMediaOwner(mediaowner = ''){
  if(mediaowner !== ''){
    mediaowner = mediaowner.toUpperCase()
    assetService.getAssetXlsByFieldValue('mediaOwner', mediaowner)
  }
}

function PanelDataAssetByMediaOwner(props) {

  const [mediaowners, setMediaOwners] = useState([])
  const [selected, setSelected] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    setLoading(true)
    assetService.getDistinctValuesOf('asset', 'mediaOwner').then(
      result =>{
        setMediaOwners(result)
        setLoading(false)
      },
      error => {
        toast.error("Error: " + error);
      }
    )
  }, [])

  const handleExportByMediaOwner = (mediaowner = '') => {
    if(mediaowner !== ''){
      setLoading(true)
      mediaowner = mediaowner.toUpperCase()
      assetService.getAssetXlsByFieldValue('mediaOwner', mediaowner).then(
        result => {
          setLoading(false)
        },
        error => {
          setLoading(false)
          console.error("Error: " + error)
        }
      )
    }
  }

  return (
    <Form.Group label="Export Asset Planner by Media Owner">
      <div className="row">
        <div className="col-8">
          <Form.Select onChange={ev => setSelected(ev.target.value)} loading={loading}>
            <option selected={selected === ''}>
              Select one Media Owner
            </option>
            {mediaowners.map(item => <option value={item} selected={selected===item}>{item}</option> )}
          </Form.Select>
        </div>
        <div className="col-4">
          <Button color="primary" className="ml-2" icon="download" disabled={selected === ''} onClick={() => handleExportByMediaOwner(selected)} loading={loading}>Export Xls</Button>
        </div>
      </div>
    </Form.Group>
  )

}

export default PanelDataAssetByMediaOwner;
