import { authHeader } from "../_helpers";
import { userService } from "./"
import FileSaver from 'file-saver';
import {defineConfig} from '../config'

export const pdfService = {
  getApiPath,
  getPdfFromUrl,
  getPdfManageUrl
};

const {apiUrl, apiVer} = defineConfig.PDF_SERVICE

function getApiPath(){
  return (apiUrl+apiVer)
}

function getPdfManageUrl(idkc, type, from = false, to = false, filtered = "all"){
  let requestUrl = null

  // Filtered only new or get all wrong and anomaly
  switch(filtered){
    case "all":
      filtered = "";
      break;
    case "new":
      filtered = "/new"
      break;
    default:
      filtered = "";
  }

  // All period or range date
  switch(type){
    case "all":
      requestUrl = apiUrl+"campaign/id/"+idkc+filtered+"/generatePDF"
      break;
    case "range":
      if(from && to){
        requestUrl = apiUrl+"campaign/id/"+idkc+"/start/"+from+"/end/"+to+filtered+"/generatePDF"
      }
      break;
  }

  if(requestUrl){
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return fetch(requestUrl, requestOptions)
        .then(handleResponse)
  }
  else{
    return false;
  }
}

function getPdfFromUrl(url) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let fileName = url.split('/')
  fileName = fileName[fileName.length-1]
  // console.log(fileName)

  return fetch(url, requestOptions)
    .then(response =>{
      // console.log("scarico....")
      return response.blob()
    })
    .then(function(blob) {
      FileSaver.saveAs(blob, fileName)
      return true;
    })
}

function handleResponse(response) {
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          userService.logout();
          window.location.reload(true);
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      return data;
    });
}
