// @flow

import * as React from "react";

import {
  Table
} from "tabler-react";

class CampaignsPanelInfo extends React.Component {
    constructor(props){
        super(props)
        this.state = ({
            data: {
                mediaOwner: null,
            }
        })
    }
    componentDidMount() {
        this.setState({
            data: this.props.data
        })
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.data!==this.props.data){
            this.setState({
                data: nextProps.data
            })
        }
    }

    render() {
        if(typeof this.state.data !== "undefined"){
            return (
                <Table striped={true} responsive className="table-border-top-0 table-firstcol-header table-md">
                    <Table.Body>
                        <Table.Row>
                            <Table.Col>Media Owner</Table.Col>
                            <Table.Col>{this.state.data.mediaOwner}</Table.Col>
                        </Table.Row>
                        <Table.Row>
                            <Table.Col>Format</Table.Col>
                            <Table.Col>{this.state.data.panelType}</Table.Col>
                        </Table.Row>
                        <Table.Row>
                            <Table.Col>Size</Table.Col>
                            <Table.Col>{this.state.data.formatoDesc ? this.state.data.formatoDesc : '-'}</Table.Col>
                        </Table.Row>
                        <Table.Row>
                            <Table.Col>City</Table.Col>
                            <Table.Col>{this.state.data.municipality}</Table.Col>
                        </Table.Row>
                        <Table.Row>
                            <Table.Col>Address</Table.Col>
                            <Table.Col>{(this.state.data.placeNames ? this.state.data.placeNames : "-") + ' ' + (this.state.data.address ? this.state.data.address : "-")}</Table.Col>
                        </Table.Row>
                        <Table.Row>
                            <Table.Col>Note</Table.Col>
                            <Table.Col>{this.state.data.note}</Table.Col>
                        </Table.Row>
                        <Table.Row>
                            <Table.Col>Cimasa</Table.Col>
                            <Table.Col>{this.state.data.cimasa} ({this.state.data.seqId})</Table.Col>
                        </Table.Row>
                    </Table.Body>
                </Table>
            );
        }
        else return null;
    }
}

export default CampaignsPanelInfo;