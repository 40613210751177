// @flow

import * as React from "react";

import {Button} from "tabler-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { campaignService } from "../../_services";

class Downloader extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            idkc: null,
            isOpenModal: false,
            zipLoading: false,
            zipUrl: null,
            zipError: null,
            zipProgress: 0,
            zipDownloading: false,
            sendingQuery: false
        }

        this.toggleModalDownload = this.toggleModalDownload.bind(this)
        this.initCampaignZipReport = this.initCampaignZipReport.bind(this)
        this.pollingCampaignZipReport = this.pollingCampaignZipReport.bind(this)
        this.onClickDownload = this.onClickDownload.bind(this)
        this.cancelTimerZip = this.cancelTimerZip.bind(this)
    }

    componentWillUnmount() {
        this.cancelTimerZip()
    }

    toggleModalDownload(){
        // console.log(this.state)

        this.initCampaignZipReport()

        let isOpened = this.state.isOpenModal 
        if(isOpened){
            this.cancelTimerZip()
        }
        
        this.setState(prevState => ({
            isOpenModal: !isOpened,
            zipProgress: 0,
            zipLoading: prevState.isOpenModal ? false: prevState.zipLoading,
            zipUrl: prevState.isOpenModal ? null: prevState.zipUrl,
            zipError: prevState.isOpenModal ? null: prevState.zipError
        }));
    }

    cancelTimerZip(){
        // console.log("stacco il timer!")
        if(this.state.sendingQuery) {
            clearInterval(this.state.sendingQuery);
        }
    }

    onClickDownload(){
        // console.log("onclick Download")
        // console.log(this.state.zipUrl)
        this.setState({
            zipDownloading: true
        })

        let url = this.state.zipUrl
        campaignService.getZipFromUrl(url).then(
            finished => {
                this.setState({
                    zipDownloading: !finished
                })
                // console.log("SCARICATO!")
            },
            error => {
                this.setState({
                    zipDownloading: false
                })
                console.error(error);
            });
    }
    
    initCampaignZipReport(){
        if(!this.state.isOpenModal){
            this.getCampaignZipReport()
        }
        else{
            this.cancelTimerZip()
        }
    }

    pollingCampaignZipReport(id) {
        campaignService.pollingZipCampaign(id).then(
            result => {
                // console.log(result)
                if(result.readyForDownload){
                    this.setState({
                        zipLoading: false,
                        zipUrl: result.downloadURL,
                        zipProgress: 100
                    })
                    this.cancelTimerZip();
                }
                else{
                    this.setState({
                        zipLoading: true,
                        zipUrl: null,
                        zipProgress: Math.round((result.auditsProcessed/result.auditsSize)*100)
                    })
                }
                if(result.auditsSize<=0){
                    this.setState({
                        zipLoading: false,
                        zipUrl: null,
                        zipProgress: 100,
                        zipError: "No photos found, nothing to download."
                    })
                    this.cancelTimerZip();
                }
            },
            error => {
                console.log(error)
            }
        )
    }
    

    getCampaignZipReport(){
        let id = this.props.idkc
        if(id){
            campaignService.getZipCampaign(id).then(
                result => {
                    this.setState({
                        sendingQuery: setInterval(()=> this.pollingCampaignZipReport(id), 1500),
                        zipLoading: true,
                        zipUrl: null
                    })

                    // console.log(result)
                },
                error => {
                    this.setState({
                        zipLoading: false,
                        zipUrl: null,
                        zipError: "No results: error export Zip"
                    })
                    console.error(error);
                }
            )
        }
    }

    render(){
        return(
            <div className={this.props.className}>
                <a href="#" onClick={this.toggleModalDownload} className={this.props.classButton}><i class={this.props.classIcon}></i> {this.props.labelButton}</a>
                <Modal isOpen={this.state.isOpenModal} toggle={this.toggleModalDownload}>
                    <ModalHeader toggle={this.toggleModalDownload}>Export campaign in Zip file</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col">
                                <div className="mb-3 text-center">
                                    {this.state.zipLoading && <span>Campaign folders processing...</span>}
                                    {this.state.zipDownloading && <span>Zip compression in progress...</span>}
                                    {this.state.zipError && <span>Empty folder</span>}
                                    {!(this.state.zipLoading || this.state.zipDownloading || this.state.zipError ) && <span>Zip created successfully</span>}
                                </div>
                                <div class="progress">
                                    <div class={"progress-bar progress-bar-animated" + (this.state.zipError ? " bg-danger": "")} role="progressbar" aria-valuenow={this.state.zipProgress} aria-valuemin="0" aria-valuemax="100" style={{"width": this.state.zipProgress+"%"}}></div>
                                </div>
                                <div className="mt-1 text-center h4">{this.state.zipProgress}%</div>
                                <div className="text-danger mt-3 text-center">{this.state.zipError}</div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {!(this.state.zipLoading || this.state.zipDownloading || !this.state.zipUrl) &&
                            <Button color="primary" RootComponent="a" href={this.state.zipUrl} disabled={this.state.zipLoading || this.state.zipDownloading || !this.state.zipUrl} loading={this.state.zipLoading || this.state.zipDownloading}>Download</Button>
                        }
                        <Button color="secondary" onClick={this.toggleModalDownload}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default Downloader;
