import { authHeader } from "../_helpers";
import { userService } from "./"
import {defineConfig} from '../config'

export const statsService = {
  getStatsNumbers,
  getStatsMOSorted,
  getStatsCertificationByCampaign,
  getMediaReportByCampaign,
  getMediaReportByMediaOwner,
  getMediaReportByRegion,
  getMediaReportByDistrict,
  getMediaReportByCity,
  getMediaReportBySegment,
  getCampaignNumbersByMO,
  getAllAdvertiser,
  initializeData
};

const {apiUrl, apiVer} = defineConfig.STATS_SERVICE

function getStatsNumbers() {
  const requestOptions = {
    method: "GET",
    // headers: authHeader(),
  };

  return fetch(apiUrl+apiVer+"/stats/find/numb", requestOptions)
  .then(handleResponse);
}

function getStatsMOSorted(what) {
    const requestOptions = {
      method: "GET",
      // headers: authHeader(),
    };

    return fetch(apiUrl+apiVer+"/stats/find/stats/top/"+what, requestOptions)
    .then(handleResponse);
}

function getAllAdvertiser() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(apiUrl+apiVer+"/stats/advertisers", requestOptions)
  .then(handleResponse);
}

function getStatsCertificationByCampaign(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(apiUrl+apiVer+"/stats/find/certification/idc/"+id, requestOptions)
  .then(handleResponse);
}

function initializeData() {
  const requestOptions = {
    method: "GET",
    // headers: authHeader(),
  };

  return fetch(apiUrl+apiVer+"/stats/initializeData", requestOptions)
  .then(handleResponse);
}

function getMediaReportByMediaOwner(year, adv) {
  let headers = authHeader();
  if(adv){
    headers.X_role = "advertiser"
    headers.X_group = adv
  }
  else{
    headers.X_group = ""
  }

  const requestOptions = {
    method: "GET",
    headers: headers,
  };

  return fetch(apiUrl+apiVer+"/stats/mo/year/"+year, requestOptions)
  .then(handleResponse);
}

function getMediaReportBySegment(year, query, adv) {
  query = query.trim() == '' ? "%2A" : query.trim()

  let headers = authHeader();
  if(adv){
    headers.X_role = "advertiser"
    headers.X_group = adv
  }
  else{
    headers.X_group = ""
  }

  if(headers.X_role == "mediaOwner"){
    query = headers.X_group
  }

  const requestOptions = {
    method: "GET",
    headers: headers,
  };

  return fetch(apiUrl+apiVer+"/stats/segment/year/"+year+"/mo/"+query, requestOptions)
  .then(handleResponse);
}

function getMediaReportByRegion(year, query, adv) {
  query = query.trim() == '' ? "%2A" : query.trim()

  let headers = authHeader();
  if(adv){
    headers.X_role = "advertiser"
    headers.X_group = adv
  }
  else{
    headers.X_group = ""
  }

  if(headers.X_role == "mediaOwner"){
    query = headers.X_group
  }

  const requestOptions = {
    method: "GET",
    headers: headers,
  };

  return fetch(apiUrl+apiVer+"/stats/region/year/"+year+"/mo/"+query, requestOptions)
  .then(handleResponse);
}

function getMediaReportByDistrict(year, query, adv) {
  query = query.trim() == '' ? "%2A" : query.trim()
  let headers = authHeader();
  if(adv){
    headers.X_role = "advertiser"
    headers.X_group = adv
  }
  else{
    headers.X_group = ""
  }

  if(headers.X_role == "mediaOwner"){
    query = headers.X_group
  }

  const requestOptions = {
    method: "GET",
    headers: headers,
  };

  return fetch(apiUrl+apiVer+"/stats/district/year/"+year+"/mo/"+query, requestOptions)
  .then(handleResponse);
}

function getMediaReportByCity(year, query, adv) {
  query = query.trim() == '' ? "%2A" : query.trim()
  let headers = authHeader();
  if(adv){
    headers.X_role = "advertiser"
    headers.X_group = adv
  }
  else{
    headers.X_group = ""
  }

  if(headers.X_role == "mediaOwner"){
    query = headers.X_group
  }

  const requestOptions = {
    method: "GET",
    headers: headers,
  };

  return fetch(apiUrl+apiVer+"/stats/city/year/"+year+"/mo/"+query, requestOptions)
  .then(handleResponse);
}

function getMediaReportByCampaign(year, query, adv) {
  query = query.trim() == '' ? "%2A" : query.trim()
  let headers = authHeader();
  if(adv){
    headers.X_role = "advertiser"
    headers.X_group = adv
  }
  else{
    headers.X_group = ""
  }

  if(headers.X_role == "mediaOwner"){
    query = headers.X_group
  }

  const requestOptions = {
    method: "GET",
    headers: headers,
  };

  return fetch(apiUrl+apiVer+"/stats/campaign/year/"+year+"/mo/"+query, requestOptions)
  .then(handleResponse);
}

function getCampaignNumbersByMO(year, query, adv) {
  let headers = authHeader();
  if(adv){
    headers.X_role = "advertiser"
    headers.X_group = adv
  }
  else{
    headers.X_group = ""
  }

  if(headers.X_role == "mediaOwner"){
    query = headers.X_group
  }

  const requestOptions = {
    method: "GET",
    headers: headers,
  };

  return fetch(apiUrl+apiVer+"/stats/campaignNumber/year/"+year+"/mo/"+query, requestOptions)
  .then(handleResponse);
}



function handleResponse(response) {
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          userService.logout();
          window.location.reload(true);
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      return data;
    });
}
