import React from "react"
import { compose, withProps, withHandlers, withState } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"
import cn from "classnames";
import _ from "lodash";
import "./Map.scss";
import { segmentDictionary } from "../../_helpers"

import iconSuccess0 from "./icons/green.png"
import iconWrong1 from "./icons/red.png"
import iconToBeVerified2 from "./icons/yellow.png"
import iconNotChecked3 from "./icons/grey.png"

// import iconSegment1 from "./icons/blue-1.png"
// import iconSegment2 from "./icons/blue-2.png"
// import iconSegment3 from "./icons/blue-3.png"
// import iconSegment4 from "./icons/blue-4.png"

import iconSuccess0_active from "./icons/green-active.png"
import iconWrong1_active from "./icons/red-active.png"
import iconToBeVerified2_active from "./icons/yellow-active.png"
import iconNotChecked3_active from "./icons/grey-active.png"

// import iconSegment1_active from "./icons/blue-1-active.png"
// import iconSegment2_active from "./icons/blue-2-active.png"
// import iconSegment3_active from "./icons/blue-3-active.png"
// import iconSegment4_active from "./icons/blue-4-active.png"

import iconCluster from "./icons/cluster.png"

import iconPOI from "./icons/poi.png"

import {Button, Dimmer} from "tabler-react";
import MapCustomControl from "./MapCustomControl.react"

// import jsonStyles from "./styles/default.json"
import jsonStyles from "./styles/style-01.json"

const iconsSegment = require.context('./icons/segment', true);

const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");

const containerClasses = cn("GoogleMapContainer");
const apiKey = "AIzaSyDk2tsrjgOei53e0A5X8J7nFfj-MiDymIY"



const defaultMapOptions = {
  fullscreenControl: false,
  streetViewControl: false,
  mapTypeControl: false,
  styles: jsonStyles
};

const minimalMapOptions = {
  fullscreenControl: false,
  streetViewControl: false,
  zoomControl: true,
  mapTypeControl: false
};

const clusterStyles = [
  {
    textColor: 'black',
    url: iconCluster,
    height: 30,
    width: 30
  },
  {
    textColor: 'black',
    url: iconCluster,
    height: 40,
    width: 40
  },
  {
    textColor: 'black',
    url: iconCluster,
    height: 50,
    width: 50
  }
];

const refs = {}
const MyMapComponent = compose(
  // withProps({
  //   googleMapURL: "https://maps.googleapis.com/maps/api/js?key="+apiKey+"&v=3.exp&libraries=geometry,drawing,places",
  //   loadingElement: <div style={{ height: `100%` }} />,
  //   containerElement: <div className={containerClasses} />,
  //   mapElement: <div style={{ height: `100%` }} />,
  // }),
  withHandlers({
    onMarkerClustererClick: () => (markerClusterer) => {
      const clickedMarkers = markerClusterer.getMarkers()
      // console.log(`Current clicked markers length: ${clickedMarkers.length}`)
      // console.log(clickedMarkers)
    },
    zoomToMarkers: () => (map, props)  => {
      if(map){
        // console.log("Zoom to markers");
        const bounds = new window.google.maps.LatLngBounds();
        map.props.children.forEach((child) => {
          if (child.type !== 'div' && child) {
            child.forEach((child) => {
              if (child.type === Marker) {
                bounds.extend(new window.google.maps.LatLng(child.props.position.lat, child.props.position.lng));
              }
            })
          }
        })
        map.fitBounds(bounds);
      }
      return props.session
    },
    getIconMarker: () => (item, index, props) => {
      let urlIcon, sizeIcon;
      switch (props.markerMode){
        case "cert":
          urlIcon = item.resultKinId == 0 ? ((index == props.selected) ? iconSuccess0_active : iconSuccess0)
            : item.resultKinId == 1 ? ((index == props.selected) ? iconWrong1_active : iconWrong1)
              : item.resultKinId == 2 ? ((index == props.selected) ? iconToBeVerified2_active : iconToBeVerified2)
                : ((index == props.selected) ? iconNotChecked3_active : iconNotChecked3)
          // sizeIcon = ((index == props.selected) ? new window.google.maps.Size(25,25) : new window.google.maps.Size(25,25))
          break;
        case "segment":
          let segment = segmentDictionary(item.segmentDesc)
          // let label = segment ? segment.label.toUpperCase() : segmentsLab[i]
          let icon = segment ? ((index == props.selected) ? segment.iconSelected : segment.icon): "9-others-01.png"

          urlIcon = iconsSegment('./' + icon);
          break;
      }
      sizeIcon = ((index == props.selected) ? new window.google.maps.Size(16,16) : new window.google.maps.Size(15,15))
      return {url: urlIcon, scaledSize: sizeIcon}
    },
    handlerCenterPosition: () => (props, places) => {
      // console.log(places.length + " places trovati")
      // console.log(places)
      let arrayPlaces = []
      for(let i=0; i<places.length;i++){
        let lat = places[i].geometry.location.lat()
        let lng = places[i].geometry.location.lng()
        let name = places[i].name
        let address = places[i].formatted_address
        arrayPlaces.push({lat, lng, name, address})
      }
      props.setPositionPOI(arrayPlaces)
    },
    onMapMounted: ref => {
      refs.map = ref;
    },
    loadCustomControls: () => (props) =>{
      let controls
      if(!props.minimal){
        controls = (
          <div>

            <div className="panelsmap-button-group panelsmap-button-group-top p-3">
              <Button.List>
                <Button color="secondary" onClick={props.onClusterSwitchClick} icon={props.isClustered ? 'move' : 'target'}>
                  {props.isClustered ? "Disable Cluster" : " Enable Cluster"}
                </Button>
                <Button
                  color={props.markerMode == "cert" ? "primary" : "secondary"}
                  className="mr-1"
                  // outline={props.markerMode == "cert" ? true : false}
                  name="mode"
                  value="cert"
                  onClick={(ev) => props.onSwitchModeClick("cert")}
                >
                  Certification
                </Button>
                <Button
                  color={props.markerMode == "segment" ? "primary" : "secondary"}
                  className=""
                  // outline={props.markerMode == "segment" ? true : false}
                  name="mode"
                  value="segment"
                  onClick={(ev) => props.onSwitchModeClick("segment")}
                >
                  Segment
                </Button>
                <MapCustomControl handlerCenterPosition={(places)=>props.handlerCenterPosition(props, places)} bounds={refs.map && (true || console.log(refs)) ? refs.map.getBounds() : console.log(refs)}/>
                {props.positionsPOI.length>0 &&
                  <Button
                    color="secondary"
                    className="bg-white text-danger"
                    name="empty-poi"
                    icon="trash-2"
                    value="empty-poi"
                    title="Delete all POI"
                    onClick={(ev) => props.deletePositionPOI()}
                  />
                }
              </Button.List>
            </div>

            {props.markerMode === "segment" ? (
                <>
                  {/* TODO: riattivare con marker nuovi */}
                  {/* <div className="panelsmap-button-group panelsmap-button-group-bottom opacity-8 bg-white border shadow-lg p-2 mb-6 ml-3">
                    {props.loadLegendSegment(props)}
                  </div> */}
                </>
              ) : 
              (
                <div className="panelsmap-button-group panelsmap-button-group-bottom opacity-8 bg-white border shadow-lg p-2 mb-6 ml-3">
                  {props.loadLegendCert()}
                </div>
              )
            }
            
          </div>
        )
      }
      else{
        controls = ""
      }
      return controls
    },
    loadLegendCert: ()=> () => {
      const certsLab = [
        "CORRECT",
        "WRONG",
        "TO BE VERIFIED",
        "NOT TRACKED"
      ]
      let listLeg = []
      for(let i=0; i<certsLab.length; i++){
        switch(i){
          case 0:
            listLeg.push(<div><img src={iconSuccess0} className="legend-icon img-fluid"/> <span className="legend-text">{certsLab[i]}</span></div>)
            break;
          case 1:
            listLeg.push(<div><img src={iconWrong1} className="legend-icon img-fluid"/> <span className="legend-text">{certsLab[i]}</span></div>)
            break;
          case 2:
            listLeg.push(<div><img src={iconToBeVerified2} className="legend-icon img-fluid"/> <span className="legend-text">{certsLab[i]}</span></div>)
            break;
          default:
            listLeg.push(<div><img src={iconNotChecked3} className="legend-icon img-fluid"/> <span className="legend-text">{certsLab[i]}</span></div>)
        }
      }
      return listLeg
    },
    loadLegendSegment: ()=> (props) => {
      const segmentsLab = props.segment
      let listLeg = []
      for(let i=0; i<segmentsLab.length; i++){
        let segment = segmentDictionary(segmentsLab[i])
        let label = segment ? segment.label.toUpperCase() : segmentsLab[i]
        label = label == "" ? segmentsLab[i] : label
        let icon = segment ? segment.icon : "9-others-01.png"
        let iconB64 = iconsSegment('./' + icon) || null;

        listLeg.push(<div><img src={iconB64} className="legend-icon img-fluid"/> <span className="legend-text">{label}</span></div>)
      }
      return listLeg
    },
  }),
  withScriptjs,
  withGoogleMap
)((props) =>
  <GoogleMap
    defaultZoom={props.zoom}
    defaultCenter={props.firstPosition}
    options={props.minimal ? minimalMapOptions : defaultMapOptions}
    key={"mode_"+ props.markerMode + "_" + props.session}
    ref={(map) =>
      ((props.onMapMounted || true) && !props.isClustered && !props.minimal && props.autozoom) ? props.zoomToMarkers(map, props) : console.log("zoom inibito")
    }
  >
    {props.loadCustomControls(props)}
    {props.isClustered && !props.minimal ?
      <div key={props.session}>
        <MarkerClusterer
          onClick={props.onMarkerClustererClick}
          minimumClusterSize={10}
          averageCenter
          enableRetinaIcons
          gridSize={60}
          maxZoom={14}
          key={props.session}
          clusterClass={'cluster-markers'}
          styles={clusterStyles}
        >
          {props.positions.map((item,index) => {
            const objMarker = props.getIconMarker(item, index, props)
            return (
              <Marker
                key={props.markerMode + "_" + props.session+index+"_"+item.cimasa+'_'+item.lat+'_'+item.lng}
                position={{ lat: item.lat, lng: item.lng }}
                onClick={(ev) => props.onMarkerClick(ev, index, item.seqId)}
                title={item.segmentDesc + ' - ' + item.mediaOwner + ' - ' + item.panelType}
                name={item.region + ' - ' + item.municipality}
                icon={objMarker}
              />
            )
          })}
        </MarkerClusterer>
      </div>
      :
          props.positions.map((item,index) => {
          const objMarker = props.getIconMarker(item, index, props)
          return (
              <Marker
                key={props.markerMode + "_" + props.session+index+"_"+item.cimasa+'_'+item.lat+'_'+item.lng}
                position={{ lat: item.lat, lng: item.lng }}
                onClick={(ev) => props.onMarkerClick(ev, index, item.seqId)}
                title={item.segmentDesc + ' - ' + item.mediaOwner + ' - ' + item.panelType}
                name={item.region + ' - ' + item.municipality}
                icon={objMarker}
              />
          )})
    }
    {props.positionsPOI.map((item,index) => {
      let sizeIcon = new window.google.maps.Size(30,30)
      let objMarkerPOI = {url: iconPOI, scaledSize: sizeIcon}

      return (
        <Marker
          key={'poi_'+index+'_'+item.lat+'_'+item.lng}
          position={{ lat: item.lat, lng: item.lng }}
          // onClick={props.onToggleOpen}
          title={'POI: '+item.name + ' - Address: ' + item.address}
          icon={objMarkerPOI}
        >
          {/* {props.isOpen &&
            <InfoWindow onCloseClick={props.onToggleOpen}>
              <div>Info Marker</div>
            </InfoWindow>
          } */}
        </Marker>
    )})}

  </GoogleMap>
)



class PanelsMap extends React.PureComponent {
  constructor(props){
    super(props)

    this.state = {
      isMarkerShown: true,
      isClustered: false,
      selectedIndex: -1,
      session: "initial-session",
      lastSession: "-",
      markerMode: "cert",
      segment: null,
      dataPOI: [],
      fullscreen: false
    }

    this.toggleFullscreen = this.toggleFullscreen.bind(this)
  }

  componentDidMount() {
    this.delayedShowMarker()
  }

  delayedShowMarker = () => {
    setTimeout(() => {
      this.setState({ isMarkerShown: true })
    }, 1000)
  }

  handleMarkerClick = (ev, index, seqId) => {
    if(!this.props.minimal){
      this.setState({
        autozoom: false,
      },()=>this.props.handleState(ev.latLng, index, seqId))

    }
  }

  handleSwitchClusterClick = (ev) => {
    this.setState((state) => ({
      isClustered: !state.isClustered,
      session: "session_"+Math.random()+"-"+Math.floor(Date.now() / 1000)
    }))
  }

  handleSwitchModeClick = (mode) => {
    this.setState((state) => ({
      markerMode: mode,
      autozoom: true,
      session: "session_"+Math.random()+"-"+Math.floor(Date.now() / 1000)
    }))
  }

  setPositionPOI = (places) => {
    // console.log("places")
    // console.log(places)
    this.setState((state) => ({
      dataPOI: [...state.dataPOI, ...places],
      // dataPOI: places,
      autozoom: true,
      session: "session_"+Math.random()+"-"+Math.floor(Date.now() / 1000)
    }))
  }

  deletePositionPOI = () => {
    this.setState((state) => ({
      dataPOI: [],
      autozoom: true,
      session: "session_"+Math.random()+"-"+Math.floor(Date.now() / 1000)
    }))
  }

  componentWillReceiveProps(nProps){
    if(nProps.data != this.props.data){
      // console.log("PanelMaps: nuovi dati")
      const session = "session_"+Math.random()+"-"+Math.floor(Date.now() / 1000)
      this.setState({
        session: session,
        lastSession: "-",
        selected: -1,
        autozoom: true,
      },()=>
        this.props.handleState({
          lng: function(){return null},
          lat: function(){return null}
        }, -1)
      )
    }
    if(nProps.segment != this.props.segment){
      this.setState({
        segment: nProps.segment
      })
    }
  }

  toggleFullscreen(){
    let fullscreen = !this.state.fullscreen
    this.setState({
        fullscreen
    })
  }

  initSegment(panels){
    let segments = _.groupBy(panels, "segmentDesc")
    let segmentsLab = _.keys(segments);
    return segmentsLab
  }
  render() {
    const minimal = this.props.minimal || false;
    const zoom = this.props.zoom || 6;
    let firstPosition = {lat: null, lng: null}, data = [];

    const selected = this.props.selected

    if(typeof this.props.data !== "undefined"){
      data = this.props.data;
      if(Array.isArray(data)){
        firstPosition = data[0]
      }
      else{
        firstPosition = data
      }

      return (
        data.length > 0 ?
          <div className={(this.state.fullscreen ? "gmaps-custom-fullscreen" : "gmaps-custom")}
          // key={this.state.session}
          >
            <Button color="secondary" icon={!this.state.fullscreen ? "maximize":"minimize"} className="gmaps-custom-btn mr-3" onClick={()=>this.toggleFullscreen()}/>
            <MyMapComponent
              key={this.state.session}
              googleMapURL={"https://maps.googleapis.com/maps/api/js?key="+apiKey+"&v=3.exp&libraries=geometry,drawing,places"}
              loadingElement={<div style={{ height: `100%`, paddingTop:"20%"}}><Dimmer active loader className={"gmaps-custom-component gmaps-custom-loading"} ></Dimmer><br/></div>}
              containerElement={<div className={"GoogleMapContainer "+(this.state.minimal ? "gmaps-custom-component-minimal": "gmaps-custom-component")}/>}
              mapElement={<div style={{ height: `100%` }} />}
              lastSession={this.state.lastSession}
              session={this.state.session}
              isMarkerShown={this.state.isMarkerShown}
              onMarkerClick={this.handleMarkerClick}
              isClustered={this.state.isClustered}
              onClusterSwitchClick={this.handleSwitchClusterClick}
              onSwitchModeClick={this.handleSwitchModeClick}
              minimal={minimal}
              zoom={zoom}
              autozoom={this.state.autozoom}
              markerMode={this.state.markerMode}
              positions={data}
              positionsPOI={this.state.dataPOI}
              setPositionPOI={this.setPositionPOI}
              deletePositionPOI={this.deletePositionPOI}
              firstPosition={firstPosition}
              selected={selected}
              segment={this.initSegment(data)}
            />
          </div>
          : <div className="col-12"><h4 className="text-center pt-6">No result found</h4></div>
      )
    }
    else {
      return (
        <Dimmer active={true} loader={true}>
          <div className="p-9"></div>
        </Dimmer>
      )
    }
  }
}

export default PanelsMap;
