// @flow

import * as React from "react";
import {Card} from "tabler-react"
import AntdConfig from 'react-awesome-query-builder/lib/config/antd';
import {Query, Builder, BasicConfig, Utils as QbUtils} from 'react-awesome-query-builder';
import 'react-awesome-query-builder/css/antd.less'; // or import "antd/dist/antd.css";
import 'react-awesome-query-builder/lib/css/styles.css';
// import 'react-awesome-query-builder/css/antd.less';
// import "antd/dist/antd.css";
// import 'react-awesome-query-builder/css/styles.scss';
// import 'react-awesome-query-builder/css/compact_styles.scss'; //optional, for more compact styles
import "./SearchBuilder.scss"

const InitialConfig = AntdConfig; // or MaterialConfig or BasicConfig

// You can load query value from your backend storage (for saving see `Query.onChange()`)
const queryValue = {"id": QbUtils.uuid(), "type": "group"};


class SearchBuilder extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      searchQuery: null,
      tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), {}),
      config: {
        ...InitialConfig,
        fields: {}
      },
      defaultQuery: queryValue,
      loading: false
    }
    this.setSearchQuery = this.setSearchQuery.bind(this)
  }

  componentDidMount(){
    const {searchQuery, fields, jsonLogic} = this.props
    const searchQueryOnMount = jsonLogic !== null ?  {logic: jsonLogic} : searchQuery ? searchQuery : null
    this.setState({
      config: {
        ...InitialConfig,
        fields
      }
    },()=>this.setSearchQuery(searchQueryOnMount))
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.fields !== this.props.fields){
      let {fields, searchQuery} = nextProps
      this.setState({
        config: {
          ...InitialConfig,
          fields: fields
        }
      }
      // ,()=>this.setSearchQuery(searchQuery)
      )
    }
    if(nextProps.searchQuery !== this.props.searchQuery){
      const {searchQuery} = nextProps
      this.setSearchQuery(searchQuery)
    }
  }

  setSearchQuery(searchQuery){
    if(searchQuery === null) {
      this.setState((state)=>({
        tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), state.config),
        loading: false
      }))
    }
    else{
      this.setState((state)=>({
        tree: QbUtils.checkTree(QbUtils.loadTree(searchQuery.logic), state.config),
        loading: false
      }))
    }
  }

  renderBuilder = (props) => (
    <div className="query-builder-container planner-query-builder-container">
      <div className="query-builder planner-query-builder">
        <Builder {...props} />
       </div>
    </div>
  )

  renderResult = ({tree: immutableTree, config}) => (
    <Card
      isCollapsed
      isCollapsible
      title="Debug dialog"
    >
      <Card.Status color="orange" side />
      <Card.Body>
        <div className="query-builder-result">
          {/* <div>Query string: <pre>{JSON.stringify(QbUtils.queryString(immutableTree, config))}</pre></div> */}
          <div>MongoDb query: <pre>{JSON.stringify(QbUtils.mongodbFormat(immutableTree, config))}</pre></div>
          <div>SQL where: <pre>{JSON.stringify(QbUtils.sqlFormat(immutableTree, config))}</pre></div>
          <div>JsonLogic: <pre>{JSON.stringify(QbUtils.jsonLogicFormat(immutableTree, config))}</pre></div>
        </div>
      </Card.Body>
    </Card>
  )

  onChange = (immutableTree, config) => {
    this.props.handleChange()
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    this.setState({
      tree: immutableTree,
      config: config
    },()=>this.getQuery(immutableTree, config));
  }

  getQuery = (immutableTree, config) => {
    let queryMongo = QbUtils.mongodbFormat(immutableTree, config)
    let queryLogic = QbUtils.getTree(immutableTree)

    if(!queryMongo && queryLogic){
      queryLogic =  JSON.stringify({
        id: queryLogic.id,
        type: queryLogic.type
      })

      queryMongo = null
    }
    else{
      queryMongo = JSON.stringify(queryMongo)
      queryLogic = JSON.stringify(queryLogic)
    }

    this.props.handleMongo(queryMongo)
    this.props.handleJson(queryLogic)
  }

  render(){
    return(
      <div>
        <Query
          {...this.state.config}
          value={this.state.tree}
          onChange={this.onChange}
          renderBuilder={this.renderBuilder}
        />
        {/* {this.state.jsonTree} */}
        {this.state.loading && <div className="alert alert-info">Loading...</div>}
      </div>
    )
  }
}

export default SearchBuilder;
