import * as React from "react";
import Wkt from "wicket"
import {geoInfoService} from '../_services'

import triangleCoordsMKT from "./MapPolygon.mkt.json"

const triangleCoords = triangleCoordsMKT.map((item)=>{
  let wkt = new Wkt.Wkt();
  wkt.read(item);

  const polygon = wkt.components[0][0].map((c)=>{
    return{
      lng: c.x,
      lat: c.y
    }
  })
  return polygon
})

const colorTriangle = [
  "#b61827",
  "#ef5350",
  "#ff867c",
  // "#00FF00",
  // "#0000FF",
  // "#FF00FF",
  // "#FFFF00",
  // "#00FFFF",
]

const { compose, withProps } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polygon
} = require("react-google-maps");

const apiKey = geoInfoService.getApiKeyGMaps()

const MapWithADrawingManager = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key="+apiKey+"&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `800px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(props =>
  <GoogleMap
    defaultZoom={12}
    defaultCenter={new window.google.maps.LatLng(45.4568735670558, 9.18301456491842)}
  >
    {
      triangleCoords.map((item, index)=>{
        return <Polygon
          path={item}
          key={1}
          editable={false}
          options={{
            strokeColor: colorTriangle[index%3],
            strokeOpacity: 0.9,
            strokeWeight: .5,
            fillColor: colorTriangle[index%3],
            fillOpacity: 0.15
          }}
        />
      })
    }
  </GoogleMap>
);
export default MapWithADrawingManager;
