import React, { useEffect, useState } from 'react';
import HeatMapTableAudience from '../Planner/Planner.HeatMapTableAudience.react';
import SyncingChartsAudience from '../Planner/Planner.SyncingChartsAudience.react';
import { audienceService } from '../_services';
import { message } from 'antd';
import CompareDays from '../Planner/Planner.CompareDays';

export default function DoohInsight ({
    ots = 0,
    numberFrames = 0,
    audienceFilter = null,
    faceIds = [],
    allData = []
}){
    const [penetrationRate, setPenetrationRate] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect( () => {
        if(!audienceFilter)
            return;

        calculatePenetrationRate();
    }, [])

    const calculatePenetrationRate = () => {
        setLoading(true);
        audienceService.getIndexesAudienceByIds(faceIds, audienceFilter.id, "dooh").then(
            result => {
                const sumTargetUser = result.reduce((a, b) => a + b.targetUserSeen, 0);
                const sumTotalUser = result.reduce((a, b) => a + b.totalUserSeen, 0);

                console.log("sumTargetUser/sumTotalUser", sumTargetUser, sumTotalUser)

                setPenetrationRate((sumTargetUser/sumTotalUser) * 100);
                setLoading(false);
            },
            error => {
                message.error("Error: " + error)
                setLoading(false);
            }
        )
    }

    const calculateAverageIndex = (items) => {
        const average = items.reduce((avg, {index}, _, panels) => {
          const indexed = panels.filter(({index}) => index !== null).length
          if(index != null){
            return avg + index / indexed;
          }
          return avg;
        }, 0);
        return average.toFixed(2).toString().replace('.',',');
    }

    return (
        audienceFilter ? <>
            <div className="row border-bottom m-0 text-center">
                <div className="card-counters-report col pt-2">
                    <p>OTS</p>
                    <h2 className="text-center">{ots}</h2>
                </div>
                <div className="card-counters-report col pt-2 bg-light">
                    <p>Frames</p>
                    <h2 className="text-center">{numberFrames}</h2>
                </div>
                <div className="card-counters-report col pt-2">
                    <p>Penetration Rate</p>
                    <h2 className="text-center">{penetrationRate.toFixed(2).toString().replace('.',',')}%</h2>
                </div>
                <div className="card-counters-report col pt-2 bg-light">
                    <p>Average Index</p>
                    <h2 className="text-center">{calculateAverageIndex(allData)}</h2>
                </div>
            </div>

            <div className='row'>
                <div className='col-6 px-5 py-2 border-right border-bottom'>
                    {/* <h4>{`Heatmap of ${faceIds.length} Face Ids`}</h4> */}
                    <HeatMapTableAudience
                        partial="totalUserSeen"
                        faceIds={faceIds}
                        audienceFilter={audienceFilter}
                        type='dooh'
                        extra={<strong className='text-uppercase'>Heatmap of {faceIds.length} Face Ids</strong>}
                    />
                </div>
                <div className='col-6 px-5 py-2 border-bottom'>
                    <HeatMapTableAudience
                        partial="targetUserSeen"
                        faceIds={faceIds}
                        audienceFilter={audienceFilter}
                        type='dooh'
                        extra={<strong className='text-uppercase'>Heatmap of {faceIds.length} Face Ids</strong>}
                    />
                </div>
            </div>

            <div className='row'>
                <div className='col px-3 py-3'>
                    <CompareDays
                        allData={allData}
                        audienceFilter={audienceFilter}
                        assetType={"dooh"}
                        params={["publisher", "type", "installation"]}
                        labels={["Publisher", "Environment", "Type"]}
                        hiddenPeriodFilters={true}
                    />
                </div>
            </div>

        </>
        :
        <h1 className="text-center p-9">No audience selected</h1>
    )
}
