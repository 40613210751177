// @flow

import * as React from "react";
import {Redirect} from "react-router-dom";

import {
  Page,
  Grid,
  Card,
} from "tabler-react";


import SiteWrapper from "../SiteWrapper.react";
import PoiManage from "./Filters/PoiManage.react"

class PoiGroupManage extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      isKineticUser: true
    }
  }

  componentWillMount(){
    this.getLocalUserInfo()
  }

  getLocalUserInfo(){
    if(localStorage.getItem("type") === "admin" || localStorage.getItem("type") === "user" || localStorage.getItem("type") == "companyleader" || localStorage.getItem("type") == "companyuser"){
      this.setState({
        isKineticUser: true
      })
    }
    else{
      this.setState({
        isKineticUser: false,
      })
    }
  }

  render() {

    if(this.state.error || !this.state.isKineticUser){
      return <Redirect
          to={{ pathname: "/404", state: { from: this.props.location } }}
      />
    }

    return (
      <SiteWrapper {...this.state}>
        <Page.Content>
          <Page.Header
            title="Poi Manager"
            // subTitle={this.state.totalItemsCount + " PoiGroups found"}
          />
          <Grid.Row cards deck>
            <Grid.Col sm={12}>
              <Card className="p-4">
                <PoiManage/>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      </SiteWrapper>
    );
  }
}

export default PoiGroupManage;
