// @flow

import * as React from "react";

import {
  Page,
  Grid,
  Card
} from "tabler-react";

import SiteWrapper from "../SiteWrapper.react";

import MapPolygonTest from "./MapPolygon.component"

class MapPolygon extends React.Component {
  render() {
    return (
      <SiteWrapper {...this.state}>
        <Page.Content>
          <Page.Header title="Audience - Istat Cells"/>
          <Grid.Row cards deck>
            <Grid.Col sm={12}>
              <Card.Map className="bg-light">
                <MapPolygonTest/>
              </Card.Map>
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      </SiteWrapper>
    )
  }
}

export default MapPolygon;
