export function formatNumber(num, formatted = true, round = false, percentage = false) {
    if(percentage){
        num = num * 100
    }
    if(round){
        num = Math.round(num)
    }
    if(formatted){
        num = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }
    
    if(percentage){
        num = num + '%'
    }

    return num
}