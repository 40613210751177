// @flow

import * as React from "react";
import _ from "lodash"
import {Redirect} from "react-router-dom";
import {Drawer} from 'antd';

import {
  Page,
  Grid,
  Card,
  Dropdown,
  Table,
  Form,
  Container,
  Tab,
  TabbedCard,
  Nav,
  Icon,
  Button,
  Dimmer,
  colors
} from "tabler-react";

import { StickyContainer, Sticky } from 'react-sticky';

import SiteWrapper from "../SiteWrapper.react";
// import {campaignsController} from "./Campaigns.controller"
import CampaignsSummary from "./Campaigns.Summary.react";
import CampaignsCertification from "./Campaigns.Certification.react";
import CampaignsPanelInfo from "./Campaigns.PanelInfo.react"
import CampaignsReportFilters from "./Campaigns.ReportFilters.react"
import { campaignService, justificativeService, statsService, imageService } from "../_services";

import PanelsMap from '../components/Map/PanelsMap.react'
import StreetView from "../components/Map/StreetView.react";
import Carousel from "../components/Carousel";
import DownloaderPolling from "../components/DownloaderPolling";
import "./Campaigns.Report.scss"

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class CampaignsMap extends React.Component {
    constructor(props){
        super(props)
        this.state = ({
            id: props.match.params.id,
            campaignInfo: {},
            campaignPanels: [],
            campaignPanelsCert: [],
            panelsAll: [],
            campaignAudit: [],
            campaignAuditAll: [],
            campaignSummary: {
                citiesNum: 0,
                districtNumber: 0,
                moPanelList: [],
                sePanelList:[],
                regionNumber: 0
            },
            moWithCounts: [],
            totalCert:{
                "totalPanels": 0,
                "trackingPanels": 0,
                "correctPanels": 0,
                "wrongPanels": 0,
                "notCheckedPanels": 0
            },
            pIdSelected: -1,
            pSelected:{
                idk: -1,
                idp: -1
            },
            lng: null,
            lat: null,
            photoIndex: 0,
            creativityIsOpen: false,
            zoom: 6,
            creativities : [],
            auditImages: [],
            regions: [],
            districts: [],
            cities: [],
            audit: [],
            segment: null,
            statusFilter: -1,
            isAdvertiser: null,

            zipLoading: false,
            zipUrl: null,
            zipError: null,

            forbidden: false,

            isDownloadingZip: false
        })

        this.handlerMOWithCounts = this.handlerMOWithCounts.bind(this)
        this.handlerStatusFiltered = this.handlerStatusFiltered.bind(this)
        this.getCampaignReport = this.getCampaignReport.bind(this)
        this.getCampaignZipReport = this.getCampaignZipReport.bind(this)
        this.handlerSegment = this.handlerSegment.bind(this)
    }

    componentDidMount(){
        this.getLocalUserInfo()
        this.loadCampaignInfo(this.state.id)
        this.loadCampaignSummary(this.state.id)
        this.loadCampaignCertification(this.state.id)
    }

    getLocalUserInfo(){
      const type = localStorage.getItem("type")

      switch(type){
        case "admin":
        case "user":
        case "mediaOwner":
        case "companyleader":
        case "companyuser":
        case "mediaOwner":
          this.setState({
            isAdvertiser: false,
            forbidden: false
          })
          break;
        case "advertiser":
          this.setState({
            isAdvertiser: true,
            forbidden: false
          })
          break;
        default:
          this.setState({
            forbidden: true
          })
      }
    }

    handlerMOWithCounts(moCounts){
        if(moCounts.length>0){
            this.setState({
                moWithCounts: moCounts
            })
        }
    }

    handlerSegment(obj){
        this.setState({
            segment: obj
        })
    }

    passPropsMap = (nProps, index, seqId) => {
        let lng = nProps.lng()
        let lat = nProps.lat()

        this.setState((state, props) => ({
            lng: (state.lat != lat && state.lng != lng) ? lng : null,
            lat: (state.lat != lat && state.lng != lng) ? lat : null,
            pIdSelected: (state.lat != lat && state.lng != lng) ? index : -1,
            // pSelected:{
            //     idk: -1,
            //     idp: -1
            // },
        }))

        if(index >= 0){
            let isKinAudit = (this.state.campaignPanels[index].resultKinId != this.state.campaignPanels[index].resultBmeId) || this.state.campaignPanels[index].lastUpdate
            let isTracked = this.state.campaignPanels[index].resultKinId != 3

            this.setState((state)=>({
                // pIdSelected: index,
                pSelected:{
                    idk: state.campaignInfo.idKinCampaign,
                    idp: state.campaignPanels[index].seqId //da modificare in state.campaignPanels[i].idPanel
                },
                auditImages: (state.campaignPanels[index].kinPhotoCheck ? [this.getValidateUrl(state.campaignPanels[index].kinPhotoCheck)]
                    : state.campaignPanels[index].bmePhotoCheck ? (state.campaignPanels[index].bmePhotoCheck.length > 1 || state.campaignPanels[index].bmePhotoCheckVisibility ? this.getValidateListUrl(state.campaignPanels[index].bmePhotoCheck) : []) : [])
                // auditImages: [] //svuoto
            }), () => this.getPhotosCheckBySeqId(this.state.pSelected.idp, isTracked, isKinAudit)) //prelevo le foto se necessario
        }
    }

    getValidateUrl(img, isCreativity = false){
      return imageService.getUrlPhoto(img, isCreativity)
    }

    getValidateListUrl(imgs){
      for(let i=0; i<imgs.length; i++){
          let img = imgs[i]
          if(img){
            img = this.getValidateUrl(img)
          }
          else{
            img = ""
          }
          imgs[i] = img
      }
      return imgs
    }

    getPhotosCheckBySeqId(seqId, tracked = false, isKin = false){
        if((tracked && this.state.auditImages.length == 0) ){
            this.setState({
                auditImages: [] //svuoto
            },()=>justificativeService.getPhotoCheckBySeq(seqId).then(
                result => {
                    let images = []
                    if(isKin) {
                        for(let i=0; i<result.length; i++){
                            let url = result[i].kinPhotoCert
                            url = this.getValidateUrl(url)
                            images=[url]
                        }
                    }
                    else{
                        for(let i=0; i<result.length; i++){
                            let url = result[i].bmePhoto
                            url = this.getValidateUrl(url)
                            images.push(url)
                        }
                    }

                    this.setState({
                        auditImages: images
                    })
                },
                error => {
                    console.error(error);
                }
            ))

        }
        // else{
            // console.log('Nessun controllo previsto!')
            // this.setState({
            //     auditImages: []
            // })
        // }
    }

    passPropsFilters = (nProps) => {
        this.setState({
            pIdSelected: -1,
            pSelected:{
                idk: -1,
                idp: -1 //da modificare in state.campaignPanels[i].idPanel
            },
            auditImages: [],
            lat: null,
            lng: null,
            selectedRegL: nProps.selectedRegL.trim().toLowerCase(),
            selectedDis: nProps.selectedDis.trim().toLowerCase(),
            selectedCitL: nProps.selectedCitL.trim().toLowerCase(),
            selectedMedOwn: nProps.selectedMedOwn.trim().toLowerCase(),
            selectedFrmt: nProps.selectedFrmt.trim().toLowerCase()
        })

        let idR = nProps.selectedRegL.trim().toLowerCase()
        let prov = nProps.selectedDis.trim().toLowerCase()
        let idC = nProps.selectedCitL.trim().toLowerCase()
        let mo = nProps.selectedMedOwn.trim().toLowerCase()
        let frmt = nProps.selectedFrmt.trim().toLowerCase()
        let status = this.state.statusFilter

        this.loadCampaignInfoFiltered(idR, prov, idC, mo, frmt, status)
    }

    loadAudit(id){
        justificativeService.getAuditFindByCampaignFiltered(id).then(
            result => {
                this.setState({
                    campaignPanels: result,
                    campaignPanelsCert: result,
                    panelsAll: result,
                    audit: result,
                }, ()=> this.loadCreativities())
            },
            error => {
                console.error(error);
            }
        )
    }

    loadCampaignInfo(id){
        campaignService.getCampaignInfo(id).then(
          result => {
            this.setState({
              campaignInfo: result,
            }, () => this.loadAudit(this.state.campaignInfo.idKinCampaign))
          },
          error => {
            console.error(error);
            this.setState({ forbidden: true })
          }
        );
    }

    loadCreativities(){
      let creativity = _.groupBy(this.state.panelsAll, "photosAds")
      creativity = _.keys(creativity)
      creativity = creativity.filter(function(item) {
        return item != "null" && item
      });

      if(creativity.length<=0){
        creativity = _.groupBy(this.state.panelsAll, "creativityDec")
        creativity = _.keys(creativity)
        creativity = creativity.filter(function(item) {
            return item != "null" && item
        });
      }

      for(let i = 0; i< creativity.length; i++){
        creativity[i] = this.getValidateUrl(creativity[i], true)
      }
      this.setState({
        creativities: creativity ? creativity : []
      })
    }

    loadCampaignInfoFiltered(idR, prov, idC, mo, idF, st){
        let appoggio = this.state.campaignPanels
        let panelsAll = this.state.panelsAll
        let filteredPanels = panelsAll.filter(function(item) {
            let region = item.region || ""
            let district = item.district || ""
            let municipality = item.municipality || ""
            let mediaOwner = item.mediaOwner || ""
            let panelType = item.panelType || ""
            let status = item.resultKinId || ""

            let condReg = region.trim().toLowerCase() == idR || !idR
            let condProv = district.trim().toLowerCase() == prov || !prov
            let condCity = municipality.trim().toLowerCase() == idC || !idC
            let condMO = mediaOwner.trim().toLowerCase() == mo || !mo
            let condFormat = panelType.trim().toLowerCase() == idF || !idF
            let condStatus = (status == st || st < 0)

            if (condReg && condProv && condCity && condMO && condFormat && condStatus) {
                return item;
            }
        })
        appoggio = filteredPanels
        this.setState({
            campaignPanels: appoggio
        }, ()=> this.loadCampaignInfoFilteredCert(idR, prov, idC, mo, idF))
    }

    // Crea l'array per il certification (non filtrato per stato)
    loadCampaignInfoFilteredCert(idR, prov, idC, mo, idF){
        let appoggio = this.state.campaignPanelsCert
        let panelsAll = this.state.panelsAll
        let filteredPanels = panelsAll.filter(function(item) {
            let region = item.region || ""
            let district = item.district || ""
            let municipality = item.municipality || ""
            let mediaOwner = item.mediaOwner || ""
            let panelType = item.panelType || ""
            let status = item.resultKinId || ""

            let condReg = region.trim().toLowerCase() == idR || !idR
            let condProv = district.trim().toLowerCase() == prov || !prov
            let condCity = municipality.trim().toLowerCase() == idC || !idC
            let condMO = mediaOwner.trim().toLowerCase() == mo || !mo
            let condFormat = panelType.trim().toLowerCase() == idF || !idF

            if (condReg && condProv && condCity && condMO && condFormat) {
                return item;
            }
        })
        appoggio = filteredPanels
        // this.state.campaignPanels = filtered
        this.setState({
            campaignPanelsCert: appoggio
        })
    }

    loadCampaignSummary(id){
        campaignService.getCampaignSummary(id).then(
          result => {
            this.setState((state) => ({
              campaignSummary: result,
              zoom: result.regionNumber == 1 ? 11: state.zoom
            }))
          },
          error => {
            console.error(error);
          }
        );
    }

    loadCampaignCertification(id){
        statsService.getStatsCertificationByCampaign(id).then(
          result => {
            this.setState({
                totalCert: result
            })
          },
          error => {
            console.error(error);
          }
        );
    }

    getCampaignReport(){
        let id = this.state.campaignInfo.idKinCampaign
        if(id){
            campaignService.getXlsCampaign(id);
        }
    }

    componentWillUnmount() {
        this.timerZip = null;
    }

    pollingCampaignZipReport(id) {
        campaignService.pollingZipCampaign(id).then(
            result => {
                this.timerZip = null;
                this.setState({
                    zipLoading: false,
                    zipUrl: result.url
                })
            },
            error => {
                console.log(error)
            }
        )
    }


    getCampaignZipReport(){
        let id = this.state.campaignInfo.idKinCampaign
        if(id){
            campaignService.getZipCampaign(id).then(
                result => {
                    this.timerZip = setInterval(()=> this.pollingCampaignZipReport(id), 1000);
                    this.setState({
                        zipLoading: true,
                        zipUrl: null
                    })
                },
                error => {
                    this.timerZip = setInterval(()=> this.pollingCampaignZipReport(id), 1000);
                    this.setState({
                        zipLoading: false,
                        zipUrl: null,
                        zipError: "No results: error export Zip"
                    })
                    console.error(error);
                }
            )
        }
    }

    initDownloadCampaignZip(){
        this.setState((prev)=>({
            isDownloadingZip: !prev
        }))
    }

    handlerStatusFiltered(status){
        this.setState({
            pIdSelected: -1,
            pSelected:{
                idk: -1,
                idp: -1 //da modificare in state.campaignPanels[i].idPanel
            },
            auditImages: [],
            lat: null,
            lng: null,
            statusFilter: status
        })

        let idR = this.state.selectedRegL
        let prov = this.state.selectedDis
        let idC = this.state.selectedCitL
        let mo = this.state.selectedMedOwn
        let frmt = this.state.selectedFrmt

        this.loadCampaignInfoFiltered(idR, prov, idC, mo, frmt, status)
    }

    render() {
        if(this.state.forbidden){
          return <Redirect
              to={{ pathname: "/404", state: { from: this.props.location } }}
          />
        }

        const { photoIndex, creativityIsOpen } = this.state;

        let lastUpdate = this.state.campaignInfo.lastUpdate ? this.state.campaignInfo.lastUpdate : '-'
        let title;
        if(this.state.isAdvertiser) {
            title = (
                <React.Fragment>
                    <h1 className="page-title mb-0 mt-0 p-0">{this.state.campaignInfo.advertiser} - {this.state.campaignInfo.campaign}</h1>
                    <h4><small className="mb-0 mt-0 p-0">Panels: {this.state.panelsAll.length} - On Air: {this.state.campaignInfo.startDate} - {this.state.campaignInfo.endDate}</small></h4>
                </React.Fragment>
            )
        }
        else{
            title = (
                <React.Fragment>
                    <h1 className="page-title mb-0 mt-0 p-0">{this.state.campaignInfo.advertiser} - {this.state.campaignInfo.campaign}</h1>
                    <h4><small className="mb-0 mt-0 p-0">Panels: {this.state.panelsAll.length} - On Air: {this.state.campaignInfo.startDate} - {this.state.campaignInfo.endDate} - Last Update: {lastUpdate}</small></h4>
                </React.Fragment>
            )
        }

        const options = (
            <React.Fragment>
                <Button color="link" RootComponent="a" href="/campaigns/">
                    Back to Campaigns
                </Button>
                <Button color="primary" onClick={() => this.setState({ creativityIsOpen: true, photoIndex: 0 })} disabled={this.state.creativities.length<=0}>
                    <Icon prefix="fa" name="image" /> View Creativity
                </Button>
                {creativityIsOpen && (
                    <Lightbox
                        mainSrc={this.state.creativities[photoIndex]}
                        nextSrc={this.state.creativities[(photoIndex + 1) % this.state.creativities.length]}
                        prevSrc={this.state.creativities[(photoIndex + this.state.creativities.length - 1) % this.state.creativities.length]}
                        onCloseRequest={() => this.setState({ creativityIsOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + this.state.creativities.length - 1) % this.state.creativities.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % this.state.creativities.length,
                            })
                        }
                    />
                )}
            </React.Fragment>
        );


        let navItems;
        if(this.state.isAdvertiser) {
            navItems = (
                <React.Fragment>
                    <Nav className="bg-white">
                        <Nav.Item to={"/campaigns/"+this.state.id+"/map"} value="Map" icon="map" active />
                        <Nav.Item to={"/campaigns/"+this.state.id+"/photo"} value="Photo" icon="camera" />
                        <Nav.Item value="Audience" icon="users" disabled />
                        {/* <Nav.Item hasSubNav value="Download" icon="download">
                            <a href="#" className="dropdown-item" onClick={() => this.getCampaignReport()}>Xls format</a>
                        </Nav.Item> */}
                        <Nav.Item to={"#"} value="Download Xls" icon="download" onClick={() => this.getCampaignReport()}/>
                        <DownloaderPolling classButton="nav-link" labelButton=" Download Zip" classIcon={"fe fe-download fa-fw pr-1"} idkc={this.state.campaignInfo.idKinCampaign}/>
                    </Nav>
                </React.Fragment>
            )
        }
        else{
            navItems = (
                <React.Fragment>
                    <Nav className="bg-white">
                        <Nav.Item to={"/campaigns/"+this.state.id+"/map"} value="Map" icon="map" active />
                        <Nav.Item to={"/campaigns/"+this.state.id+"/photo"} value="Photo" icon="camera" />
                        <Nav.Item value="Audience" icon="users" disabled />
                        <Nav.Item to={"/campaigns/"+this.state.id+"/justificative"} value="Manage" icon="edit" />
                        <Nav.Item to={"#"} value="Download Xls" icon="download" onClick={() => this.getCampaignReport()}/>
                        <DownloaderPolling classButton="nav-link" labelButton=" Download Zip" classIcon={"fe fe-download fa-fw pr-1"} idkc={this.state.campaignInfo.idKinCampaign}/>
                    </Nav>
                </React.Fragment>
            )
        }


        if(this.state.campaignInfo != {})
        return (
        <SiteWrapper {...this.state}>
            <Page.Content>
                <Page.Header
                    title={title}
                    options={options}
                />
                <StickyContainer>
                    <Sticky relative={false}>
                    {({ style }) => (
                        <div style={style} className="z-index-1">
                            <Grid.Row cards deck >
                                <Grid.Col sm={12}>
                                    <Card className="bg-light">
                                        <Container>
                                            {navItems}
                                            <CampaignsReportFilters
                                                idC={this.state.id}
                                                handleState={this.passPropsFilters}
                                                idkc={this.state.campaignInfo.idKinCampaign}
                                                panels={this.state.campaignPanels}
                                                panelsAll={this.state.panelsAll}
                                            />
                                        </Container>
                                    </Card>
                                </Grid.Col>
                            </Grid.Row>
                        </div>
                    )}
                    </Sticky>

                    <Grid.Row>
                        <Grid.Col sm={12} className="card-p-0">
                            <TabbedCard initialTab="Summary">
                                <Tab title="Summary">
                                    {(this.state.campaignInfo != {}) ?
                                        <CampaignsSummary data={this.state.campaignSummary} panels={this.state.panelsAll} handlerMOWithCounts={this.handlerMOWithCounts} handlerSegment={this.handlerSegment}/>
                                        : <Dimmer active={true} loader={true}><div className="p-4"></div></Dimmer>
                                    }
                                </Tab>
                                <Tab title="Certification">
                                    <CampaignsCertification all={this.state.panelsAll} filtered={this.state.campaignPanelsCert} handlerStatusFiltered={this.handlerStatusFiltered}/>
                                </Tab>
                                <Tab title="Trend" disabled>
                                    <h1 className="text-center p-9">Not Available</h1>
                                </Tab>
                            </TabbedCard>
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row cards deck>
                        <Grid.Col sm={12} className="mb-5">
                            <Card.Map className="border rounded">
                                <PanelsMap handleState={this.passPropsMap} selected={this.state.pIdSelected} zoom={this.state.zoom} data={this.state.campaignPanels} segment={this.state.segment}/>
                            </Card.Map>
                        </Grid.Col>
                    </Grid.Row>
                    
                    <Drawer
                        // title="Basic Drawer"
                        placement="bottom"
                        closable={false}
                        height={450}
                        onClose={()=>this.setState({lat: null, lng: null})}
                        visible={this.state.lat!==null && this.state.lng!==null}
                        // key={placement}
                    >
                        <Grid.Row>
                            <Grid.Col sm={12} className="card-p-0">
                                {/* <TabbedCard initialTab="Photo Certification"> //TODO
                                    <Tab title="Photo Certification"> */}
                                        <Grid.Row>
                                            <Grid.Col md={4} className="border-right pr-sm-0 pt-0">
                                                <div className="bg-light report-title">
                                                    <h4 className="pl-3 pr-3 pb-2 pt-2">
                                                        <small>Panel Info</small>
                                                    </h4>
                                                </div>
                                                <Grid.Row>
                                                    <Grid.Col md={12} className="text-left pt-2">
                                                        {
                                                            (typeof this.state.campaignPanels !== "undefined")
                                                            ? <CampaignsPanelInfo data={this.state.campaignPanels[this.state.pIdSelected]} />
                                                            : ''
                                                        }
                                                    </Grid.Col>
                                                </Grid.Row>
                                            </Grid.Col>
                                            <Grid.Col md={4} className="card-counters pr-sm-0 pl-sm-0 pt-0 border-right">
                                                <div className="bg-light">
                                                    <h4 className="pl-3 pr-3 pb-2 pt-2 m-0">
                                                        <small>Photo</small>
                                                    </h4>
                                                </div>
                                                <Grid.Row>
                                                    <Grid.Col md={12} className="text-center card-streetview">
                                                        {(this.state.pSelected.idp != -1
                                                            && this.state.pSelected.idk != -1
                                                            && this.state.auditImages.length>0
                                                            && this.state.campaignPanels[this.state.pIdSelected].resultKinId!=3
                                                            && (typeof this.state.campaignPanels[this.state.pIdSelected] !== 'undefined'))
                                                            ?
                                                            <div>
                                                                <Carousel idk={this.state.idk} idp={this.state.idp} images={this.state.auditImages} />
                                                                <p className="mt-2">
                                                                    <span className={
                                                                        this.state.campaignPanels[this.state.pIdSelected].resultKinId==0 ? "status-icon bg-success"
                                                                        : this.state.campaignPanels[this.state.pIdSelected].resultKinId==1 ? "status-icon bg-danger"
                                                                        : this.state.campaignPanels[this.state.pIdSelected].resultKinId==2 ? "status-icon bg-warning"
                                                                        : "status-icon bg-secondary"
                                                                    }></span>

                                                                    <strong>
                                                                        Check date:{' '}
                                                                    </strong>
                                                                    {this.state.campaignPanels[this.state.pIdSelected].checkDate}
                                                                </p>
                                                            </div>
                                                            : <div className="col-12"><h4 className="text-center pt-6">No photos</h4></div>
                                                        }
                                                    </Grid.Col>
                                                </Grid.Row>
                                            </Grid.Col>
                                            <Grid.Col md={4} className="card-counters pl-sm-0 pt-0">
                                                <div className="bg-light">
                                                    <h4 className="pl-3 pr-3 pb-2 pt-2 m-0">
                                                        <small>Street View</small>
                                                    </h4>
                                                </div>
                                                <Grid.Row>
                                                    <Grid.Col md={12} className="text-center">
                                                        <Card.Map className="border p-0 card-streetview">
                                                            <StreetView lat={this.state.lat} lng={this.state.lng} />
                                                        </Card.Map>
                                                    </Grid.Col>
                                                </Grid.Row>
                                            </Grid.Col>
                                        </Grid.Row>
                                    {/* </Tab>
                                    <Tab title="Audience" disabled className="disabled">
                                        <h1 className="text-center p-9">Not Available</h1>
                                    </Tab>
                                </TabbedCard> */}
                            </Grid.Col>
                        </Grid.Row>
                    </Drawer>
                        
               </StickyContainer>
            </Page.Content>
        </SiteWrapper>
        );
    }
}

export default CampaignsMap;
