// @flow

import * as React from "react";
import { Form, Card, Button, StandaloneFormPage, Icon } from "../../../"

class ForgotPasswordPageSuccess extends React.Component {
    render(){
        return (
            <StandaloneFormPage imageURL={"/demo/brand/kinetic.png"}>
                <Form className="card">
                    <Card.Body className="p-6">
                    <Card.Title RootComponent="div" className="text-center"><Icon prefix="fa" name="check text-success" className="fa-fw fa-lg" /> Password reset successful</Card.Title>
                    <Form.Footer className="text-center">
                        <Button color="primary" blocked RootComponent="a" href={'/logout'}>Login now</Button>
                    </Form.Footer>
                    </Card.Body>
                </Form>
                
            </StandaloneFormPage>
        )
    }
}

export default ForgotPasswordPageSuccess;



