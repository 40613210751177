import Dexie from 'dexie';

const kineticDB = (dbname, table) => {
  const db = new Dexie(dbname);
  db.version(1).stores(table);
  db.open();
  return db;
  /* const db = new Dexie(dbname,);
  db.version(1).stores({
    friends: `name,age`
  });

  db.open(); */
};
// insert function
const bulkcreate = (dbtable, data, dropCollection = false) => {
    if(dropCollection){
        dbtable.clear();
    }
    let flag = empty(data);
    if (flag) {
        dbtable.bulkAdd(data);
        console.log('data inserted successfully');
    } else {
        console.log('please provide data..');
    }
    return flag;
};

const empty = object => {
  let flag = false;
  for (const value in object) {
    if (object[value] !== '' && object.hasOwnProperty(value)) {
      flag = true;
    } else {
      flag = false;
    }
  }
  return flag;
};

const getData = async dbtable => {
  let tableData = [];
  await dbtable.count(async count => {
    console.log('count is', count);
    if (count) {
      await dbtable.each(data => {
        tableData = [data, ...tableData];
      });
    } else {
      return tableData;
    }
  });

  return tableData;
};
/* selecting list items and update respectively  */
const getSingleData = async (dbtable, id) => {
  return await dbtable.get(id);
};

const upsertItemData = async (dbtable, id, data) => {
  const ifExists = !!(await getSingleData(dbtable, id));
  if(ifExists){
    console.log('Cart already exists, updating...');
    dbtable.update(id, data);
  }
  else{
    console.log('New cart, creating...');
    bulkcreate(dbtable, [data], false);
  }
};

const updateItemData = (dbtable, id, data) => {
   dbtable.update(id, data);
};

/* delete list itms and delete all */

const deleteItem = (dbtable,id)=>{
  console.log('delete called',id)
  dbtable.delete(id)
}

export default kineticDB;
export { bulkcreate, getData, getSingleData, updateItemData, upsertItemData, deleteItem };
